import * as d3 from "d3";

export const margin = {
  top: 7,
  right: 32,
  bottom: 30,
  left: 32
};

export const timeFormats = {
  long: d3.timeFormat("%H:%M:%S"),
  short: d3.timeFormat("%H:%M")
};
