import React from "react";

import TicketView from "./containers/TicketView";

import withReducers from "Hocs/withReducers";

const Page = () => <TicketView />;

export default withReducers({
  view: () => import("Reducers/tickets/view"),
  region: () => import("Reducers/project/region"),
  subscription: () => import("Reducers/subscription"),
  user: () => import("Reducers/user"),
  environment: () => import("Reducers/environment")
})(Page);
