import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";

import Datatable from "Components/Datatable";
import { BodyCell } from "Components/Datatable/Table.styles";
import { PERMISSIONS } from "Constants/constants";
import EmptyState from "Components/Datatable/EmptyState";

import EditMemberModal from "../../components/EditMemberModal";
import DeleteMemberConfirmationModal from "../../components/DeleteMemberConfirmationModal";
import Email from "../../components/Email";
import Permissions from "../../components/Permissions";

import {
  membersSelector,
  loadNextMembersPage,
  hasMoreMembersSelector,
  loadingListSelector,
  deleteMember as deleteMemberAction,
  openDeleteConfirmModal,
  closeDeleteConfirmModal,
  updateMember,
  openEditModal,
  closeEditModal,
  editedItemSelector,
  editModalStateSelector,
  confirmDeleteModalStateSelector,
  memberErrorSelector
} from "Reducers/organization/member";
import { usersSelector } from "Reducers/user";

import { Tag, DisplayName } from "./styles";

const HEADERS = [
  {
    id: "member_name",
    name: "Name",
    showOnMobile: true,
    width: "30%"
  },
  {
    id: "member_email",
    name: "Email",
    showOnMobile: true,
    width: "30%"
  },
  {
    id: "roles",
    name: "Organization permissions",
    showOnMobile: true,
    width: "40%"
  }
];

const MemberList = ({ organizationId, setAddModelOpen }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const isEditModalOpen = useSelector(editModalStateSelector);
  const isConfirmDeleteModalOpen = useSelector(confirmDeleteModalStateSelector);

  const members = useSelector(state =>
    membersSelector(state, { organizationId })
  );
  const editedMember = useSelector(editedItemSelector);
  const users = useSelector(usersSelector);

  const hasMore = useSelector(state =>
    hasMoreMembersSelector(state, { organizationId })
  );

  const errors = useSelector(state =>
    memberErrorSelector(state, { organizationId })
  );

  const isLoading = useSelector(loadingListSelector);

  const loadMore = () => {
    if (hasMore) {
      dispatch(loadNextMembersPage({ organizationId }));
    }
  };

  const getBodyCell = (header, cellIndex, value) => (
    <BodyCell
      key={`${header.id}-value-${cellIndex}`}
      width={header.width}
      index={cellIndex}
    >
      {value}
    </BodyCell>
  );

  const renderCell = (header, cellIndex, data) => {
    const user = users?.get(data.user_id);

    switch (header.id) {
      case "member_email":
        return getBodyCell(header, cellIndex, <Email>{user?.email}</Email>);
      case "member_name":
        return getBodyCell(
          header,
          cellIndex,
          <DisplayName>
            {`${user?.first_name} ${user?.last_name}`}{" "}
            {data.owner && <Tag>Owner</Tag>}
          </DisplayName>
        );
      case "roles":
        return getBodyCell(
          header,
          cellIndex,
          <Permissions
            permissions={
              data.owner
                ? PERMISSIONS
                : data?.permissions?.map(p => ({
                    value: p,
                    label: intl.formatMessage({ id: `access.${p}` })
                  }))
            }
          />
        );
    }
  };

  const onMenuClick = (index, item) => {
    switch (index) {
      case 0:
        dispatch(openEditModal({ item }));
        break;
      case 1:
        dispatch(openDeleteConfirmModal({ item }));
        break;
    }
  };

  const editMember = permissions => {
    dispatch(updateMember({ permissions, organizationId }));
  };

  const deleteMember = () => {
    dispatch(
      deleteMemberAction({ organizationId, memberId: editedMember?.id })
    );
  };

  const getMenuItems = item => {
    return item?.owner
      ? []
      : [
          intl.formatMessage({ id: "organizations.edit_user" }),
          intl.formatMessage({ id: "organizations.delete_user" })
        ];
  };

  return (
    <>
      <Datatable
        title={intl.formatMessage({ id: "settings.organization_settings" })}
        data={members.valueSeq().toJS()}
        headers={HEADERS}
        hasMore={hasMore}
        isLoading={isLoading}
        loadMore={loadMore}
        rowHeight={50}
        menuWidth={140}
        menuItems={getMenuItems}
        renderCell={renderCell}
        onFilterChange={() => {}}
        onAddButtonClick={() => setAddModelOpen(true)}
        onMenuClick={onMenuClick}
        emptyState={
          <EmptyState>{intl.formatMessage({ id: "no_users" })}</EmptyState>
        }
      />
      <EditMemberModal
        isOpen={isEditModalOpen}
        closeModal={() => dispatch(closeEditModal())}
        editMember={editMember}
        editedMember={editedMember}
        users={users}
        errors={errors}
      />
      <DeleteMemberConfirmationModal
        isOpen={isConfirmDeleteModalOpen}
        closeModal={() => dispatch(closeDeleteConfirmModal())}
        deleteMember={deleteMember}
        editedMember={editedMember}
        users={users}
        errors={errors}
      />
    </>
  );
};

MemberList.propTypes = {
  organizationId: Proptypes.string,
  setAddModelOpen: Proptypes.func
};

export default MemberList;
