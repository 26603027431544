import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { LiveMessage } from "react-aria-live";
import { List } from "immutable";

import useDecodedParams from "Hooks/useDecodedParams";

import SubNavBar from "Components/SubNavBar";

import ServiceNode from "../ServiceNode";

import * as S from "./styles";

const ServiceInfoPanel = ({ children }) => {
  const { pathname } = useLocation();
  const {
    environmentId,
    organizationId,
    projectId,
    appName = "routes"
  } = useDecodedParams();

  const data = useSelector(state =>
    state.service?.getIn([
      "data",
      organizationId,
      projectId,
      environmentId,
      "current",
      appName
    ])
  );
  const workers = useSelector(state =>
    state.service
      ?.getIn(
        [
          "data",
          organizationId,
          projectId,
          environmentId,
          "current",
          "workers"
        ],
        new List()
      )
      .filter(w => w.get("name", "").startsWith(`${appName}--`))
  );

  if (!data) return null;

  const getTabs = () => {
    const type = data.get("class") || appName;
    const tabs = [
      {
        name: "overview",
        route: `/${organizationId}/${projectId}/${encodeURIComponent(
          environmentId
        )}/services/${appName}`,
        types: ["app", "routes", "service", "worker"]
      },
      {
        name: "configuration",
        route: `/${organizationId}/${projectId}/${encodeURIComponent(
          environmentId
        )}/services/${appName}/configuration`,
        types: ["app", "routes", "service", "worker"]
      }
    ];
    return tabs.filter(tab => tab.types.includes(type));
  };

  const tabs = getTabs();
  const name = data.get("name", "router");

  return (
    <S.Panel className="col-9">
      <S.Title className={`title-${name}`}>
        {name !== "router" && <ServiceNode name={data.get("icon", "router")} />}
        <span>{name}</span>
      </S.Title>
      <LiveMessage message={`${name}`} aria-live="polite" />
      {tabs && <SubNavBar tabs={tabs} currentPathName={pathname} />}
      {React.cloneElement(children, { data, workers })}
    </S.Panel>
  );
};

ServiceInfoPanel.propTypes = {
  children: PropTypes.node
};

export default ServiceInfoPanel;
