import styled from "styled-components";

import EmptyTree from "../../../../../../common/containers/ServicesDisplay/EmptyTree";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Tree = styled(EmptyTree)`
  margin: 60px auto;
`;
