import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { load, loadMore } from "Reducers/tickets/comments";

import { LoadingComments } from "./styles";

import Comments from "../../components/Comments";
import Pane from "../../../../common/components/Pane";
import LoadMore from "../../../../common/components/LoadMore";

const CommentsContainer = ({ ticketId }) => {
  const dispatch = useDispatch();

  const hasMore = useSelector(({ view }) => view.comments?.get("hasMore"));
  const isLoading = useSelector(({ view }) => view.comments?.get("isLoading"));
  const comments = useSelector(({ view }) =>
    view.comments?.get("comments")?.toJS()
  );

  useEffect(
    () => {
      dispatch(load(ticketId));
    },
    [ticketId]
  );

  return isLoading && !comments ? (
    <LoadingComments iconOnly />
  ) : (
    comments?.length > 0 && (
      <>
        <Comments comments={comments} />
        <Pane>
          <LoadMore
            hasMore={hasMore}
            isLoading={isLoading && !!comments?.length}
            onClick={() => dispatch(loadMore(ticketId))}
          />
        </Pane>
      </>
    )
  );
};

CommentsContainer.propTypes = {
  ticketId: PropTypes.string
};

export default CommentsContainer;
