import React from "react";
import { useIntl } from "react-intl";

import CopyableArea from "Components/CopyableArea";
import Label from "Components/fields/Label";

import * as S from "../Steps.styles";

const Routes = () => {
  const intl = useIntl();

  const routesYaml =
    intl.formatMessage({ id: "yaml_routes_description" }) +
    `\n"https://www.{default}/":\n    type: upstream\n    upstream: "app:http"\n\n"https://{default}/":\n    type: redirect\n    to: "https://www.{default}/"`;

  return (
    <S.Wrapper>
      <S.FileExampleLabelWrapper>
        <Label>{intl.formatMessage({ id: "example_file" })}: routes.yaml</Label>
      </S.FileExampleLabelWrapper>
      <CopyableArea
        id="routes-yaml"
        content={routesYaml}
        iconAbove={true}
        linkStyle={true}
      >
        {routesYaml}
      </CopyableArea>
    </S.Wrapper>
  );
};

export default Routes;
