import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Map } from "immutable";
import { LiveMessage } from "react-aria-live";

import {
  updateEnvironment,
  toggleEnvironmentActivation,
  setEditLine
} from "Reducers/environment";

import { httpStatusDisplay } from "Libs/utils";

import Heading2 from "Components/styleguide/Heading2";
import SettingLine from "Components/SettingLine";
import EnvironmentSettingWrapper from "./EnvironmentSettingWrapper";
import EnvironmentSettingsLayout from "./EnvironmentSettingsLayout";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";
import PageMeta from "Components/PageMeta";
import {
  BasicSetting,
  EmailSetting,
  HttpAccessSetting,
  IndexingSetting,
  StatusSetting
} from "./SettingsOptions";
import * as S from "./styles";

class EnvironmentGeneralSettings extends React.Component {
  state = {
    environmentStatus: true,
    isModalOpen: false,
    confirmDelete: false,
    confirmDeleteModal: false,
    updatePlanModal: false,
    environmentType: "",
    http_access: {
      is_enabled: false,
      addresses: [],
      basic_auth: {}
    },
    enable_smtp: false,
    restrict_robots: false,
    accessChanged: false,
    acceptDelete: false,
    environmentParent: {},
    environmentTitle: "",
    isChanged: false,
    logins: [],
    newLine: "",
    titleChange: false
  };

  componentDidMount() {
    const { environment } = this.props;
    if (environment) {
      const { basic_auth } = environment.http_access;
      this.setState({
        logins: Object.keys(basic_auth).map(k => ({
          login: k,
          password: basic_auth[k]
        })),
        environmentTitle: environment?.title,
        environmentParent: environment?.parent,
        environmentType: environment?.type,
        environmentStatus: environment.isActive && environment.isActive(),
        http_access: {
          is_enabled: environment.http_access.is_enabled,
          addresses: environment.http_access.addresses,
          basic_auth
        },
        enable_smtp: environment.enable_smtp,
        restrict_robots: environment.restrict_robots,
        titleChange: false,
        isChanged: false
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { environment } = this.props;
    if (environment !== nextProps.environment) {
      const basic_auth = nextProps.environment.http_access.basic_auth;
      this.setState({
        logins: Object.keys(basic_auth).map(k => ({
          login: k,
          password: basic_auth[k]
        })),
        environmentTitle: nextProps.environment?.title,
        environmentParent: nextProps.environment?.parent,
        environmentType: nextProps.environment?.type,
        environmentStatus:
          nextProps.environment.isActive && nextProps.environment.isActive(),
        http_access: {
          is_enabled: nextProps.environment.http_access.is_enabled,
          addresses: nextProps.environment.http_access.addresses,
          basic_auth
        },
        enable_smtp: nextProps.environment.enable_smtp,
        restrict_robots: nextProps.environment.restrict_robots,
        titleChange: false,
        isChanged: false,
        isModalLeaveOpen: false
      });
    }
  }

  saveStatus = () => {
    const {
      environment: { status },
      toggleEnvironmentActivation
    } = this.props;
    const { isModalOpen } = this.state;

    status !== "inactive" && !isModalOpen
      ? this.setState({ isModalOpen: true })
      : toggleEnvironmentActivation();
  };

  openModal = modalContent => {
    this.setState({
      isModalOpen: true,
      modalContent
    });
  };

  openDeleteConfirm = () => {
    this.setState({
      confirmDeleteModal: true
    });
  };

  openUpdatePlan = () => {
    this.setState({
      updatePlanModal: true
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      isModalLeaveOpen: false,
      confirmDeleteModal: false,
      updatePlanModal: false
    });
  };

  onEnvironmentTitleChange = e => {
    const environmentTitle = e.target.value;
    this.setState(prevState => ({
      environmentTitle,
      titleChange:
        environmentTitle === prevState.environmentTitle ? false : true,
      isChanged: true
    }));
  };

  onEnvironmentParentChange = ({ value }) => {
    this.setState({
      environmentParent: value,
      isChanged: true
    });
  };

  onEnvironmentTypeChange = ({ value }) => {
    this.setState({
      environmentType: value,
      isChanged: true
    });
  };

  onChange(name, value) {
    this.setState({
      [name]: value
    });
  }

  updateEnvironmentSubmit = () => {
    const { updateEnvironment } = this.props;
    const {
      environmentTitle,
      environmentParent,
      http_access,
      enable_smtp,
      restrict_robots,
      environmentType
    } = this.state;

    updateEnvironment({
      title: environmentTitle,
      parent: environmentParent,
      http_access,
      enable_smtp,
      restrict_robots,
      type: environmentType
    });
    this.setState({
      isChanged: false
    });
  };

  onEmailSendingChange = () => {
    const { enable_smtp } = this.state;
    this.onChange("enable_smtp", !enable_smtp, true);
    setTimeout(() => {
      this.updateEnvironmentSubmit();
    }, 500);
  };
  onIndexingChange = () => {
    this.setState(prevState => ({
      restrict_robots: !prevState.restrict_robots
    }));
    setTimeout(() => {
      this.updateEnvironmentSubmit();
    }, 500);
  };
  onHttpAccessControlChange = () => {
    const { http_access } = this.props.environment || {};
    const { addresses = [], basic_auth = {} } = http_access;
    this.setState(prevState => ({
      http_access: {
        is_enabled: !prevState.http_access.is_enabled,
        addresses,
        basic_auth
      },
      accessChanged: true,
      isChanged: true
    }));
  };
  onIpTextAreaChange = event => {
    const value = event.target.value;
    this.setState(prevState => ({
      ipText: value,
      http_access: {
        ...prevState.http_access,
        addresses: this.textToIp(value) || []
      },
      accessChanged: true,
      isChanged: true
    }));
  };
  textToIp(text) {
    if (!text) {
      return false;
    }
    const lines = text.split("\n");

    return lines
      .filter(function(line) {
        return line != "";
      })
      .map(splitedLine => {
        const columns = splitedLine.split(" ");
        return {
          address: columns[0],
          permission: columns[1]
        };
      });
  }

  onLoginChange = (logins, removed) => {
    let basicAuth = {};
    Object.keys(removed).forEach(login => {
      basicAuth[login] = null;
    });

    basicAuth = logins.reduce((logins, auth) => {
      logins[auth.login] = auth.password;
      return logins;
    }, basicAuth);

    this.setState(prevState => ({
      logins,
      http_access: {
        ...prevState.http_access,
        basic_auth: basicAuth
      },
      accessChanged: true,
      isChanged: true
    }));
  };

  resetAccessChanged = () => {
    this.setState({
      accessChanged: false
    });
  };

  editLine = index => {
    const { editedLine, setEditLine } = this.props;
    const { isChanged, isModalLeaveOpen } = this.state;
    if (isChanged && !isModalLeaveOpen) {
      this.setState({
        isModalLeaveOpen: true,
        newLine: index
      });
    } else {
      if (editedLine === index) {
        setEditLine("");
        this.setState(prevState => ({
          titleChange:
            this.props.environment?.title === prevState.environmentTitle
              ? false
              : true,
          isChanged: false,
          isModalLeaveOpen: false
        }));
      } else {
        setEditLine(index);
        this.setState(prevState => ({
          titleChange:
            this.props.environment?.title === prevState.environmentTitle
              ? false
              : true,
          isChanged: false,
          isModalLeaveOpen: false
        }));
      }
    }
  };

  acceptDeleteToggle = () => {
    this.setState(prevState => ({
      acceptDelete: !prevState.acceptDelete
    }));
  };

  cancel = () => {
    const {
      environment,
      environment: {
        http_access: { basic_auth }
      }
    } = this.props;
    this.setState({
      logins: Object.keys(basic_auth).map(k => ({
        login: k,
        password: basic_auth[k]
      })),
      environmentTitle: environment?.title,
      environmentParent: environment.parent,
      environmentStatus: environment.isActive && environment.isActive(),
      http_access: {
        is_enabled: environment.http_access.is_enabled,
        addresses: environment.http_access.addresses,
        basic_auth
      },
      enable_smtp: environment.enable_smtp,
      restrict_robots: environment.restrict_robots,
      titleChange: false
    });
  };

  getTitleBar = (index, environment, intl) => {
    switch (index) {
      case "basic":
        return (
          <S.InfoLayout>
            Environment name{" "}
            <strong id={`${index}-title`}>{environment?.title}</strong>
          </S.InfoLayout>
        );
      case "status":
        return (
          <S.InfoLayout>
            <div id={`${index}-title`}>
              Status is{" "}
              <strong className="cap">
                {environment.status == "dirty"
                  ? "building"
                  : environment.status}
              </strong>
            </div>
          </S.InfoLayout>
        );
      case "email":
        return (
          <S.InfoLayout>
            <div id={`${index}-title`}>
              Outgoing emails are{" "}
              <strong>{environment.enable_smtp ? "On" : "Off"}</strong>
            </div>
          </S.InfoLayout>
        );
      case "indexing":
        return (
          <S.InfoLayout>
            <div id={`${index}-title`}>
              {intl.formatMessage({ id: "is_hidden" })}{" "}
              <strong>
                {environment.restrict_robots
                  ? intl.formatMessage({ id: "on" })
                  : intl.formatMessage({ id: "off" })}
              </strong>
            </div>
          </S.InfoLayout>
        );
      case "httpaccess":
        return (
          <S.InfoLayout>
            <div id={`${index}-title`}>
              HTTP access control is{" "}
              <strong>
                {httpStatusDisplay(environment)
                  ? intl.formatMessage({ id: "on" })
                  : intl.formatMessage({ id: "off" })}
              </strong>
            </div>
          </S.InfoLayout>
        );
    }
  };

  redirectToPlan = project => {
    if (project && project.data && project.data.plan_uri) {
      window.location = project.data.plan_uri;
    }
  };

  render() {
    const {
      intl,
      editedLine,
      environments = [],
      environment = {},
      project,
      projectId,
      istoggleActivationLoading,
      isUpdateLoading,
      errors,
      toggleEnvironmentActivation
    } = this.props;

    let currentStatus =
      environment && environment.isActive && environment.isActive();

    if (!environment) {
      return false;
    }

    const {
      accessChanged,
      acceptDelete,
      confirmDeleteModal,
      enable_smtp,
      environmentTitle,
      environmentParent,
      http_access,
      isChanged,
      isModalLeaveOpen,
      environmentType,
      logins,
      newLine,
      updatePlanModal
    } = this.state;

    const parentEnvironments = environments
      .valueSeq()
      .toJS()
      .map(env => ({
        value: env.id,
        label: env?.title
      }))
      .filter(item => item.value !== environment.id);

    const activeEnvironments = environments
      .valueSeq()
      .toJS()
      .map(env => ({
        id: env.id,
        status: env.status
      }))
      .filter(item => item.status !== "inactive").length;
    const environmentLimit =
      project && project.subscription && project.subscription.environments + 1;

    const ipText =
      this.state.ipText ||
      (http_access.addresses &&
        http_access.addresses
          .map(a => `${a.address} ${a.permission}\n`)
          .join("")) ||
      [];
    const basicAuth = logins;

    // login must have both login/user and password filled out to be valid
    const invalidLogins = loginList => {
      return loginList.some(item => {
        return (
          !item.login ||
          !item.password ||
          item?.login.length === 0 ||
          item?.password.length === 0
        );
      });
    };

    // 'Save' button will be disabled if configs are incomplete/invalid
    const incompleteConfigs =
      http_access.is_enabled &&
      http_access.addresses.length === 0 &&
      (logins.length === 0 || invalidLogins(logins));

    const settingsOptions = [
      "basic",
      "status",
      "email",
      "indexing",
      "httpaccess"
    ];
    return (
      <EnvironmentSettingsLayout>
        <LiveMessage
          message={`${environment?.title} basic settings`}
          aria-live="polite"
        />
        <PageMeta
          title={`Settings | ${environment?.title} | ${project &&
            project?.title}`}
        />
        <Heading2 id="settings-heading">
          {intl.formatMessage({ id: "general" })}
        </Heading2>
        <section aria-labelledby="settings-heading">
          {settingsOptions.map(index => {
            return (
              <SettingLine
                key={`${index}-read`}
                id={`settings-${index}`}
                info={this.getTitleBar(index, environment, intl)}
                openText={intl.formatMessage({ id: "edit" })}
                isOpen={editedLine === index}
                onClick={() => this.editLine(index)}
              >
                {editedLine === index &&
                  editedLine === "basic" && (
                    <EnvironmentSettingWrapper
                      close={() => this.editLine("")}
                      isUpdateLoading={isUpdateLoading}
                      save={this.updateEnvironmentSubmit}
                      savePermission={
                        environment.hasPermission &&
                        environment.hasPermission("#edit")
                      }
                      itemId={index}
                      cancel={this.cancel}
                      hideButtons={!isChanged}
                    >
                      <BasicSetting
                        environment={environment}
                        environmentParent={environmentParent}
                        environmentTitle={environmentTitle}
                        environmentType={environmentType}
                        errors={errors}
                        onEnvironmentParentChange={
                          this.onEnvironmentParentChange
                        }
                        onEnvironmentTitleChange={this.onEnvironmentTitleChange}
                        onEnvironmentTypeChange={this.onEnvironmentTypeChange}
                        parentEnvironments={parentEnvironments}
                      />
                    </EnvironmentSettingWrapper>
                  )}
                {editedLine === index &&
                  editedLine === "status" && (
                    <EnvironmentSettingWrapper
                      title={`Status is ${
                        currentStatus ? "active" : "inactive"
                      }`}
                      close={() => this.editLine("")}
                      isUpdateLoading={istoggleActivationLoading}
                      save={e => {
                        if (currentStatus) {
                          if (acceptDelete) {
                            e.preventDefault();
                            this.openDeleteConfirm();
                          }
                        } else {
                          if (environmentLimit <= activeEnvironments) {
                            e.preventDefault();
                            this.openUpdatePlan();
                          } else {
                            toggleEnvironmentActivation();
                          }
                        }
                      }}
                      saveText={
                        currentStatus
                          ? intl.formatMessage({ id: "deactivate_button" })
                          : intl.formatMessage({ id: "activate_button" })
                      }
                      savePermission={
                        environment.hasPermission &&
                        environment.hasPermission("#edit")
                      }
                      itemId={index}
                      isChanged={!currentStatus ? !currentStatus : acceptDelete}
                      hideButtons={environment.is_main}
                    >
                      <StatusSetting
                        acceptDelete={acceptDelete}
                        acceptDeleteToggle={this.acceptDeleteToggle}
                        closeModal={this.closeModal}
                        confirmDeleteModal={confirmDeleteModal}
                        currentStatus={currentStatus}
                        environment={environment}
                        project={project}
                        redirectToPlan={this.redirectToPlan}
                        toggleEnvironmentActivation={
                          toggleEnvironmentActivation
                        }
                        updatePlanModal={updatePlanModal}
                      />
                    </EnvironmentSettingWrapper>
                  )}
                {editedLine === index &&
                  editedLine === "email" && (
                    <EnvironmentSettingWrapper
                      title={intl.formatMessage({
                        id: "change_email_settings"
                      })}
                      close={() => this.editLine("")}
                      isUpdateLoading={isUpdateLoading}
                      savePermission={
                        environment.hasPermission &&
                        environment.hasPermission("#edit")
                      }
                      itemId={index}
                      isChanged={
                        enable_smtp !== this.props.environment.enable_smtp
                      }
                      cancel={this.cancel}
                    >
                      <EmailSetting
                        environment={environment}
                        enable_smtp={enable_smtp}
                        onEmailSendingChange={this.onEmailSendingChange}
                      />
                    </EnvironmentSettingWrapper>
                  )}
                {editedLine === index &&
                  editedLine === "indexing" && (
                    <EnvironmentSettingWrapper
                      title="Change search settings"
                      close={() => this.editLine("")}
                      isUpdateLoading={isUpdateLoading}
                      savePermission={
                        environment.hasPermission &&
                        environment.hasPermission("#edit")
                      }
                      itemId={index}
                      isChanged={
                        this.state.restrict_robots !==
                        this.props.environment.restrict_robots
                      }
                      cancel={this.cancel}
                    >
                      <IndexingSetting
                        environment={environment}
                        onIndexingChange={this.onIndexingChange}
                        restrict_robots={this.state.restrict_robots}
                      />
                    </EnvironmentSettingWrapper>
                  )}
                {editedLine === index &&
                  editedLine === "httpaccess" && (
                    <EnvironmentSettingWrapper
                      title="Change access settings"
                      close={() => this.editLine("")}
                      isUpdateLoading={isUpdateLoading}
                      incompleteConfigs={incompleteConfigs}
                      save={() => {
                        this.updateEnvironmentSubmit();
                        this.resetAccessChanged();
                      }}
                      savePermission={
                        environment.hasPermission &&
                        environment.hasPermission("#edit")
                      }
                      itemId={index}
                      isChanged={accessChanged}
                      cancel={this.cancel}
                      hideButtons={!isChanged}
                    >
                      <HttpAccessSetting
                        basicAuth={basicAuth}
                        environment={environment}
                        errors={errors}
                        ipText={ipText}
                        isEnabled={http_access.is_enabled}
                        onHttpAccessControlChange={
                          this.onHttpAccessControlChange
                        }
                        onIpTextAreaChange={this.onIpTextAreaChange}
                        onLoginChange={this.onLoginChange}
                        projectId={projectId}
                      />
                    </EnvironmentSettingWrapper>
                  )}
              </SettingLine>
            );
          })}
          <ModalConfirmLeaveForm
            isOpen={isModalLeaveOpen}
            closeModal={this.closeModal}
            continueFunction={() => {
              this.editLine(newLine);
            }}
            cancelFunction={this.closeModal}
          />
        </section>
      </EnvironmentSettingsLayout>
    );
  }
}

EnvironmentGeneralSettings.propTypes = {
  intl: PropTypes.object,
  projectId: PropTypes.string,
  organizationId: PropTypes.string,
  environmentId: PropTypes.string,
  editedLine: PropTypes.string,
  updateEnvironment: PropTypes.func,
  setEditLine: PropTypes.func,
  toggleEnvironmentActivation: PropTypes.func,
  environment: PropTypes.object,
  parent: PropTypes.object,
  environments: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  istoggleActivationLoading: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  errors: PropTypes.object,
  project: PropTypes.object
};

const mapStateToProps = (state, props) => {
  const environment = state.environment.getIn([
    "data",
    props.organizationId,
    props.projectId,
    props.environmentId
  ]);
  const project = state.project.getIn(
    ["data", props.organizationId, props.projectId],
    {}
  );
  return {
    environment,
    project,
    editedLine: state.environment.get("editedLine"),
    parent:
      environment &&
      state.environment.getIn(
        ["data", props.organizationId, props.projectId, environment.parent],
        new Map()
      ),
    environments: state.environment.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
    errors: state.environment.getIn(["errors", "detail"], new Map()),
    isLoading: state.environment.get("loading"),
    isUpdateLoading: state.environment.get("updateLoading"),
    istoggleActivationLoading: state.environment.get("toggleActivationLoading")
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  updateEnvironment: data =>
    dispatch(
      updateEnvironment(
        props.organizationId,
        props.projectId,
        props.environmentId,
        data
      )
    ),
  toggleEnvironmentActivation: () =>
    dispatch(
      toggleEnvironmentActivation(
        props.organizationId,
        props.projectId,
        props.environmentId
      )
    ),
  setEditLine: index => dispatch(setEditLine(index))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EnvironmentGeneralSettings));
