import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import useDecodedParams from "Hooks/useDecodedParams";

import CopyableArea from "Components/CopyableArea";
import * as S from "./styles";

const Worker = ({ worker }) => {
  const { environmentId, organizationId, projectId } = useDecodedParams();
  const project = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId])
  );
  const environment = useSelector(state =>
    state.environment?.getIn(["data", organizationId, projectId, environmentId])
  );

  const cmd = `ssh://${projectId}-${environment.machine_name}--${
    worker.name
  }@ssh.${project.region}`;

  return (
    <>
      <S.Cell>
        <S.Title>{worker.name}</S.Title>
      </S.Cell>
      <S.Cell>{worker.version}</S.Cell>
      <S.Cell>{worker.size}</S.Cell>
      <S.Cell>{worker.disk}</S.Cell>
      <CopyableArea content={cmd} singleLine={true}>
        {cmd}
      </CopyableArea>
    </>
  );
};

Worker.propTypes = {
  worker: PropTypes.object
};

export default Worker;
