import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import InlineBanner from "Components/InlineBanner";

import Timeframe from "../../components/TimeframeSelector";
import HostSelector from "../../components/HostSelector";
import LastUpdated from "../../components/LastUpdated";
import { EmptyMetrics } from "../../components/Errors";

import Skeleton from "./Dedication.skeleton";
import Service from "../../containers/Service";

import useDedication from "../../hooks/useDedication";
import useHosts from "../../hooks/useHosts";

import { timeframes } from "../../settings";

import * as V from "../../views/views.styles";
import * as S from "../Grid/Grid.styles";

const Dedication = ({ chorusURL, collection }) => {
  const [
    services,
    hosts,
    [firstDataPoint, lastDataPoint],
    selectedTimeframe,
    setSelectedTimeframe,
    isLoading,
    error
  ] = useDedication(chorusURL, collection);

  const [selectedHosts, setHosts] = useHosts(hosts);

  if (error) {
    return (
      <InlineBanner padding="16px" level="error">
        {error?.message}
      </InlineBanner>
    );
  }

  const showServices = !!services;
  const showEmptyMetrics =
    !isLoading && services && Object.keys(services).length === 0;

  return (
    <>
      <V.Header>
        <V.Heading>
          <FormattedMessage id="metrics.grid.heading" />
        </V.Heading>
        <V.TimeframeColumn>
          {selectedTimeframe && (
            <LastUpdated
              interval={selectedTimeframe.interval}
              lastDataPoint={lastDataPoint}
              isCustomTimeframe={!selectedTimeframe.id}
            />
          )}
          <V.Row style={{ marginLeft: "auto" }}>
            <HostSelector
              hosts={hosts}
              selected={selectedHosts}
              onChange={setHosts}
            />
            <Timeframe
              selectedTimeframe={selectedTimeframe}
              timeframes={timeframes}
              onChange={timeframe => setSelectedTimeframe(timeframe.label)}
              to={lastDataPoint}
              from={firstDataPoint}
            />
          </V.Row>
        </V.TimeframeColumn>
      </V.Header>
      <S.Layout>
        {isLoading && !services && <Skeleton />}
        {showEmptyMetrics && <EmptyMetrics />}
        {showServices &&
          services?.map(service => (
            <Service
              key={service.id}
              service={service}
              chorusURL={chorusURL}
              selectedTimeframe={selectedTimeframe}
              onBrush={(from, to) => setSelectedTimeframe({ from, to })}
              hosts={selectedHosts}
              isLoading={isLoading}
              kind="dedication"
            />
          ))}
      </S.Layout>
    </>
  );
};

Dedication.propTypes = {
  chorusURL: PropTypes.string,
  collection: PropTypes.string
};

export default Dedication;
