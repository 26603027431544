import React from "react";
import { Route, useRouteMatch } from "react-router-dom";

import BackupActionPage from "./backup/Page";
import MergeActionPage from "./merge/Page";
import SyncActionPage from "./synchronisation/Page";
import BranchActionPage from "./branch/Page";
import RedeployActionPage from "./redeploy/Page";

const Routes = () => {
  const match = useRouteMatch();

  return (
    <Route path={`${match.path}/:action`}>
      <Route exact path={`${match.path}/sync`}>
        <SyncActionPage />
      </Route>
      <Route exact path={`${match.path}/merge`}>
        <MergeActionPage />
      </Route>
      <Route exact path={`${match.path}/backup`}>
        <BackupActionPage />
      </Route>
      <Route exact path={`${match.path}/branch`}>
        <BranchActionPage />
      </Route>
      <Route exact path={`${match.path}/redeploy`}>
        <RedeployActionPage />
      </Route>
    </Route>
  );
};

export default Routes;
