import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import Page from "./Page";

import PlanPage from "./plan/list/Page";
import PlanDetailsPage from "./plan/details/Page";
import HistoryListPage from "./history/list/Page";
import HistoryDetailPage from "./history/detail/Page";
import VouchersPage from "./vouchers/Page";
import BillingDetailPage from "./details/Page";

const Routes = () => {
  const match = useRouteMatch();

  return (
    <Page>
      <Switch>
        <Route exact path={`${match.path}/history/:orderId`}>
          <HistoryDetailPage />
        </Route>
        <Route exact path={`${match.path}/history`}>
          <HistoryListPage />
        </Route>

        <Route exact path={`${match.path}/vouchers`}>
          <VouchersPage />
        </Route>

        <Route exact path={`${match.path}/plan/:subscriptionId`}>
          <PlanDetailsPage />
        </Route>
        <Route exact path={`${match.path}/plan`}>
          <PlanPage />
        </Route>

        <Route path={match.path}>
          <BillingDetailPage />
        </Route>
      </Switch>
    </Page>
  );
};

export default Routes;
