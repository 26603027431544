import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";

import ModalWrapper from "Components/Modal";
import Button from "Components/Button";
import ButtonWrapper from "Components/ButtonWrapper";

const ModalBody = styled.div`
  margin-bottom: 48px;
  color: ${props => props.theme.sectionText};
`;

const InvitationResendModal = ({
  isOpen,
  closeModal,
  email,
  resendInvitation
}) => {
  const intl = useIntl();

  return (
    <ModalWrapper
      modalClass="modal-medium"
      isOpen={isOpen}
      closeModal={closeModal}
      title={intl.formatMessage({ id: "invitation.resend" })}
      id={"invitation-resend-modal"}
    >
      <ModalBody className="modal-body">
        <FormattedMessage
          id="invitation.manualResendModalBody"
          values={{
            email
          }}
        />
      </ModalBody>
      <ButtonWrapper className="modal-buttons">
        <Button
          type="button"
          className="primary"
          aria-label={intl.formatMessage({ id: "invitation.revoke" })}
          onClick={resendInvitation}
        >
          <FormattedMessage id="resend" />
        </Button>
        <Button
          type="button"
          className="secondary"
          aria-label={intl.formatMessage({ id: "cancel" })}
          onClick={closeModal}
        >
          <FormattedMessage id="cancel" />
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  );
};

InvitationResendModal.propTypes = {
  isOpen: PropTypes.bool,
  email: PropTypes.string,
  closeModal: PropTypes.func,
  resendInvitation: PropTypes.func,
  revokeInvitation: PropTypes.func
};

export default InvitationResendModal;
