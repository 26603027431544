import util from "./util";
import getIcon from "Components/icons/technologies";
const d3 = require("d3");

const getNodesOrderedByLayer = graph => {
  const layers = [];

  graph.nodes().forEach(nodeName => {
    const node = graph.node(nodeName);
    const rank = Math.abs(node.rank);

    if (node.order !== undefined) {
      if (!layers[rank]) {
        layers[rank] = [];
      }
      layers[rank][node.order] = nodeName;
    } else {
      layers[rank] = [...(layers[rank] || []), nodeName];
    }
  });

  return Array.prototype.concat(...layers.reverse());
};

function createNodes(selection, g) {
  let simpleNodes = getNodesOrderedByLayer(g).filter(function(v) {
    return !util.isSubgraph(g, v);
  });
  selection.selectAll("g.node").remove();
  let svgNodes = selection
    .selectAll("g.node")
    .data(simpleNodes, function(v) {
      return v;
    })
    .classed("update", true);

  svgNodes.exit().remove();

  svgNodes
    .enter()
    .append("g")
    .attr("class", "node")
    .attr("tabindex", 0)
    .attr("aria-labelledby", "node-tooltip")
    .attr("aria-haspopup", true)
    .attr("aria-expanded", false)
    .style("opacity", 0);

  svgNodes = selection.selectAll("g.node");

  svgNodes.each(function(v) {
    const node = g.node(v),
      thisGroup = d3.select(this);
    util.applyClass(
      thisGroup,
      node["class"],
      (thisGroup.classed("update") ? "update " : "") + "node"
    );

    node.elem = this;

    const { width, height } = node;

    if (node.id) {
      thisGroup.attr("id", node.id);
    }
    if (node.labelId) {
      thisGroup.attr("id", node.labelId);
    }
    thisGroup.select(".label-container").remove();
    const rectNode = thisGroup
      .insert("rect", ":first-child")
      .classed("label-container", true)
      .attr("transform", "rotate(-45)")
      .attr("rx", 8)
      .attr("ry", 8)
      .attr("x", -width / 2)
      .attr("y", -height / 2)
      .attr("width", width)
      .attr("height", height)
      .style("filter", "url(#shadow)");

    const activate = () => {
      rectNode.style("filter", "url(#shadow-hover)");
      d3.selectAll("." + v + "-path")
        .classed("highlight", true)
        .raise();
    };

    const deactivate = () => {
      rectNode.style("filter", "url(#shadow)");
      d3.selectAll(".edgePath").classed("highlight", false);
    };

    const customEventFactory = name =>
      new CustomEvent(name, {
        detail: {
          x: node.x,
          y: node.y,
          size: width,
          metadata: node.metadata,
          class: node.class,
          icon: node.icon
        }
      });

    const onMouseout = () => {
      deactivate();
      this.dispatchEvent(customEventFactory("treeSvgOut"));
    };

    const onMouseover = () => {
      activate();
      this.dispatchEvent(customEventFactory("treeSvgOver"));
    };

    const onClick = () => {
      this.dispatchEvent(customEventFactory("treeSvgClick"));
    };

    const onKeyDown = () => {
      if (event.which === 13) {
        return this.dispatchEvent(customEventFactory("treeSvgClick"));
      }
    };

    thisGroup
      .on("focus", onMouseover)
      .on("blur", onMouseout)
      .on("mouseover", onMouseover)
      .on("mouseout", onMouseout)
      .on("click", onClick)
      .on("keydown", onKeyDown, false);

    thisGroup.select(".icon").remove();

    const iconSize = 20;
    thisGroup
      .insert("g")
      .classed("icon", true)
      .html(
        `<image x="-${iconSize / 2}" y="-${iconSize /
          2}" width="${iconSize}" height="${iconSize}" xlink:href="data:image/svg+xml;base64,${window.btoa(
          getIcon(
            node.icon || "placeholder",
            -(iconSize / 2),
            -(iconSize / 2),
            iconSize,
            iconSize,
            node.iconColor
          )
        )}"></image>`
      );

    if (node.class === "worker") {
      const worker = thisGroup
        .insert("g")
        .classed("badge", true)
        .style("transform", "translate(6px, -23px)");

      worker
        .append("circle")
        .attr("cx", 8)
        .attr("cy", 8)
        .attr("r", 8)
        .style("fill", "#C9D0E4");

      worker
        .append("path")
        .attr(
          "d",
          "M12.2876 5.30078H10.875L10.0264 9.50635H9.96729L8.8877 5.30078H7.55566L6.47607 9.50635H6.42236L5.57373 5.30078H4.14502L5.60059 10.9995H7.09912L8.19482 6.94434H8.23779L9.3335 10.9995H10.8267L12.2876 5.30078Z"
        )
        .style("fill", "#1A192B");
    }
    // addLabel(thisGroup, node, "bottom");
  });

  let exitSelection;

  if (svgNodes.exit) {
    exitSelection = svgNodes.exit();
  } else {
    exitSelection = svgNodes.selectAll(null); // empty selection
  }

  util
    .applyTransition(exitSelection, g)
    .style("opacity", 0)
    .style("filter", "url(#blur)")
    .remove();

  return svgNodes;
}

export default createNodes;
