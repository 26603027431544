import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import InfoDialog from "Components/InfoDialog";

export const Wrapper = styled.div`
  position: relative;
`;

export const ProjectActions = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 15px;
  right: 0;
  position: absolute;

  .tooltip {
    line-height: 24px;
  }
`;

export const WarningDialog = styled(InfoDialog)`
  margin-right: 8px;
`;

export const ProjectIdCell = styled.div.attrs(() => ({
  className: "cell cell-id col-2"
}))`
  display: flex;
  align-items: center;
`;

export const Ellipsis = styled.div`
  text-transform: capitalize;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const RegionSuffix = styled.span`
  color: ${props => (props.theme.name === "contrast" ? "#53585f" : "#98a0ab")};
  margin-left: 7px;
  font-size: ${props => (props.inline ? "11px" : "13px")};
  line-height: 0.8;
  ${semiBoldAlias};
`;
