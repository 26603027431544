import React from "react";
import { FormattedMessage } from "react-intl";

import {
  DOCS_GETTING_STARTED_URL,
  DOCS_GOLIVE_CHECKLIST_URL
} from "Constants/documentationUrls";
import List from "../../../../common/components/List";
import ListItem from "../../../../common/components/ListItem";
import Link from "../../../../common/components/Link";

import { Layout } from "./styles";

const links = [
  {
    href: DOCS_GETTING_STARTED_URL,
    text: "tickets.list.quicklinks.gettingstarted"
  },
  {
    href: DOCS_GOLIVE_CHECKLIST_URL,
    text: "tickets.list.quicklinks.live"
  },
  {
    href: "https://community.platform.sh/c/tutorial",
    text: "tickets.list.quicklinks.tutorials"
  },
  {
    href: "https://api.platform.sh/docs/",
    text: "tickets.list.quicklinks.reference"
  },
  {
    href: "https://community.platform.sh/c/questions-answers",
    text: "tickets.list.quicklinks.qa"
  }
];

const QuickLinks = props => (
  <Layout
    header={<FormattedMessage id="tickets.list.quicklinks.header" />}
    paragraph={<FormattedMessage id="tickets.list.quicklinks.paragraph" />}
    {...props}
  >
    <List>
      {links.map((link, index) => (
        <ListItem key={index}>
          <Link target="_blank" rel="noopener noreferrer" href={link.href}>
            <FormattedMessage id={link.text} />
          </Link>
        </ListItem>
      ))}
    </List>
  </Layout>
);

export default QuickLinks;
