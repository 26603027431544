import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "immutable";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { LiveMessage } from "react-aria-live";

import withReducers from "Hocs/withReducers";
import useDecodedParams from "Hooks/useDecodedParams";

import { getSubscriptionEditUrl } from "Libs/utils";
import {
  addDomain,
  deleteDomain,
  getDomains,
  updateDomain
} from "Reducers/project/settings/domain";
import { subscriptionSelector } from "Reducers/subscription";

import { DOCS_DNS_URL } from "Constants/documentationUrls";

import ButtonAdd from "Components/ButtonAdd";
import CopyableArea from "Components/CopyableArea";
import DottedLineWrapper from "Components/InlineBanner";
import Heading2 from "Components/styleguide/Heading2";
import InfoDialog from "Components/InfoDialog";
import Loading from "Components/Loading";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";
import PageDescription from "Components/PageDescription";
import PageMeta from "Components/PageMeta";

import SettingLine from "Components/SettingLine";
import DomainForm from "../../components/DomainForm";
import EmptyText from "Components/EmptyText";

import * as S from "./ProjectDomains.styles";

const ProjectDomains = ({ isNew = false, url }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizationId, projectId, domainId = "" } = useDecodedParams();
  const [modal, setModal] = useState({ isOpen: false });
  const [isChanged, setIsChanged] = useState(false);

  const project = useSelector(({ project }) =>
    project?.getIn(["data", organizationId, projectId], Map())
  );
  const environments = useSelector(({ environment }) =>
    environment?.getIn(["data", organizationId, projectId], Map())
  );
  const subscription = useSelector(state => {
    return subscriptionSelector(state, {
      organizationId,
      projectId,
      id: project.subscription_id
    });
  });
  const projectDomain = useSelector(
    ({ projectDomain }) => projectDomain || Map()
  );
  const loading = projectDomain.get("loading", true);
  const status = projectDomain.get("status");
  const errors = projectDomain.get("errors", {});
  const domains = projectDomain
    .getIn(["data", organizationId, projectId], Map())
    ?.valueSeq()
    ?.toJS();

  useEffect(
    () => {
      if (["added", "updated", "deleted"].includes(status)) cancel();
    },
    [status]
  );

  useEffect(
    () => {
      if (project.id && project.subscription?.plan !== "development") {
        dispatch(getDomains({ organizationId, project }));
      }
    },
    [project]
  );

  useEffect(
    () => {
      if (
        domainId &&
        !loading &&
        !domains.find(domain => encodeDomainId(domain.id) === domainId)
      )
        cancel();
    },
    [loading, domainId]
  );

  const cancel = () => {
    history.replace(url);
  };

  const save = data => {
    setIsChanged(false);

    if (isNew) {
      dispatch(addDomain({ organizationId, project, data }));
    } else {
      const domain = domains.find(elt => elt.id === data.id);
      dispatch(
        updateDomain({
          organizationId,
          project,
          domain,
          data
        })
      );
    }
  };

  const encodeDomainId = id => {
    return id.replace(/\./g, "");
  };

  const handleCancel = () => {
    if (isChanged && !modal.isOpen) {
      setModal({ isOpen: true, type: "leave" });
      setIsChanged(true);
    } else {
      setIsChanged(false);
      cancel();
    }
  };

  const expand = id => {
    if (isNew && id === "new") return;
    if (!id || domainId === encodeDomainId(id)) {
      handleCancel();
    } else {
      if (isChanged && !modal.isOpen) {
        setModal({ isOpen: true, type: "leave", next: id });
        setIsChanged(true);
      } else {
        history.replace(
          `${url}/${
            id === "new" ? "-/new" : encodeURIComponent(encodeDomainId(id))
          }`
        );
        setIsChanged(false);
      }
    }
  };

  const subscriptionEditUrl = useMemo(
    () => getSubscriptionEditUrl({ project, subscription }),
    [project, subscription]
  );
  const hasDomainPermission = useMemo(
    () => project?.hasPermission && project.hasPermission("#manage-domains"),
    [project]
  );
  const mainEnvironment = useMemo(
    () => environments?.find(environment => environment.is_main),
    [environments]
  );
  const isDevelopment = useMemo(
    () => project?.subscription?.plan === "development",
    [project]
  );

  if (!mainEnvironment) return null;

  return (
    <S.Wrapper>
      <LiveMessage
        message={`${project.title} domain settings`}
        aria-live="polite"
      />
      <PageMeta title={`Domains | ${project?.title}`} />
      {hasDomainPermission && (
        <ButtonAdd
          id="add-new-domain"
          css={{ float: "right" }}
          customText={intl.formatMessage({
            id: "settings.domains.add.button"
          })}
          onClick={() => expand("new")}
        />
      )}

      <Heading2
        id="settings-heading"
        style={{ marginBottom: isDevelopment ? "24px" : "16px" }}
      >
        {intl.formatMessage({ id: "domains" })}
      </Heading2>

      {isDevelopment && (
        <DottedLineWrapper className="col-9" padding="16px" level="info">
          <S.AlertWrapper>
            <p>{intl.formatMessage({ id: "settings.domains.development" })}</p>
            {subscriptionEditUrl && (
              <S.ButtonLink
                to={{
                  pathname: subscriptionEditUrl.url,
                  state: { from: "project_settings" }
                }}
                external={subscriptionEditUrl.external}
                text={intl.formatMessage({
                  id: "settings.domains.upgrade_plan"
                })}
                blank={false}
              />
            )}
          </S.AlertWrapper>
        </DottedLineWrapper>
      )}

      <PageDescription className="page-description">
        <p>
          <InfoDialog
            text={intl.formatMessage({
              id: "settings.domains.description.info"
            })}
            linkText={intl.formatMessage({ id: "learnmore" })}
            to={DOCS_DNS_URL}
          />{" "}
          {intl.formatMessage({ id: "settings.domains.description" })}
        </p>
        <p>
          {intl.formatMessage(
            { id: "settings.domains.note_1" },
            {
              code: (...chunks) => <code>{chunks}</code> // eslint-disable-line react/display-name
            }
          )}
        </p>
        <p>{intl.formatMessage({ id: "settings.domains.note_2" })}</p>
        <p>
          <br />
          <CopyableArea
            id="environment-cli-cmd"
            content={mainEnvironment.data.edge_hostname}
            singleLine={true}
          >
            {mainEnvironment.data.edge_hostname}
          </CopyableArea>
        </p>
      </PageDescription>

      {!isDevelopment && (
        <section aria-labelledby="settings-heading">
          {loading ? (
            <Loading />
          ) : (
            <>
              {isNew && (
                <SettingLine
                  id={`project-domain-new`}
                  isNew={true}
                  addNewTitle={intl.formatMessage({
                    id: "settings.domains.add.title"
                  })}
                  isOpen={true}
                  onClick={expand}
                >
                  <DomainForm
                    key="domain-new-form"
                    errors={errors}
                    isChanged={isChanged}
                    isChangedUpdate={() => setIsChanged(true)}
                    isLoading={status === "pending"}
                    onSave={save}
                    onCancel={handleCancel}
                  />
                </SettingLine>
              )}

              {domains.length === 0 && !isNew ? (
                <EmptyText>
                  {intl.formatMessage({ id: "no_domains" })}
                </EmptyText>
              ) : (
                <>
                  {domains.map(domain => (
                    <SettingLine
                      key={`domain-${domain.id}`}
                      id={`project-domain-list-${encodeDomainId(domain.id)}`}
                      isOpen={domainId === encodeDomainId(domain.id)}
                      onClick={() => expand(domain.id)}
                      openText={intl.formatMessage({ id: "edit" })}
                      info={
                        <S.InfoLayout>
                          <span>{domain.name}</span>
                          <span>{domain.updated_at}</span>
                        </S.InfoLayout>
                      }
                    >
                      {domainId === domain.id.replace(/\./g, "") && (
                        <DomainForm
                          key={`domain-${encodeDomainId(domain.id)}-form`}
                          errors={errors}
                          domain={domain}
                          isChanged={isChanged}
                          isChangedUpdate={() => setIsChanged(true)}
                          isDefault={domain.name === project.default_domain}
                          isLoading={status === "pending"}
                          onSave={save}
                          onCancel={handleCancel}
                          onDelete={() =>
                            setModal({ isOpen: true, domain, type: "delete" })
                          }
                        />
                      )}
                    </SettingLine>
                  ))}
                </>
              )}
            </>
          )}

          <ModalConfirmDelete
            isOpen={modal.isOpen && modal.type === "delete"}
            closeModal={() => setModal({ isOpen: false })}
            deleteFunction={() =>
              dispatch(
                deleteDomain({ organizationId, project, domain: modal.domain })
              )
            }
            itemType="domain"
            itemName={modal.domain?.name}
            itemId={encodeDomainId(modal.domain?.id || "")}
          />

          <ModalConfirmLeaveForm
            isOpen={modal.isOpen && modal.type === "leave"}
            closeModal={() => setModal({ isOpen: false })}
            continueFunction={() => expand(modal.next)}
            cancelFunction={() => setModal({ isOpen: false })}
          />
        </section>
      )}
    </S.Wrapper>
  );
};

ProjectDomains.propTypes = {
  isNew: PropTypes.bool,
  url: PropTypes.string.isRequired
};

export default withReducers({
  project: () => import("Reducers/project"),
  projectDomain: () => import("Reducers/project/settings/domain")
})(ProjectDomains);
