import React from "react";
import PropTypes from "prop-types";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import PageLayout from "Components/PageLayout";
import NavBar from "Containers/NavBar";
import PageMeta from "Components/PageMeta";

const OrganizationSettings = ({ children }) => {
  const intl = useIntl();
  const { organizationId } = useParams();
  const { pathname } = useLocation();
  const { push } = useHistory();

  return (
    <>
      <PageMeta title={intl.formatMessage({ id: "billing.documenttitle" })} />
      <NavBar
        push={push}
        projectId={""}
        organizationId={organizationId}
        role="navigation"
        currentPathName={pathname}
        fixed={true}
      />
      <PageLayout>{children}</PageLayout>
    </>
  );
};

OrganizationSettings.propTypes = {
  children: PropTypes.node
};

export default OrganizationSettings;
