import React from "react";
import { useHistory } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
import EnvironmentBranchPane from "./containers/BranchPane";

import withReducers from "Hocs/withReducers";

const EnvironmentActionsBranch = () => {
  const { push } = useHistory();
  const params = useDecodedParams();

  return <EnvironmentBranchPane push={push} {...params} />;
};

export default withReducers({
  branch: () => import("Reducers/environment/actions/branch")
})(EnvironmentActionsBranch);
