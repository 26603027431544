import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import { DOCS_METRICS_DEDICATED } from "Constants/documentationUrls";

import {
  Mountpoint,
  Name,
  MetricNameLayout,
  StyledInfoDialog
} from "./Metric.styles";

const MetricName = ({ name = "", kind, serviceKind = "" }) => {
  const intl = useIntl();
  const tooltipTranslationKey = `metrics.${kind}.${name}.tooltip`;

  let children = <Name>{name}</Name>;

  const dialog = kind &&
    intl.messages[tooltipTranslationKey] && (
      <StyledInfoDialog
        text={
          <FormattedMessage
            id={tooltipTranslationKey}
            values={{ serviceKind }}
          />
        }
        // For now we just have documentation for Dedicated projects
        to={DOCS_METRICS_DEDICATED}
        linkText={intl.formatMessage({ id: "learnmore" })}
      />
    );

  // Has translations
  if (["cpu", "memory"].includes(name)) {
    children = (
      <Name>
        {intl.formatMessage({
          id: `metrics.dedication.${name}.title`,
          defaultMessage: name
        })}
        {dialog}
      </Name>
    );
  } else if (["/mnt", "/tmp"].includes(name)) {
    children = (
      <MetricNameLayout>
        <Name>
          <FormattedMessage id={`metrics.grid.${name}`} />{" "}
          <Mountpoint>{name}</Mountpoint>
        </Name>
        {name === "/tmp" &&
          !intl.messages[tooltipTranslationKey] && (
            <StyledInfoDialog
              title="Shared /tmp disk"
              text="Some detailed information about this feature."
              to={DOCS_METRICS_DEDICATED}
              linkText={intl.formatMessage({ id: "learnmore" })}
            />
          )}
        {dialog}
      </MetricNameLayout>
    );
  }

  return children;
};

MetricName.propTypes = {
  name: PropTypes.string,
  kind: PropTypes.string,
  serviceKind: PropTypes.string
};

export default MetricName;
