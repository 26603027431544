import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Layout = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  @media (min-width: 1240px) {
    flex-wrap: nowrap;
    flex-direction: row;
  }
`;

export const DisksLayout = styled(Layout)`
  margin-top: 0;

  @media (min-width: 1240px) {
    flex-wrap: wrap;
  }
`;
