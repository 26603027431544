import { useMemo } from "react";

import useURLSearchState, { transforms } from "Hooks/useURLSearchState";

const useHosts = availableHosts => {
  const [hosts, setHosts] = useURLSearchState(
    availableHosts,
    "hosts",
    transforms.array
  );

  const selectedHosts = useMemo(
    () =>
      hosts.map(host =>
        availableHosts.find(fullHost => fullHost.startsWith(host))
      ),
    [hosts]
  );

  return [selectedHosts, setHosts];
};

export default useHosts;
