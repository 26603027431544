import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 8px;
  height: 24px;
`;

export const Icon = styled.img`
  opacity: ${props => (props.isConnected ? 1 : 0.5)};
  padding: 4px;
`;

export const Link = styled.a`
  text-decoration: none;
`;
