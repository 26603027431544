import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

import Button from "Components/Button";

export const FormWrapper = styled.form`
  padding: 0 32px 32px;
  color: ${props => props.theme.sectionText};
`;

export const Row = styled.div`
  margin: 0 -32px;
  padding: 0 32px;
  border-bottom: 1px solid ${props => props.theme.sectionBorder};
  .dropdown {
    .input-wrapper,
    .select-box {
      width: 150px;
    }
    .select-box__control,
    .select-box:hover .select-box__control {
      background: transparent;
      border: none;
    }
    .select-box__control--is-focused {
      margin: -1px -11px 0 0;
      padding-right: 11px;
    }
    .select-box__single-value {
      width: calc(100% - 32px);
      text-align: right;
    }
    .select-box__dropdown-indicator {
      padding: 0;
      margin-right: -4px;
    }
  }
  &.total-row {
    border-bottom: none;
    .total-wrapper {
      padding-top: 24px;
      padding-bottom: 32px;
      min-height: auto;
    }
    .description {
      color: ${props => props.theme.descriptionText};
      margin: 0;
      display: flex;
      align-items: center;
    }
    .info-dialog {
      cursor: default;
    }
  }

  &.info-icon {
    svg path {
      fill: ${props => props.theme.inputActiveBorder};
    }
    &:hover svg path {
      fill: ${props => props.theme.label};
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  .label {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    width: 354px;
  }
  .info,
  .options {
    display: flex;
    align-items: center;
  }
  .info-dialog {
    vertical-align: middle;
  }

  .total {
    height: 40px;
    width: 64px;
    box-sizing: border-box;
    line-height: 40px;
    padding: 0 12px;
    font-size: 16px;
    font-weight: 700;
    background: ${props => props.theme.inputBg};
    color: ${props => props.theme.text};
    margin-left: 16px;
  }
`;

export const Includes = styled.div`
  .label {
    font-size: 15px;
    margin-bottom: 16px;
    ${semiBoldAlias};
  }
  .features {
    display: flex;
    align-items: flex-start;
  }
  &.development .included ul {
    column-count: 2;
    width: 800px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  min-height: 32px;
  box-sizing: border-box;
  button + button {
    margin-left: 16px;
  }
`;

export const DeleteButton = styled(Button)`
  margin-right: auto !important;
`;
