import { createSelector } from "@reduxjs/toolkit";

const webappsSelector = createSelector(
  state => state,
  state => {
    if (!state.router.location || !state.deployment) return null;
    const [
      organizationId,
      projectId,
      environmentId
    ] = state?.router?.location?.pathname.split("/").slice(1, 4);
    const webapps = Object.keys(
      state?.deployment
        ?.getIn([
          "data",
          organizationId,
          projectId,
          environmentId,
          "current",
          "webapps"
        ])
        ?.toJS()
    );
    return webapps;
  }
);

export default webappsSelector;
