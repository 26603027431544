import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { capitalize } from "Libs/utils";
import AlertIcon from "Icons/AlertIcon";
import CopyableArea from "Components/CopyableArea";
import CheckboxField from "Components/fields/CheckboxField";
import Heading6 from "Components/styleguide/Heading6";
import Loading from "Components/Loading";
import ModalConfirmDelete from "Components/ModalConfirmDelete";

import * as S from "../styles";

const StatusSetting = ({
  acceptDelete,
  acceptDeleteToggle,
  closeModal,
  confirmDeleteModal,
  currentStatus,
  environment,
  project,
  redirectToPlan,
  toggleEnvironmentActivation,
  updatePlanModal
}) => {
  const intl = useIntl();

  return (
    <>
      {environment.is_main ? (
        <>
          <p className="description">
            {intl.formatMessage({
              id: "status_active_description"
            })}
          </p>
          <p className="description">
            {intl.formatMessage({
              id: "production_environment_text"
            })}
          </p>
        </>
      ) : currentStatus ? (
        <S.TextWrapper>
          <p className="description">
            {intl.formatMessage({
              id: "status_active_description"
            })}
          </p>
          <hr className="full-width" />
          <Heading6>
            {intl.formatMessage({
              id: "deactivate_environment"
            })}
          </Heading6>
          <S.WarningText>
            <AlertIcon color="#ff8359" />
            {intl.formatMessage({
              id: "status_active_delete_warning"
            })}
          </S.WarningText>
          <p className="description">
            {intl.formatMessage({
              id: "status_active_delete_description"
            })}
          </p>
          <ul className="description">
            <li>
              {intl.formatMessage({
                id: "status_active_delete_bullet_1"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "status_active_delete_bullet_2"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "status_active_delete_bullet_3"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "status_active_delete_bullet_4"
              })}
            </li>
          </ul>
          <hr />
          <S.AgreeWrapper>
            <CheckboxField
              label={intl.formatMessage({
                id: "status_active_delete_verify"
              })}
              id="verify"
              value={acceptDelete}
              onChange={acceptDeleteToggle}
              forId="verify-delete"
              required={true}
            />
          </S.AgreeWrapper>
          <ModalConfirmDelete
            isOpen={confirmDeleteModal}
            closeModal={closeModal}
            deleteFunction={() => {
              toggleEnvironmentActivation();
              this.closeModal();
            }}
            modalSize="small"
            title={`${capitalize(
              intl.formatMessage({
                id: "deactivate_environment"
              })
            )}?`}
            body={
              <span>
                {intl.formatMessage({
                  id: "deactivate_confirm_text"
                })}{" "}
                <strong>{environment?.title}</strong>{" "}
                {intl.formatMessage({ id: "from" })}{" "}
                <strong>{project?.title}</strong>?
              </span>
            }
            deleteText={intl.formatMessage({
              id: "deactivate_confirm_button"
            })}
          />
        </S.TextWrapper>
      ) : (
        <S.TextWrapper>
          {environment.status === "dirty" ? (
            <Loading iconOnly={true} />
          ) : (
            <>
              <p className="description">
                {intl.formatMessage({
                  id: "status_inactive_description"
                })}
              </p>
              <p className="description">
                {intl.formatMessage({
                  id: "delete_git_branch_text"
                })}
              </p>
              <CopyableArea
                id="environment-delete-branch"
                content={`git push platform --delete ${environment.name}`}
                singleLine={true}
              >
                git push platform --delete {environment.name}
              </CopyableArea>
              <hr className="full-width" />
              <Heading6>
                {intl.formatMessage({
                  id: "activate_environment"
                })}
              </Heading6>
              <p className="description">
                {intl.formatMessage({
                  id: "status_activate_description"
                })}
              </p>
              <ModalConfirmDelete
                isOpen={updatePlanModal}
                closeModal={closeModal}
                deleteFunction={() => {
                  if (project.data._links["#subscription_change"]) {
                    redirectToPlan(project);
                    closeModal();
                  } else {
                    closeModal();
                  }
                }}
                title={intl.formatMessage({
                  id: "environment.branch.error.title"
                })}
                body={
                  <span>
                    {project.data._links["#subscription_change"]
                      ? intl.formatMessage({
                          id: "environment.upgrade.admin.description"
                        })
                      : intl.formatMessage({
                          id: "environment.upgrade.standard.description"
                        })}
                  </span>
                }
                deleteText={
                  project.data._links["#subscription_change"]
                    ? intl.formatMessage({
                        id: "project.plan.upgrade"
                      })
                    : intl.formatMessage({ id: "okay" })
                }
                cancelButton={
                  project.data._links["#subscription_change"] ? true : false
                }
                cancelText={capitalize(intl.formatMessage({ id: "cancel" }))}
              />
            </>
          )}
        </S.TextWrapper>
      )}
    </>
  );
};

StatusSetting.propTypes = {
  acceptDelete: PropTypes.bool,
  acceptDeleteToggle: PropTypes.func,
  closeModal: PropTypes.func,
  confirmDeleteModal: PropTypes.func,
  currentStatus: PropTypes.bool,
  environment: PropTypes.object,
  intl: PropTypes.object,
  project: PropTypes.object,
  redirectToPlan: PropTypes.func,
  toggleEnvironmentActivation: PropTypes.func,
  updatePlanModal: PropTypes.bool
};

export default StatusSetting;
