import { fromJS, Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getAccesses = createAsyncThunk(
  "app/environment/accesses",
  async ({ environment }) => {
    if (!environment) return [];

    const accesses = await environment.getUsers();
    return accesses;
  }
);

export const addAccess = createAsyncThunk(
  "app/environment/access/add",
  async ({ environment, data }) => {
    if (!environment) return false;
    const result = await environment.addUser(data.email, data.role, false);
    const access = await result?.getEntity();
    return access;
  }
);

export const updateAccess = createAsyncThunk(
  "app/environment/access/update",
  async ({ access, role }) => {
    if (!access) return false;

    const result = await access.update({ role });
    const newAccess = await result?.getEntity();
    return newAccess;
  }
);

export const deleteAccess = createAsyncThunk(
  "app/project/access/delete",
  async ({ access }) => {
    if (!access) return false;
    await access.delete();
    return access;
  }
);

const setError = (state, action) =>
  state.set("errors", action.error.message).set("loading", false);

const environmentAccesses = createSlice({
  name: "environmentAccesses",
  initialState: Map({ data: new Map(), loading: "idle" }),
  reducers: {
    editAccess(state, action) {
      return state
        .delete("errors")
        .set("status", "idle")
        .set("edited", action.payload.id);
    }
  },
  extraReducers: {
    // GET LIST
    [getAccesses.pending]: state => state.set("loading", true),
    [getAccesses.fulfilled]: (state, action) => {
      const { organizationId, projectId, environment } = action.meta.arg;
      return state
        .setIn(
          ["data", organizationId, projectId, environment.id],
          fromJS(
            action.payload.reduce((envAccesses, access) => {
              envAccesses[access.id] = access;
              return envAccesses;
            }, {})
          )
        )
        .set("loading", false)
        .set("status", "idle");
    },
    [getAccesses.rejected]: (state, action) => setError(state, action),

    // ADD
    [addAccess.pending]: state =>
      state.set("status", "pending").delete("errors"),
    [addAccess.fulfilled]: (state, action) => {
      const { organizationId, projectId, environment } = action.meta.arg;
      return state
        .setIn(
          [
            "data",
            organizationId,
            projectId,
            environment.id,
            action.payload.id
          ],
          fromJS(action.payload)
        )
        .set("status", "added")
        .set("edited", action.payload.id);
    },
    [addAccess.rejected]: (state, action) =>
      state.set("errors", action.payload?.errors).set("status", "rejected"),

    // UPDATE
    [updateAccess.pending]: state =>
      state.set("status", "pending").delete("errors"),
    [updateAccess.fulfilled]: (state, action) => {
      const { organizationId, projectId, environmentId } = action.meta.arg;
      return state
        .setIn(
          ["data", organizationId, projectId, environmentId, action.payload.id],
          fromJS(action.payload)
        )
        .set("status", "updated");
    },
    [updateAccess.rejected]: (state, action) =>
      state.set("errors", action.payload.errors).set("status", "rejected"),

    // DELETE
    [deleteAccess.pending]: state =>
      state.set("status", "pending").delete("errors"),
    [deleteAccess.fulfilled]: (state, action) => {
      const { organizationId, projectId, environmentId } = action.meta.arg;
      return state
        .deleteIn([
          "data",
          organizationId,
          projectId,
          environmentId,
          action.payload.id
        ])
        .set("status", "deleted");
    },
    [deleteAccess.rejected]: (state, action) =>
      state.set("errors", action.payload.errors).set("status", "rejected")
  }
});

export const { editAccess } = environmentAccesses.actions;
export default environmentAccesses.reducer;

export const environmentAccessErrorsSelector = state =>
  state.environmentAccess?.get("errors", {});

export const isLoadingSelector = state =>
  state.environmentAccess?.get("loading");
