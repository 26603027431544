import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import client from "Libs/platform";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import Heading2 from "Components/styleguide/Heading2";
import EmptyVouchers from "./EmptyVouchers";
import VouchersList from "./VouchersList";

import { organizationSelector } from "Reducers/organization";

const Vouchers = ({ organizationId }) => {
  const [vouchers, setVouchers] = useState([]);

  const organization = useSelector(state =>
    organizationSelector(state, {
      organizationId
    })
  );

  useEffect(
    () => {
      let isCanceled = false;
      const getVouchers = async () => {
        const voucherData = await client.getOrganizationVouchers(
          organizationId
        );
        if (isCanceled) {
          return;
        }
        setVouchers(voucherData.vouchers);
      };

      if (organization) {
        getVouchers();
      }

      return () => (isCanceled = true);
    },
    [organization]
  );
  const hasVouchers = vouchers.length === 0;

  return (
    <div>
      <Heading2>
        <FormattedMessage id="vouchers" />
      </Heading2>
      {hasVouchers ? <EmptyVouchers /> : <VouchersList vouchers={vouchers} />}
    </div>
  );
};

Vouchers.propTypes = {
  organizationId: PropTypes.string
};

export default Vouchers;
