import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import Details from "./Details/Page";
import Configure from "./Configure/Page";
import Setup from "./Setup/Page";

const Routes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/setup`}>
        <Setup />
      </Route>
      <Route path={`${path}/:integrationId/configure`}>
        <Configure />
      </Route>
      <Route
        path={[
          `${path}/:integrationId/log/:activityId`,
          `${path}/:integrationId?`
        ]}
      >
        <Details />
      </Route>
    </Switch>
  );
};

export default Routes;
