import React from "react";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";

import SuccessStateIcon from "Icons/SuccessStateIcon";

import * as S from "./StepTitle.styles";

const StepTitle = ({ isActive, isComplete, step, onClick }) => {
  const intl = useIntl();

  const handleClick = e => {
    e?.preventDefault();
    if (isComplete) {
      onClick(step);
    }
  };

  return (
    <S.Wrapper
      className={`step${isActive ? " active" : ""}${
        isComplete ? " complete" : ""
      }`}
      isActive={isActive}
      onClick={handleClick}
      onKeyUp={e => {
        if (e.keyCode === 13 || e.keyCode === 27 || e.keyCode === 32) {
          handleClick();
        }
      }}
      tabIndex={isComplete ? "0" : ""}
      role={isComplete ? "button" : ""}
    >
      {isComplete && <SuccessStateIcon color="#23b7b7" />}
      <S.Name className="name">
        {intl.formatMessage({ id: `setup.breadcrumb.steps.${step}` })}
      </S.Name>
    </S.Wrapper>
  );
};

StepTitle.propTypes = {
  isActive: Proptypes.bool,
  isComplete: Proptypes.bool,
  step: Proptypes.string,
  onClick: Proptypes.func
};

export default StepTitle;
