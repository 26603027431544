import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import { capitalize } from "Libs/utils";
import Button from "UI/Button";
import Loading from "Components/Loading";
import ButtonWrapper from "Components/ButtonWrapper";
import AccessibleTooltip from "Components/AccessibleTooltip";
import * as S from "./styles";

const EnvironmentSettingWrapper = ({
  cancel,
  children,
  close,
  deletePermission,
  hideButtons,
  incompleteConfigs,
  isUpdateLoading,
  itemId,
  save,
  savePermission,
  saveText = "",
  title
}) => {
  const intl = useIntl();

  return (
    <S.FormWrapper
      id={`environment-${itemId}-form`}
      aria-labelledby="environment-basics-heading"
    >
      <LiveMessage message={title} aria-live="polite" />
      {isUpdateLoading ? (
        <Loading />
      ) : (
        <>
          {children}
          {(deletePermission || save) &&
            !hideButtons && (
              <ButtonWrapper>
                {savePermission &&
                  save && (
                    <AccessibleTooltip
                      tooltipProps={{
                        children: intl.formatMessage({
                          id: "is_controlled.missing_configs"
                        }),
                        place: "bottom",
                        disable: !incompleteConfigs
                      }}
                    >
                      <Button
                        disabled={incompleteConfigs}
                        id={`environment-basics-${itemId}-save-btn`}
                        type="submit"
                        aria-label={
                          saveText || intl.formatMessage({ id: "save" })
                        }
                        onClick={save}
                      >
                        {capitalize(
                          saveText || intl.formatMessage({ id: "save" })
                        )}
                      </Button>
                    </AccessibleTooltip>
                  )}
                {cancel &&
                  save && (
                    <Button
                      id={`environment-basics-${itemId}-cancel-btn`}
                      variant="secondary"
                      aria-label={intl.formatMessage({ id: "cancel" })}
                      onClick={() => {
                        close();
                        cancel();
                      }}
                    >
                      {capitalize(intl.formatMessage({ id: "cancel" }))}
                    </Button>
                  )}
              </ButtonWrapper>
            )}
        </>
      )}
    </S.FormWrapper>
  );
};

EnvironmentSettingWrapper.propTypes = {
  title: PropTypes.string,
  close: PropTypes.func,
  save: PropTypes.func,
  saveText: PropTypes.string,
  children: PropTypes.node,
  deletePermission: PropTypes.bool,
  savePermission: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  itemId: PropTypes.string,
  cancel: PropTypes.func,
  hideButtons: PropTypes.bool,
  incompleteConfigs: PropTypes.bool
};

export default EnvironmentSettingWrapper;
