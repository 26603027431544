import styled from "styled-components";

const TicketDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: ${props => props.theme.sectionText};
  white-space: pre-wrap;

  :last-child {
    margin-bottom: 0;
  }
`;

export default TicketDescription;
