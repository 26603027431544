import styled from "styled-components";

export const UnstyledList = styled.ul`
  list-style: none;
  margin-top: 0;
  margin-bottom: 32px;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
  }
`;
