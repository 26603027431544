import { useState, useEffect } from "react";

export const useUserPicture = picture => {
  const [src, setSrc] = useState();

  useEffect(
    () => {
      if (!picture) {
        setSrc(null);
      } else if (typeof picture === "object") {
        const reader = new FileReader();
        reader.onload = () => setSrc(reader.result);
        reader.readAsDataURL(picture);
      } else if (typeof picture === "string") {
        setSrc(picture);
      }
    },
    [picture]
  );

  return src;
};

export default useUserPicture;
