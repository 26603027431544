import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { ThemeContext } from "styled-components";

import { themeHelpers } from "Libs/theme";
import CheckMark from "Icons/CheckMark";

import * as S from "./styles";

const DEFAULTS = [
  { key: "project.plan.includes.user", value: 1 },
  { key: "project.plan.includes.envs", value: 3 },
  { key: "project.plan.includes.storage", value: 5 }
];

const PLANS = [
  {
    name: ["D", "development"],
    includes: [{ key: "project.plan.includes.branch" }]
  },
  {
    name: ["small"],
    defaults: [{ key: "project.plan.includes.envs", value: 1 }],
    includes: [
      { key: "project.plan.includes.additional_service", value: 1 },
      { key: "project.plan.includes.production_site" },
      { key: "project.plan.includes.custom_domains" },
      { key: "project.plan.includes.ssl" }
    ]
  },
  {
    name: ["S", "standard"],
    includes: [
      {
        key: "gb_memory",
        value: 0.8
      },
      { key: "low_med_traffic_websites" },
      { key: "handles_thousands" }
    ]
  },
  {
    name: ["M", "medium"],
    includes: [
      {
        key: "gb_memory",
        value: 3
      },
      { key: "med_higher_traffic_websites" },
      { key: "multi_app_multi_data_apps" },
      { key: "suitable_five_hund" }
    ]
  },
  {
    name: ["L", "large"],
    includes: [
      {
        key: "gb_memory",
        value: 6
      },
      { key: "higher_traffic_websites" },
      { key: "multiple_backends" },
      { key: "complex_micro_service_applications" },
      { key: "scales_mil" }
    ]
  },
  {
    name: ["XL", "xlarge"],
    includes: [
      {
        key: "gb_memory",
        value: 12
      },
      { key: "higher_traffic_websites" },
      { key: "multiple_backends" },
      { key: "memory_intensive_apps" },
      { key: "complex_micro_service_applications" },
      { key: "scales_few_mil" }
    ]
  },
  {
    name: ["XXL", "2xlarge"],
    includes: [
      {
        key: "gb_memory",
        value: 24
      },
      { key: "higher_traffic_websites" },
      { key: "multiple_backends" },
      { key: "memory_intensive_apps" },
      { key: "complex_micro_service_applications" },
      { key: "scales_mils" }
    ]
  }
];

const PlanInfo = ({ plan, project }) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);

  const details = PLANS.find(elt => elt.name.includes(plan));
  const defaults = DEFAULTS.map(elt => {
    if (!details?.defaults) return elt;
    const override = details?.defaults.find(obj => obj.key === elt.key);
    if (override) {
      return override;
    }
    return elt;
  });

  return (
    <S.Wrapper>
      <S.FeatureList>
        {defaults.map((elt, i) => (
          <S.FeatureItem key={`default-${i}`}>
            <CheckMark color={themeHelpers(theme, "primaryColor")} />{" "}
            <span>
              {intl.formatMessage(
                {
                  id: elt.key
                },
                {
                  value: elt.value,
                  b: txt => <strong>{txt}</strong> // eslint-disable-line react/display-name
                }
              )}
            </span>
          </S.FeatureItem>
        ))}
      </S.FeatureList>

      {details?.includes && (
        <S.FeatureList>
          {details.includes.map((elt, i) => (
            <S.FeatureItem key={`include-${i}`}>
              <CheckMark color={themeHelpers(theme, "primaryColor")} />{" "}
              <span>
                {intl.formatMessage(
                  {
                    id: elt.key
                  },
                  {
                    value: elt.value,
                    defaultBranch: project?.default_branch || "master",
                    b: txt => <strong>{txt}</strong> // eslint-disable-line react/display-name
                  }
                )}
              </span>
            </S.FeatureItem>
          ))}
        </S.FeatureList>
      )}
    </S.Wrapper>
  );
};

PlanInfo.propTypes = {
  plan: PropTypes.string,
  project: PropTypes.object
};

export default PlanInfo;
