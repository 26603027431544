import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";

import Heading2 from "Components/styleguide/Heading2";
import { EmptyVouchers as EmptyVouchersIllustration } from "Components/illustrations";

const EmptyVouchersContainer = styled.div`
  text-align: center;
`;
const EmptyVouchersHeading = styled(Heading2)`
  margin-top: 32px;
`;

function EmptyVouchers() {
  const intl = useIntl();

  return (
    <EmptyVouchersContainer>
      <EmptyVouchersIllustration />
      <EmptyVouchersHeading>
        {intl.formatMessage({ id: "no_vouchers_available" })}
      </EmptyVouchersHeading>
    </EmptyVouchersContainer>
  );
}

export default EmptyVouchers;
