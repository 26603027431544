import React from "react";
import PropTypes from "prop-types";

import { Skeleton, Square } from "Components/Skeleton";
import * as S from "../Metric/Metric.styles";

const LargeMetricSkeleton = ({ name }) => (
  <Skeleton synchronize style={{ overflow: "visible" }}>
    <S.Layout>
      <S.Row style={{ flexGrow: 2 }}>
        {name ? name : <Square fixedWidth height="24px" width="80px" />}
        <div>
          <Square height="24px" fixedWidth width="110px" />
        </div>
      </S.Row>
      <S.Row>
        <Square height="24px" fixedWidth width="110px" />
        <Square height="24px" fixedWidth width="32px" margin="0 0 0 12px" />
      </S.Row>
    </S.Layout>
    <Square height="226px" width="100%" margin="12px 0 0 0" />
  </Skeleton>
);

LargeMetricSkeleton.propTypes = {
  name: PropTypes.node
};

export default LargeMetricSkeleton;
