import styled from "styled-components";

import Pane from "../../../../common/components/Pane";
import H from "../../../../common/components/Header";

export const Wrapper = styled(Pane)`
  margin-bottom: 32px;
  padding: 32px 24px;

  @media screen and (min-width: 768px) {
    margin-right: 32px;
    flex: calc(50% - 32px);
    padding: 32px 32px 18px 32px;
  }
  @media screen and (min-width: 1200px) {
    margin-right: 0;
  }
`;

export const Header = styled(H)`
  display: flex;
  a {
    margin-left: auto;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ActivityList = styled.dl`
  margin: 0 0 16px 0;
  dt {
    margin: 0 0 16px 0;
    padding: 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
  }
  dd {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    & + dd {
      margin-top: 16px;
    }
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
