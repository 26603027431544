import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { Map } from "immutable";
import momentjs from "moment";

import { formatAsCurrency } from "Libs/utils";

import * as S from "./styles";

const TrialWidget = ({ hasPaymentSource = false }) => {
  const { formatMessage } = useIntl();

  const me = useSelector(state => state.app?.get("me", new Map())?.toJS());
  const { current_trial } = me.data;

  if (!current_trial?.active) return null;
  const credit =
    (current_trial.spend?.amount || 0) - (current_trial.current?.amount || 0);

  return (
    <S.Wrapper level="low">
      <S.Header>
        <S.Remaining>
          {formatMessage({
            id: "invoice.trial.remaining",
            defaultMessage: "Free trial credit remaining"
          })}
          {current_trial.spend?.currency_code && (
            <S.Credit>
              {formatAsCurrency(credit, current_trial.spend.currency_code)}
            </S.Credit>
          )}
        </S.Remaining>
        {current_trial.expiration && (
          <S.Expiration>
            {formatMessage(
              {
                id: "invoice.trial.expires",
                defaultMessage: "Expires: {date}"
              },
              {
                date: momentjs(current_trial.expiration).format(
                  "D MMMM YYYY [at] h:mm a"
                )
              }
            )}
          </S.Expiration>
        )}
      </S.Header>

      {!me.projects.length || !hasPaymentSource}
      <S.Content>
        <p>
          {formatMessage(
            {
              id: `invoice.trial.with${me.projects.length ? "" : "out"}_project`
            },
            {
              // eslint-disable-next-line react/display-name
              a: txt => <Link to="/projects/create-project">{txt}</Link>
            }
          )}
        </p>
      </S.Content>
    </S.Wrapper>
  );
};

TrialWidget.propTypes = {
  hasPaymentSource: PropTypes.bool
};

export default TrialWidget;
