import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  justify-content: space-between;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin-bottom: 40px;
  flex-direction: column;
  h2 {
    color: ${props => props.theme.text} !important;
    margin-bottom: 16px;
  }

  img {
    max-width: 40px;
    max-height: 40px;
    margin-bottom: 16px;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
`;

export const Form = styled.div`
  flex: 1;
  .field,
  .dropdown .input-wrapper {
    margin-bottom: 24px;
    width: 316px;
  }
  .dropdown .input-wrapper .select-box {
    width: 316px;
    .select-box__placeholder {
      color: ${props => props.theme.subtitleText};
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 24px 0;

  button + button {
    margin-left: 10px;
  }
`;
