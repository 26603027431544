import styled, { css } from "styled-components";

export const Wrapper = styled.section`
  margin-top: 24px;
`;

export const Name = styled.span`
  font-size: 15px;
  line-height: 24px;
  line-height: 24px;
  margin: 0 0 0 16px;
  white-space: nowrap;
`;

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  padding: 12px 20px;
  width: 200px;
  line-height: 0;
  cursor: pointer;
  border-radius: 2px;
  box-sizing: border-box;
  ${props =>
    props.theme.name === "dark" &&
    css`
      background: ${props => props.theme.orderTableBorder};
    `};
  &:hover {
    ${props =>
      props.theme.name !== "dark" &&
      css`
        box-shadow: 0px 1px 4px rgba(152, 160, 171, 0.4);
      `};
  }
`;
