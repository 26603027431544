import styled from "styled-components";

const RightPane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 960px) {
    align-items: center;
    width: 100%;
  }
`;

export default RightPane;
