import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px;
  margin-bottom: 24px;
  background: ${props => props.theme.sectionBg};
  border-radius: 2px;
`;

export const Header = styled.header`
  display: flex;
  h2 {
    flex: 1;
    .info-dialog {
      vertical-align: bottom;
    }
  }
`;

export const Cmds = styled.section`
  display: block;

  article {
    display: inline-block;
    width: 400px;
    margin-right: 32px;

    label {
      display: block;
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: ${props => props.theme.sectionTextLight};
    }
  }
`;
