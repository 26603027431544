import React from "react";
import { useParams } from "react-router-dom";

import ProjectOverview from "../containers/ProjectOverview";

import withProjectRedirect from "../../../common/hocs/withProjectRedirect";

const Page = () => {
  const { projectId, organizationId } = useParams();

  return (
    <ProjectOverview projectId={projectId} organizationId={organizationId} />
  );
};

export default withProjectRedirect(Page);
