import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import ContentLayout from "Components/ContentLayout";
import ProjectVariables from "./containers/ProjectVariables";

const Routes = () => {
  const { path, url } = useRouteMatch();

  return (
    <ContentLayout>
      <Switch>
        <Route path={`${path}/-/new`} exact>
          <ProjectVariables isNew={true} url={url} />
        </Route>
        <Route path={`${path}/:variableId?`}>
          <ProjectVariables url={url} />
        </Route>
      </Switch>
    </ContentLayout>
  );
};

export default Routes;
