import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import useInput from "Hooks/useInput";
import { capitalize } from "Libs/utils";

import { DOCS_OWN_DOMAIN_URL } from "Constants/documentationUrls";

import Button from "UI/Button";
import ButtonWrapper from "Components/ButtonWrapper";
import CheckboxField from "Components/fields/CheckboxField";
import Error from "Components/Error";
import InfoDialog from "Components/InfoDialog";
import Loading from "Components/Loading";

import * as S from "./DomainForm.styles";

const DomainForm = ({
  errors = {},
  domain,
  isChanged,
  isChangedUpdate,
  isDefault = false,
  isLoading,
  onCancel,
  onDelete,
  onSave
}) => {
  const intl = useIntl();

  const { inputs, handleInputChange } = useInput(
    {
      name: domain?.name || "",
      isDefault
    },
    isChangedUpdate
  );

  const isNew = !domain?.id;

  const showSaveAndCancelButtons = isChanged || isNew;
  const showDeleteButton =
    domain?.hasPermission && domain?.hasPermission("#delete");

  const handleSave = e => {
    e?.preventDefault();

    if (isNew && !inputs.name) return;

    onSave({
      id: domain?.id || null,
      name: inputs.name,
      isDefault: inputs.isDefault
    });
  };

  return (
    <S.Wrapper>
      <LiveMessage
        message={intl.formatMessage({ id: `${isNew ? "edit" : "add"}_domain` })}
        aria-live="polite"
      />

      <S.FormWrapper aria-labelledby="edit-domain-heading">
        {errors.error && <Error>{errors.error}</Error>}

        <S.Description>
          <InfoDialog
            title={intl.formatMessage({ id: "settings.domains.info.title" })}
            text={intl.formatMessage({ id: "settings.domains.info.text" })}
            linkText={intl.formatMessage({
              id: "learnmore"
            })}
            to={DOCS_OWN_DOMAIN_URL}
          />
          {intl.formatMessage(
            { id: "settings.domains.edit" },
            {
              code: (...chunks) => <code>{chunks}</code> // eslint-disable-line react/display-name
            }
          )}
        </S.Description>

        <S.InputWrapper>
          <S.Input
            id="name"
            label={intl.formatMessage({ id: "settings.domains.name" })}
            value={inputs.name}
            onChange={elt => handleInputChange(elt)}
            error={errors["name"]}
            isDisabled={!isNew}
          />
        </S.InputWrapper>

        <S.Info>
          {intl.formatMessage({
            id: "is_default.description"
          })}
        </S.Info>

        <S.Option>
          <CheckboxField
            forId="isDefault"
            label={intl.formatMessage({
              id: "settings.domains.is_default"
            })}
            value={inputs.isDefault}
            onChange={elt => handleInputChange(elt)}
          />
        </S.Option>

        <ButtonWrapper>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {showSaveAndCancelButtons && (
                <>
                  <Button
                    id="save-domain-btn"
                    type="submit"
                    aria-label={intl.formatMessage({
                      id: isNew ? "add_domain" : "save"
                    })}
                    onClick={handleSave}
                  >
                    {capitalize(
                      intl.formatMessage({
                        id: isNew ? "add_domain" : "save"
                      })
                    )}
                  </Button>
                  <Button
                    id="cancel-domain-btn"
                    type="button"
                    variant="secondary"
                    aria-label={intl.formatMessage({ id: "cancel" })}
                    onClick={onCancel}
                  >
                    {capitalize(intl.formatMessage({ id: "cancel" }))}
                  </Button>
                </>
              )}

              {showDeleteButton && (
                <S.DeleteButton
                  variant="tertiary"
                  id="project-domain-delete-btn"
                  type="button"
                  aria-label={intl.formatMessage({ id: "delete" })}
                  onClick={onDelete}
                >
                  {capitalize(intl.formatMessage({ id: "delete" }))}
                </S.DeleteButton>
              )}
            </>
          )}
        </ButtonWrapper>
      </S.FormWrapper>
    </S.Wrapper>
  );
};

DomainForm.propTypes = {
  domain: PropTypes.object,
  errors: PropTypes.object,
  isChanged: PropTypes.bool,
  isChangedUpdate: PropTypes.func,
  isDefault: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func
};

export default DomainForm;
