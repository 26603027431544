import styled from "styled-components";

import { makePalette } from "Libs/theme";

const theme = makePalette(palette => ({
  ruler: {
    default: palette.ebonyLight,
    dark: palette.snow
  }
}));

export const Layout = styled.line`
  stroke-width: 1;
  stroke-dasharray: 4 4;
  stroke: ${theme.ruler};
  pointer-events: none;
`;
