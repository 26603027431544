import styled from "styled-components";

export const Wrapper = styled.section`
  text-align: center;
  margin-top: 130px;
  width: 100%;
  h2 {
    margin-bottom: 20px;
  }
  p {
    color: ${props => props.theme.sectionTextLight};
    font-size: 14px;
    width: 432px;
    max-width: 100%;
    margin: 0 auto 33px;
  }
  &.no-projects {
    span.create-project {
      display: inline-block;
      margin-bottom: 170px;
    }
  }
`;

export const ImageWrapper = styled.article`
  margin: 0 auto 24px;
  width: 156px;
  svg {
    max-width: 100%;
    height: auto;
  }
  &.no-projects-image {
    width: 664px;
    margin-bottom: 60px;
  }
`;
