import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  gap: 10px;
`;

export const Layout = styled.div`
  padding: 0;
  .rccs {
    float: right;
  }
`;

export const PaymentWrapper = styled.div`
  h3 {
    margin-top: 0;
    font-size: 15px;
    line-height: 33px;
  }
`;

export const CardWrapper = styled.div`
  margin-bottom: 32px;
  max-width: 100%;
  .checkbox-field {
    margin-bottom: 0;
    label {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .InputElement.is-empty {
    font-size: 14px;
    color: #4a4a4a;
    opacity: 0.4;
  }
  &.card-form-credit-card {
    width: 434px;
  }
  button {
    display: none;
  }
`;
