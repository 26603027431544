import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  div + img {
    margin-left: 88px;
  }

  background: ${props => props.theme.sectionBg};
  padding: 32px 72px 32px 32px;
  min-width: 300px;
  flex: 0.5;
  margin-top: 44px;
  min-height: 143px;

  border-radius: 4px;
`;

export const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 12px;
  color: ${props => props.theme.sectionHeading};
  text-transform: capitalize;
  ${props => props.withTopSpacing && "margin-top: 32px"};
  ${props =>
    props.border &&
    `
    border-${props.border}: 1px solid ${props.theme.sectionBorder};
    margin-${props.border}: 31px;
    padding-${props.border}: 30px;
  `};
`;

export const Text = styled.span`
  color: ${props => props.theme.sectionText};
  font-size: 14px;
  line-height: 22px;
`;

export const LearnMore = styled.div`
  margin-top: 12px;
`;

export const IntroductionIllustration = styled.img`
  width: 132px;
  height: 94px;
  margin-top: 19px;
`;

export const TextAndLink = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`;

export const LeftPane = styled.div`
  display: flex;
  flex-direction: column;
`;
