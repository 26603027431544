import React from "react";
import { Redirect } from "react-router-dom";

import client from "Libs/platform";

const Page = () => {
  client.wipeToken();

  return <Redirect push to="/" />;
};

export default Page;
