import styled, { css } from "styled-components";

import Description from "Components/fields/Description";

export const Layout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const FileInput = styled.input.attrs({ type: "file" })`
  display: none;
`;

export const FileDescription = styled(Description)`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  color: ${props => props.theme.label};

  span {
    font-size: 13px;
    margin-top: 6px;
  }
`;

export const PreviewWrapper = styled.div`
  height: auto;
  width: 80px;
  height: 80px;
  overflow: hidden;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px 0 0;
  border-radius: 2px;

  ${props =>
    props.empty &&
    css`
      border: ${props => `1px solid ${props.theme.subsectionBorder}`};
      svg {
        width: 18px;
        height: auto;
      }
    `};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MaxSizeText = styled.span`
  color: ${props => props.theme.subtitleText};
  margin-left: 8px;
`;
