import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import InfoDialog from "Components/InfoDialog";

import * as S from "./styles";

const Permissions = ({ permissions = [] }) => {
  const intl = useIntl();
  return (
    <S.Layout>
      {permissions.map((p, index) => (
        <InfoDialog
          key={p?.value}
          text={intl.formatMessage({
            id: `organization.permissions.${p?.value}.info`
          })}
          iconWidth="auto"
          align="left"
          icon={
            <>
              {p?.label}
              {index !== permissions.length - 1 && ","}
            </>
          }
        />
      ))}
    </S.Layout>
  );
};

Permissions.propTypes = {
  permissions: PropTypes.array
};

export default Permissions;
