import styled from "styled-components";

import BaseButtonWrapper from "Components/ButtonWrapper";
import DefaultLabel from "Components/fields/Label";
import BaseButton from "UI/Button";

import { themeHelpers } from "Libs/theme";

export const ButtonWrapper = styled(BaseButtonWrapper)`
  margin-top: 48px;
  justify-content: space-between;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.sectionBg};
  padding: 32px 40px;
  flex: 1;
  border-radius: 2px;
  max-width: 276px;
  min-width: 276px;
  height: 100%;
  min-height: calc(100vh - 120px - 42px);
  @media (min-width: 1920px) {
    padding-left: 60px;
  }

  @media (max-width: 960px) {
    max-width: 100%;
    margin-left: 60px;
  }
`;

export const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  color: ${props => props.theme.sectionText};
  text-transform: capitalize;
  ${props => props.withTopSpacing && "margin-top: 32px"};
  ${props =>
    props.border &&
    `
    border-${props.border}: 1px solid ${props.theme.sectionBorder};
    margin-${props.border}: 31px;
    padding-${props.border}: 30px;
  `};
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 16px;
  background-color: white;
  background: ${props => props.theme.sectionBg};
  .field {
    width: 100%;
  }
`;

export const Info = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.textLight};
`;

export const LabelInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const OrgniazationLabelValue = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled(DefaultLabel)`
  color: ${props => props.theme.envLabel};
  margin-bottom: 0px;
  margin-right: 12px;
`;

export const OrganizationLabel = styled.h3`
  margin: 0px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: ${props => props.theme.sectionHeading};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
`;

export const Separator = styled.hr`
  margin: 24px 0px;
`;

export const DeleteButton = styled(BaseButton)`
  max-width: 190px;
`;

export const EditLink = styled(BaseButton)`
  margin-left: 16px;
`;

const InputField = styled.div`
  input {
    background: none;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  input:focus {
    outline: none;
    box-shadow: none;
    background: none;
    border: none;
  }

  input.changed {
    outline: none;
    border: none;
    box-shadow: none;
    background: none;
  }
`;

export const LabelInputField = styled(InputField)`
  max-height: 24px;
  input {
    max-height: 24px;
    padding: 0px;
  }
  input:focus {
    padding: 0px;
  }

  input.changed {
    padding: 0px;
  }

  .input-field {
    padding: 0px;
    margin: 0px;
  }
`;

export const NameInputField = styled(InputField)`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-left: 16px;
  background: ${props =>
    props.theme.name === "dark"
      ? themeHelpers(props.theme, "darkShade")
      : props.theme.inputBg};

  input {
    background: none;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;

    padding: 0px;
  }

  input:focus {
    padding: 0px;
  }
`;

export const NameInputPrefix = styled.div`
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 8px;
  color: ${props => props.theme.subNavTextRev};
`;

export const NameInputPrefixValue = styled.span`
  color: ${props => props.theme.textLight};
`;
