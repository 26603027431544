import styled from "styled-components";

import { breakpoints, semiBoldAlias, vwGrid } from "Libs/theme";

export const InfoLayout = styled.div`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  @media (min-width: ${breakpoints[2]}) {
    padding: 32px;
  }
`;

export const SummaryLayout = styled(InfoLayout)`
  border-bottom: 1px solid ${props => props.theme.tableBorder};
  @media (min-width: ${breakpoints[2]}) {
    display: none;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
`;

export const Column = styled.div`
  width: 30%;
  label {
    display: inline-block;
    width: 88px;
    margin-right: 22px;
    padding-top: 2px;
  }
`;

export const InfoTable = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  width: 100%;
  @media (min-width: ${breakpoints[2]}) {
    grid-template-columns: calc(${vwGrid.standard} - 32px) repeat(
        auto-fill,
        ${vwGrid.standard}
      );
    grid-column-gap: 32px;
  }
  @media (min-width: ${breakpoints[3]}) {
    grid-template-columns: calc(${vwGrid.wide} - 32px) repeat(
        auto-fill,
        ${vwGrid.wide}
      );
  }
  @media (min-width: 1920px) {
    grid-template-columns: calc(${vwGrid.extraWide} - 32px) repeat(
        auto-fill,
        ${vwGrid.extraWide}
      );
  }
`;

export const CommonSpan = styled.span`
  grid-column: auto / span 3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.sectionText};
`;

export const Plan = styled(CommonSpan)`
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const Total = styled.div`
  background: ${props => props.theme.inputBg};
  line-height: 40px;
  height: 40px;
  padding: 0 16px;
  color: ${props => props.theme.sectionText};
  font-size: 14px;
  width: 264px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 42px;
  span {
    display: flex;
    align-items: center;
  }
`;

export const Amount = styled.span`
  font-size: 16px;
  ${semiBoldAlias};
`;
