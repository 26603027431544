import styled from "styled-components";

export const Layout = styled.div`
  .header-arrow + .header-arrow {
    border-top: 1px solid #d8e2ef;
  }
`;

export const EmptyWrapper = styled.div`
  text-align: center;
  margin: 96px 0 130px 0;
  width: 100%;
  h2 {
    margin-bottom: 16px;
  }
  p {
    color: ${props => props.theme.sectionTextLight};
    font-size: 14px;
    width: 432px;
    max-width: 100%;
    margin: 0 auto 40px;
  }
  span.create-project {
    display: inline-block;
    margin-bottom: 170px;
  }
`;

export const EmptyImage = styled.div`
  margin: 0 auto 48px;
  width: 156px;
  svg {
    max-width: 100%;
    height: auto;
  }
`;
