import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import * as S from "./Specificities.styles";

const Health = ({ integration }) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      {integration.from_address && (
        <p>
          <label>
            {intl.formatMessage({
              id: "integration.health.from_address",
              defaultMessage: "From address"
            })}
          </label>
          <a
            href={`mailto:${integration.from_address}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {integration.from_address}
          </a>
        </p>
      )}

      {integration.recipients?.length && (
        <p>
          <label>
            {intl.formatMessage({
              id: "integration.health.recipients",
              defaultMessage: "Recipients"
            })}
          </label>
          {integration.recipients
            .map((mail, i) => (
              <S.EmailRecipient key={`recipient-${i}`}>{mail}</S.EmailRecipient>
            ))
            .reduce((prev, curr) => [prev, ", ", curr])}
        </p>
      )}

      {integration.url && (
        <p>
          <label>
            {intl.formatMessage({
              id: "integration.health.url",
              defaultMessage: "Webhook url"
            })}
          </label>
          <a href={integration.url} target="_blank" rel="noopener noreferrer">
            {integration.url}
          </a>
        </p>
      )}

      {integration.shared_key && (
        <p>
          <label>
            {intl.formatMessage({
              id: "integration.health.shared_key",
              defaultMessage: "Shared key"
            })}
          </label>
          <span>{integration.shared_key}</span>
        </p>
      )}

      {integration.routing_key && (
        <p>
          <label>
            {intl.formatMessage({
              id: "integration.health.routing_key",
              defaultMessage: "Routing key"
            })}
          </label>
          <span>{integration.routing_key}</span>
        </p>
      )}

      {integration.channel && (
        <p>
          <label>
            {intl.formatMessage({
              id: "integration.health.channel",
              defaultMessage: "Channel"
            })}
          </label>
          <a
            href={`https://slack.com/app_redirect?channel=${
              integration.channel
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            #{integration.channel}
          </a>
        </p>
      )}
    </S.Wrapper>
  );
};

Health.propTypes = {
  integration: PropTypes.object
};

export default Health;
