import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

export const DisplayName = styled.div`
  display: flex;
`;

export const Tag = styled.div`
  margin: 0 4px 0 0px;
  padding: 2px 8px;
  border-radius: 2px;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 20px;
  background: ${props => props.theme.background};
  color: ${props => props.theme.textLight};
`;

export const EmptyState = styled.div`
  text-align: center;
  background: ${props => props.theme.sectionBg};
  padding: 16px 0px;
  border-radius: 4px;

  font-size: 14px;
  line-height: 24px;
  ${semiBoldAlias};
`;
