import React from "react";

import ServiceOverview from "./components/ServiceOverview";
import ServiceInfoPanel from "../containers/ServiceInfoPanel";

const InfoPanel = () => {
  return (
    <ServiceInfoPanel>
      <ServiceOverview />
    </ServiceInfoPanel>
  );
};

export default InfoPanel;
