import styled from "styled-components";

import { breakpoints } from "Libs/theme";
import BaseButtonWrapper from "Components/ButtonWrapper";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;

  @media (min-width: ${breakpoints[1]}) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.sectionBg};
  margin-left: -24px;
  margin-right: -24px;
  // Override Settings container margin
  margin-top: -37px;
  padding: 24px 32px 24px 24px;
  min-height: calc(100vh -98px);

  @media (min-width: ${breakpoints[1]}) {
    max-width: 340px;
    min-height: calc(100vh - 148px);
    margin-right: 0;
    margin-left: -40px;
    padding-left: 40px;
  }

  h1 {
    font-size: 18px;
    line-height: 24px;
    color: ${props => props.theme.sectionHeading};
    margin-top: 16px;
    margin-bottom: 18px;
    font-weight: 700;
  }

  h2 {
    font-size: 14px;
    line-height: 24px;
    color: ${props => props.theme.sectionHeading};
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 700;
  }
`;

export const PageTitle = styled.h1`
  display: none;
  @media (min-width: ${breakpoints[1]}) {
    display: revert;
  }
`;

export const Header = styled.header`
  padding-right: 24px;
  padding-bottom: 24px;
  min-width: 200px;
  border-bottom: 1px solid ${props => props.theme.subsectionBorder};
`;

export const Section = styled.section`
  background-color: ${props => props.theme.sectionBg};
  color: ${props => props.theme.sectionText};

  margin: 24px 0 32px;
  padding: 24px;
  height: min-content;

  @media (min-width: ${breakpoints[1]}) {
    margin-top: 0;
    padding: 32px;
  }

  article :not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.subsectionBorder};
    margin-bottom: 24px;
    @media (min-width: ${breakpoints[1]}) {
      margin-bottom: 32px;
      border-bottom: revert;
    }
  }

  h2 {
    margin-top: 0;
    font-size: 15px;
  }

  p {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
  }
`;

export const RightPane = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${breakpoints[1]}) {
    margin: -5px 0 0 40px;
  }
`;

export const ButtonWrapper = styled(BaseButtonWrapper)`
  margin-top: 48px;
  justify-content: space-between;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

export const HelpText = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.sectionText};
  margin-bottom: 10px;
`;

export const UserName = styled.h1`
  margin-bottom: 12px;
`;
