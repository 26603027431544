import React from "react";
import PropTypes from "prop-types";

import IconLink from "Icons/IconLink";
import { Layout, Overlay, Link, Ellipsis } from "./styles";

const URLOverlay = ({ url, children, className, ...props }) =>
  url ? (
    <Layout tabIndex="0" className={className} {...props}>
      {children}
      <Overlay>
        <Link target="_blank" href={`//${url}`}>
          <IconLink /> <Ellipsis>{url}</Ellipsis>
        </Link>
      </Overlay>
    </Layout>
  ) : (
    children || false
  );

URLOverlay.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

export default URLOverlay;
