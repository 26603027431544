import React from "react";
import { useHistory } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
import EnvironmentSyncPane from "./containers/EnvironmentSyncPane";

import withReducers from "Hocs/withReducers";

const EnvironmentActionsSync = () => {
  const { push } = useHistory();
  const params = useDecodedParams();
  return <EnvironmentSyncPane push={push} {...params} />;
};

export default withReducers({
  synchronisation: () => import("Reducers/environment/actions/sync")
})(EnvironmentActionsSync);
