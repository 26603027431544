import styled, { css } from "styled-components";
import { semiBoldAlias, makePalette } from "Libs/theme";

const theme = makePalette(palette => ({
  tooltip_background: {
    default: palette.snow,
    dark: palette.ebony
  },
  tooltip_shadow: {
    default: `0px 6px 24px rgba(152, 160, 171, 0.4)`,
    dark: `0px 8px 24px ${palette.night}`
  },
  tooltip_line_active: {
    default: palette.skyeLight,
    dark: palette.ebonyLight
  }
}));

export const Layout = styled.div`
  padding: 10px 8px 10px 8px;
  background-color: ${theme.tooltip_background};
  box-shadow: ${theme.tooltip_shadow};
  border-radius: 2px;
  min-width: 260px;
  z-index: 9999;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
`;

export const TableRow = styled.tr`
  height: 24px;
  font-size: 13px;
  line-height: 18px;
  color: ${props => props.theme.text};
  background-color: transparent;
  > :first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  > :last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  td:nth-child(1) {
    width: 1%;
    padding-left: 8px;
    ${semiBoldAlias};
  }

  td:nth-child(2) {
    width: 1%;
    padding-left: 8px;
    padding-right: 8px;
  }

  td:nth-child(3) {
    text-align: left;
    white-space: nowrap;
    padding-right: 8px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${theme.tooltip_line_active};
    `};
`;
