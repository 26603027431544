import React, { memo, useRef } from "react";
import PropTypes from "prop-types";

import Portal from "./TimeTooltip.portal";
import * as S from "./TimeTooltip.styles";

const TimeTooltip = ({ children, x, y, forwardedRef, isVisible }) => {
  const textRef = useRef();

  const offset = Math.round((textRef.current?.offsetWidth || 0) / 2);

  return (
    <Portal forwardedRef={forwardedRef}>
      <S.Text isVisible={isVisible} ref={textRef} x={x - offset} y={y}>
        {children}
      </S.Text>
    </Portal>
  );
};

TimeTooltip.propTypes = {
  children: PropTypes.node,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  isVisible: PropTypes.bool,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default memo(TimeTooltip);
