import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { StripeProvider, Elements } from "react-stripe-elements";
import { Link } from "react-router-dom";

import withReducers from "Hocs/withReducers";

import {
  getOrganizationPaymentSource,
  organizationPaymentSourceSelector
} from "Reducers/organization/paymentSource";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { getOrganizationProfile } from "Reducers/organization/profile";

import client from "Libs/platform";
import { capitalize } from "Libs/utils";
import Heading3 from "Components/styleguide/Heading3";
import Button from "UI/Button";

import PaymentForm from "../../components/PaymentForm";
import AddressForm from "../../components/AddressForm";
import OrderDetailSummary from "../../components/OrderDetailSummary";
import TrialWidget from "../../components/TrialWidget";
import ModalChangeMethod from "../../components/PaymentForm/ModalChangeMethod";
import NewPaymentSource from "../../components/NewPaymentSource";

import * as S from "./Billing.styles";

const Billing = ({ cancel, organizationId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [modalConfirm, setModalConfirm] = useState(false);
  const [newPaymentSource, setNewPaymentSource] = useState(true);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [addressData, setAddressData] = useState();

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );
  const orgId = organization?.id;

  useEffect(
    () => {
      let isCanceled = false;
      const getAddress = async () => {
        const result = await client.getOrganizationAddress(orgId);
        if (isCanceled) {
          return;
        }
        setAddressData(result);
      };
      if (organization) {
        getAddress();
        dispatch(getOrganizationProfile({ organizationId }));
      }

      return () => (isCanceled = true);
    },
    [organizationId, organization]
  );

  const paymentSource = useSelector(state =>
    organizationPaymentSourceSelector(state, { organizationId })
  );

  useEffect(
    () => {
      let isCanceled = false;
      const getAllowed = async () => {
        const pt = await client.getOrganizationPaymentSourcesAllowed(orgId);
        if (isCanceled) {
          return;
        }
        setPaymentTypes(pt?.items);
      };
      if (organization) {
        getAllowed();
        dispatch(getOrganizationPaymentSource({ organizationId }));
      }

      return () => (isCanceled = true);
    },
    [organizationId, organization]
  );

  useEffect(
    () => {
      setNewPaymentSource(!paymentSource?.type);
    },
    [paymentSource]
  );

  const confirmChange = () => {
    setModalConfirm(false);
    setNewPaymentSource(true);
  };

  const refreshPayment = async () => {
    dispatch(getOrganizationPaymentSource({ organizationId }));
    setNewPaymentSource(false);
  };

  const existingPaymentSourceType =
    paymentSource?.source_type || paymentSource?.type || "credit-card";
  const hasPaymentSource = !!paymentSource?.type;

  return (
    <S.BillingDetailsContainer>
      <S.Aside>
        <S.InvoicesSection>
          <S.HeaderLayout>
            <Heading3>
              {intl.formatMessage({ id: "upcoming_invoice" })}
            </Heading3>
            <Link to={`/${organizationId}/-/billing/history`}>
              {intl.formatMessage({ id: "view_all" })}
            </Link>
          </S.HeaderLayout>
          <S.SideSectionBox>
            <OrderDetailSummary
              organizationDescriptionId={organizationId}
              organizationId={orgId}
            />
          </S.SideSectionBox>

          <TrialWidget hasPaymentSource={hasPaymentSource} />
        </S.InvoicesSection>
      </S.Aside>

      <S.Content>
        {organization?.hasLink("payment-source") && (
          <S.PaymentMethodsSection>
            <ModalChangeMethod
              isOpen={modalConfirm}
              cancel={() => setModalConfirm(false)}
              confirm={confirmChange}
            />
            {newPaymentSource && (
              <NewPaymentSource
                finalize={refreshPayment}
                close={() => setNewPaymentSource(false)}
                hasPaymentSource={hasPaymentSource}
                paymentTypes={paymentTypes}
                organizationId={organizationId}
                name_line={addressData?.name_line}
              />
            )}

            {hasPaymentSource &&
              existingPaymentSourceType !== "invoice_account" && (
                <>
                  <S.HeaderLayoutChangePayment>
                    {newPaymentSource ? (
                      <Heading3>
                        {intl.formatMessage({ id: "existing_payment_method" })}
                      </Heading3>
                    ) : (
                      <>
                        <Heading3>
                          {intl.formatMessage({ id: "payment_method" })}
                        </Heading3>
                        <span>
                          {intl.formatMessage({ id: "only_one_method" })}
                        </span>
                      </>
                    )}
                    <Button
                      id="change-method"
                      onClick={() => setModalConfirm(true)}
                      aria-label={intl.formatMessage({ id: "change_method" })}
                      disabled={newPaymentSource}
                    >
                      {capitalize(intl.formatMessage({ id: "change_method" }))}
                    </Button>
                  </S.HeaderLayoutChangePayment>
                  {(existingPaymentSourceType === "credit-card" ||
                    existingPaymentSourceType === "stripe_sepa_debit") && (
                    <S.SectionBox>
                      <Heading3>
                        {intl.formatMessage({
                          id: existingPaymentSourceType
                        })}
                      </Heading3>
                      {existingPaymentSourceType === "credit-card" && (
                        <p>
                          {intl.formatMessage({
                            id: "payment_information_description"
                          })}
                        </p>
                      )}
                      {paymentTypes.length && (
                        <StripeProvider
                          apiKey={
                            paymentTypes.find(
                              pt => pt.id === existingPaymentSourceType
                            )?.stripe_public_key || null
                          }
                        >
                          <Elements>
                            <PaymentForm
                              currentPaymentSourceType={
                                existingPaymentSourceType
                              }
                              edit={false}
                              number={paymentSource?.number}
                              organizationId={organizationId}
                              name_line={addressData?.name_line}
                            />
                          </Elements>
                        </StripeProvider>
                      )}
                    </S.SectionBox>
                  )}
                </>
              )}
          </S.PaymentMethodsSection>
        )}

        <S.BillingDetailsSection>
          <S.HeaderLayout>
            <Heading3>{intl.formatMessage({ id: "billing_details" })}</Heading3>
          </S.HeaderLayout>
          <S.SectionBox>
            <AddressForm
              goForwardText={intl.formatMessage({
                id: "update_billing"
              })}
              finalize={cancel}
              organizationId={organizationId}
              addressData={addressData}
            />
          </S.SectionBox>
        </S.BillingDetailsSection>
      </S.Content>
    </S.BillingDetailsContainer>
  );
};

Billing.propTypes = {
  cancel: PropTypes.func,
  organizationId: PropTypes.string
};

export default withReducers({
  organizationPaymentSource: () =>
    import("Reducers/organization/paymentSource"),
  organizationProfile: () => import("Reducers/organization/profile")
})(Billing);
