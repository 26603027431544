import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

export default styled.div`
  flex-shrink: 0;
  height: 100%;
  width: 100%;
  color: ${props => themeHelpers(props.theme, "darkTint")};
  .setting-wrapper {
    padding: 20px 32px;
    box-sizing: border-box;
  }
  .field,
  .select-box {
    min-width: 264px;
    max-width: 100%;
  }
  .field {
    margin-bottom: 32px;
  }
  .toggle-field {
    .field {
      margin: 0;
    }
  }
  .delete {
    float: right;
    cursor: pointer;
    line-height: 35px;
  }
  .description {
    color: ${props => props.theme.descriptionText};
    font-size: 15px;
    margin: 0 0 24px;
  }
  @media (min-width: 920px) {
    .inline-fields {
      display: flex;
      .field .field {
        margin-bottom: 0;
      }
      .field + .field {
        margin-left: 24px;
      }
    }
  }
`;
