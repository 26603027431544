import styled, { css, keyframes } from "styled-components";

import { themeHelpers } from "Libs/theme";

import ActionDropdown from "Components/ActionDropdown";

const bump = keyframes`
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.97);
  }
  80% {
    transform: scale(1.01);
  }
  100 {
    transform: scale(1);
  }

`;

export const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-bottom: 32px;
  background: ${props => themeHelpers(props.theme, "sectionColor")};
  border-radius: 2px;
  box-shadow: ${props => props.theme.wizardDropShadow};
  outline: none;

  &:focus {
    outline: none;
  }

  ${props =>
    props.animate &&
    css`
      animation: ${bump} 300ms ease-out;
    `};
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  &::before {
    content: "";
    display: block;
    height: 2px;
    width: ${props => props.completed || 0}%;
    background: ${props => themeHelpers(props.theme, "primaryColor")};
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0 32px;
`;

export const StepsDropdown = styled(ActionDropdown)`
  margin-top: 8px;
  display: inline-block;

  button {
    border: none;
    margin-left: -16px;
    width: auto;
    .text {
      white-space: nowrap;
      color: ${props => props.theme.subtitleText};
      span {
        color: ${props => props.theme.sectionTextLight};
      }
    }
    &:active,
    &:focus {
      box-shadow: none;
      border: none;
    }
  }

  .tooltip {
    left: 0;
    right: auto;
    width: auto;
  }
`;

export const Footer = styled.footer`
  display: flex;
  padding: 0 32px 32px 32px;

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    line-height: 24px;
    span {
      transition: all 0.1s ease;
    }
    &:hover {
      color: ${props => themeHelpers(props.theme, "buttonHover")};
      svg path {
        fill: ${props => themeHelpers(props.theme, "buttonHover")};
      }
      span {
        margin-left: 10px;
      }
      &:after {
        display: none;
      }
    }
    &:focus {
      margin: -3px -1px -1px;
    }
    &:active {
      margin: 0;
    }
    &.next {
      padding-left: 0;
      > span {
        padding-left: 3px;
      }
    }
    &.back-link {
      position: relative;
      margin-right: 25px;
      padding: 0;
      &:hover span {
        margin-left: -10px;
        margin-right: 10px;
      }
      &:after {
        display: inline-block;
        position: absolute;
        left: 100%;
        top: 0;
        margin-left: 10px;
        height: 24px;
        width: 1px;
        content: "";
        background: ${props => props.theme.environmentTreeLine};
      }
    }
  }
`;
