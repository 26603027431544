import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getOwnerInfoLabel } from "Libs/utils";
import { projectSelector } from "Reducers/project";

import ProjectMoreLinks from "Components/ProjectMoreLinks";

import * as S from "./ListItem.styles";

const PlanListItem = ({ organizationId, organizations, subscription }) => {
  const project = useSelector(state =>
    projectSelector(state, {
      organizationId,
      projectId: subscription?.project_id
    })
  );

  return (
    <S.InfoLayout>
      <S.Project>{subscription.project_title || "Untitled Project"}</S.Project>
      <S.End>
        <S.DetailLayout
          hasEditLinks={
            project.hasPermission &&
            project.hasPermission("#subscription_change")
          }
        >
          <S.Plan>{subscription.plan}</S.Plan>
          <S.CommonSpan>
            <strong>{subscription.project_region_label}</strong>
          </S.CommonSpan>
          <S.CommonSpan>
            {getOwnerInfoLabel(subscription, organizations?.toJS())}
          </S.CommonSpan>
        </S.DetailLayout>

        <ProjectMoreLinks
          iconColor="black"
          id={`${subscription.project_id}-plan-more-link`}
          from="plans"
          project={project}
          subscription={subscription}
        />
      </S.End>
    </S.InfoLayout>
  );
};

PlanListItem.propTypes = {
  organizationId: PropTypes.string,
  organizations: PropTypes.object,
  subscription: PropTypes.object
};

export default PlanListItem;
