import styled, { css } from "styled-components";

export const Layout = styled.div`
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;

  ${({ isSupport }) =>
    !isSupport &&
    css`
      :not(:last-child) {
        box-shadow: inset 0px -1px 0px #c9d0e4;
      }
    `};

  ${props =>
    props.isSupport &&
    css`
      background: #f5f7fa;
      border-radius: 2px;
      :not(:last-child) {
        margin-bottom: 4px;
      }
    `};
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SupportLabel = styled.div`
  background: #ffffff;
  border-radius: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #302f45;
  margin-right: 26px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const Date = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #98a0ab;
  margin-left: auto;
`;
