import styled from "styled-components";
import { transparentize } from "polished";

import { semiBoldAlias } from "Libs/theme";

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: 32px;
  padding-right: 32px;
  background-color: ${transparentize(0.04, "#e7f0ff")};
  align-items: center;
  opacity: 1;
  border-radius: 2px 2px 0 0;
  display: none;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn;
  animation-duration: 0.25s;
`;

export const Layout = styled.div`
  position: relative;
  outline: none;
  overflow: hidden;
  height: 207px;

  &:hover,
  &:focus,
  &:focus-within {
    ${Overlay} {
      display: flex;
    }
  }
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-style: normal;
  ${semiBoldAlias};
  font-size: 14px;
  line-height: 19px;
  overflow: hidden;
  text-align: center;
  max-width: 100%;
  position: relative;
  color: ${props => props.theme.links};
  margin: 0 auto;

  &:hover {
    &:after {
      content: "";
      width: calc(100% - 32px);
      position: absolute;
      height: 1px;
      background: ${props => props.theme.links};
      bottom: 2px;
      right: 0;
      display: inline-block;
    }
  }
  &:focus {
    outline: none;
    border: 1px solid ${props => props.theme.inputFocus};
    border-radius: 4px;
    box-shadow: 0 0 2px ${props => props.theme.inputFocus};
    text-decoration: none;
  }
  &:active {
    border: none;
    box-shadow: none;
    text-decoration: underline;
  }

  svg {
    margin-right: 8px;
    height: 24px;
    width: 24px;
  }
`;

export const Ellipsis = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`;
