import styled from "styled-components";

export default styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridTemplate || "repeat(4, 25%)"};
  min-height: 41px;
  width: 100%;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    height: 100%;
    width: 100%;
  }
`;
