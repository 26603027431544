import React from "react";
import PropTypes from "prop-types";

import InlineBanner from "Components/InlineBanner";

class ServiceMetricsErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return { error };
  }

  state = { error: null };

  render() {
    if (this.state.error) {
      if (this.state.error.message.startsWith("Metrics not available")) {
        return null;
      }

      return (
        <InlineBanner padding="16px" level="error">
          {this.state.error.message}
        </InlineBanner>
      );
    }

    return this.props.children;
  }
}

ServiceMetricsErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default ServiceMetricsErrorBoundary;
