import { Map } from "immutable";

import client from "Libs/platform";

const initialState = Map({
  isLoading: false
  // priorities: Array
  // error: Object
});

// Actions
const LOAD_START = "tickets/priorities/load_start";
const LOAD_SUCCESS = "tickets/priorities/load_success";
const LOAD_FAILURE = "tickets/priorities/load_failure";

// Action creators
export const loadStart = () => ({ type: LOAD_START });
export const loadFailure = error => ({ type: LOAD_FAILURE, payload: error });
export const loadSuccess = priorities => ({
  type: LOAD_SUCCESS,
  payload: priorities
});

// Thunks
export const load = (subscriptionId, categoryName) => async dispatch => {
  dispatch(loadStart());
  try {
    const priorities = await client.getTicketPriorities(
      subscriptionId,
      categoryName
    );
    dispatch(loadSuccess(priorities));
  } catch (error) {
    dispatch(loadFailure(error));
  }
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_START:
      return state.set("isLoading", true);
    case LOAD_SUCCESS:
      return state.set("isLoading", false).set("priorities", action.payload);
    case LOAD_FAILURE:
      return state.set("isLoading", false).set("error", action.payload);
    default:
      return state;
  }
};

export default reducer;
