import React from "react";
import PropTypes from "prop-types";

import SearchInput from "Components/SearchInput";

import Dropdown from "./Dropdown";
import { Layout } from "./styles";

const Filters = ({
  search,
  onFilterChange,
  visibilityOptions,
  sortByOptions
}) => (
  <Layout>
    <SearchInput
      placeholder="Search"
      className="float"
      value={search}
      onChange={event =>
        onFilterChange({ type: "SEARCH", value: event.target.value })
      }
    />
    <Dropdown
      visibilityOptions={visibilityOptions}
      sortByOptions={sortByOptions}
      onFilterChange={onFilterChange}
    />
  </Layout>
);

Filters.propTypes = {
  search: PropTypes.string,
  onFilterChange: PropTypes.func,
  visibilityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      isActive: PropTypes.bool
    })
  ),
  sortByOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      isActive: PropTypes.bool
    })
  )
};

export default Filters;
