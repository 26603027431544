import { fromJS, Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { isJson } from "Libs/utils";
import logger from "Libs/logger";

/**
 * Get project's variables
 *
 * @param {string} organizationId
 * @param {string} projectId
 *
 */
export const getVariables = createAsyncThunk(
  "app/project/variables",
  async ({ projectId }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const variables = await client.getProjectVariables(projectId);
    return variables;
  }
);

/**
 * Create a variable
 *
 * @param {string} organizationId
 * @param {object} project
 * @param {object} data
 *
 */
export const addVariable = createAsyncThunk(
  "app/project/variable/add",
  async ({ project, data }, { rejectWithValue }) => {
    if (!project) return false;

    try {
      const result = await project.setVariable(
        data.name,
        data.value,
        data.is_json,
        data.is_sensitive,
        data.visible_build,
        data.visible_runtime
      );

      if ((result.data && result.data.code / 100) === 4) {
        return rejectWithValue({ error: result.detail });
      }

      const variable = await result?.getEntity();
      return variable;
    } catch (err) {
      logger(
        { errMessage: err.message, projectId: project.id },
        {
          action: "projectAddVariable"
        }
      );
      return rejectWithValue({ errors: err.detail });
    }
  }
);

/**
 * Update a variable
 *
 * @param {string} organizationId
 * @param {string} projectId
 * @param {object} variable
 * @param {object} data
 *
 */
export const updateVariable = createAsyncThunk(
  "app/project/variable/update",
  async ({ projectId, variable, data }, { rejectWithValue }) => {
    const fields = Object.assign(
      {},
      { projectId },
      { variableId: variable.id },
      data
    );
    try {
      const result = await variable.update(fields);
      const newVariable = await result?.getEntity();
      return newVariable;
    } catch (err) {
      logger(
        { errMessage: err.message, projectId, variableId: variable.id },
        {
          action: "projectUpdateVariable"
        }
      );
      return rejectWithValue({ errors: err.detail });
    }
  }
);

/**
 * Delete a variable
 *
 * @param {string} organizationId
 * @param {string} projectId
 * @param {object} variable
 *
 */
export const deleteVariable = createAsyncThunk(
  "app/project/variable/delete",
  async ({ variable }) => {
    if (!variable) return false;
    await variable.delete().catch(err => {
      const errMessage = isJson(err) ? JSON.parse(err) : err.message;
      logger(
        { errMessage, variableId: variable.id },
        {
          action: "projectDeleteVariable"
        }
      );
      throw new Error(errMessage.error);
    });
    return variable;
  }
);

const setError = (state, action) =>
  state
    .set("errors", action.error.message)
    .set("loading", false)
    .set("status", "rejected");

const variables = createSlice({
  name: "app/project/variable",
  initialState: Map({ data: Map() }),
  extraReducers: {
    // GET LIST
    [getVariables.pending]: state => state.set("loading", true),
    [getVariables.fulfilled]: (state, action) => {
      const { organizationId, projectId } = action.meta.arg;
      return state
        .set(
          "data",
          action.payload.reduce((list, variable) => {
            return list.setIn(
              [organizationId, projectId, variable.id],
              variable
            );
          }, Map())
        )
        .set("loading", false)
        .set("status", "idle");
    },
    [getVariables.rejected]: (state, action) => setError(state, action),

    // ADD
    [addVariable.pending]: state =>
      state.set("status", "pending").delete("errors"),
    [addVariable.fulfilled]: (state, action) => {
      const { organizationId, project } = action.meta.arg;
      return state
        .setIn(
          ["data", organizationId, project.id, action.payload.id],
          action.payload
        )
        .set("status", "added");
    },
    [addVariable.rejected]: (state, action) =>
      state.set("errors", action.payload.errors).set("status", "rejected"),

    // UPDATE
    [updateVariable.pending]: state =>
      state.set("status", "pending").delete("errors"),
    [updateVariable.fulfilled]: (state, action) => {
      const { organizationId, projectId } = action.meta.arg;
      return state
        .setIn(
          ["data", organizationId, projectId, action.payload.id],
          fromJS(action.payload)
        )
        .set("status", "updated");
    },
    [updateVariable.rejected]: (state, action) =>
      state.set("errors", action.payload.errors).set("status", "rejected"),

    // DELETE
    [deleteVariable.pending]: state =>
      state.set("status", "pending").delete("errors"),
    [deleteVariable.fulfilled]: (state, action) => {
      const { organizationId, projectId } = action.meta.arg;
      return state
        .deleteIn(["data", organizationId, projectId, action.payload.id])
        .set("status", "deleted");
    },
    [deleteVariable.rejected]: (state, action) => setError(state, action)
  }
});

export default variables.reducer;
