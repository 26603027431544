import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

export const HttpAccessControlInputs = styled.div`
  margin: 0 0 24px;
  width: 100%;
  box-sizing: border-box;
  .field {
    margin-bottom: 24px;
  }
  .login-list-field {
    width: 100%;
    .row {
      width: 100%;
      margin: 0 0 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .current-values {
        width: 264px;
      }
      &.editing {
        align-items: flex-start;
        .field {
          margin-bottom: 0;
          width: 264px;
        }
        button {
          margin-top: 26px;
        }
      }
      button {
        margin-left: 16px;
      }
    }
  }
  .access-ips {
    display: flex;
    flex-direction: row;
    align-items: top;
    textarea {
      min-width: 50%;
      width: auto;
    }
  }
  .row {
    line-height: 40px;
  }
  input {
    height: 40px;
    line-height: 40px;
  }
  h6 {
    margin-top: 24px;
  }
`;

export const IpTextArea = styled.textarea`
  width: 200px;
  min-height: 50px;
  resize: none;
  font-size: 14px;
  padding: 15px;
  background: ${props =>
    props.theme.name === "dark"
      ? themeHelpers(props.theme, "darkShade")
      : props.theme.inputBg};
  color: ${props => props.theme.inputText};
  border: 1px solid
    ${props =>
      props.theme.name === "dark"
        ? themeHelpers(props.theme, "darkShade")
        : props.theme.inputBg};
  &:focus {
    border: 1px solid ${props => themeHelpers(props.theme, "primaryShade")};
    box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "primaryShade")};
  }
`;
