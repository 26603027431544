import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fromJS, Map } from "immutable";

import logger from "Libs/logger";
import { hasHtml, isJson } from "Libs/utils";
import { updateProject } from "Reducers/project";

/**
 * Get project's domains
 *
 * @param {string} organizationId
 * @param {object} project
 *
 */
export const getDomains = createAsyncThunk(
  "app/project/domains",
  async ({ organizationId, project }, { rejectWithValue }) => {
    if (!project) return false;

    try {
      const domains = await project.getDomains();
      return domains;
    } catch (err) {
      if (err.code / 100 === 4 && !hasHtml(err)) {
        const errorMessage = isJson(err)
          ? err
          : "An error occurred while attempting to load domains.";
        logger(errorMessage, {
          action: "loadDomains",
          meta: {
            organizationId,
            projectId: project.id
          }
        });
        return rejectWithValue({ errors: err.detail });
      }
    }
  }
);

/**
 * Get project's domains
 *
 * @param {object} data
 * @param {string} organizationId
 * @param {object} project
 *
 */
export const addDomain = createAsyncThunk(
  "app/project/domain/add",
  async ({ data, organizationId, project }, { dispatch, rejectWithValue }) => {
    if (!project) return false;

    try {
      const result = await project.addDomain(data.name);

      if ((result.data && result.data.code / 100) === 3) {
        return rejectWithValue({ error: result.detail });
      }

      const domain = await result?.getEntity();
      if (data.isDefault) {
        dispatch(
          updateProject(organizationId, project.id, {
            default_domain: domain.name
          })
        );
      }
      return domain;
    } catch (err) {
      logger(
        { errorMessage: err.message, projectId: project.id },
        { action: "projectAddDomain" }
      );
      const errors =
        err.detail === "This domain is already claimed by another service"
          ? "This domain is already claimed by another project. If this is incorrect or you are trying to add a subdomain, please open a ticket with support."
          : err.detail;

      return rejectWithValue({ errors });
    }
  }
);

/**
 * Update a domain
 *
 * @param {object} data
 * @param {object} domain
 * @param {string} organizationId
 * @param {object} project
 *
 */
export const updateDomain = createAsyncThunk(
  "app/project/domain/update",
  async (
    { data, domain, organizationId, project },
    { dispatch, rejectWithValue }
  ) => {
    try {
      if (domain.name === project.default_domain && !data.isDefault) {
        dispatch(
          updateProject(organizationId, project.id, {
            default_domain: null
          })
        );
      }

      if (domain.name !== project.default_domain && data.isDefault) {
        dispatch(
          updateProject(organizationId, project.id, {
            default_domain: domain.name
          })
        );
      }

      return domain;
    } catch (err) {
      logger(
        { errorMessage: err.message, projectId: project.id },
        { action: "projectUpdateDomain" }
      );
      const errors =
        err.detail === "This domain is already claimed by another service"
          ? "This domain is already claimed by another project. If this is incorrect or you are trying to add a subdomain, please open a ticket with support."
          : err.detail;

      return rejectWithValue({ errors });
    }
  }
);

/**
 * Delete a domain
 *
 * @param {object} domain
 * @param {string} organizationId
 * @param {object} project
 *
 */
export const deleteDomain = createAsyncThunk(
  "app/project/domain/delete",
  async (
    { domain, organizationId, project },
    { dispatch, rejectWithValue }
  ) => {
    if (!domain) return false;

    try {
      await domain.delete();
      if (domain.name === project.default_domain) {
        dispatch(
          updateProject(organizationId, project.id, {
            default_domain: ""
          })
        );
      }
      return domain;
    } catch (err) {
      logger(
        { errMessage: err.message, domainId: domain.id },
        { action: "projectDeleteDomain" }
      );
      return rejectWithValue({ errors: err });
    }
  }
);

const setError = (state, action) =>
  state
    .set("errors", action.error.message)
    .set("loading", false)
    .set("status", "rejected");

const domains = createSlice({
  name: "app/project/domains",
  initialState: Map({ data: Map() }),
  extraReducers: {
    // GET LIST
    [getDomains.pending]: state => state.set("loading", true),
    [getDomains.fulfilled]: (state, action) => {
      const { organizationId, project } = action.meta.arg;
      return state
        .set(
          "data",
          action.payload.reduce((list, domain) => {
            return list.setIn([organizationId, project.id, domain.id], domain);
          }, Map())
        )
        .set("loading", false)
        .set("status", "idle");
    },
    [getDomains.rejected]: (state, action) => setError(state, action),

    // ADD
    [addDomain.pending]: state =>
      state.set("status", "pending").delete("errors"),
    [addDomain.fulfilled]: (state, action) => {
      const { organizationId, project } = action.meta.arg;
      return state
        .setIn(
          ["data", organizationId, project.id, action.payload.id],
          action.payload
        )
        .set("status", "added");
    },
    [addDomain.rejected]: (state, action) =>
      state.set("errors", action.payload.errors).set("status", "rejected"),

    // UPDATE
    [updateDomain.pending]: state =>
      state.set("status", "pending").delete("errors"),
    [updateDomain.fulfilled]: (state, action) => {
      const { organizationId, project } = action.meta.arg;
      return state
        .setIn(
          ["data", organizationId, project.id, action.payload.id],
          fromJS(action.payload)
        )
        .set("status", "updated");
    },
    [updateDomain.rejected]: (state, action) =>
      state.set("errors", action.payload.errors).set("status", "rejected"),

    // DELETE
    [deleteDomain.pending]: state =>
      state.set("status", "pending").delete("errors"),
    [deleteDomain.fulfilled]: (state, action) => {
      const { organizationId, project } = action.meta.arg;
      return state
        .deleteIn(["data", organizationId, project.id, action.payload.id])
        .set("status", "deleted");
    },
    [deleteDomain.rejected]: (state, action) => setError(state, action)
  }
});

export default domains.reducer;
