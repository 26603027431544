import styled from "styled-components";

export const Panel = styled.div`
  width: 100%;
  height: 100%;
`;

export const Title = styled.div`
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
  padding: 0 14px 0 0;
  text-transform: capitalize;

  span {
    margin-top: 6px;
  }

  svg {
    margin-right: 16.43px;
  }
  &.title-router {
    margin-bottom: 18px;
  }
`;
