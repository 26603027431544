import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  background: ${props => props.theme.sectionBg};
  border-radius: 4px;

  p {
    margin: 0 0 24px 0;
    font-size: 14px;
    line-height: 22px;
    ${semiBoldAlias};
  }
`;
