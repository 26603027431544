import { useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";

import useDecodedParams from "Hooks/useDecodedParams";
import { loadDeployment } from "Reducers/environment/deployment";

import useMetrics from "./useMetrics";
import useRange from "./useRange";
import useServices from "./useServices";

import objectToFields from "../utils/objectToFields";
import sortAlphabetically from "../utils/sortAlphabetically";
import { clearCache } from "../utils/parseDimensions";
import {
  explodeMetrics,
  groupByTimestamp,
  mergeSameTimestampData
} from "../utils/transformMetrics";
import { gridTimeframes } from "../settings";

const fields = {
  "cpu.average.value":
    "SUM((`cpu.user` + `cpu.kernel`) / `interval`, 'service')",
  "cpu.average.percentage":
    "SUM(100 * (`cpu.user` + `cpu.kernel`) / ( `interval` * `cpu.cores`), 'service')",
  "cpu.max": "`cpu.cores`",
  "memory.average.value":
    "SUM(`memory.apps` + `memory.kernel` + `memory.buffers`, 'service')",
  "memory.average.percentage":
    "SUM(100 * (`memory.apps` + `memory.kernel` + `memory.buffers`) / `memory.limit`, 'service')",
  "memory.max": "`memory.limit`",
  "disk.average.value": "`disk.space.used`",
  "disk.average.percentage": "(`disk.space.used`/`disk.space.limit`)*100",
  "disk.max": "AVG(`disk.space.limit`, 'mountpoint', 'service')"
};

const getServiceMetrics = (serviceId, metrics) => {
  if (!metrics?.length || !serviceId) {
    return undefined;
  }
  const serviceMetrics = metrics.filter(point => point.service === serviceId);
  const mountpoints = sortAlphabetically(
    Array.from(
      new Set(
        metrics
          .map(({ mountpoint }) => mountpoint)
          .filter(mountpoint => mountpoint != null)
      )
    )
  ).reduce((mountpoints, mountpoint) => {
    const mountpointMetrics = groupByTimestamp(
      serviceMetrics.filter(point => point.mountpoint === mountpoint)
    ).map(mergeSameTimestampData);

    if (mountpointMetrics.length) {
      return {
        ...mountpoints,
        [mountpoint]: mountpointMetrics
      };
    }

    return mountpoints;
  }, {});
  const cpu = groupByTimestamp(
    serviceMetrics.filter(point => point.type === "cpu")
  ).map(mergeSameTimestampData);
  const memory = groupByTimestamp(
    serviceMetrics.filter(point => point.type === "memory")
  ).map(mergeSameTimestampData);

  return {
    cpu,
    memory,
    ...mountpoints
  };
};

const getServiceData = (services, metrics) => {
  if (!services || !metrics) {
    return undefined;
  }

  return services.map(service => ({
    ...service,
    metrics: getServiceMetrics(service.id, metrics)
  }));
};

const getMetricsRange = metrics => {
  if (!metrics?.metrics?.length) {
    return [undefined, undefined];
  }
  const from = metrics.metrics[0].point.timestamp;
  const to = metrics.metrics[metrics.metrics.length - 1].point.timestamp;
  return [from, to];
};

const useDedication = (chorusURL, collection) => {
  const { organizationId, projectId, environmentId } = useDecodedParams();
  const dispatch = useDispatch();
  const [selectedTimeframe, setSelectedTimeframe] = useRange(gridTimeframes);
  const range = useMemo(
    () => {
      if (selectedTimeframe.range) {
        return selectedTimeframe.range;
      }

      return { from: selectedTimeframe.from, to: selectedTimeframe.to };
    },
    [selectedTimeframe]
  );
  const query = useMemo(
    () => ({
      interval: `${selectedTimeframe.interval}s`,
      fields: objectToFields(fields),
      stream: {
        collection: collection,
        stream: "metrics"
      }
    }),
    [selectedTimeframe]
  );
  const [data, isLoading, error] = useMetrics(chorusURL, query, range);
  const [services, hosts, isLoadingSerivces] = useServices(
    organizationId,
    projectId,
    environmentId,
    collection,
    chorusURL,
    60 * 15,
    true
  );

  const servicesWithData = useMemo(
    () => getServiceData(services, explodeMetrics(data)),
    [services, data]
  );

  useEffect(() => {
    dispatch(loadDeployment(organizationId, projectId, environmentId));
  }, []);

  useEffect(() => () => clearCache(), []);

  const metricsRange = useMemo(() => getMetricsRange(data), [data]);

  return [
    servicesWithData,
    hosts,
    metricsRange,
    selectedTimeframe,
    setSelectedTimeframe,
    isLoading || isLoadingSerivces,
    error
  ];
};

export default useDedication;
