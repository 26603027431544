import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Page from "./Page";
import NavBar from "Containers/NavBar";
import PageLayout from "Components/PageLayout";

const Routes = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { organizationId } = useParams();

  return (
    <>
      <NavBar
        push={push}
        role="navigation"
        currentPathName={pathname}
        fixed={true}
        organizationId={organizationId}
      />
      <PageLayout className="project">
        <Page />
      </PageLayout>
    </>
  );
};

export default Routes;
