import { fromJS, Map } from "immutable";

import { getProjectId, isJson } from "Libs/utils";
import logger from "Libs/logger";

const LOAD_DEPLOYMENT_START = "app/environmentDeployment/load_start";
const LOAD_DEPLOYMENT_SKIP = "app/environmentDeployment/load_skip";
export const LOAD_DEPLOYMENT_SUCCESS = "app/environmentDeployment/load_success";
const LOAD_DEPLOYMENT_FAILURE = "app/environmentDeployment/load_failure";

export const loadDeployment = (
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId,
  settings = {}
) => {
  return async (dispatch, getState) => {
    const { hasRedeployed } = settings;
    let deploymentIsLoaded = Object.keys(
      getState().deployment.getIn(
        [
          "data",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          "current"
        ],
        {}
      )
    ).length;
    let deploymentIsLoading = getState().deployment.get("loading");

    if (!hasRedeployed && (deploymentIsLoading || deploymentIsLoaded > 0)) {
      dispatch({ type: LOAD_DEPLOYMENT_SKIP });
      return;
    }

    dispatch({ type: LOAD_DEPLOYMENT_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const encodedEnvId = encodeURIComponent(environmentDescriptionId);

      const projectId = getProjectId(getState, projectDescriptionId);
      const deployment = await client.getCurrentDeployment(
        projectId,
        encodedEnvId,
        { verbose: true }
      );

      dispatch({
        type: LOAD_DEPLOYMENT_SUCCESS,
        payload: deployment,
        meta: {
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId,
          deploymentId: "current",
          schemas:
            deployment.data._links.self.meta.get.responses.default.content[
              "application/json"
            ].schema.properties
        }
      });
    } catch (err) {
      if (![404, 403].includes(err.code)) {
        const errorMessage = isJson(err)
          ? err
          : "An error occurred while attempting to load deployment.";

        logger(errorMessage, {
          action: "load_deployment",
          meta: {
            organizationDescriptionId,
            environmentDescriptionId,
            projectDescriptionId,
            deploymentId: "current"
          }
        });
      }
      dispatch({ type: LOAD_DEPLOYMENT_FAILURE, error: true, payload: err });
    }
  };
};

const currentDeployment = (state, { organizationId, projectId }) => {
  const project = state.project.getIn(["data", organizationId, projectId]);

  return state.deployment
    ?.getIn([
      "data",
      organizationId,
      projectId,
      project?.default_branch || "master",
      "current"
    ])
    ?.toJS();
};

export const deploymentSelectors = {
  currentDeployment
};

export default function environmentDeploymentReducer(
  state = new Map(),
  action
) {
  switch (action.type) {
    case LOAD_DEPLOYMENT_START:
      return state.set("loading", true);
    case LOAD_DEPLOYMENT_SUCCESS:
      return state
        .set("loading", false)
        .setIn(
          [
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.meta.environmentDescriptionId,
            action.meta.deploymentId
          ],
          fromJS(action.payload.data)
        )
        .set("schemas", fromJS(action.meta.schemas))
        .set("errors", false);
    case LOAD_DEPLOYMENT_FAILURE:
      return state.set("loading", false).set("errors", action.payload);
    default:
      return state;
  }
}
