import React from "react";
import PropTypes from "prop-types";

import AccessibleTooltip from "Components/AccessibleTooltip";

import formatValue from "../../../utils/formatValue";

import * as S from "./Metric.styles";
import Skeleton from "./Metric.skeleton";
import MetricName from "./MetricName";
import { useIntl } from "react-intl";

const Metric = ({
  name,
  value,
  max,
  percentage,
  isLoading,
  kind,
  serviceKind,
  ...props
}) => {
  const intl = useIntl();

  if (isLoading) {
    return (
      <Skeleton
        name={<MetricName name={name} serviceKind={serviceKind} {...props} />}
      />
    );
  }

  return (
    <S.Layout {...props}>
      <S.Metadata>
        <MetricName name={name} kind={kind} serviceKind={serviceKind} />
        <S.Nowrap>
          <S.Value>{formatValue(name, value)}</S.Value>
          <S.Max> / {formatValue(name, max)}</S.Max>
        </S.Nowrap>
      </S.Metadata>
      <S.Row flexEnd>
        <AccessibleTooltip
          tooltipProps={{
            children: intl.formatMessage({ id: "metrics.currentaverage" })
          }}
        >
          <S.Percentage>{formatValue("%", percentage)}</S.Percentage>
        </AccessibleTooltip>
      </S.Row>
    </S.Layout>
  );
};

Metric.defaultProps = {
  isLoading: false
};

Metric.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.instanceOf(Date).isRequired,
      value: PropTypes.number.isRequired
    })
  ),
  isLoading: PropTypes.bool,
  kind: PropTypes.string,
  serviceKind: PropTypes.string
};

export default React.memo(Metric);
