import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";

export const InfoLayout = styled.div`
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
`;

export const Column = styled.div`
  width: 30%;
  label {
    display: inline-block;
    width: 88px;
    margin-right: 22px;
    padding-top: 2px;
  }
`;

export const InfoTable = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 200px;
  row-gap: 24px;
`;

export const Total = styled.div`
  background: ${props => props.theme.inputBg};
  line-height: 40px;
  height: 40px;
  padding: 0 16px;
  color: ${props => props.theme.sectionText};
  font-size: 14px;
  width: 264px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 42px;
  span {
    display: flex;
    align-items: center;
  }
`;

export const Amount = styled.span`
  font-size: 16px;
  ${semiBoldAlias};
`;
