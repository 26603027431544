import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { ThemeContext } from "styled-components";

import ChevronIcon from "Icons/ChevronIcon";
import { Cron } from "./";

import * as S from "./styles";
import EmptyCard from "../../../components/EmptyCard";

const Crons = ({ list }) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);

  const [sortList, setSortList] = useState({ by: "name", order: "asc" });

  const sortCrons = (a, b) => {
    const { by, order } = sortList;
    const valA = typeof a[by] === "string" ? a[by].toLowerCase() : a[by];
    const valB = typeof b[by] === "string" ? b[by].toLowerCase() : b[by];
    if (order === "asc") {
      if (valA < valB) return -1;
      if (valA > valB) return 1;
      return 0;
    } else {
      if (valA > valB) return -1;
      if (valA < valB) return 1;
      return 0;
    }
  };

  const cronsList = Object.entries(list)
    .reduce((acc, [key, values]) => {
      acc.push({ name: key, ...values });
      return acc;
    }, [])
    .sort(sortCrons);

  const sortable = cronsList.length > 1;

  const handleSortBy = by => {
    if (!sortable) return;
    setSortList({
      by,
      order:
        sortList.by === by ? (sortList.order === "asc" ? "desc" : "asc") : "asc"
    });
  };

  return (
    <S.Wrapper>
      <S.Heading>
        {intl.formatMessage(
          {
            id: "services.crons.title"
          },
          {
            count: cronsList.length,
            b: txt => <strong>{txt}</strong> // eslint-disable-line react/display-name
          }
        )}
      </S.Heading>

      {!cronsList.length ? (
        <EmptyCard>
          {intl.formatMessage({ id: "services.crons.none" })}
        </EmptyCard>
      ) : (
        <S.Layout>
          <S.Grid>
            <S.GridHeader className="sort" onClick={() => handleSortBy("name")}>
              {intl.formatMessage({ id: "name" })}{" "}
              {sortable && (
                <ChevronIcon
                  color={sortList.by === "name" ? theme.links : "#4b6180"}
                  isOpen={sortList.by === "name" && sortList.order === "desc"}
                  animate={true}
                />
              )}
            </S.GridHeader>
            <S.GridHeader className="sort" onClick={() => handleSortBy("spec")}>
              {intl.formatMessage({ id: "frequency" })}{" "}
              {sortable && (
                <ChevronIcon
                  color={sortList.by === "spec" ? theme.links : "#4b6180"}
                  isOpen={sortList.by === "spec" && sortList.order === "desc"}
                  animate={true}
                />
              )}
            </S.GridHeader>
            <S.GridHeader>
              {intl.formatMessage({ id: "run_command" })}
            </S.GridHeader>
          </S.Grid>
          <hr />
          <S.Grid>{cronsList.map(c => <Cron key={c.name} {...c} />)}</S.Grid>
        </S.Layout>
      )}
    </S.Wrapper>
  );
};

Crons.propTypes = {
  list: PropTypes.object
};

export default Crons;
