import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";

export const Note = styled.p`
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 20px;
  color: #4a495e;
`;
