import styled from "styled-components";

export const Line = styled.div`
  border-radius: 2px;
  display: flex;
  color: ${props => props.theme.orderInfo};
  font-size: 15px;
  line-height: 24px;
  padding: 8px 16px;
  margin: 2px 0;
  > :first-child {
    color: ${props => props.theme.orderInfoNumber};
    font-weight: bold;
    flex: 1 0;
    &.no-invoices {
      font-weight: 600;
    }
  }
  &.no-invoices {
    text-align: center;
    margin: 0;
    padding-bottom: 40px;
  }
  &.billing-period {
    padding: 0 0 8px;
    margin: 0 0 16px 0;
    border-bottom: 1px solid ${props => props.theme.tableBorder};
    a {
      color: ${props => props.theme.link};
      font-weight: 600;
      text-decoration: none;
    }
  }
  &.trial {
    background: #d6f6ef;
  }
  &.subtotal-discount,
  &.base_price {
    background: ${props => props.theme.orderSummarySubtotalBg};
  }
  &.empty {
    color: ${props => props.theme.orderTableHeader};
    font-weight: 600;
    > :first-child {
      color: ${props => props.theme.orderTableHeader};
    }
  }
  &.total-row {
    background: ${props => props.theme.orderSummaryTotalBg};
    margin: 6px 0 0;
    padding: 16px;
    flex-direction: column;
    > :first-child {
      flex: 0 0;
    }
    h2 {
      line-height: 33px;
      margin-bottom: 0;
      .total-currency {
        margin-left: 7px;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: ${props => props.theme.paymentCardSubtext};
      }
    }
    > span {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: ${props => props.theme.labelLight};
    }
  }
`;
