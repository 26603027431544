import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import useDecodedParams from "Hooks/useDecodedParams";
import BasicSettings from "./containers/BasicSettings";
import Introduction from "./components/Introduction";
import Actions from "./components/Actions";
import RightPane from "./components/RightPane";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import AppVersion from "Components/AppVersion";

import {
  updateOrganization,
  organizationByDescriptionIdSelector,
  organizationLoadingSelector,
  deleteOrganization
} from "Reducers/organization";
import {
  organizationProfileSelector,
  updateOrganizationProfile,
  organizationProfileLoadingSelector
} from "Reducers/organization/profile";

import * as s from "./styles";

const Basics = () => {
  const [modalIsOpen, setOpenModal] = useState(false);

  const { organizationId } = useDecodedParams();

  const dispatch = useDispatch();
  const intl = useIntl();

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  const organizationProfile = useSelector(state =>
    organizationProfileSelector(state, { organizationId })
  );

  const isLoading = useSelector(organizationLoadingSelector);
  const isProfileLoading = useSelector(organizationProfileLoadingSelector);

  const onSave = (organizationData, organizationProfileData) => {
    if (organizationData && Object.keys(organizationData).length) {
      dispatch(updateOrganization(organizationId, organizationData));
    }
    if (
      organizationProfileData &&
      Object.keys(organizationProfileData).length
    ) {
      dispatch(
        updateOrganizationProfile({
          organizationId,
          data: organizationProfileData
        })
      );
    }
  };

  const onConfirmDelete = () => {
    dispatch(deleteOrganization(organizationId));
  };

  const onDelete = () => {
    setOpenModal(true);
  };

  if (!isLoading && !organization) {
    return false;
  }

  return (
    <>
      <s.OrganizationSettingsLayout>
        <s.Layout>
          <BasicSettings
            onSave={onSave}
            onDelete={onDelete}
            organization={organization}
            organizationProfile={organizationProfile}
            isLoading={isLoading || isProfileLoading}
          />
          <RightPane>
            <div>
              <Introduction />
              <Actions
                organizationId={organizationId}
                organization={organization}
              />
            </div>
            <AppVersion />
          </RightPane>
        </s.Layout>
      </s.OrganizationSettingsLayout>
      <ModalConfirmDelete
        title={intl.formatMessage({
          id: "organizations.settings.delete_modal.title",
          defaultMessage: "Delete organization"
        })}
        size="medium"
        deleteFunction={onConfirmDelete}
        isOpen={modalIsOpen}
        closeModal={() => setOpenModal(false)}
        body={intl.formatMessage(
          {
            id: "organizations.settings.delete_modal.sentence"
          },
          {
            sub: organization?.label,
            b: (...chunks) => <strong>{chunks}</strong> //eslint-disable-line
          }
        )}
      />
    </>
  );
};

export default Basics;
