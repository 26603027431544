const KEY_VALUE_SEPARATOR = "=";
const PAIR_SEPARATOR = "/";

let cache = {};

/**
 * Deletes the whole cache
 */
export const clearCache = () => {
  cache = {};
};

/**
 * Given a dimension string, transform it into a hash
 * This function will cache the results by default
 *
 * dimesionString format => `hostname=id-123/key=value`
 * { hostname: "id-123", key: "value" }
 *
 * @param {string} dimensionString
 * @return {object} the parsed dimension string
 */
const parseDimensions = dimensionString => {
  if (typeof dimensionString !== "string") {
    return undefined;
  }

  if (cache[dimensionString]) {
    return cache[dimensionString];
  }

  const dimensions = dimensionString.split(PAIR_SEPARATOR);
  const dimensionsObject = {};

  for (let i = 0; i < dimensions.length; i++) {
    const dimension = dimensions[i];
    if (dimension) {
      const [key, value] = dimension.split(KEY_VALUE_SEPARATOR);
      if (key) {
        // @TODO remove the decodeURIComponent from the line below and move
        // it to the view, it will slightly increase the performance of this
        // function when called whith thousands of points
        dimensionsObject[key] = decodeURIComponent(value);
      }
    }
  }

  cache[dimensionString] = dimensionsObject;

  return dimensionsObject;
};

export default parseDimensions;
