import styled from "styled-components";

import Heading2 from "Components/styleguide/Heading2";

export const Wrapper = styled.aside`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Heading = styled(Heading2)`
  line-height: 33px;
  margin-top: 0;
  margin-bottom: 16px;
`;
