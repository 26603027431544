import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { load, loadMore } from "Reducers/tickets/list";
import useMediaQuery from "Hooks/useMediaQuery";

import SystemStatusBanner from "../../components/SystemStatusBanner";

import {
  TicketLayout,
  TicketTableLayout,
  QuikcLinksLayout,
  MoreHelpLayout,
  SystemStatusLayout,
  SubGrid
} from "./styles";

const arrayToIndexedObject = array =>
  array.reduce(
    (object, value, index) => ({
      ...object,
      [index]: value
    }),
    {}
  );

const buildParameters = filters => {
  let parameters = {};

  const statuses = Object.entries(filters.show)
    .filter(([, value]) => !!value)
    .map(([key]) => key);

  if (statuses.length) {
    parameters = {
      ...parameters,
      filter: {
        status: {
          value: arrayToIndexedObject(statuses),
          operator: arrayToIndexedObject(statuses.map(() => "IN"))
        }
      }
    };
  }

  return parameters;
};

const getProjectIdFromSubscription = (projects, subscriptionId) => {
  if (!subscriptionId) {
    return;
  }
  const project = projects.find(
    ({ subscription_id }) => subscription_id == subscriptionId
  );

  return project?.id;
};

const List = () => {
  const dispatch = useDispatch();
  const onDesktop = useMediaQuery("(min-width: 768px)");

  const tickets = useSelector(({ tickets }) => tickets?.get("tickets"));
  const projects = useSelector(({ app }) =>
    app?.getIn(["me", "projects"]).toJS()
  );
  const isLoading = useSelector(({ tickets }) => tickets?.get("isLoading"));
  const hasMore = useSelector(({ tickets }) => tickets?.get("hasMore"));
  const error = useSelector(({ tickets }) => tickets?.get("error"));

  const onFilterChange = filters => dispatch(load(buildParameters(filters)));
  const onLoadMore = filters => dispatch(loadMore(buildParameters(filters)));

  const visibleTickets = tickets?.map(ticket => ({
    ...ticket,
    project: getProjectIdFromSubscription(projects, ticket.subscription_id)
  }));

  return (
    <TicketLayout>
      {!onDesktop && <SystemStatusBanner anchor={true} />}

      <TicketTableLayout
        tickets={visibleTickets}
        isLoading={isLoading}
        hasMore={hasMore}
        loadMore={onLoadMore}
        error={error}
        onFilterChange={onFilterChange}
      />
      <SubGrid>
        <SystemStatusLayout />
        <QuikcLinksLayout />
        <MoreHelpLayout />
      </SubGrid>
    </TicketLayout>
  );
};

export default List;
