import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import BodyText from "../BodyText";
import Attachment from "../../../../common/components/Attachment";
import AttachmentList from "../../../../common/components/AttachmentList";

import UserDetails from "../../containers/UserDetails";

import { Layout, Details, Date, SupportLabel, FlexRow } from "./styles";
import useUserIsSupport from "../../../../common/useUserIsSupport";

const Comment = ({ createdAt, body, author, attachments, ...props }) => {
  const isSupport = useUserIsSupport(author);

  moment.updateLocale("en", {
    relativeTime: {
      past: "%s ago"
    }
  });

  return (
    <Layout {...props} isSupport={isSupport}>
      <Details>
        <UserDetails userId={author} />
        <FlexRow>
          {isSupport && <SupportLabel>Support</SupportLabel>}
          <Date>{moment(createdAt).fromNow()}</Date>
        </FlexRow>
      </Details>
      <BodyText>{body}</BodyText>
      {!!attachments?.length && (
        <AttachmentList>
          {attachments.map(({ url, filename }) => (
            <Attachment
              as="a"
              target="_blank"
              href={url}
              key={url}
              isTransparent
              hasPaperclip
              name={filename}
            />
          ))}
        </AttachmentList>
      )}
    </Layout>
  );
};

Comment.propTypes = {
  createdAt: PropTypes.string,
  body: PropTypes.string,
  author: PropTypes.string,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      filename: PropTypes.string,
      contentType: PropTypes.string
    })
  )
};

export default Comment;
