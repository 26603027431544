import React from "react";

import Link from "Components/styleguide/Link";
import { UnstyledList } from "./styles";

import { providers } from "./data";

const Providers = () => (
  <UnstyledList>
    {providers.map(provider => (
      <li key={provider.name}>
        <Link href={provider.href} target="_blank" rel="noopener noreferrer">
          {provider.name}
        </Link>
      </li>
    ))}
  </UnstyledList>
);

export default Providers;
