import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

export const Layout = styled.div`
  display: flex;
  font-size: 13px;
  line-height: 18px;

  ${semiBoldAlias};

  color: ${props => props.theme.sectionTextLight};

  & > div {
    margin-right: 2px;
  }
`;
