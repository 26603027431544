import { hot } from "react-hot-loader/root";
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

const Page = React.lazy(() => import("./Page"));
const BasicsPage = React.lazy(() => import("./pages/basics/Page"));
const UsersPage = React.lazy(() => import("./pages/members/Page"));

const Routes = () => {
  const { path } = useRouteMatch();

  return (
    <Page>
      <Switch>
        <Route path={`${path}/users`}>
          <UsersPage />
        </Route>
        <Route path={path}>
          <BasicsPage />
        </Route>
      </Switch>
    </Page>
  );
};
export default hot(Routes);
