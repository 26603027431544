import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { projectSelector } from "Reducers/project";

import ProjectMoreLinks from "Components/ProjectMoreLinks";
import PlaceholderText from "../../../../../common/components/PlaceholderText";
import WarningIcon from "Icons/WarningIcon";

import { getOwnerInfoName } from "Libs/utils";

import * as S from "./ProjectListItem.styles";

const ProjectListItem = ({
  hasWarning,
  organizationId,
  region,
  subscription,
  organizations
}) => {
  const project = useSelector(state =>
    projectSelector(state, {
      organizationId,
      projectId: subscription?.project_id
    })
  );

  return (
    <S.Wrapper>
      <Link
        to={`/${organizationId}/${subscription.project_id}`}
        id={`project-card-${subscription.project_id}`}
      >
        <div className="row">
          <div className="cell cell-project">
            {subscription.project_title || <PlaceholderText text="loading" />}
          </div>
          <S.Ellipsis className="cell cell-organization col-2">
            {getOwnerInfoName(subscription, organizations.toJS())}
          </S.Ellipsis>
          <div className="cell cell-region col-2">
            {region && (
              <span className="region-label">
                <>
                  {region.title}
                  <S.RegionSuffix inline>{region.suffix}</S.RegionSuffix>
                </>
              </span>
            )}
          </div>
          <div className="cell cell-plan col-2">{subscription?.plan}</div>
          <S.ProjectIdCell>{subscription?.project_id}</S.ProjectIdCell>
        </div>
      </Link>
      <S.ProjectActions>
        {hasWarning && (
          <S.WarningDialog
            align="right"
            icon={<WarningIcon color="#eda900" />}
            iconColor="#eda900"
            text={<FormattedMessage id="trial_expired_tooltip" />}
          />
        )}
        <ProjectMoreLinks
          from="projects"
          subscription={subscription}
          project={project}
        />
      </S.ProjectActions>
    </S.Wrapper>
  );
};

ProjectListItem.propTypes = {
  hasWarning: PropTypes.bool,
  organizationId: PropTypes.string,
  region: PropTypes.object,
  subscription: PropTypes.object,
  organizations: PropTypes.object
};

export default ProjectListItem;
