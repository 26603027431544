import { Map } from "immutable";
import client from "Libs/platform";

const initialState = Map({
  isLoading: false
  // error: obj
  // ticket: obj
});

const defaultLoadFailureError = {
  message: "An error has occurred while opening a ticket"
};

// Actions
const START = "tickets/open/start";
const FAILURE = "tickets/open/failure";
const SUCCESS = "tickets/open/success";
const RESET = "tickets/open/reset";

// Action creators
export const loadStart = () => ({ type: START });
export const loadFailure = error => ({ type: FAILURE, payload: error });
export const loadSuccess = ticket => ({ type: SUCCESS, payload: ticket });
export const reset = () => ({ type: RESET });

// Thunks
export const open = ticket => {
  return async dispatch => {
    dispatch(loadStart());
    try {
      const response = await client.openTicket(ticket);
      dispatch(loadSuccess(response));
    } catch (error) {
      dispatch(loadFailure(error));
    }
  };
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case START:
      return state.set("isLoading", true);
    case SUCCESS:
      return state.set("isLoading", false).set("ticket", action.payload);
    case FAILURE:
      return state
        .set("isLoading", false)
        .set("error", Map(action.payload || defaultLoadFailureError));
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export const name = "openTicket";

export default reducer;
