import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "immutable";

import client from "Libs/platform";

import { open, reset } from "Reducers/tickets/open";
import { load as loadPriorities } from "Reducers/tickets/priorities";
import { loadEnvironments } from "Reducers/environment";

import ContentLayout from "Components/ContentLayout";

import useQuery from "Hooks/useQuery";

import Back from "../../../../common/components/Back";
import Form from "../../components/Form";

import transform from "../../../../common/toAttachmentEndpointFormat";

import { TicketHeader } from "./styles";

const transformEnvironments = environments =>
  environments?.toArray().map(({ id, name }) => ({
    label: name,
    value: id
  }));

const transformProjects = projects =>
  projects?.map(({ name, subscription_id, id }) => ({
    id: id,
    label: `${name} (${id})`,
    value: subscription_id
  }));

const OpenTicketForm = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { followup, subscription } = useQuery();

  const organizationId = useSelector(state =>
    state.app.getIn(["me", "data", "username"])
  );
  const openSuccess = useSelector(state => !!state.openTicket.get("ticket"));
  const requester_id = useSelector(state => state.app.getIn(["me", "id"]));
  const isLoadingNewTicket = useSelector(state =>
    state.openTicket.get("isLoading", false)
  );
  const isLoadingProjects = useSelector(
    state => !!state.project.getIn(["me", "app"])
  );
  const isLoadingPriorities = useSelector(state =>
    state.priorities.get("isLoading", false)
  );
  const isLoadingEnvironments = useSelector(state =>
    state.environment.get("loading", false)
  );
  const priorities = useSelector(state => state.priorities.get("priorities"));
  const projects = useSelector(state =>
    state.app.getIn(["me", "projects"]).toJS()
  );
  const environments = useSelector(state =>
    state.environment.getIn(["data", organizationId], new Map())
  );
  const error = useSelector(({ openTicket }) =>
    openTicket.get("error")?.toJS()
  );

  const [projectId, setProjectId] = useState(undefined);
  const [category, setCategory] = useState(undefined);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [categories, setCategories] = useState();

  useEffect(() => {
    setIsLoadingCategories(true);
    client
      .getTicketCategories()
      .then(categories =>
        setCategories(
          categories.map(category => ({
            id: category.id,
            label: category.label,
            value: category.id
          }))
        )
      )
      .then(() => setIsLoadingCategories(false));
  }, []);

  useEffect(() => {
    dispatch(loadPriorities());
  }, []);

  useEffect(() => () => dispatch(reset()), [reset]);

  useEffect(
    () => {
      if (projectId) {
        const subscriptionId = projects.find(({ id }) => id === projectId)
          .subscription_id;
        dispatch(loadEnvironments(projectId, organizationId));
        dispatch(loadPriorities(subscriptionId, category));
      }
    },
    [projectId, category]
  );

  useEffect(
    () => {
      if (openSuccess) {
        push(`/-/users/${organizationId}/tickets`, { sortBy: "updated" });
      }
    },
    [openSuccess]
  );

  const onSubmit = ticket => {
    transform(ticket.attachments).then(attachments => {
      dispatch(
        open({
          subject: ticket.subject,
          description: ticket.description,
          requester_id: requester_id,
          priority: ticket.priority,
          category: ticket.category,
          affected_url: ticket.affectedUrl,
          subscription_id: ticket.project,
          attachments,
          followup_tid: followup
        })
      );
    });
  };

  const projectsAsOptions = transformProjects(projects);
  const initialProject = projectsAsOptions.find(
    option => option.value === subscription
  );

  return (
    <ContentLayout>
      <Back to={`/-/users/${organizationId}/tickets`} />
      <TicketHeader>
        <FormattedMessage id="tickets.create" />
      </TicketHeader>

      <Form
        projects={projectsAsOptions}
        onSubmit={onSubmit}
        onProjectChange={setProjectId}
        onCategoryChange={setCategory}
        environments={transformEnvironments(environments.get(projectId))}
        priorities={priorities}
        categories={categories}
        isLoadingCategories={isLoadingCategories}
        isLoadingNewTicket={isLoadingNewTicket}
        isLoadingProjects={isLoadingProjects}
        isLoadingPriorities={isLoadingPriorities}
        isLoadingEnvironments={isLoadingEnvironments}
        error={error}
        initialProject={initialProject}
      />
    </ContentLayout>
  );
};

export default OpenTicketForm;
