import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import BillingListField from "../../containers/BillingListField";
import Layout from "../../components/Layout";

import withReducers from "Hocs/withReducers";

const Page = ({ titleFilter }) => {
  const { username } = useParams();

  return (
    <Layout>
      <ContentLayout id="settings-billing" className="settings-content">
        <Heading2>
          <FormattedMessage id="invoices" />
        </Heading2>
        <BillingListField username={username} titleFilter={titleFilter} />
      </ContentLayout>
    </Layout>
  );
};

Page.propTypes = {
  titleFilter: PropTypes.string
};

export default withReducers({
  project: () => import("Reducers/project"),
  order: () => import("Reducers/user/order")
})(Page);
