import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { compareNumber } from "../../../../common/config";

import { capitalize } from "Libs/utils";
import { DOCS_AVAILABILITY_INCIDENT_URL } from "Constants/documentationUrls";
import Button from "UI/Button";
import InfoDialog from "Components/InfoDialog";
import TextAreaField from "Components/fields/TextAreaField";

import Attachments from "../../../../common/components/Attachments";
import PriorityOption from "../PriorityOption";
import PrioritySingleValue from "../PrioritySingleValue";

import {
  Layout,
  Actions,
  StyledDropdown,
  PriorityLabel,
  PriorityDropdown,
  Input,
  SubjectField,
  Label
} from "./styles";

const transformPriorities = priorities =>
  priorities
    .sort((a, b) => compareNumber(b.weight, a.weight))
    .map(({ id }) => ({
      value: id
    }));

const Form = ({
  onSubmit,
  projects,
  priorities = [],
  categories = [],
  isLoadingNewTicket,
  isLoadingProjects,
  isLoadingPriorities,
  isLoadingEnvironments,
  isLoadingCategories,
  environments = [],
  onProjectChange,
  onCategoryChange,
  error,
  initialProject
}) => {
  const intl = useIntl();

  const initialState = {
    project: null,
    priority: null,
    category: null,
    subject: "",
    description: "",
    environment: null,
    affectedUrl: "",
    attachments: null
  };
  const [ticket, setTicket] = useState({ ...initialState });

  const isValid = ticket.subject && ticket.description;

  const submit = event => {
    event.preventDefault();

    if (!isValid) {
      return;
    }

    onSubmit({
      ...ticket,
      project: ticket.project?.value,
      priority: ticket.priority?.value,
      category: ticket.category?.value
    });
  };

  const reset = () => setTicket({ ...initialState });

  const onProjectDropdownChange = selectedOption => {
    setTicket({
      ...ticket,
      project: selectedOption,
      environment: null,
      priority: null
    });
    onProjectChange(selectedOption.id);
  };

  const onCategoryDropdownChange = selectedOption => {
    setTicket({
      ...ticket,
      category: selectedOption,
      priority: null
    });
    onCategoryChange(selectedOption.id);
  };

  useEffect(() => {
    if (initialProject) {
      onProjectDropdownChange(initialProject);
    }
  }, []);

  return (
    <Layout onSubmit={submit} onReset={reset} noValidate>
      <StyledDropdown
        id="project"
        label={intl.formatMessage({ id: "tickets.open.project" })}
        options={projects}
        value={ticket.project}
        isLoading={isLoadingProjects}
        onChange={onProjectDropdownChange}
        defaultText="Select project"
        disabled={projects.length === 0 || isLoadingNewTicket || initialProject}
      />

      <StyledDropdown
        id="category"
        required
        label={intl.formatMessage({ id: "tickets.open.category" })}
        options={categories}
        value={ticket.category}
        isLoading={isLoadingCategories}
        onChange={onCategoryDropdownChange}
        defaultText="Select category"
        disabled={
          categories.length === 0 ||
          isLoadingCategories ||
          isLoadingNewTicket ||
          initialProject
        }
      />

      <PriorityDropdown
        id="priority"
        htmlLabel={
          <PriorityLabel>
            <Label htmlFor="priority">
              {intl.formatMessage({ id: "tickets.open.priority" })}
            </Label>
            <InfoDialog
              text={intl.formatMessage({
                id: "tickets.open.priorityDialog.text"
              })}
              to={DOCS_AVAILABILITY_INCIDENT_URL}
              linkText={intl.formatMessage({
                id: "tickets.open.priorityDialog.link"
              })}
            />
          </PriorityLabel>
        }
        options={transformPriorities(priorities)}
        value={ticket.priority}
        label={intl.formatMessage({ id: "tickets.list.table.priority" })}
        isLoading={isLoadingPriorities}
        onChange={selectedOption => {
          setTicket({
            ...ticket,
            priority: selectedOption
          });
        }}
        searchable={false}
        defaultText="Select priority"
        disabled={isLoadingPriorities || isLoadingNewTicket}
        components={{
          SingleValue: PrioritySingleValue,
          Option: PriorityOption
        }}
      />

      <SubjectField
        required
        label={intl.formatMessage({ id: "tickets.open.subject" })}
        onChange={event =>
          setTicket({ ...ticket, subject: event.target.value })
        }
        placeholder="Ticket subject"
        id="subject"
        value={ticket.subject}
        disabled={isLoadingNewTicket}
      />

      <TextAreaField
        required
        label={intl.formatMessage({ id: "tickets.open.description" })}
        onChange={event =>
          setTicket({ ...ticket, description: event.target.value })
        }
        value={ticket.description}
        placeholder="Describe the issue in as much detail as possible"
        id="description"
        disabled={isLoadingNewTicket}
      />

      <Attachments
        onChange={attachments =>
          setTicket({
            ...ticket,
            attachments
          })
        }
        append
        disabled={isLoadingNewTicket}
        value={ticket.attachments}
        error={error?.title?.includes("file") && error}
      />

      <StyledDropdown
        id="environment"
        label={intl.formatMessage({ id: "tickets.open.environment" })}
        options={environments}
        value={ticket.environment}
        isLoading={isLoadingEnvironments}
        onChange={selectedOption => {
          setTicket({
            ...ticket,
            environment: selectedOption
          });
        }}
        defaultText="Select environment"
        disabled={
          !ticket.project || !environments?.length || isLoadingNewTicket
        }
      />

      <Input
        onChange={event =>
          setTicket({ ...ticket, affectedUrl: event.target.value })
        }
        label={intl.formatMessage({ id: "tickets.open.url" })}
        required={false}
        placeholder="Enter URL"
        id="affectedurl"
        value={ticket.affectedUrl}
        disabled={isLoadingNewTicket}
      />

      <Actions>
        <Button type="submit" disabled={!isValid || isLoadingNewTicket}>
          {capitalize(intl.formatMessage({ id: "submit" }))}
        </Button>
        <Button type="reset" variant="secondary">
          {capitalize(intl.formatMessage({ id: "cancel" }))}
        </Button>
      </Actions>
    </Layout>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  priorities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      short_description: PropTypes.string,
      description: PropTypes.string
    })
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  isLoadingNewTicket: PropTypes.bool,
  isLoadingProjects: PropTypes.bool,
  isLoadingPriorities: PropTypes.bool,
  isLoadingEnvironments: PropTypes.bool,
  isLoadingCategories: PropTypes.bool,
  onProjectChange: PropTypes.func,
  onCategoryChange: PropTypes.func,
  environments: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.shape({
    title: PropTypes.string,
    status: PropTypes.number,
    detail: PropTypes.string
  }),
  initialProject: PropTypes.object
};

export default Form;
