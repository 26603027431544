import getAccessToken from "./getAccessToken";

import streamToArray from "../utils/streamToArray";

/**
 * Shape of a metrics query
 * ```
 * {
 *   "fields": [
 *     {
 *       "name": "Name of the field",
 *       "expr": "SUM(`system.disk.used.bytes`)"
 *     }
 *   ],
 *   "stream": {
 *     "collection": "demo",
 *     "stream": "metrics"
 *   },
 *   "range": {
 *     "to": "2020-06-30T15:30:12+02:00",
 *     "from": "2020-06-30T15:15:12+02:00"
 *   },
 *   "interval": "10s"
 * }
 * ```
 * @async
 * @param {MetricsQuery} query
 * @param {Promise<Metrics>} transform optional transform function for each point. Defaults to identity
 */
const getMetrics = async (chorusURL, query) => {
  const endpoint = `${chorusURL}/v1/metrics/query`;
  let accessToken = await getAccessToken();

  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.access_token}`
    },
    body: JSON.stringify(query)
  });

  return streamToArray(response.body);
};

export default getMetrics;
