import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .UserAvatar {
    font-size: 12px;
  }

  .UserAvatar--inner,
  .UserAvatar--img {
    border-radius: 4px !important;
    overflow: hidden;
    text-transform: uppercase;
    color: #1a192b;
    ${semiBoldAlias};
  }
`;

export const Name = styled.span`
  font-style: normal;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 18px;
  color: ${props => props.theme.sectionText};
  display: inline-block;
  margin-right: 24px;
  margin-left: 24px;
`;

export const avatarColors = [
  "#ffcdd2",
  "#e1bee7",
  "#c5cae9",
  "#b3e5fc",
  "#b2dfdb",
  "#dcedc8",
  "#fff9c4",
  "#ffe0b2",
  "#d7ccc8",
  "#f8bbd0",
  "#d1c4e9",
  "#bbdefb",
  "#b2ebf2",
  "#c8e6c9",
  "#f0f4c3",
  "#ffecd3",
  "#ffccbc"
];
