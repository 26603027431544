import styled from "styled-components";

export const Wrapper = styled.section`
  padding: 32px;
  color: ${props => props.theme.sectionText};
  font-size: 14px;

  &:focus {
    outline: none;
  }

  h2 {
    margin-bottom: 16px;
    line-height: 33px;

    span {
      font-size: 14px;
      color: ${props => props.theme.subtitleText};
      font-weight: normal;
    }
  }

  p,
  ul {
    color: ${props => props.theme.sectionText};
    font-size: 14px;
  }
  p {
    line-height: 24px;
    margin: 0 0 5px;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const NoCopyCode = styled.pre`
  padding: 12px 24px;
  line-height: 19px;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  font-style: italic;
  margin-bottom: 24px;
  color: ${({ theme }) =>
    theme.name === "contrast" ? "#4a495e" : theme.subtitleText};
`;

export const CodeExample = styled.div`
  position: relative;
  margin: 29px 0 20px;

  .copyable-area {
    position: relative;
    pre {
      white-space: pre;
      margin: 0;
    }
    .link-style {
      position: absolute;
      top: -36px;
      right: 0;
      button.copy-to-clipboard {
        padding: 0;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-right: 0;
          path {
            fill: ${props => props.theme.subtitleText};
          }
        }
        span {
          display: none;
        }
        &:hover {
          background: ${props => props.theme.inputBg};
          svg path {
            fill: ${props => props.theme.buttons};
          }
        }
      }
      .copied-message {
        height: 32px;
        line-height: 32px;
      }
    }
    &.single-line {
      .copy-content-wrapper {
        height: 32px;
      }

      button {
        width: 32px;
        height: 32px;
      }

      pre {
        line-height: 32px !important;
      }
    }
  }
`;
