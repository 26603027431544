import parseDimensions from "./parseDimensions";

export const mergeSameTimestampData = data => {
  const point = {
    timestamp: new Date(data[0].timestamp)
  };

  for (let i = 0; i < data.length; i++) {
    let { value, name, instance } = data[i];
    let key;

    if (name === "max") {
      key = "max";
      value = Math.max(value, point.max || 0);
    } else if (name === "average.value") {
      key = "average@value";
    } else if (name === "average.percentage") {
      key = "average@percentage";
    } else {
      key = `${instance?.split(".")[1]}@${name}`;
    }

    point[key] = value;
  }

  return point;
};

export const groupByTimestamp = metrics => {
  const list = {};

  for (let i = 0; i < metrics.length; i++) {
    const point = metrics[i];
    const group = list[point.timestamp] || [];
    group.push(point);
    list[point.timestamp] = group;
  }

  return Object.values(list);
};

export const explodePoint = point => {
  if (!point?.values) {
    return [];
  }
  const explodedPoints = [];
  const dimensions = parseDimensions(point.dimension);

  for (let i = 0; i < point.values.length; i++) {
    const value = point.values[i];
    if (value) {
      const [type, ...name] = value.info.name.split(".");
      const isMax = name.includes("max");

      let pointValue = 0;
      if (isMax) {
        pointValue = value.value.max;
      } else {
        const sum = value.value.sum || 0;
        const count = value.value.count || 1;
        pointValue = sum / count;
      }

      explodedPoints.push(
        Object.assign(
          {
            type: type,
            name: name.join("."),
            value: pointValue,
            timestamp: point.timestamp
          },
          dimensions
        )
      );
    }
  }

  return explodedPoints;
};

export const explodeMetrics = data =>
  data?.metrics?.map(point => explodePoint(point.point)).flat();
