import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import withReducers from "Hocs/withReducers";
import {
  deleteIntegration,
  getIntegration,
  updateIntegration
} from "Reducers/integration";

import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import BackLink from "Components/BackLink";

import ModalConfirmDelete from "Components/ModalConfirmDelete";
import IntegrationForm from "../components/IntegrationForm";

import * as S from "./Configure.styles";

const ConfigureIntegration = () => {
  const [showModal, toggleModal] = useState(false);

  const { integrationId, organizationId, projectId } = useParams();

  const project = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId])
  );
  const integration = useSelector(state =>
    state.integration?.getIn(["data", projectId, integrationId])
  );
  const status = useSelector(state => state.integration?.get("status"));

  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();

  useEffect(
    () => {
      dispatch(getIntegration({ projectId, integrationId }));
    },
    [integrationId]
  );

  useEffect(
    () => {
      if (status === "updated")
        history.push(
          `/${organizationId}/${projectId}/-/integrations/${integrationId}`
        );

      if (status === "deleted")
        history.push(`/${organizationId}/${projectId}/-/integrations`);
    },
    [status]
  );

  const onCancel = () => {
    history.push(
      `/${organizationId}/${projectId}/-/integrations/${integrationId}`
    );
  };

  const onSubmit = data => {
    dispatch(updateIntegration({ projectId, integration, data }));
  };

  const onConfirmRemove = e => {
    if (e) e.preventDefault();
    dispatch(deleteIntegration({ projectId, integration }));
  };

  return (
    <ContentLayout>
      <S.Header>
        <BackLink
          to={`/${organizationId}/${projectId}/-/integrations/${integrationId}`}
        />
        <Heading2>
          {intl.formatMessage({
            id: "integration.configure.title",
            defaultMessage: "Edit integration"
          })}
        </Heading2>
      </S.Header>

      {integration && (
        <S.Content>
          <ModalConfirmDelete
            isOpen={showModal}
            closeModal={() => toggleModal(false)}
            cancelFunction={() => toggleModal(false)}
            deleteFunction={onConfirmRemove}
            title={intl.formatMessage({
              id: "integration.remove.title",
              default: "Remove integration"
            })}
            body={intl.formatMessage(
              {
                id: "integration.remove.sentence"
              },
              {
                b: (...chunks) => <b>{chunks}</b>, // eslint-disable-line react/display-name
                integration: intl.formatMessage({
                  id: `integration.type.${integration.type}`
                }),
                project: project?.title
              }
            )}
            deleteText={intl.formatMessage({
              id: "confirm_remove",
              defaultMessage: "Yes"
            })}
          />
          <IntegrationForm
            integration={integration}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onRemove={() => toggleModal(true)}
          />
        </S.Content>
      )}
    </ContentLayout>
  );
};

export default withReducers({
  integration: () => import("Reducers/integration")
})(ConfigureIntegration);
