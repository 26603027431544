import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { Label, Value } from "Components/LabeledInfo/styles";

export const InfoLayout = styled.div`
  display: grid;
  grid-template-columns: 25% 30% auto;

  @media only screen and (max-width: 1400px) {
    grid-template-columns: 15% 30% auto;
  }
  @media only screen and (max-width: 1000px) {
    grid-template-columns: 15% 25% auto;
  }
  align-items: center;
  .user {
    display: flex;
    flex-align: center;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .display-name {
      font-weight: bold;
      font-size: 16px;
    }
    .email {
      font-weight: normal;
      font-size: 15px;
      text-overflow: ellipsis;
    }
  }
  .role {
    display: inline-flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    font-size: 13px;
    color: ${props => props.theme.settingBarLabel};
    ${semiBoldAlias};
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .lock-icon {
    height: 24px;
    width: 24px;
    idsplay: inline-flex;
    align-items: stretch;
    margin-left: 16px;
    svg {
      width: 100%;
    }
  }

  ${Value} {
    font-size: 15px;
    font-weight: normal;
    line-height: 18px;
    color: ${props => props.theme.envTreeInactiveIcon} !important;
  }

  ${Label} {
    font-size: 13px;
    ${semiBoldAlias};
    line-height: 18px;
    color: ${props => props.theme.envTreeInactiveIcon};
  }
`;

export const StateLabel = styled.span`
  padding: 4px 8px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  background: #f0f2f5;
  color: #5f5e70;
  height: 16px;
  max-height: 16px;
  max-width: 83px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Role = styled.span`
  display: inline-block;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  &:first-letter {
    text-transform: uppercase;
  }
  color: #98a0ab;
`;

export const Email = styled.div`
  font-weight: normal;
  font-size: 15px;
  color: #98a0ab;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DisplayName = styled(Email)`
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SettingLineLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;
`;

export const RoleWrapper = styled.div`
  display: inline-flex;
  flex: 1;
  margin-left: 20px;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  font-size: 13px;
  color: ${props => props.theme.settingBarLabel};
  ${semiBoldAlias};
  &:first-letter {
    text-transform: uppercase;
  }
  max-width: 500px;
`;

export const LabeledInfoWrapper = styled.div`
  width: 100px;
  min-width: 100px;

  span {
    text-transform: none !important;
  }
`;
