import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
// import logger from "Libs/logger";

import withReducers from "Hocs/withReducers";

import { loadProject } from "Reducers/project";
import { loadEnvironment } from "Reducers/environment";

import ErrorBoundary from "Components/ErrorBoundary";
import PageLayout from "Components/PageLayout";

import SubNavBarContainer from "Containers/SubNavBarContainer";
import NavBar from "Containers/NavBar";

import ProjectError from "./containers/ProjectError";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Project = ({ children }) => {
  const dispatch = useDispatch();
  const { projectId, organizationId, environmentId } = useDecodedParams();
  const { pathname } = useLocation();
  const { push } = useHistory();

  useEffect(
    () => {
      dispatch(loadProject(projectId));
    },
    [projectId]
  );

  useEffect(
    () => {
      if (environmentId) {
        dispatch(loadEnvironment(environmentId, projectId, organizationId));
      }
    },
    [environmentId]
  );

  return (
    <ProjectError projectId={projectId} organizationId={organizationId}>
      <NavBar
        push={push}
        projectId={projectId}
        organizationId={organizationId}
        environmentId={environmentId}
        role="navigation"
        currentPathName={pathname}
        fixed={true}
      />

      <PageLayout className="project">
        <SubNavBarContainer
          currentPathName={pathname}
          organizationId={organizationId}
          environmentId={environmentId}
          projectId={projectId}
        />
        <ErrorBoundary>
          <Layout>{children}</Layout>
        </ErrorBoundary>
      </PageLayout>
    </ProjectError>
  );
};

Project.propTypes = {
  children: PropTypes.node.isRequired
};

export default withReducers({
  project: () => import("Reducers/project"),
  invitation: () => import("Reducers/invitation"),
  environment: () => import("Reducers/environment"),
  deployment: () => import("Reducers/environment/deployment"),
  service: () => import("Reducers/environment/service"),
  activity: () => import("Reducers/activity"),
  region: () => import("Reducers/project/region"),
  plan: () => import("Reducers/plan"),
  projectWizard: () => import("Reducers/project/wizard"),
  subscription: () => import("Reducers/subscription"),
  organizationSubscription: () => import("Reducers/organization/subscription"),
  log: () => import("Reducers/log")
})(Project);
