import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { ThemeContext } from "styled-components";

import ChevronIcon from "Icons/ChevronIcon";
import { Worker } from "./";

import * as S from "./styles";
import EmptyCard from "../../../components/EmptyCard";

const Workers = ({ list }) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);

  const [sortList, setSortList] = useState({ by: "name", order: "asc" });

  const sortWorkers = (a, b) => {
    const { by, order } = sortList;
    const valA = typeof a[by] === "string" ? a[by].toLowerCase() : a[by];
    const valB = typeof b[by] === "string" ? b[by].toLowerCase() : b[by];
    if (order === "asc") {
      if (valA < valB) return -1;
      if (valA > valB) return 1;
      return 0;
    } else {
      if (valA > valB) return -1;
      if (valA < valB) return 1;
      return 0;
    }
  };

  const workersList = list
    .valueSeq()
    .toJS()
    .sort(sortWorkers);
  const sortable = workersList.length > 1;

  const handleSortBy = by => {
    if (!sortable) return;
    setSortList({
      by,
      order:
        sortList.by === by ? (sortList.order === "asc" ? "desc" : "asc") : "asc"
    });
  };

  return (
    <S.Wrapper>
      <S.Heading>
        {intl.formatMessage(
          {
            id: "services.workers.title"
          },
          {
            count: workersList.length,
            b: txt => <strong>{txt}</strong> // eslint-disable-line react/display-name
          }
        )}
      </S.Heading>

      {!workersList.length ? (
        <EmptyCard>
          {intl.formatMessage({ id: "services.workers.none" })}
        </EmptyCard>
      ) : (
        <S.Layout>
          <S.Grid>
            {["name", "version", "size", "disk"].map(field => (
              <S.GridHeader
                key={`header-${field}`}
                className="sort"
                onClick={() => handleSortBy(field)}
              >
                {intl.formatMessage({ id: field })}{" "}
                {sortable && (
                  <ChevronIcon
                    color={sortList.by === field ? theme.links : "#4b6180"}
                    isOpen={sortList.by === field && sortList.order === "desc"}
                    animate={true}
                  />
                )}
              </S.GridHeader>
            ))}
            <S.GridHeader>{intl.formatMessage({ id: "ssh" })}</S.GridHeader>
          </S.Grid>
          <hr />
          <S.Grid>
            {workersList.map((w, i) => (
              <Worker key={`worker-${i}`} worker={w} />
            ))}
          </S.Grid>
        </S.Layout>
      )}
    </S.Wrapper>
  );
};

Workers.propTypes = {
  list: PropTypes.object
};

export default Workers;
