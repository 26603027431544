import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@platformsh/ui-kit";

import { getSubscriptionEditUrl } from "Libs/utils";

import * as S from "./PlanNav.styles";

const PlanNav = ({ project, subscription }) => {
  const intl = useIntl();
  const location = useLocation();

  const subscriptionEditUrl = useMemo(
    () => getSubscriptionEditUrl({ project, subscription }),
    [project, subscription]
  );

  return (
    <S.Container>
      <Button
        as={Link}
        to={{
          pathname: subscriptionEditUrl.url,
          state: {
            from: "project_settings",
            url: location.pathname
          }
        }}
        target={subscriptionEditUrl.external ? "_blank" : null}
      >
        {intl.formatMessage({ id: "edit_plan" })}
      </Button>
    </S.Container>
  );
};

PlanNav.propTypes = {
  project: PropTypes.object,
  subscription: PropTypes.object
};

export default PlanNav;
