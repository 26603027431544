import * as d3 from "d3";

/**
 * Gets the maximum value on a datapoint.
 *
 * A datapoint has at least a max field, and other values defined by the
 * the keys ending on `@value`. This can include host or average data.
 *
 * Other fields are ignored.
 *
 * ```js
 * {
 *   "2@value": 0.22, // Value reported on the host #2
 *   "2@percentage": 12, // Ignored
 *   "average@value": 0.3, // Average value of all hosts
 *   "max": 2 // Maximum value reported
 * }
 * ```
 *
 * @param {{[string]: number, max: number}} point
 * @returns {number} The maximum value on the datapoint
 */
const getMaxReportedValue = point => {
  const values = Object.entries(point)
    .filter(([key]) => key === "max" || key.endsWith("@value"))
    .map(([, value]) => value);

  return d3.max(values);
};

/**
 * Overcommits can happen when there are values in the dataset that are higher
 * than the predefined max value.
 *
 * @typedef {Object} OvercommitMetadata
 * @property {number} fixedMax
 * @property {number} reportedMax
 * @property {boolean} hasOverCommit
 * @property {number} max 
 
 * @param {Array<Object>} data
 * @returns {OvercommitMetadata}
 */
export const getOverCommitInfo = data => {
  const fixedMax = d3.max(data, point => point.max);
  const reportedMax = d3.max(data, getMaxReportedValue);

  return {
    hasOverCommit: fixedMax < reportedMax,
    fixedMax,
    reportedMax,
    max: d3.max([fixedMax, reportedMax])
  };
};

/**
 * Defines the Y axis thresholds based on the max value of the dataset. Sorted
 * from low to high
 *
 * @param {number} fixedMax
 * @param {number} reportedMax
 *
 * @returns {Array<number>} List of thresholds
 */
export const getThresholds = (fixedMax, reportedMax) => {
  const thresholds = [
    0,
    fixedMax / 2,
    (fixedMax / 4) * 3,
    (fixedMax / 8) * 7,
    fixedMax
  ];

  return reportedMax > fixedMax ? [...thresholds, reportedMax] : thresholds;
};

export const getGradient = (fixedMax, reportedMax) => {
  const fixed75 = (fixedMax / 4) * 3;
  const fixed87 = (fixedMax / 8) * 7;

  return [
    (fixed75 / reportedMax) * 100,
    (fixed87 / reportedMax) * 100,
    (fixedMax / reportedMax) * 100
  ];
};
