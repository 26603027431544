import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { useEffect } from "react";

const VALID_TYPES = ["dedicated", "dedication", "grid"];

const selectEnvironment = state => state.environment;

/**
 * Given an environment path return the organization project and environment ID
 *
 * @param {string} path url path to be parsed
 * @returns [organizationId, projectId, environmentId]
 */
const extractEnvironmentInfoFromPath = path =>
  path
    ?.split("/")
    .slice(1, 4)
    .map(decodeURIComponent) || [];

const selectPath = createSelector(
  state => state.router.location?.pathname,
  extractEnvironmentInfoFromPath
);

const environmentFromRoute = createSelector(
  selectEnvironment,
  selectPath,
  (environment, path) => environment.getIn(["data", ...path])
);

const getChorusLink = createSelector(environmentFromRoute, environment =>
  getMetricsLink(environment?.data._links["#metrics"])
);

const getMetricsLink = links => {
  if (!(links instanceof Array)) {
    return false;
  }

  if (links.length < 1) {
    return false;
  }

  const [link] = links;

  if (!link.href || !link.collection || !link.type) {
    return false;
  }

  return link;
};

const throwError = (environment, link) => {
  if (environment && !link) {
    throw new Error("Metrics not available for this environment");
  }

  if (environment && link && !VALID_TYPES.includes(link.type)) {
    throw new Error(`Invalid link type: ${link.type}`);
  }
};

/**
 * @typedef MetricsLink
 * @property {string} collection collection string
 * @property {string} href Chorus endpoint
 * @property {string} type Kind of backend
 */
/**
 * Gets the metrics link based on the current url.
 *
 * @returns {MetricsLink} the metrics link
 */
const useMetricsLink = () => {
  const environment = useSelector(
    environmentFromRoute,
    (prev, next) => prev?.id === next?.id
  );
  const link = useSelector(
    getChorusLink,
    (prev, next) => prev?.collection === next?.collection
  );

  useEffect(() => throwError(environment, link), [environment, link]);

  return link;
};

export default useMetricsLink;
