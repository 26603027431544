import styled from "styled-components";

import BaseButtonWrapper from "Components/ButtonWrapper";

export const QRTitle = styled.p`
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 30px;
`;

export const QRCode = styled.img`
  width: 283px;
  margin-left: -25px;
`;

export const ButtonWrapper = styled(BaseButtonWrapper)`
  margin-top: 32px;
`;
