import client from "Libs/platform";

const Page = () => {
  client.wipeToken();

  window.location.href = process.env.LOGOUT_URL;

  return null;
};

export default Page;
