import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

export const Note = styled.dl`
  dt {
    margin: 0 0 8px 0;
    padding: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }
  dd {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const ListTitle = styled.h3`
  margin: 24px 0 8px 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.textLight};
  span {
    margin: 0 0 0 3px;
    font-weight: normal;
    color: ${props => props.theme.envLabel};
  }
`;

export const EmptyList = styled.div`
  margin-bottom: 16px;
  padding: 24px 32px;
  border-radius: 4px;
  background: ${props => props.theme.lineBackground};
  font-size: 14px;
  line-height: 18px;
  ${semiBoldAlias};
  color: ${props => props.theme.sectionText};
  text-align: center;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
