import React from "react";
import { Route, useParams, Switch, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";

import Page from "./Page";

import ProjectBasicSettingsContainer from "./containers/ProjectBasicSettings";
import EnvironmentTypeAccess from "./environmentType/Page";
import Access from "./access/Page";
import Domains from "./domains/Routes";
import Certificates from "./certificates/Page";
import DeployKey from "./deployKey/Page";
import Variables from "./variables/Routes";

import { isEnvironmentTypeProjectSelector } from "Reducers/project";

const Routes = () => {
  const { organizationId, projectId } = useParams();
  const { path } = useRouteMatch();

  let isEnvironmentTypeProject = useSelector(state =>
    isEnvironmentTypeProjectSelector(state, { organizationId, projectId })
  );

  return (
    <Page>
      <Switch>
        <Route path={`${path}/variables`}>
          <Variables />
        </Route>
        <Route path={`${path}/deploy_key`}>
          <DeployKey projectId={projectId} organizationId={organizationId} />
        </Route>
        <Route path={`${path}/certificates`}>
          <Certificates projectId={projectId} organizationId={organizationId} />
        </Route>
        <Route path={`${path}/domains`}>
          <Domains />
        </Route>
        <Route path={`${path}/access`}>
          {process.env.ENVIRONMENT_TYPE_ENABLED && isEnvironmentTypeProject ? (
            <EnvironmentTypeAccess
              projectId={projectId}
              organizationId={organizationId}
            />
          ) : (
            <Access projectId={projectId} organizationId={organizationId} />
          )}
        </Route>
        <Route exact path={path}>
          <ProjectBasicSettingsContainer
            projectId={projectId}
            organizationId={organizationId}
          />
        </Route>
      </Switch>
    </Page>
  );
};

export default Routes;
