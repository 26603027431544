import React from "react";
import PropTypes from "prop-types";

import { Skeleton, Square } from "Components/Skeleton";

import * as S from "./Metric.styles";

const MetricSkeleton = ({ name }) => (
  <Skeleton synchronize style={{ overflow: "visible" }}>
    <S.Layout>
      <S.Row>
        {name ? name : <Square height="24px" width="80px" />}
        <Square height="24px" width="96px" />
      </S.Row>
      <S.Row flexEnd>
        <Square height="24px" width="64px" />
        <Square height="24px" width="40px" />
      </S.Row>
    </S.Layout>
  </Skeleton>
);

MetricSkeleton.propTypes = {
  name: PropTypes.node
};

export default MetricSkeleton;
