import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import useDecodedParams from "Hooks/useDecodedParams";

import ModalWrapper from "Components/Modal";
import ContentPaneLayout from "Components/ContentPaneLayout";

const Message = styled.div`
  margin-bottom: 32px;
`;

const MAX_TRY_NUMBER = 5;

const ProjectInvitationWaitingScreen = () => {
  const [count, setCount] = useState(0);
  const [error, setError] = useState(false);
  const { projectId } = useDecodedParams();

  const intl = useIntl();
  const { push } = useHistory();

  useEffect(
    () => {
      let isCanceled = false;
      const timeout = setTimeout(async () => {
        const platformLib = await import("Libs/platform");
        const client = platformLib.default;

        try {
          await client.getProject(projectId);

          push(`/invitation/${projectId}`);
        } catch (err) {
          if (isCanceled) {
            return;
          }
          if (count < MAX_TRY_NUMBER) {
            setCount(count + 1);
          } else {
            setError(true);
          }
        }
      }, count && 5000);

      return () => {
        isCanceled = true;
        clearTimeout(timeout);
      };
    },
    [count]
  );

  const onClose = () => push("/");

  return (
    <ModalWrapper
      id="project-wait-invitation-modal"
      shouldCloseOnOverlayClick={true}
      isOpen={true}
      title={[intl.formatMessage({ id: "invitation.pending" })]}
      onRequestClose={onClose}
      closeModal={onClose}
      modalClass="modal-medium modal-invitation"
    >
      <ContentPaneLayout className="modal-body">
        <Message>
          {error ? (
            <FormattedMessage id="invitation.pendingFail" />
          ) : (
            <FormattedMessage id="invitation.pendingLoading" />
          )}
        </Message>
      </ContentPaneLayout>
    </ModalWrapper>
  );
};

export default ProjectInvitationWaitingScreen;
