import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import Button from "Components/Button";
import Loading from "Components/Loading";

const ExternalLink = styled.a`
  font-size: 15px;
  line-height: 40px;
  color: ${props => props.theme.links};
  text-decoration: none;
`;

const DisconnectButton = styled(Button)`
  color: ${props => props.theme.links};
  background-color: transparent;
  padding: 0;
  height: auto;
  line-height: 40px;
  font-size: 15px;

  &:hover {
    color: ${props => props.theme.links};
    background-color: transparent;
  }
`;

const ConnectedAccountLineLink = ({
  provider,
  isConnected,
  isLoading,
  disconnectAccount
}) => {
  if (isLoading) {
    return <Loading />;
  }

  return !isConnected ? (
    <ExternalLink
      id={`${provider.name}-connect-link`}
      href={`${process.env.AUTH_URL}/auth/authorize/${
        provider.name
      }?intent=signup`}
      className="link-style"
    >
      <FormattedMessage id="connect_with" /> {provider.label}
    </ExternalLink>
  ) : (
    <DisconnectButton
      id={`${provider.name}-disconnect-link`}
      onClick={() => disconnectAccount(provider.name)}
    >
      <FormattedMessage id="disconnect" />
    </DisconnectButton>
  );
};

ConnectedAccountLineLink.propTypes = {
  provider: PropTypes.object,
  disconnectAccount: PropTypes.func,
  isLoading: PropTypes.bool,
  isConnected: PropTypes.bool
};

export default ConnectedAccountLineLink;
