import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import Button from "Components/Button";
import ButtonLink from "Components/ButtonLink";
import LargeInlineError from "Components/LargeInlineError";

import TicketInfo from "../../components/TicketInfo";
import Description from "../../components/Description";
import AddComment from "../../components/AddComment";
import BackLink from "Components/BackLink";
import Status from "../../../../common/components/Status";

import useUserIsSupport from "../../../../common/useUserIsSupport";

import Comments from "../Comments";
import AllAttachments from "../AllAttachments";

import {
  load as loadTicket,
  updateTicketStatus
} from "Reducers/tickets/ticket";
import { send as sendComment } from "Reducers/tickets/comments";
import { loadRegions } from "Reducers/project/region";
import { Actions, TicketLayout, MainContent, Sidebar, Buttons } from "./styles";

import useEnvironments from "Hooks/useEnvironments";
import { getRegionLabel } from "Libs/utils";
import transform from "../../../../common/toAttachmentEndpointFormat";
import InlineBanner from "../../../../../common/components/InlineBanner/InlineBanner";

const TicketView = () => {
  const { ticketId } = useParams();
  const dispatch = useDispatch();

  const ticket = useSelector(({ view }) => view.ticket?.get("content"));
  const error = useSelector(({ view }) => view.ticket?.get("error"));
  const isLoadingTicket = useSelector(({ view }) =>
    view.ticket?.get("isLoading")
  );
  const project = useSelector(state =>
    state.app
      .getIn(["me", "projects"])
      .toJS()
      .find(project => project.subscription_id == ticket?.subscription_id)
  );
  const region = getRegionLabel(
    useSelector(state => state.region?.getIn(["byUrl", project?.region]))
  );

  const currentUserId = useSelector(({ app }) => app?.getIn(["me", "id"]));
  const currentUserUsername = useSelector(({ app }) =>
    app?.getIn(["me", "id"])
  );
  const currentUserIsSupport = useUserIsSupport(currentUserId);

  useEffect(() => {
    dispatch(loadRegions());
    dispatch(loadTicket(ticketId));
  }, []);

  const onSubmit = data => {
    transform(data.attachments).then(attachments =>
      dispatch(
        sendComment({
          ...data,
          attachments,
          ticket_id: ticket.ticket_id,
          author_id: currentUserId
        })
      )
    );
  };

  const environments = useEnvironments(project?.id);

  const getEnvironmentName = () => {
    if (isLoadingTicket) {
      return "-";
    }
    if (ticket?.environment) {
      return ticket.environment;
    }

    if (ticket?.description && environments) {
      const lastLine = ticket.description.split("\n").pop();
      const environmentIds = Object.keys(environments);
      return environmentIds.includes(lastLine) ? lastLine : "-";
    }

    return "-";
  };

  const isClosedOrSolved = ["solved", "closed"].includes(ticket?.status);
  const isReopenVisible = isClosedOrSolved && currentUserIsSupport;
  const isCreateFollowUpVisible = ticket?.status === "closed";
  const isSolveVisible = !isClosedOrSolved;
  const environmentName = getEnvironmentName();
  const ticketDescription =
    environmentName === "-"
      ? ticket?.description
      : ticket?.description
          ?.split("\n")
          .slice(0, -1)
          .join("\n");

  return (
    <TicketLayout>
      <Actions>
        <BackLink to={`/-/users/${currentUserUsername}/tickets`} />

        {!error &&
          !isLoadingTicket && (
            <Buttons>
              {isCreateFollowUpVisible && (
                <ButtonLink
                  to={`/-/users/${currentUserUsername}/tickets/open?followup=${
                    ticket?.ticket_id
                  }&subscription=${ticket?.subscription_id}`}
                  outline={ticket?.status === "solved"}
                  text={<FormattedMessage id="tickets.view.followup" />}
                  className={ticket?.status === "solved" && "outline"}
                />
              )}

              {isReopenVisible && (
                <Button
                  onClick={() =>
                    dispatch(updateTicketStatus(ticket?.ticket_id, "open"))
                  }
                >
                  <FormattedMessage id="tickets.view.reopen" />
                </Button>
              )}

              {isSolveVisible && (
                <Button
                  onClick={() =>
                    dispatch(updateTicketStatus(ticket?.ticket_id, "solved"))
                  }
                >
                  <FormattedMessage id="tickets.view.solve" />
                </Button>
              )}
            </Buttons>
          )}
      </Actions>

      {error && <LargeInlineError>{error.message}</LargeInlineError>}

      {!error && (
        <MainContent>
          {isClosedOrSolved && (
            <InlineBanner padding="16px" level="low">
              <FormattedMessage
                id="tickets.view.markedas"
                values={{
                  // eslint-disable-next-line react/display-name, react/no-multi-comp
                  status: () => <Status plain status={ticket?.status} />,
                  date: moment(ticket?.updated).format("MMMM D")
                }}
              />
            </InlineBanner>
          )}
          <Description
            subject={ticket?.subject}
            description={ticketDescription}
            date={ticket?.created}
            attachments={ticket?.attachments}
            userId={ticket?.requester_id}
          />
          <div css="margin-top: 32px" />
          <AddComment onSubmit={onSubmit} />

          <Comments ticketId={ticketId} />
        </MainContent>
      )}

      {!error && (
        <Sidebar>
          <TicketInfo
            ticketId={ticket?.ticket_id}
            status={ticket?.status}
            projectId={project?.id}
            environment={environmentName}
            region={region}
            priority={ticket?.priority}
            url={ticket?.affected_url}
          />

          <div css="margin-top: 32px" />
          <AllAttachments ticketId={ticketId} />
        </Sidebar>
      )}
    </TicketLayout>
  );
};

export default TicketView;
