import styled from "styled-components";

export const Layout = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  // Using the same height as the refresh icon will avoid
  // unnecessary layout shifts after selecting a custom range.
  line-height: 24px;
  color: #98a0ab;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  margin-bottom: 8px;
  margin-left: auto;
`;

export const Text = styled.div`
  :first-letter {
    text-transform: uppercase;
  }
`;
