import styled from "styled-components";

export const Layout = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.theme.descriptionText};
`;
