import { Map, List } from "immutable";
import client from "Libs/platform";

const initialState = Map({
  isLoading: false,
  isSending: false
});

const defaultLoadFailureError = {
  message: "An error has occurred while loading comments"
};

const defaultSendFailureError = {
  message: "An error has occurred while sending a comment"
};

const LOAD_START = "tickets/comments/load_start";
const LOAD_FAILURE = "tickets/comments/load_failure";
const LOAD_SUCCESS = "tickets/comments/load_success";
const LOAD_MORE_SUCCESS = "tickets/comments/load_more_success";

const SEND_START = "tickets/comments/send_start";
const SEND_SUCCESS = "tickets/comments/send_success";
const SEND_FAILURE = "tickets/comments/send_failure";

export const loadStart = () => ({ type: LOAD_START });
export const loadFailure = error => ({ type: LOAD_FAILURE, payload: error });
export const loadSuccess = comments => ({
  type: LOAD_SUCCESS,
  payload: comments
});
export const loadMoreSuccess = comments => ({
  type: LOAD_MORE_SUCCESS,
  payload: comments
});

export const sendStart = () => ({ type: SEND_START });
export const sendSuccess = comment => ({
  type: SEND_SUCCESS,
  payload: comment
});
export const sendFailure = error => ({ type: SEND_FAILURE, payload: error });

/**
 *
 * @param {Number|string} ticketId Return comments related to that ticket
 * @param {Object} filters Object containing filters
 */
export const load = (
  ticketId,
  filters,
  action = loadSuccess
) => async dispatch => {
  dispatch(loadStart());
  try {
    const response = await client.loadComments(ticketId, {
      ...filters,
      all: 1
    });

    let nextPage;

    if (response?._links?.next) {
      nextPage = new URL(response._links.next.href).searchParams.get("page");
    }

    dispatch(
      action({
        comments: response.comments,
        hasMore: !!nextPage,
        page: Number(nextPage) - 1 || 1
      })
    );
  } catch (error) {
    dispatch(loadFailure(error.message));
  }
};

export const loadMore = ticketId => async (dispatch, getState) => {
  const { view } = getState();
  if (view.comments.get("hasMore")) {
    load(
      ticketId,
      { page: Number(view.comments.get("page", 0)) + 1 },
      loadMoreSuccess
    )(dispatch);
  }
};

export const send = comment => async dispatch => {
  dispatch(sendStart());
  try {
    const result = await client.sendComment(comment);
    dispatch(sendSuccess(result));
    dispatch(load(comment.ticket_id));
  } catch (error) {
    dispatch(sendFailure(error.message));
  }
};

const onLoadSuccess = (state, action) =>
  state
    .set("isLoading", false)
    .set("hasMore", action.payload?.hasMore)
    .set("page", action.payload?.page);

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_START:
      return state.set("isLoading", true);
    case LOAD_FAILURE:
      return state
        .set("isLoading", false)
        .set("error", Map(action.payload || defaultLoadFailureError));
    case LOAD_SUCCESS:
      return onLoadSuccess(state, action).set(
        "comments",
        List(action.payload?.comments)
      );
    case LOAD_MORE_SUCCESS:
      return onLoadSuccess(state, action).set(
        "comments",
        state.get("comments", List([])).concat(List(action.payload?.comments))
      );
    case SEND_START:
      return state.set("isSending", true);
    case SEND_FAILURE:
      return state
        .set("isSending", false)
        .set("error", Map(action.payload || defaultSendFailureError));
    case SEND_SUCCESS:
      return state.set("isSending", false).set("newComment", action.payload);
    default:
      return state;
  }
};

export default reducer;
