import { fromJS, Map, List } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isJson, getOrganizationId, normalize } from "Libs/utils";

import { organizationByDescriptionIdSelector } from "./index";
import { loadUsersSuccess } from "Reducers/user";

export const loadMembers = createAsyncThunk(
  "app/members",
  async (
    { organizationId: organizationDescriptionId },
    { getState, dispatch }
  ) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const organizationId = getOrganizationId(
      getState,
      organizationDescriptionId
    );

    const result = await client.getOrganizationMembers(organizationId);

    const users = await result?.getLinksManager()?.getUsers();
    dispatch(loadUsersSuccess(users));

    return result;
  }
);

export const loadNextMembersPage = createAsyncThunk(
  "app/members/next",
  async (
    { organizationId: organizationDescriptionId },
    { getState, dispatch }
  ) => {
    const linkManager = getState().organizationMember.getIn([
      "links",
      organizationDescriptionId
    ]);

    const result = await linkManager.next();

    const users = await result?.getLinksManager()?.getUsers();
    dispatch(loadUsersSuccess(users));

    return result;
  }
);

export const addMember = createAsyncThunk(
  "app/members/create",
  async (
    { organizationId: organizationDescriptionId, member },
    { getState }
  ) => {
    const organization = organizationByDescriptionIdSelector(getState(), {
      organizationDescriptionId
    });

    return await organization.addMember(member);
  }
);

export const updateMember = createAsyncThunk(
  "app/members/update",
  async ({ permissions }, { getState }) => {
    const member = editedItemSelector(getState());

    return await member.update({ permissions }, member.getLink("update"));
  }
);

export const deleteMember = createAsyncThunk(
  "app/members/delete",
  async (
    { organizationId: organizationDescriptionId, memberId },
    { getState, dispatch }
  ) => {
    const member = memberSelector(getState(), {
      organizationId: organizationDescriptionId,
      memberId
    });

    const deletedMember = await member.delete();

    dispatch(loadMembers({ organizationId: organizationDescriptionId }));

    return deletedMember;
  }
);

const setError = (state, action) => {
  let message = action.error.message;
  if (isJson(action.error.message)) {
    const errors = JSON.parse(action.error.message);
    if (errors?.detail?.errors.length) message = errors.detail.errors[0];
  }

  const { organizationId } = action.meta.arg;
  return state
    .setIn(["errors", organizationId], message)
    .set("loadingList", false)
    .set("loadingUpdate", false)
    .set("loadingCreate", false);
};

const members = createSlice({
  name: "organizationMembers",
  initialState: Map({ data: new Map(), loading: "idle" }),
  reducers: {
    openEditModal(state, action) {
      return state
        .delete("errors")
        .set("editModalOpen", true)
        .set("editedMember", action.payload?.item);
    },
    closeEditModal(state) {
      return state
        .delete("errors")
        .set("editModalOpen", false)
        .delete("editedMember");
    },
    openDeleteConfirmModal(state, action) {
      return state
        .delete("errors")
        .set("confirmDeleteModalOpen", true)
        .set("editedMember", action.payload?.item);
    },
    closeDeleteConfirmModal(state) {
      return state
        .delete("errors")
        .set("confirmDeleteModalOpen", false)
        .delete("editedMember");
    }
  },
  extraReducers: {
    // GET LIST
    [loadMembers.pending]: state => state.set("loadingList", true),
    [updateMember.pending]: state => state.set("loadingUpdate", true),
    [deleteMember.pending]: state => state.set("loadingDelete", true),
    [loadNextMembersPage.pending]: state => state.set("loadingList", true),
    [addMember.pending]: state => state.set("loadingCreate", true),
    [addMember.fulfilled]: (state, action) => {
      const { organizationId } = action.meta.arg;

      return state
        .setIn(
          ["data", organizationId, action.payload.id],
          fromJS(action.payload)
        )
        .set("loadingCreate", false)
        .set("status", "idle");
    },
    [deleteMember.fulfilled]: (state, action) => {
      const { organizationId, memberId } = action.meta.arg;

      return state
        .delete("confirmDeleteModalOpen")
        .delete("editedMember")
        .deleteIn([organizationId, memberId])
        .set("loadingDelete", false);
    },
    [loadMembers.fulfilled]: (state, action) => {
      const { organizationId } = action.meta.arg;

      return state
        .setIn(
          ["data", organizationId],
          fromJS(normalize(action.payload.items))
        )
        .setIn(
          ["list", organizationId],
          fromJS(action.payload.items.map(i => i.id))
        )
        .setIn(
          ["links", organizationId],
          fromJS(action.payload.getLinksManager())
        )
        .set("loadingList", false)
        .set("status", "idle");
    },
    [updateMember.fulfilled]: (state, action) => {
      const { organizationId } = action.meta.arg;

      return state
        .setIn(["data", organizationId, action.payload.id], action.payload)
        .set("loadingUpdate", false)
        .set("editModalOpen", false);
    },
    [loadNextMembersPage.fulfilled]: (state, action) => {
      const { organizationId } = action.meta.arg;

      return state
        .setIn(
          ["data", organizationId],
          state
            .getIn(["data", organizationId])
            .merge(fromJS(normalize(action?.payload?.items)))
        )
        .setIn(
          ["list", organizationId],
          fromJS([
            ...state.getIn(["list", organizationId]).toJS(),
            ...action?.payload?.items?.map(i => i.id)
          ])
        )
        .setIn(
          ["links", organizationId],
          fromJS(action.payload.getLinksManager())
        )
        .set("loadingList", false)
        .set("status", "idle");
    },
    [loadMembers.rejected]: (state, action) => setError(state, action),
    [addMember.rejected]: (state, action) => setError(state, action)
  }
});

export default members.reducer;

export const {
  openEditModal,
  closeEditModal,
  openDeleteConfirmModal,
  closeDeleteConfirmModal
} = members.actions;

export const membersSelector = (state, props) =>
  state.organizationMember
    .getIn(["list", props.organizationId], new List())
    .map(id =>
      memberSelector(state, {
        organizationId: props.organizationId,
        memberId: id
      })
    );

export const memberSelector = (state, props) =>
  state.organizationMember.getIn(
    ["data", props.organizationId, props.memberId],
    new Map()
  );

export const memberErrorSelector = (state, props) =>
  state.organizationMember.getIn(["errors", props.organizationId]);

export const loadingListSelector = state =>
  state.organizationMember.get("loadingList", false);

export const hasMoreMembersSelector = (state, props) =>
  state.organizationMember.getIn(["links", props.organizationId])?.hasMore();

export const editModalStateSelector = state =>
  state.organizationMember.get("editModalOpen", false);

export const confirmDeleteModalStateSelector = state =>
  state.organizationMember.get("confirmDeleteModalOpen", false);

export const editedItemSelector = state =>
  state.organizationMember.get("editedMember");
