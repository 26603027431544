import React from "react";

import ContentLayout from "Components/ContentLayout";
import ProjectAccessList from "./containers/ProjectAccessList";

const ProjectAccess = () => (
  <ContentLayout className="settings">
    <ProjectAccessList />
  </ContentLayout>
);

export default ProjectAccess;
