import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { capitalize } from "Libs/utils";

import Card from "Icons/Card";
import Safe from "Icons/Safe";
import Button from "UI/Button";
import { CardWrapper } from "./PaymentTypeCard.styles";
import { SectionBox } from "../../../containers/Billing/Billing.styles";

const PaymentTypeCard = ({ paymentSourceType, buttonAction }) => {
  const intl = useIntl();

  const Icon = paymentSourceType === "credit-card" ? Card : Safe;
  const title = intl.formatMessage({
    id: paymentSourceType === "credit-card" ? "credit_card" : "bank_account"
  });
  const description = intl.formatMessage({
    id:
      paymentSourceType === "credit-card"
        ? "payment_information_description"
        : "sepa_disclaimer_shorter"
  });

  return (
    <SectionBox>
      <CardWrapper>
        <div className="icon-wrapper">
          <Icon color="#5F5E70" />
        </div>
        <strong>{title}</strong>
        <span>{description}</span>
        <Button
          id={`add-${paymentSourceType}`}
          onClick={buttonAction}
          aria-label={intl.formatMessage({ id: "add" })}
        >
          {capitalize(intl.formatMessage({ id: "add" }))}
        </Button>
      </CardWrapper>
    </SectionBox>
  );
};

PaymentTypeCard.propTypes = {
  paymentSourceType: PropTypes.string,
  buttonAction: PropTypes.func
};

export default PaymentTypeCard;
