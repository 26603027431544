import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ThemeContext } from "styled-components";

import Link from "../../../../../common/components/Link";

import NoTicketsLightIcon from "Components/icons/NoTicketsLightIcon";
import NoTicketsDarkIcon from "Components/icons/NoTicketsDarkIcon";

import { Layout, Header, Paragraph } from "./styles";

const NoTickets = props => {
  const theme = useContext(ThemeContext);
  const username = useSelector(({ app }) => app?.getIn(["me", "username"]));

  return (
    <Layout {...props}>
      {theme.name === "dark" ? <NoTicketsDarkIcon /> : <NoTicketsLightIcon />}
      <Header>
        <FormattedMessage id="tickets.notickets" />
      </Header>
      <Paragraph>
        If you’re having any issues please{" "}
        <Link as={RouterLink} to={`/-/users/${username}/tickets/open`}>
          create a support ticket
        </Link>{" "}
        and we’ll take a look at it for you. You can also check out our public{" "}
        <Link href="https://chat.platform.sh" target="_blank">
          Slack channel
        </Link>{" "}
        for more support and help.
      </Paragraph>
    </Layout>
  );
};

export default NoTickets;
