import React from "react";
import PropTypes from "prop-types";

import { Layout } from "./styles";

const PrioritySingleValue = ({ data, innerProps }) => (
  <Layout priority={data.value} {...innerProps} />
);

PrioritySingleValue.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string
  }),
  innerProps: PropTypes.object
};

export default PrioritySingleValue;
