import styled from "styled-components";

import { Container } from "../../styles";

export const Layout = styled(Container)`
  padding: 32px 32px 24px 32px;
  ${({ hasWrapper }) => hasWrapper && "margin-bottom: 0;"};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;
