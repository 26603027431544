import styled from "styled-components";

export const Wrapper = styled.section`
  display: block;
`;

export const Header = styled.header`
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.sectionHeading};
`;

export const Title = styled.h2`
  display: inline-block;
  margin: 0 19px 0 0;
  font-size: 15px;
  line-height: 24px;
  text-transform: capitalize;
  font-weight: bold;
  color: ${props => props.theme.sectionHeading};
`;

export const List = styled.section`
  margin: 16px 0 24px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 834px) {
    display: grid;
    grid-template-columns: minmax(302px, 1fr) minmax(302px, 1fr);
    grid-row-gap: 24px;
    grid-column-gap: 24px;
    justify-items: center;
  }
  @media (min-width: 1024px) {
    grid-template-columns: minmax(302px, 1fr) minmax(302px, 1fr);
  }
  @media (min-width: 1280px) {
    grid-template-columns: minmax(302px, 1fr) minmax(302px, 1fr) minmax(
        302px,
        1fr
      );
  }
  @media (min-width: 1440px) {
    grid-template-columns:
      minmax(302px, 1fr) minmax(302px, 1fr) minmax(302px, 1fr)
      minmax(302px, 1fr);
  }
`;
