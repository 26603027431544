import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

import * as S from "./Lines.styles";

const getLineShape = (data, xAxis, yAxis) =>
  d3
    .line()
    .defined(point => point?.value >= 0)
    .x(point => xAxis(point.timestamp))
    .y(point => yAxis(point.value))(data);

// Generates areas to fill overcommits
// @TODO only render this shapes if the line has over commit
const getAreaShape = (data, xAxis, yAxis) =>
  d3
    .area()
    .defined(point => point?.value >= 0)
    .x(point => xAxis(point.timestamp))
    .y0(() => yAxis.range()[0])
    .y1(point => yAxis(point.value))(data);

const Lines = ({
  chartId,
  data,
  hosts,
  xAxis,
  yAxis,
  activeLine,
  onActiveChange,
  ...props
}) => {
  const lines = useMemo(
    () =>
      hosts
        .map(host => {
          const values = data.map(point => ({
            value: point[`${host}@value`],
            timestamp: point.timestamp
          }));

          return {
            id: host,
            lineShape: getLineShape(values, xAxis, yAxis),
            areaShape: getAreaShape(values, xAxis, yAxis),
            setActive: () => onActiveChange(host)
          };
        })
        .reverse(),
    [data, hosts, xAxis, yAxis, onActiveChange]
  );

  const clearActive = useCallback(() => onActiveChange(undefined), [
    onActiveChange
  ]);

  return (
    <g {...props}>
      {/*Areas must be rendered first so all the lines are rendered above
       * and can be hovered */}
      {lines.map(({ id, areaShape }) => (
        <S.OverCommitArea key={id} chartId={chartId} d={areaShape} />
      ))}
      {lines.map(({ id, lineShape, setActive }) => (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <S.Line
          mask={`url(#mask-${chartId})`}
          key={id}
          id={chartId}
          isAverage={id === "average"}
          anyActive={!!activeLine}
          isActive={activeLine === id}
          onMouseOver={setActive}
          onMouseOut={clearActive}
          d={lineShape}
        />
      ))}
    </g>
  );
};

Lines.propTypes = {
  chartId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.array,
  hosts: PropTypes.array,
  xAxis: PropTypes.func,
  yAxis: PropTypes.func,
  activeLine: PropTypes.string,
  onActiveChange: PropTypes.func
};

export default React.memo(Lines);
