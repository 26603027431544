import React from "react";
import { Route } from "react-router-dom";

import BillingPage from "./Page";

const Routes = () => (
  <Route exact path="/billing">
    <BillingPage />
  </Route>
);

export default Routes;
