import styled from "styled-components";

import Heading3 from "Components/styleguide/Heading3";
import { semiBoldAlias, themeHelpers } from "Libs/theme";

export const Wrapper = styled.section`
  margin: 26px 0 0 0;
`;

export const Heading = styled(Heading3)`
  margin: 0 0 11px 0;
  line-height: 24px;
  font-weight: bold;
  strong {
    margin: 0 0 0 5px;
    color: ${props => props.theme.cancelled};
    font-weight: normal;
  }
`;

export const Layout = styled.div`
  background-color: ${props => themeHelpers(props.theme, "sectionColor")};
  padding: 13px 32px 32px;
  margin-top: 8px;
  hr {
    margin: 9px 0 16px 0;
    background: ${props => props.theme.sectionBorder};
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 21.2% 9% 9% 9% 44%;
  grid-row-gap: 14px;
  grid-column-gap: 16px;
`;

export const GridHeader = styled.div`
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  color: ${props => props.theme.subtitleText};
  text-transform: capitalize;
  ${semiBoldAlias};
  display: flex;
  align-items: center;
  &.sort {
    cursor: pointer;
    .chevron {
      height: 16px;
      margin-top: 2px;
    }
  }
`;

export const Cell = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  color: ${props => props.theme.sectionTextLight};
  font-size: 15px;

  .copyable-area {
    flex: 1;
  }
`;

export const Title = styled.span`
  color: ${props => props.theme.sectionText};
  ${semiBoldAlias};
`;
