import styled, { css } from "styled-components";
import { semiBoldAlias, makePalette } from "Libs/theme";

const theme = makePalette((palette, props) => ({
  layout_background: {
    default: palette.snow,
    dark: palette.ebony
  },
  layout_color: {
    default: palette.slate,
    dark: palette.snow
  },
  list_separator: {
    default: palette.periwinkleGrey,
    dark: palette.slate
  },
  toggle_color: {
    default: props.isActive ? palette.skye : palette.granite,
    dark: props.isActive ? palette.snow : palette.granite,
    contrast: props.isActive ? palette.snow : palette.slate
  },
  toggle_background: {
    default: props.isActive ? palette.skyeLight : "transparent",
    dark: props.isActive ? palette.ebonyLight : "transparent",
    contrast: props.isActive ? palette.skyeDark : "transparent"
  },
  toggle_background_hover: {
    default: palette.skyeLight,
    dark: palette.ebonyLight,
    contrast: palette.skyeDark
  }
}));

export const Layout = styled.div`
  padding-right: 16px;
  padding-left: 8px;
  background-color: ${theme.layout_background};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 32px;
  box-sizing: border-box;
  color: ${theme.layout_color};
  font-size: 13px;
  line-height: 18px;
  ${semiBoldAlias};
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 16px;
  padding-right: 8px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 16px;
    background-color: ${theme.list_separator};
    right: 0;
    top: 4px;
  }
`;

export const ListItem = styled.li`
  position: relative;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 2px;
  }
`;

export const Toggle = styled.button.attrs(props => ({
  "aria-pressed": props.isActive ? "true" : null
}))`
  ${semiBoldAlias};
  cursor: pointer;
  font-size: 13px;
  line-height: 18px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${theme.toggle_background};
  height: 24px;
  color: ${theme.toggle_color};
  text-decoration: none;

  transition-duration: 0.25s;
  transition-property: color, background-color;

  :hover {
    background-color: ${theme.toggle_background_hover};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-radius: 2px;
      outline: none;
    `};
`;
