import WizardStartImage from "./wizard-01@2x.png";
import WizardEndImage from "./wizard-02@2x.png";
import WizardStartImageDark from "./wizard-01-dark@2x.png";
import WizardEndImageDark from "./wizard-02-dark@2x.png";

import UnbrandedWizardStartImage from "./vendor-wizard-01@2x.png";
import UnbrandedWizardEndImage from "./vendor-wizard-02@2x.png";
import UnbrandedWizardStartImageDark from "./vendor-wizard-01-dark@2x.png";
import UnbrandedWizardEndImageDark from "./vendor-wizard-02-dark@2x.png";

export default function getWizardImages(theme) {
  let startImage;
  let endImage;

  if (process.env.ENABLE_UNBRANDED_ILLUSTRATIONS) {
    if (theme.name === "dark") {
      startImage = UnbrandedWizardStartImageDark;
      endImage = UnbrandedWizardEndImageDark;
    } else {
      startImage = UnbrandedWizardStartImage;
      endImage = UnbrandedWizardEndImage;
    }
  } else {
    if (theme.name === "dark") {
      startImage = WizardStartImageDark;
      endImage = WizardEndImageDark;
    } else {
      startImage = WizardStartImage;
      endImage = WizardEndImage;
    }
  }

  return { startImage, endImage };
}
