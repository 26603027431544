import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Comment from "../Comment";

import { Header, Layout } from "./styles";

const Comments = ({ comments }) => (
  <Layout>
    <Header>
      <FormattedMessage id="tickets.comments.recent" />
    </Header>
    {comments.map(comment => (
      <Comment
        key={comment?.comment_id}
        createdAt={comment.created_at}
        body={comment.body}
        author={comment.author_id}
        attachments={comment.attachments}
      />
    ))}
  </Layout>
);

Comments.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      body: PropTypes.string,
      author: PropTypes.string,
      attachments: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          filename: PropTypes.string,
          contentType: PropTypes.string
        })
      )
    })
  ).isRequired
};

export default Comments;
