import styled from "styled-components";

const getColor = props => {
  switch (props.priority) {
    case "low":
      return "#98A0AB";

    case "normal":
      return "#5F5E70";

    case "high":
      return "#FF8359";

    case "urgent":
      return "#FB3728";

    default:
      return props.theme.subtitleText;
  }
};

const Cell = styled.td`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: left;
  color: ${getColor};

  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 24px;

  @media (min-width: 768px) {
    color: ${props => props.theme.subtitleText};
    padding-right: 32px;
  }
`;

export default Cell;
