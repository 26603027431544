import React from "react";
import PropTypes from "prop-types";

import OrderDetailProjectTotal from "../OrderDetailProjectTotal";
import TableRow from "Components/TableRow";
import TableCell from "Components/TableCell";
import Heading3 from "Components/styleguide/Heading3";
import momentjs from "moment";
import { formatAsCurrency } from "Libs/utils";
import Table from "Components/Table";
import styled from "styled-components";

const ProjectDetailLayout = styled.div`
  && .table-layout {
    width: 100%;
    padding: 24px 0 0 0;
    margin: 0;
    overflow: auto;
  }

  && .table-layout .subtotal-discount {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 0 24px;
  }

  && .table-layout .total-row.product {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 0 32px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  && .table-layout .subtotal-discount .product,
  && .table-layout .cell.product h3 {
    margin-right: 16px;
  }

  .table-layout {
    .cell {
      &:not(.total) {
        flex: 1 1 auto;
        width: auto;
      }
    }
    .product {
      min-width: auto;
      width: auto;
    }
    h3 {
      margin: auto;
    }
  }
`;

function OrderProjectDetail(props) {
  const { subscriptionId, lineItemsByOrder } = props;
  const lineItems = lineItemsByOrder[subscriptionId].line_items;
  const durationDate = date => momentjs(date).format("MMM D");
  const orderCurrency = lineItems[0].components.base_price.currency;
  // We need subscriptions API endpoint to get plan sizes here

  return (
    <ProjectDetailLayout>
      <Table
        headers={["Product", "Duration", "Unit price", "Quantity", "Total"]}
      >
        {lineItems.map((lineItem, index) => (
          <React.Fragment key={index}>
            <TableRow>
              <TableCell className="cell product">
                <Heading3 className={lineItem.product.toLowerCase()}>
                  {lineItem.product}
                </Heading3>
              </TableCell>
              <TableCell className="cell duration">
                {`${durationDate(lineItem.start)} – ${durationDate(
                  lineItem.end
                )}`}
              </TableCell>
              <TableCell className="cell unit-price">
                <span>
                  {formatAsCurrency(
                    lineItem.components.base_price.amount,
                    orderCurrency
                  )}
                </span>
                <span className="discount">
                  {lineItem.components.discount &&
                    formatAsCurrency(
                      lineItem.components.discount.amount,
                      orderCurrency
                    )}
                </span>
              </TableCell>
              <TableCell className="cell quantity">
                {lineItem.quantity}
              </TableCell>
              <TableCell className="cell total">
                {formatAsCurrency(lineItem.total_price, orderCurrency)}
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
        <OrderDetailProjectTotal
          expanded={true}
          lineItems={lineItemsByOrder[subscriptionId].line_items}
        />
      </Table>
    </ProjectDetailLayout>
  );
}

OrderProjectDetail.propTypes = {
  subscriptionId: PropTypes.string,
  lineItemsByOrder: PropTypes.array
};

export default OrderProjectDetail;
