import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto;
`;

export const Loading = styled.div`
  text-align: center;
  color: ${props => props.theme.text};
  h2 {
    margin-bottom: 16px;
    color: ${props => props.theme.text};
  }
  p {
    line-height: 25px;
    font-size: 15px;
  }
  .icon-loading {
    width: 80px;
    height: auto;
    margin-bottom: 30px;
  }
  .graphic {
    margin-bottom: 40px;
  }
  a {
    color: ${props => props.theme.links};
  }
  .close-link {
    margin-bottom: 160px;
    a {
      display: inline-block;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
`;

export const ErrorHelpText = styled.div`
  margin-bottom: 32px;
`;
