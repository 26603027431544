import { fromJS, Map, List } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isJson, getOrganizationId, normalize } from "Libs/utils";

import { loadMembers } from "./member";

export const addInvitation = createAsyncThunk(
  "app/organizationInvitation/create",
  async (
    { organizationId: organizationDescriptionId, email, permissions, force },
    { getState, dispatch }
  ) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const organizationId = getOrganizationId(
      getState,
      organizationDescriptionId
    );

    const result = await client.createOrganizationInvitation(
      email,
      organizationId,
      permissions,
      force
    );

    const newInvitation = new platformLib.entities.OrganizationInvitation({
      ...result.data,
      organizationId
    });

    // We need to load the invitations if the user does not exist yet in auth or the call to Git from auth take some time
    // But we need to load the members if the call is quick and the user already exist in auth
    dispatch(loadInvitations({ organizationId: organizationDescriptionId }));
    dispatch(loadMembers({ organizationId: organizationDescriptionId }));

    return newInvitation;
  }
);

export const addInvitations = createAsyncThunk(
  "app/organizationInvitation/create",
  async (
    {
      organizationId: organizationDescriptionId,
      invitations,
      force,
      permissions
    },
    { getState, dispatch }
  ) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const organizationId = getOrganizationId(
      getState,
      organizationDescriptionId
    );

    const newInvitations = [];

    for (let i = 0; i < invitations.length; i++) {
      const invitation = invitations[i];

      const result = await client.createOrganizationInvitation(
        invitation?.email,
        organizationId,
        permissions, // We want to apply to global permissions selected to all the users
        force
      );

      const newInvitation = new platformLib.entities.OrganizationInvitation({
        ...result.data,
        organizationId
      });

      newInvitations.push(newInvitation);
    }

    // We need to load the invitations if the user does not exist yet in auth or the call to Git from auth take some time
    // But we need to load the members if the call is quick and the user already exist in auth
    dispatch(loadInvitations({ organizationId: organizationDescriptionId }));
    dispatch(loadMembers({ organizationId: organizationDescriptionId }));

    return newInvitations;
  }
);

// Revoke organization invitation
export const revokeInvitation = createAsyncThunk(
  "app/organizationInvitation/delete",
  async ({ invitation, organizationId }, { dispatch }) => {
    if (!invitation) return;

    await invitation.delete();

    dispatch(loadInvitations({ organizationId }));

    return invitation;
  }
);

export const loadInvitations = createAsyncThunk(
  "app/organizationInvitation/load",
  async ({ organizationId: organizationDescriptionId }, { getState }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const organizationId = getOrganizationId(
      getState,
      organizationDescriptionId
    );

    const result = await client.getOrganizationInvitations(organizationId);
    return result;
  }
);

export const loadNextInvitationsPage = createAsyncThunk(
  "app/organizationInvitation/next",
  async ({ organizationId: organizationDescriptionId }, { getState }) => {
    const linkManager = getState().organizationMember.getIn([
      "links",
      organizationDescriptionId
    ]);

    const result = await linkManager.next();

    return result;
  }
);

const invitationFullfiled = (state, action, invitation) => {
  const { organizationId } = action.meta.arg;

  if (invitation) {
    return state
      .set("editModalOpen", false)
      .set("resendModalOpen", false)
      .set("confirmModalOpen", true)
      .setIn(["data", organizationId, invitation.id], fromJS(invitation))
      .set("status", "resent");
  } else {
    return state
      .setIn(["data", organizationId, invitation.id], fromJS(invitation))
      .set("addModalOpen", false)
      .set("status", "added");
  }
};

const setError = (state, action) => {
  let message = action.error.message;
  if (isJson(action.error.message)) {
    const errors = JSON.parse(action.error.message);
    if (errors?.detail?.errors.length) message = errors.detail.errors[0];
  }

  const { organizationId } = action.meta.arg;
  return state
    .setIn(["errors", organizationId], message)
    .set("loadingList", false)
    .set("loadingUpdate", false)
    .set("loadingCreate", false);
};

const invitations = createSlice({
  name: "organizationInvitations",
  initialState: Map({ data: new Map(), loading: "idle" }),
  reducers: {
    openAddModal(state) {
      return state
        .delete("errors")
        .delete("fieldsErrors")
        .set("addModalOpen", true);
    },
    closeAddModal(state) {
      return state
        .delete("errors")
        .delete("fieldsErrors")
        .delete("invitations")
        .delete("permissions")
        .set("addModalOpen", false);
    },
    closeConfirmationModal(state) {
      return state.set("confirmModalOpen", false);
    },
    openRevokeModal(state, action) {
      return state
        .delete("errors")
        .set("revokeModalOpen", true)
        .set("editedInvitation", action?.payload?.item);
    },
    closeRevokeModal(state) {
      return state.delete("errors").set("revokeModalOpen", false);
    },
    openResendModal(state, action) {
      return state
        .delete("errors")
        .set("resendModalOpen", true)
        .set("editedInvitation", action?.payload?.item);
    },
    closeResendModal(state) {
      return state.delete("errors").set("resendModalOpen", false);
    },
    setInvitations(state, action) {
      return state.set("invitations", action.payload);
    },
    setPermissions(state, action) {
      return state.set("permissions", action.payload);
    }
  },
  extraReducers: {
    [loadInvitations.pending]: state => state.set("loadingList", true),
    [addInvitations.pending]: state => state.set("loadingCreate", true),
    [addInvitation.pending]: state => state.set("loadingCreate", true),
    [loadNextInvitationsPage.pending]: state => state.set("loadingList", true),
    [addInvitations.fulfilled]: (state, action) => {
      const invitations = action.payload;

      let newState;

      for (let invitation in invitations) {
        newState = invitationFullfiled(state, action, invitation);
      }

      return newState;
    },
    [addInvitation.fulfilled]: (state, action) => {
      const { organizationId, invitation } = action.meta.arg;

      if (invitation) {
        return state
          .set("editModalOpen", false)
          .set("resendModalOpen", false)
          .set("confirmModalOpen", true)
          .setIn(
            ["data", organizationId, invitation.id],
            fromJS(action.payload)
          )
          .deleteIn(["errors", organizationId])
          .deleteIn(["fieldsErrors", organizationId])
          .delete("invitations")
          .delete("permissions")
          .set("status", "resent")
          .delete("invitations")
          .delete("permissions");
      } else {
        return state
          .setIn(
            ["data", organizationId, action.payload.id],
            fromJS(action.payload)
          )
          .deleteIn(["errors", organizationId])
          .deleteIn(["fieldsErrors", organizationId])
          .delete("invitations")
          .delete("permissions")
          .set("addModalOpen", false)
          .set("status", "added")
          .delete("invitations")
          .delete("permissions");
      }
    },
    [loadInvitations.fulfilled]: (state, action) => {
      const { organizationId } = action.meta.arg;

      return (
        state
          .setIn(["data", organizationId], fromJS(normalize(action.payload)))
          .setIn(
            ["list", organizationId],
            fromJS(action.payload.map(i => i.id))
          )
          /*.setIn(
          ["links", organizationId],
          fromJS(action.payload.getLinksManager())
        )*/
          .set("loadingList", false)
          .set("status", "idle")
          .deleteIn(["errors", organizationId])
          .deleteIn(["fieldsErrors", organizationId])
      );
    },
    [loadNextInvitationsPage.fulfilled]: (state, action) => {
      const { organizationId } = action.meta.arg;

      return state
        .setIn(
          ["data", organizationId],
          state
            .getIn(["data", organizationId])
            .merge(fromJS(normalize(action?.payload?.items)))
        )
        .setIn(
          ["list", organizationId],
          fromJS([
            ...state.getIn(["list", organizationId]).toJS(),
            ...action?.payload?.items?.map(i => i.id)
          ])
        )
        .setIn(
          ["links", organizationId],
          fromJS(action.payload.getLinksManager())
        )
        .set("loadingList", false)
        .set("status", "idle");
    },
    [loadInvitations.rejected]: (state, action) => setError(state, action),
    // Delete
    [revokeInvitation.pending]: state =>
      state.set("status", "delete.pending").delete("errors"),
    [revokeInvitation.fulfilled]: (state, action) => {
      const { organizationId, projectId } = action.meta.arg;
      return state
        .deleteIn(["data", organizationId, projectId, action.payload.id])
        .set("status", "deleted")
        .set("revokeModalOpen", false);
    },
    [revokeInvitation.rejected]: (state, action) => {
      const { organizationId } = action.meta.arg;

      return state
        .setIn(
          ["errors", organizationId],
          JSON.parse(action.error.message).message
        )
        .setIn(["status", organizationId], "rejected");
    },
    [addInvitations.rejected]: (state, action) => {
      const { organizationId } = action.meta.arg;

      const errors = JSON.parse(action.error.message);
      return state
        .setIn(["errors", organizationId], errors?.message)
        .setIn(["fieldsErrors", organizationId], errors?.detail);
    }
  }
});

export default invitations.reducer;

export const {
  openAddModal,
  closeAddModal,
  closeConfirmationModal,
  openRevokeModal,
  closeRevokeModal,
  openResendModal,
  closeResendModal,
  setInvitations,
  setPermissions
} = invitations.actions;

export const invitationsSelector = (state, props) =>
  state.organizationInvitation
    .getIn(["list", props.organizationId], new List())
    .map(id =>
      invitationSelector(state, {
        organizationId: props.organizationId,
        memberId: id
      })
    );

export const invitationSelector = (state, props) =>
  state.organizationInvitation.getIn(
    ["data", props.organizationId, props.memberId],
    new Map()
  );

export const invitationErrorSelector = (state, props) =>
  state.organizationInvitation.getIn(["errors", props.organizationId]);

export const invitationErrorDetailSelector = (state, props) =>
  state.organizationInvitation.getIn(["fieldsErrors", props.organizationId]);

export const loadingListSelector = state =>
  state.organizationInvitation.get("loadingList", false);

export const hasMoreInvitationsSelector = (state, props) =>
  state.organizationInvitation
    .getIn(["links", props.organizationId])
    ?.hasMore();

export const editModalStateSelector = state =>
  state.organizationInvitation.get("editModalOpen", false);

export const addModalStateSelector = state =>
  state.organizationInvitation.get("addModalOpen", false);

export const confirmRevokeModalStateSelector = state =>
  state.organizationInvitation.get("revokeModalOpen", false);

export const confirmResendModalStateSelector = state =>
  state.organizationInvitation.get("resendModalOpen", false);

export const confirmModalStateSelector = state =>
  state.organizationInvitation.get("confirmModalOpen", false);

export const editedItemSelector = state =>
  state.organizationInvitation.get("editedInvitation");
