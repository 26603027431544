import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Layout, HeaderSort, Chevron } from "./styles";

const HeaderCell = ({ className, direction, onClick, isActive, id }) => (
  <Layout id={id} className={className}>
    <HeaderSort onClick={() => onClick({ type: "SORT", value: id })}>
      <FormattedMessage id={`tickets.list.table.${id}`} />
      <Chevron
        width={20}
        height={20}
        animate
        isActive={isActive}
        isOpen={isActive && direction === "ASC"}
      />
    </HeaderSort>
  </Layout>
);

HeaderCell.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  id: PropTypes.string
};

export default HeaderCell;
