import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import ButtonAdd from "Components/ButtonAdd";

import * as S from "./Empty.styles";

const Empty = ({ onClick }) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <p>
        {intl.formatMessage({
          id: "settings.variables.empty"
        })}
      </p>
      <ButtonAdd
        id="add-new-variable"
        customText={intl.formatMessage({
          id: "settings.variables.add.button"
        })}
        onClick={onClick}
      />
    </S.Wrapper>
  );
};

Empty.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default Empty;
