import styled from "styled-components";

import BaseHeader from "../../../../common/components/Header";
import Pane from "../../../../common/components/Pane";

export const Header = styled(BaseHeader)`
  font-size: 15px;
  padding-left: 16px;
`;

export const Layout = styled(Pane)`
  box-shadow: inset 0px 1px 0px #c9d0e4;

  @media screen and (min-width: 768px) {
    padding-left: 42px;
  }
`;
