import { fromJS, Map, List } from "immutable";

import { normalize } from "Libs/utils";
import logger from "Libs/logger";

// const LOAD_USERS_START = 'app/users/load_users_start';
const LOAD_USERS_SUCCESS = "app/users/load_users_success";
// const LOAD_USERS_FAILURE = 'app/users/load_users_failure';

const LOAD_USER_START = "app/users/load_user_start";
const LOAD_USER_SUCCESS = "app/users/load_user_success";
const LOAD_USER_FAILURE = "app/users/load_user_failure";

export const loadUsersSuccess = users => {
  return async (dispatch, getState) => {
    // Merge the current state of users with the old one.
    let mergedUsers = users;
    let prevUsers = getState().user.get("data");

    let mappedUsers = fromJS(normalize(users));

    if (typeof prevUsers !== "undefined") {
      mergedUsers = prevUsers.merge(mappedUsers);
    }

    dispatch({
      type: LOAD_USERS_SUCCESS,
      payload: mergedUsers
    });
  };
};

export const loadUser = userId => async (dispatch, getState) => {
  const { user } = getState();
  const isInProgress = user.get("loadInProgress", List()).includes(userId);

  if (isInProgress) {
    return;
  }

  dispatch({ type: LOAD_USER_START, payload: userId });
  try {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const user = await client.getAccount(userId);

    dispatch({ type: LOAD_USER_SUCCESS, payload: user });
  } catch (err) {
    if (![404, 403].includes(err.code)) {
      logger(err, {
        action: "user_load",
        userId
      });
    }
    dispatch({
      type: LOAD_USER_FAILURE,
      error: true,
      payload: { userId, err }
    });
  }
};

const removeLoadInProgress = (state, id) => {
  const loadInProgress = state.get("loadInProgress", List());
  const updated = loadInProgress.filter(_id => _id !== id);
  return state.set("loadInProgress", updated);
};

export default function userReducer(state = Map(), action) {
  switch (action.type) {
    case LOAD_USER_START:
      return state.set(
        "loadInProgress",
        state.get("loadInProgress", List()).push(action.payload)
      );
    case LOAD_USERS_SUCCESS:
      return state
        .set("loading", false)
        .set("data", fromJS(normalize(action.payload)));
    case LOAD_USER_SUCCESS:
      return removeLoadInProgress(state, action.payload.id)
        .set("loading", false)
        .setIn(["data", action.payload.id], action.payload);
    case LOAD_USER_FAILURE:
      return removeLoadInProgress(state, action.payload)
        .set("loading", false)
        .set("error", action.payload);
    default:
      return state;
  }
}

export const usersSelector = state => state.user.get("data");
