import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";

import Datatable from "Components/Datatable";
import { BodyCell } from "Components/Datatable/Table.styles";

import RevokeInvitationConfirmationModal from "../../components/RevokeInvitationConfirmationModal";
import InvitationResendModal from "../../components/InvitationResendModal";
import Email from "../../components/Email";
import Permissions from "../../components/Permissions";

import {
  invitationsSelector,
  hasMoreInvitationsSelector,
  invitationErrorSelector,
  loadingListSelector,
  editedItemSelector,
  // loadNextInvitationsPage,
  confirmRevokeModalStateSelector,
  openRevokeModal,
  closeRevokeModal,
  openResendModal,
  closeResendModal,
  confirmResendModalStateSelector,
  revokeInvitation as revokeInvitationAction
} from "Reducers/organization/invitation";

import * as S from "./styles";

const HEADERS = [
  {
    id: "member_name",
    name: "Name",
    showOnMobile: true,
    searchable: false,
    width: "30%"
  },
  {
    id: "member_email",
    name: "Email",
    showOnMobile: true,
    width: "30%"
  },
  {
    id: "roles",
    name: "Organization permissions",
    showOnMobile: true,
    width: "40%"
  }
];

const InvitationList = ({ organizationId, setAddModelOpen, inviteMember }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const isConfirmRevokeModalOpen = useSelector(confirmRevokeModalStateSelector);
  const isConfirmResendModalOpen = useSelector(confirmResendModalStateSelector);

  const invitations = useSelector(state =>
    invitationsSelector(state, { organizationId })
  );
  const editedInvitation = useSelector(editedItemSelector);

  const hasMore = useSelector(state =>
    hasMoreInvitationsSelector(state, { organizationId })
  );

  const errors = useSelector(state =>
    invitationErrorSelector(state, { organizationId })
  );

  const isLoading = useSelector(loadingListSelector);

  const loadMore = () => {
    if (hasMore) {
      // dispatch(loadNextInvitationsPage({ organizationId }));
    }
  };

  const getBodyCell = (header, cellIndex, value) => (
    <BodyCell
      key={`${header.name}-value-${cellIndex}`}
      width={header.width}
      index={cellIndex}
    >
      {value}
    </BodyCell>
  );

  const renderCell = (header, cellIndex, data) => {
    switch (header.id) {
      case "member_email":
        return getBodyCell(header, cellIndex, <Email>{data?.email}</Email>);
      case "member_name":
        return getBodyCell(
          header,
          cellIndex,
          <S.DisplayName>
            <S.Tag>{intl.formatMessage({ id: "invitation_pending" })}</S.Tag>
          </S.DisplayName>
        );
      case "roles":
        return getBodyCell(
          header,
          cellIndex,
          <Permissions
            permissions={data?.permissions?.map(p => ({
              value: p,
              label: intl.formatMessage({ id: `access.${p}` })
            }))}
          />
        );
    }
  };

  const onMenuClick = (index, item) => {
    switch (index) {
      case 0:
        dispatch(openResendModal({ item }));
        break;
      case 1:
        dispatch(openRevokeModal({ item }));
        break;
    }
  };

  const resendInvitation = () => {
    dispatch(inviteMember(editedInvitation, true));
  };

  const revokeInvitation = () => {
    dispatch(
      revokeInvitationAction({
        invitation: editedInvitation,
        organizationId
      })
    );
  };

  return (
    <>
      <Datatable
        title={intl.formatMessage({ id: "settings.organization_settings" })}
        data={invitations.valueSeq().toJS()}
        headers={HEADERS}
        hasMore={hasMore}
        isLoading={isLoading}
        loadMore={loadMore}
        rowHeight={50}
        menuWidth={155}
        menuItems={[
          intl.formatMessage({ id: "invitation.resend" }),
          intl.formatMessage({ id: "invitation.revoke" })
        ]}
        renderCell={renderCell}
        onFilterChange={() => {}}
        onAddButtonClick={() => setAddModelOpen(true)}
        onMenuClick={onMenuClick}
        emptyState={
          <S.EmptyState>
            {intl.formatMessage({ id: "organizations.empty_invitations" })}
          </S.EmptyState>
        }
      />
      <RevokeInvitationConfirmationModal
        isOpen={isConfirmRevokeModalOpen}
        closeModal={() => dispatch(closeRevokeModal())}
        revokeInvitation={revokeInvitation}
        editedInvitation={editedInvitation}
        errors={errors}
      />
      <InvitationResendModal
        isOpen={isConfirmResendModalOpen}
        email={editedInvitation?.email}
        closeModal={() => dispatch(closeResendModal())}
        resendInvitation={resendInvitation}
        editedInvitation={editedInvitation}
        errors={errors}
      />
    </>
  );
};

InvitationList.propTypes = {
  organizationId: Proptypes.string,
  setAddModelOpen: Proptypes.func,
  inviteMember: Proptypes.func
};

export default InvitationList;
