import React from "react";
import momentjs from "moment";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import OrderDetail from "../OrderDetail";
import SettingLine from "Components/SettingLine";
import Loading from "Components/Loading";
import Heading3 from "Components/styleguide/Heading3";
import { Layout } from "./OrderItemList.styles";
import OrderItem from "../OrderItem";

const OrderItemList = props => {
  const {
    orders,
    orderDetails,
    expandedItem,
    title,
    loadOrder,
    expand,
    intl,
    organizationId
  } = props;

  const billingCycle = date => momentjs(date).format("MMMM YYYY");
  const orderFilter = order => {
    const title = order.id;

    if (!title || !props.titleFilter) {
      return true;
    }

    return title.includes(props.titleFilter);
  };

  return (
    <Layout>
      <Heading3>{title}</Heading3>

      {props.isLoading ? (
        <Loading />
      ) : (
        orders &&
        orders
          .sort((a, b) => {
            if (a.billing_period_end < b.billing_period_end) return 1;
            if (a.billing_period_end > b.billing_period_end) return -1;
            return 0;
          })
          .filter(orderFilter)
          .map(order => (
            <SettingLine
              key={order.id}
              id={`billing-history-recurring-${order.id}`}
              info={<OrderItem order={order} organizationId={organizationId} />}
              isOpen={expandedItem === order.id}
              openText={intl.formatMessage({ id: "view" })}
              onClick={() => {
                expand(order.id);
                loadOrder(order.id);
              }}
            >
              {expandedItem === order.id &&
                orderDetails[order.id] && (
                  <OrderDetail
                    loading={orderDetails[order.id].loading}
                    order={orderDetails[order.id].data}
                    billingCycle={billingCycle(order.billing_period_end)}
                    showProjects={true}
                    organizationId={organizationId}
                    showPDF={true}
                  />
                )}
            </SettingLine>
          ))
      )}
    </Layout>
  );
};

OrderItemList.propTypes = {
  orders: PropTypes.object,
  orderDetails: PropTypes.object,
  expandedItem: PropTypes.string,
  title: PropTypes.string,
  loadOrder: PropTypes.func,
  expand: PropTypes.func,
  intl: PropTypes.object,
  titleFilter: PropTypes.string,
  isLoading: PropTypes.bool,
  organizationId: PropTypes.string
};

export default injectIntl(OrderItemList);
