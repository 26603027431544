import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 24px;

  .dropdown .input-wrapper {
    width: 192px;
    margin-bottom: 14px;
    .select-box {
      width: 192px;
    }
  }

  .copyable-area {
    position: relative;

    pre {
      min-height: 218px;
      height: 218px;
      overflow: scroll;
      margin: 0;
      white-space: pre;
    }
    &.empty-copy pre {
      color: ${props => props.theme.subtitleText};
    }
  }
  .link-style {
    position: absolute;
    top: -36px;
    right: 0;
    button.copy-to-clipboard {
      padding: 0;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 0;
        path {
          fill: ${props => props.theme.subtitleText};
        }
      }
      span {
        display: none;
      }
      &:hover {
        background: ${props => props.theme.inputBg};
        svg path {
          fill: ${props => props.theme.buttons};
        }
      }
    }
    .copied-message {
      height: 32px;
      line-height: 32px;
    }
  }
`;

export const FileExampleLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 36px;
  margin: 0 0 4px 0;
  label {
    margin-bottom: 0;
    color: ${props => props.theme.subtitleText};
  }
`;

export const ExpandButton = styled.button`
  width: 32px;
  height: 32px;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  svg {
    margin-right: 0;
    path {
      fill: ${props => props.theme.subtitleText};
    }
  }
  span {
    display: none;
  }
  &:hover {
    background: ${props => props.theme.inputBg};
    svg > path {
      fill: ${props => props.theme.buttons};
    }
  }
`;
