import styled from "styled-components";

import Heading2 from "Components/styleguide/Heading2";

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const Heading = styled(Heading2)`
  margin-bottom: 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TimeframeColumn = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
`;
