import styled from "styled-components";

export const Layout = styled.div`
  .checkbox-field,
  .checkbox-field label {
    margin: 0;
  }
  .checkbox-field {
    margin-bottom: 8px;
    &.checked {
      label {
        color: ${props => props.theme.primary};
      }
    }
  }
`;
