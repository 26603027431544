import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import Cell from "../Cell";

export const Subject = styled(Cell)`
  position: relative;
`;

export const Project = styled(Cell)`
  ${props =>
    props.theme.name === "dark" && `color: ${props.theme.sectionText}`};
`;

export const Layout = styled.tr`
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;

  :not(:last-child) {
    box-shadow: inset 0px -1px 0px ${props => props.theme.environmentTreeLine};
  }

  &:focus {
    outline: none;
    ${props =>
      props.theme.name === "contrast" &&
      css`
        box-shadow: inset 0 0 0 1px ${props.theme.links};
      `};
  }
`;

export const SubjectLink = styled(Link)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: 0;
  right: 32px;
  top: 17px;
  text-decoration: none;
  color: ${props => props.theme.sectionText};
  font-size: 15px;
  font-weight: normal;

  &:focus,
  &:hover {
    outline: none;
    color: ${props => props.theme.links};
  }
`;
