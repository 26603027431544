import React from "react";
import { FormattedMessage } from "react-intl";

import Link from "../../../../common/components/Link";
import { Layout } from "./styles";

const MoreHelp = props => (
  <Layout {...props}>
    <FormattedMessage id="tickets.list.morehelp" /> {""}
    <Link href="https://chat.platform.sh" target="_blank">
      <FormattedMessage id="tickets.list.slack" />
    </Link>
  </Layout>
);

export default MoreHelp;
