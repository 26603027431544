export const timeframes = [
  {
    id: "short",
    label: "15m",
    range: 60 * 15,
    interval: 10
  },
  {
    id: "medium",
    label: "1h",
    range: 60 * 60,
    interval: 60
  },
  {
    id: "long",
    label: "24hrs",
    range: 60 * 60 * 24,
    interval: 60 * 10
  }
];

export const gridTimeframes = [
  {
    id: "short",
    label: "15m",
    range: 60 * 15,
    interval: 10
  },
  {
    id: "medium",
    label: "1h",
    range: 60 * 60,
    interval: 60
  },
  {
    id: "long",
    label: "8hrs",
    range: 60 * 60 * 8,
    interval: 60 * 10
  }
];
