import React from "react";
import PropTypes from "prop-types";

const User = ({ user }) => {
  return (
    <span className="user">
      {user.display_name && (
        <span className="display-name">{user.display_name}</span>
      )}
      {user.email && <span className="email">{user.email}</span>}
    </span>
  );
};

User.propTypes = {
  user: PropTypes.object
};

export default User;
