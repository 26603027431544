import React from "react";
import {
  Route,
  useRouteMatch,
  Switch,
  Redirect,
  useParams
} from "react-router-dom";

import Page from "./Page";
import SshKeysPage from "./sshKeys/Page";
import TokensPage from "./apiTokens/Page";
import AccountsSettingsPage from "./accounts/Page";
import SecurityRoutes from "./security/Routes";
import OrganizationBasicSettingsContainer from "./BasicSettings/Page";
import OrganizationBasicSettingsCustomContainer from "./BasicSettings/CustomPage";

const customBasicSettingsEnabled =
  process.env.CUSTOM_VENDOR_CONSOLE_URL &&
  process.env.CUSTOM_VENDOR_CONSOLE_TITLE;

const Routes = () => {
  const match = useRouteMatch();
  const { username } = useParams();

  return (
    <Page>
      <Switch>
        <Route path={`${match.path}/tokens`}>
          <TokensPage />
        </Route>
        <Route path={`${match.path}/ssh-keys`}>
          <SshKeysPage />
        </Route>
        <Route path={`${match.path}/security`}>
          {process.env.ENABLE_ACCOUNT_SECURITY_ROUTE ? (
            <SecurityRoutes />
          ) : (
            <Redirect to={`/-/users/${username}/settings`} />
          )}
        </Route>
        <Route path={`${match.path}/accounts`}>
          {process.env.ENABLE_CONNECTED_ACCOUNTS_ROUTE ? (
            <AccountsSettingsPage />
          ) : (
            <Redirect to={`/-/users/${username}/settings`} />
          )}
        </Route>
        <Route path={match.path}>
          {customBasicSettingsEnabled ? (
            <OrganizationBasicSettingsCustomContainer />
          ) : (
            <OrganizationBasicSettingsContainer username={username} />
          )}
        </Route>
      </Switch>
    </Page>
  );
};

export default Routes;
