import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Page from "./Page";

import Setup from "./pages/setup/Page";
import Manage from "./pages/manage/Page";

const Routes = () => {
  const { path } = useRouteMatch();
  return (
    <Page>
      <Switch>
        <Route path={`${path}/setup`}>
          <Setup />
        </Route>
        <Route path={path}>
          <Manage />
        </Route>
      </Switch>
    </Page>
  );
};

export default Routes;
