import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import Layout from "../../../../components/Layout";
import Row from "../../../../components/Row";
import ServiceInfo from "../../../../../../../../common/components/ServiceInfos";

import ServiceMetrics from "../../ServiceMetrics";
import ServiceMetricsError from "../../ServiceMetrics/ServiceMetrics.error";

const ServiceOverviewDetails = ({ metadata, intl }) => (
  <>
    <Layout>
      <Row>
        <ServiceInfo metadata={metadata} intl={intl} />
      </Row>
    </Layout>
    <ServiceMetricsError>
      <ServiceMetrics name={metadata.name} />
    </ServiceMetricsError>
  </>
);

ServiceOverviewDetails.propTypes = {
  metadata: PropTypes.object,
  intl: PropTypes.object
};

export default injectIntl(ServiceOverviewDetails);
