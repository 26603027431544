import React, { useState } from "react";
import PropTypes from "prop-types";

import CopyableArea from "Components/CopyableArea";

import * as S from "./SshHostList.styles";

const SshHostList = ({ sshList }) => {
  const [selected, setSelected] = useState(() => (sshList && sshList[0]) || {});

  return (
    <S.Layout>
      <S.HostDropdown
        id="ssh-host-selector"
        withBorder={false}
        label={<span style={{ whiteSpace: "nowrap" }}>{selected.id}</span>}
      >
        <S.DropdownButtonGroup>
          {sshList.map(ssh => (
            <S.DropdownButton onClick={() => setSelected(ssh)} key={ssh.id}>
              {ssh.id}
            </S.DropdownButton>
          ))}
        </S.DropdownButtonGroup>
      </S.HostDropdown>
      <CopyableArea
        key={selected.value}
        id={`ssh-key-${selected.id}`}
        content={selected.value}
        singleLine={true}
      >
        {selected.value}
      </CopyableArea>
    </S.Layout>
  );
};

SshHostList.propTypes = {
  sshList: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, value: PropTypes.string })
  )
};

export default SshHostList;
