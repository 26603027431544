import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

export const SYSTEM_STATUS = {
  WARNING: "warning",
  CRITICAL: "critical",
  MINOR: "minor",
  MAJOR: "major",
  SUCCESS: "success",
  NONE: "none",
  MAINTENANCE: "maintenance"
};

const getColorLevel = (level, theme) => {
  switch (level) {
    case SYSTEM_STATUS.NONE:
    case SYSTEM_STATUS.SUCCESS:
      return {
        bg: "#D6F6EF",
        ico: theme.success
      };
    case SYSTEM_STATUS.MAINTENANCE:
      return { bg: `#F0F2F5`, ico: theme.warning };

    case SYSTEM_STATUS.MINOR:
    case SYSTEM_STATUS.MAJOR:
    case SYSTEM_STATUS.WARNING:
      return {
        bg: "#FFF6DF",
        ico: theme.warning
      };
    case SYSTEM_STATUS.CRITICAL:
      return {
        bg: "#FFF0F0",
        ico: theme.error
      };
    default:
      return {
        bg: theme.name === "dark" ? theme.dark : "#FFF"
      };
  }
};

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  padding: 8px 16px;
  font-size: 13px;
  line-height: 24px;
  ${semiBoldAlias}
  color: ${props => props.theme.bannerInlineWarningColor};
  background-color: ${props => getColorLevel(props.level, props.theme).bg};
  border-radius: 2px;

  .icon-success-wrapper, .icon-warning-wrapper {
    margin-right: 14px;
    svg path {
      fill: ${props => getColorLevel(props.level, props.theme).ico};
    }
  }
`;

export const Link = styled.a`
  margin: 0 0 0 auto;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: inherit;
`;
