import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import SettingLine from "Components/SettingLine";
import Heading3 from "Components/styleguide/Heading3";
import OrderProjectDetail from "../../../../components/ProjectDetail";

const Layout = styled.div`
  margin-bottom: 32px;
  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 24px;
  }
`;

const OrderProjectList = props => {
  const { organizationId, order, expandedItem, title, expand, intl } = props;

  const lineItemsByOrder = order => {
    let lineItemsByOrder;
    if (order && order.line_items) {
      lineItemsByOrder = order.line_items.reduce((items, item) => {
        if (!items[item.subscription_id]) {
          items[item.subscription_id] = { title: item.project, line_items: [] };
        }
        items[item.subscription_id].line_items.push(item);
        return items;
      }, []);
    }

    return lineItemsByOrder;
  };

  const lineItems = lineItemsByOrder(order);

  return (
    <Layout>
      <Heading3>{title}</Heading3>
      {Object.keys(lineItems).map(subscriptionId => (
        <SettingLine
          key={subscriptionId}
          id={`billing-history-recurring-${subscriptionId}`}
          info={
            <div>
              <span className="project-name">
                <strong>
                  {lineItems[subscriptionId].title
                    ? lineItems[subscriptionId].title
                    : "Untitled Project"}
                </strong>
              </span>
            </div>
          }
          isOpen={expandedItem === subscriptionId}
          openText={intl.formatMessage({ id: "view" })}
          onClick={() => {
            expand(subscriptionId);
          }}
        >
          {expandedItem === subscriptionId &&
            lineItems[subscriptionId] && (
              <OrderProjectDetail
                lineItemsByOrder={lineItems}
                subscriptionId={subscriptionId}
                organizationId={organizationId}
              />
            )}
        </SettingLine>
      ))}
    </Layout>
  );
};

OrderProjectList.propTypes = {
  orders: PropTypes.object,
  orderDetails: PropTypes.object,
  expandedItem: PropTypes.string,
  title: PropTypes.string,
  loadOrder: PropTypes.func,
  expand: PropTypes.func,
  intl: PropTypes.object,
  titleFilter: PropTypes.string,
  isLoading: PropTypes.bool,
  organizationId: PropTypes.string,
  order: PropTypes.shape({
    line_items: PropTypes.arrayOf(PropTypes.object)
  })
};

export default injectIntl(OrderProjectList);
