import React from "react";

import withReducers from "Hocs/withReducers";
import useDecodedParams from "Hooks/useDecodedParams";

import Billing from "./containers/Billing";

const Page = () => {
  const { organizationId } = useDecodedParams();
  return <Billing organizationId={organizationId} />;
};

export default withReducers({
  setup: () => import("Reducers/project/setup"),
  organizationSetup: () => import("Reducers/organization/setup"),
  plan: () => import("Reducers/plan"),
  organizationOrder: () => import("Reducers/organization/order"),
  profile: () => import("Reducers/profile")
})(Page);
