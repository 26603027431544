import React from "react";
import PropTypes from "prop-types";

import { Skeleton, Card, Square } from "Components/Skeleton";
import MetricSkeleton from "../../containers/Service/Metric/Metric.skeleton";
import { Grid } from "../../containers/Service/Service.styles";
import * as S from "./Grid.styles";

const GridSkeleton = () => (
  <Skeleton synchronize>
    <S.BaseLayout>
      {[1, 2, 3].map(key => (
        <Card key={key} width="100%" padding="16px 32px">
          <Square height="24px" width="124px" margin="0 0 20px 0" />
          <Grid length={3}>
            {[1, 2, 3].map(key => (
              <MetricSkeleton key={key} height="24px" width="100%" />
            ))}
          </Grid>
        </Card>
      ))}
    </S.BaseLayout>
  </Skeleton>
);

GridSkeleton.propTypes = {
  Wrapper: PropTypes.node
};

export default React.memo(GridSkeleton);
