import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Heading6 from "Components/styleguide/Heading6";
import RadioField from "Components/fields/RadioField";

import { Layout } from "./styles";

const SortBy = ({ setValue, options }) => (
  <Layout>
    <Heading6>
      <FormattedMessage id="tickets.sort" />
    </Heading6>
    {options &&
      options.map(({ id, isActive }) => (
        <RadioField
          key={id}
          forId={`sort-${id}`}
          name="sort"
          label={<FormattedMessage id={`tickets.sortby.${id}.label`} />}
          value={isActive}
          onChange={() => setValue(id)}
        />
      ))}
  </Layout>
);

SortBy.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      isActive: PropTypes.bool
    })
  )
};

export default SortBy;
