import React from "react";
import { FormattedMessage } from "react-intl";

import Button from "UI/Button";
import { DOCS_ENV_REDEPLOY } from "Constants/documentationUrls";

import * as S from "./Errors.styles";

const EmptyMetrics = () => (
  <S.Layout>
    <S.Illustration />
    <S.H3>
      <FormattedMessage id="metrics.error.empty.header" />
    </S.H3>
    <S.Paragraph>
      <FormattedMessage id="metrics.error.empty.paragraph" />
    </S.Paragraph>
    <Button variant="link" as="a" target="_blank" href={DOCS_ENV_REDEPLOY}>
      <FormattedMessage id="learnmore" />
    </Button>
  </S.Layout>
);

export default EmptyMetrics;
