import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  capitalize,
  getOwnerInfoLabel,
  getSubscriptionEditUrl
} from "Libs/utils";
import { projectSelector } from "Reducers/project";

import Button from "UI/Button";
import ButtonWrapper from "Components/ButtonWrapper";
import InfoDialog from "Components/InfoDialog";
import LabeledInfo from "Components/LabeledInfo";

import * as S from "./PlanDetails.styles";

const PlanDetails = ({
  organizationId,
  organizations,
  subscription,
  total
}) => {
  const intl = useIntl();

  const project = useSelector(state =>
    projectSelector(state, {
      organizationId,
      projectId: subscription?.project_id
    })
  );

  const subscriptionEditUrl = useMemo(
    () => getSubscriptionEditUrl({ project, subscription }),
    [project, subscription]
  );

  return (
    <>
      <S.SummaryLayout>
        <S.InfoTable>
          <S.Plan>{subscription.plan}</S.Plan>
          <S.CommonSpan>
            <strong>{subscription.project_region_label}</strong>
          </S.CommonSpan>
          <S.CommonSpan>
            {getOwnerInfoLabel(subscription, organizations?.toJS())}
          </S.CommonSpan>
        </S.InfoTable>
      </S.SummaryLayout>
      <S.InfoLayout>
        <S.ColumnWrapper>
          <S.InfoTable>
            <LabeledInfo
              label="Region"
              value={subscription.project_region_label}
            />
            <LabeledInfo
              label="Environments"
              value={subscription.environments}
            />
            {subscription.big_dev && (
              <LabeledInfo
                label={intl.formatMessage({
                  id: "environment_application_size"
                })}
                value={subscription.big_dev}
              />
            )}
            <LabeledInfo
              label={intl.formatMessage({ id: "plan" })}
              value={subscription.plan}
            />
            <LabeledInfo
              label={intl.formatMessage({ id: "storage" })}
              value={`${subscription.storage / 1024} GB`}
            />
            <LabeledInfo
              label={intl.formatMessage({ id: "users" })}
              value={subscription.user_licenses}
            />
          </S.InfoTable>
          <S.Column>
            <S.Total>
              <span>
                {intl.formatMessage({ id: "total_monthly_cost" })}*{" "}
                <InfoDialog
                  title={intl.formatMessage({ id: "estimated_cost" })}
                  text={intl.formatMessage({
                    id: "billing_system_explanation"
                  })}
                />
              </span>{" "}
              <S.Amount>{total}</S.Amount>
            </S.Total>
          </S.Column>
        </S.ColumnWrapper>

        {subscriptionEditUrl && (
          <ButtonWrapper>
            {subscriptionEditUrl.external ? (
              <a
                href={subscriptionEditUrl.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {capitalize(
                  intl.formatMessage({
                    id: "edit_plan"
                  })
                )}
              </a>
            ) : (
              <Button
                as={Link}
                to={{
                  pathname: subscriptionEditUrl.url,
                  state: { from: "plans" }
                }}
                aria-label={intl.formatMessage({
                  id: "edit_plan"
                })}
              >
                {capitalize(
                  intl.formatMessage({
                    id: "edit_plan"
                  })
                )}
              </Button>
            )}
          </ButtonWrapper>
        )}
      </S.InfoLayout>
    </>
  );
};

PlanDetails.propTypes = {
  organizationId: PropTypes.string,
  organizations: PropTypes.object,
  subscription: PropTypes.object,
  total: PropTypes.string
};

export default PlanDetails;
