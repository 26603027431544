import React, { useEffect, useState } from "react";
import client from "Libs/platform";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import { FormattedMessage } from "react-intl";
import Heading2 from "Components/styleguide/Heading2";
import EmptyVouchers from "./EmptyVouchers";
import VouchersList from "./VouchersList";

const Vouchers = ({ username }) => {
  const userId = useSelector(
    state => state.profile && state.profile.getIn(["data", username, "id"])
  );
  const [vouchers, setVouchers] = useState([]);

  useEffect(() => {
    getVouchers();
  }, []);

  const getVouchers = async () => {
    const voucherData = await client.getVouchers(userId);
    setVouchers(voucherData.vouchers);
  };

  const hasVouchers = vouchers.length === 0;

  return (
    <div>
      <Heading2>
        <FormattedMessage id="vouchers" />
      </Heading2>
      {hasVouchers ? <EmptyVouchers /> : <VouchersList vouchers={vouchers} />}
    </div>
  );
};

Vouchers.propTypes = {
  username: PropTypes.string
};

export default Vouchers;
