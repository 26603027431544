import React from "react";
import { FormattedMessage } from "react-intl";

import PageMeta from "Components/PageMeta";
import ReportPopup from "Components/ReportPopup";
import ContentLayout from "Components/ContentLayout";

import MetricsErrorBoundary from "./components/ErrorBoundary";
import useMetricsLink from "./hooks/useMetricsLink";

import View from "./views";

const Page = () => {
  const link = useMetricsLink();

  if (!link) {
    return null;
  }

  return (
    <MetricsErrorBoundary>
      <ContentLayout>
        <PageMeta title="Metrics" />
        <View type={link.type} href={link.href} collection={link.collection} />
        <ReportPopup
          id="metrics"
          title={<FormattedMessage id="metrics.report.title" />}
          text={<FormattedMessage id="metrics.report.text" />}
        />
      </ContentLayout>
    </MetricsErrorBoundary>
  );
};

export default Page;
