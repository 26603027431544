import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import ContentLayout from "Components/ContentLayout";
import EnvironmentVariables from "./containers/EnvironmentVariables";

const Routes = () => {
  const { path, url } = useRouteMatch();

  return (
    <ContentLayout className="settings">
      <Switch>
        <Route path={`${path}/-/new`} exact>
          <EnvironmentVariables isNew={true} url={url} />
        </Route>
        <Route path={`${path}/:variableId?`}>
          <EnvironmentVariables url={url} />
        </Route>
      </Switch>
    </ContentLayout>
  );
};

export default Routes;
