import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import useDecodedParams from "Hooks/useDecodedParams";

import { DOCS_URL } from "Constants/documentationUrls";
import Heading2 from "Components/styleguide/Heading2";
import Heading3 from "Components/styleguide/Heading3";
import Link from "Components/styleguide/Link";

import * as S from "./NoServices.styles";

const NoServices = ({ children }) => {
  const { environmentId, organizationId, projectId } = useDecodedParams();

  const environment = useSelector(({ environment }) =>
    environment?.getIn(["data", organizationId, projectId, environmentId])
  );
  const currentDeploymentError = useSelector(state =>
    state.deployment?.get("errors")
  );

  const isEnvironmentLoading = useSelector(state =>
    state.environment?.get("loading")
  );

  let message;

  if (currentDeploymentError) {
    message = (
      <>
        <Heading3>
          <FormattedMessage id="services.display.error.title" />
        </Heading3>
        <p>
          <FormattedMessage
            id="services.display.error.description"
            values={{
              // eslint-disable-next-line react/display-name
              a: (...chunks) => (
                <Link href="https://platform.sh/contact/" target="_blank">
                  {chunks}
                </Link>
              ),
              // eslint-disable-next-line react/display-name
              br: () => <br />
            }}
          />
        </p>
      </>
    );
  }

  if (!isEnvironmentLoading && environment && !environment.data.has_code) {
    message = (
      <>
        <Heading3>
          <FormattedMessage id="services.display.empty.title" />
        </Heading3>
        <p>
          <FormattedMessage
            id="services.display.empty.description"
            values={{
              // eslint-disable-next-line react/display-name
              code: (...chunks) => <code>{chunks}</code>,
              // eslint-disable-next-line react/display-name
              a: (...chunks) => (
                <Link href={DOCS_URL} target="_blank">
                  {chunks}
                </Link>
              ),
              // eslint-disable-next-line react/display-name
              br: () => <br />
            }}
          />
        </p>
      </>
    );
  }

  if (message) {
    return (
      <S.Layout>
        <Heading2>
          <FormattedMessage id="services" />
        </Heading2>
        <S.Tree maxMessageWidth="100%">{message}</S.Tree>
      </S.Layout>
    );
  }

  return children;
};

NoServices.propTypes = {
  children: PropTypes.node,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  environmentId: PropTypes.string
};

export default NoServices;
