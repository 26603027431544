import styled from "styled-components";

export const OrganizationSettingsLayout = styled.div`
  background-color: transparent;
  margin-left: -24px;
  @media (min-width: 768px) {
    margin-left: -40px;
  }
  @media (min-width: 1920px) {
    margin-left: -60px;
  }
`;

export const Layout = styled.div`
  display: flex;
  @media (max-width: 2300px) {
    & > div + div {
      margin-left: 60px;
    }
  }

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
