import { fromJS, Map } from "immutable";

import logger from "Libs/logger";
import { isJson } from "Libs/utils";

const LOAD_CONNECTED_ACCOUNTS_START = "app/users/load_connected_account_start";
const LOAD_CONNECTED_ACCOUNTS_SUCCESS =
  "app/users/load_connected_account_success";
const LOAD_CONNECTED_ACCOUNTS_FAILURE =
  "app/users/load_connected_account_failure";

const DELETE_CONNECTED_ACCOUNTS_START =
  "app/users/delete_connected_account_start";
const DELETE_CONNECTED_ACCOUNTS_SUCCESS =
  "app/users/delete_connected_account_success";
const DELETE_CONNECTED_ACCOUNTS_FAILURE =
  "app/users/delete_connected_account_failure";

export const loadConnectedAccounts = user => async dispatch => {
  dispatch({ type: LOAD_CONNECTED_ACCOUNTS_START });
  try {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const connectedAccounts = await client.getConnectedAccounts(user.id);

    dispatch({
      type: LOAD_CONNECTED_ACCOUNTS_SUCCESS,
      payload: connectedAccounts,
      meta: { username: user.username }
    });
  } catch (err) {
    if (![404, 403].includes(err.code)) {
      const errorMessage = isJson(err)
        ? err
        : "An error occurred while attempting to load connected account.";
      logger(errorMessage, {
        action: "user_load_connected_account_success"
      });
    }
    dispatch({
      type: LOAD_CONNECTED_ACCOUNTS_FAILURE,
      error: true,
      payload: new Map(err)
    });
  }
};

export const deleteConnectedAccount = (username, provider) => async (
  dispatch,
  getState
) => {
  dispatch({ type: DELETE_CONNECTED_ACCOUNTS_START, payload: { provider } });
  try {
    const connectedAccount = getState().connectedAccounts.getIn([
      "data",
      username,
      provider
    ]);

    await connectedAccount.delete();

    dispatch({
      type: DELETE_CONNECTED_ACCOUNTS_SUCCESS,
      payload: { provider },
      meta: { username }
    });
  } catch (err) {
    if (![404, 403, 400].includes(err.code)) {
      logger(err, {
        action: DELETE_CONNECTED_ACCOUNTS_FAILURE
      });
    }
    dispatch({
      type: DELETE_CONNECTED_ACCOUNTS_FAILURE,
      error: true,
      payload: new Map(err)
    });
  }
};

export default function connectedAccountsReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_CONNECTED_ACCOUNTS_SUCCESS:
      return action.payload
        .reduce((connectedAccounts, connectedAccount) => {
          return connectedAccounts.setIn(
            ["data", action.meta.username, connectedAccount.provider],
            fromJS(connectedAccount)
          );
        }, state)
        .set("loading", false);
    case DELETE_CONNECTED_ACCOUNTS_FAILURE:
    case LOAD_CONNECTED_ACCOUNTS_FAILURE:
      return state.set("error", action.payload).set("loading", false);
    case DELETE_CONNECTED_ACCOUNTS_START:
      return state.set("loading", action.payload.provider);
    case DELETE_CONNECTED_ACCOUNTS_SUCCESS:
      return state
        .deleteIn(["data", action.meta.username, action.payload.provider])
        .set("loading", false);
    default:
      return state;
  }
}
