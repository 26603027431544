import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import Heading2 from "Components/styleguide/Heading2";
import PageDescription from "Components/PageDescription";
import Error from "Components/Error";

import ManageTFA from "../../components/ManageTFA";
import RecoveryCodes from "../../components/RecoveryCodes";
import DisableTFAModal from "../../components/DisableTFAModal";

const FlexibleHeading2 = styled(Heading2)`
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

import {
  loadAuthUser,
  resetRecoveryCodes,
  disableTFA
} from "Reducers/organization/settings/security";

const Setup = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const userId = useSelector(({ app }) => app?.getIn(["me", "id"]));
  const authUser = useSelector(({ security }) =>
    security.getIn(["user", "data"])
  );
  const recoveryCodes = useSelector(({ security }) =>
    security.getIn(["recoveryCodes", "data"])
  );

  const loadUserError = useSelector(({ security }) =>
    security.getIn(["user", "error"])
  );

  const recoveryCodesError = useSelector(({ security }) =>
    security.getIn(["recoveryCodes", "error"])
  );

  const error = loadUserError || recoveryCodesError || null;

  useEffect(
    () => {
      dispatch(loadAuthUser(userId));
    },
    [userId]
  );

  return (
    <>
      <FlexibleHeading2>
        <FormattedMessage id="security.header" />
      </FlexibleHeading2>
      <PageDescription fullWidth>
        <FormattedMessage id="security.description" />
      </PageDescription>

      {error && <Error>{error}</Error>}

      <ManageTFA
        isTFAEnabled={!!authUser?.mfa_enabled}
        onRemove={() => setIsModalOpen(true)}
      />

      {authUser?.mfa_enabled && (
        <RecoveryCodes
          recoveryCodes={recoveryCodes}
          onResetCodes={() => dispatch(resetRecoveryCodes(userId))}
        />
      )}

      {authUser?.mfa_enabled && (
        <DisableTFAModal
          isOpen={isModalOpen}
          onDisable={() => {
            setIsModalOpen(false);
            dispatch(disableTFA(userId));
          }}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default Setup;
