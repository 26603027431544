import React from "react";
import proptypes from "prop-types";

import * as s from "./styles";

import Link from "Components/styleguide/Link";

const Action = ({ title, text, link, linkText }) => (
  <s.Layout>
    <s.SectionTitle>{title}</s.SectionTitle>
    <s.Body>
      <s.Text>{text}</s.Text>
      <s.LearnMore>
        <Link href={link}>{linkText}</Link>
      </s.LearnMore>
    </s.Body>
  </s.Layout>
);

Action.propTypes = {
  title: proptypes.string,
  text: proptypes.string,
  link: proptypes.string,
  linkText: proptypes.string
};

export default Action;
