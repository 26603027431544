import client from "Libs/platform";

/**
 * @typedef {Object} Accesstoken
 * @property {string} access_token
 * @property {number} expires
 * @property {number} expires_in
 * @property {string} scope
 * @property {string} token_type
 */

/**
 * Gets the current access token, it automatically reauthenticates if the token
 * has expried.
 *
 * @returns {Promise<Accesstoken>} the access token
 */
const getAccessToken = async () => {
  let accessToken = await client.getAccessToken();

  const currentDate = Math.round(new Date().getTime() / 1000.0);
  const expirationDate = accessToken.expires;

  if (expirationDate !== -1 && currentDate >= expirationDate) {
    accessToken = await client.reAuthenticate();
  }

  return accessToken;
};

export default getAccessToken;
