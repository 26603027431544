import React from "react";

import ContentLayout from "Components/ContentLayout";
import EnvironmentAccessList from "./containers/EnvironmentAccessList";

const EnvironmentAccessesRoutes = () => {
  return (
    <ContentLayout className="settings">
      <EnvironmentAccessList />
    </ContentLayout>
  );
};

export default EnvironmentAccessesRoutes;
