import React from "react";
import PropTypes from "prop-types";

import * as Icons from "Components/icons/integrations";

const ICONS = {
  bitbucket: "Bitbucket",
  bitbucket_server: "Bitbucket",
  github: "GitHub",
  gitlab: "Gitlab",
  hipchat: "Hipchat",
  webhook: "Webhook",
  "health.email": "Email",
  "health.pagerduty": "Pagerduty",
  "health.slack": "Slack",
  "health.webhook": "Webhook",
  script: "Script"
};

const IntegrationIcon = ({ type }) => {
  const name = ICONS[type] || "default";
  const Icon = Icons[name];

  // FIXME: use default icon
  if (!Icon) return false;
  return (
    <div className={name.toLowerCase()}>
      <Icon />
    </div>
  );
};

IntegrationIcon.propTypes = {
  type: PropTypes.string
};

export default IntegrationIcon;
