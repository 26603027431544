import { fromJS, Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import logger from "Libs/logger";

export const getIntegrations = createAsyncThunk(
  "app/integrations",
  async ({ projectId }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const integrations = await client.getIntegrations(projectId);
    return integrations;
  }
);

export const getIntegration = createAsyncThunk(
  "app/integration",
  async ({ projectId, integrationId }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const integration = await client.getIntegration(projectId, integrationId);
    return integration;
  }
);

export const addIntegration = createAsyncThunk(
  "app/integration/add",
  async ({ project, type, data }, { rejectWithValue }) => {
    if (!project) return false;

    try {
      const result = await project.addIntegration(type, data);
      const integration = await result?.getEntity();
      return integration;
    } catch (err) {
      logger(err, {
        action: "addIntegration",
        meta: {
          projectId: project.id
        }
      });
      return rejectWithValue({ errors: err.detail });
    }
  }
);

export const updateIntegration = createAsyncThunk(
  "app/integration/update",
  async ({ projectId, integration, data }, { rejectWithValue }) => {
    const fields = Object.assign(
      {},
      { projectId },
      { integrationId: integration.id },
      data
    );
    try {
      const result = await integration.update(fields);
      const newIntegration = await result?.getEntity();
      return newIntegration;
    } catch (err) {
      logger(
        {
          errMessage: err.message,
          integrationId: integration.id,
          projectId
        },
        {
          action: "updateIntegration"
        }
      );
      return rejectWithValue({ errors: err.detail });
    }
  }
);

export const deleteIntegration = createAsyncThunk(
  "app/integration/delete",
  async ({ projectId, integration }) => {
    if (!integration) return false;
    await integration.delete(projectId).catch(err => {
      const errMessage = JSON.parse(err);
      logger(
        {
          errMessage,
          integrationId: integration.id,
          projectId
        },
        {
          action: "deleteIntegration"
        }
      );
      throw new Error(errMessage.error);
    });
    return integration;
  }
);

const setError = (state, action) =>
  state.set("errors", action.error.message).set("loading", false);

const integrations = createSlice({
  name: "integrations",
  initialState: Map({ data: new Map(), loading: "idle" }),
  reducers: {
    initForm(state, action) {
      return state
        .delete("errors")
        .set("status", "idle")
        .set("lastEdited", action.payload.id);
    }
  },
  extraReducers: {
    // GET LIST
    [getIntegrations.pending]: state => state.set("loading", true),
    [getIntegrations.fulfilled]: (state, action) => {
      const { projectId } = action.meta.arg;
      return state
        .setIn(
          ["data"],
          fromJS(
            action.payload.reduce((projectIntegrations, integration) => {
              if (!projectIntegrations[projectId]) {
                projectIntegrations[projectId] = {};
              }
              projectIntegrations[projectId][integration.id] = fromJS(
                integration
              );
              return projectIntegrations;
            }, {})
          )
        )
        .set("loading", false)
        .set("status", "idle")
        .delete("lastEdited");
    },
    [getIntegrations.rejected]: (state, action) => setError(state, action),

    // GET
    [getIntegration.pending]: state =>
      state.set("loading", true).delete("errors"),
    [getIntegration.fulfilled]: (state, action) => {
      const { projectId, integrationId } = action.meta.arg;
      return state
        .setIn(["data", projectId, integrationId], fromJS(action.payload))
        .set("loading", false);
    },
    [getIntegration.rejected]: (state, action) => setError(state, action),

    // ADD
    [addIntegration.pending]: state =>
      state.set("status", "pending").delete("errors"),
    [addIntegration.fulfilled]: (state, action) => {
      const { project } = action.meta.arg;
      return state
        .setIn(["data", project.id, action.payload.id], fromJS(action.payload))
        .set("status", "added")
        .set("lastEdited", action.payload.id);
    },
    [addIntegration.rejected]: (state, action) =>
      state.set("errors", action.payload.errors).set("status", "rejected"),

    // UPDATE
    [updateIntegration.pending]: state =>
      state.set("status", "pending").delete("errors"),
    [updateIntegration.fulfilled]: (state, action) => {
      const { projectId } = action.meta.arg;
      return state
        .setIn(["data", projectId, action.payload.id], fromJS(action.payload))
        .set("status", "updated");
    },
    [updateIntegration.rejected]: (state, action) =>
      state.set("errors", action.payload.errors).set("status", "rejected"),

    // DELETE
    [deleteIntegration.pending]: state =>
      state.set("status", "pending").delete("errors"),
    [deleteIntegration.fulfilled]: (state, action) => {
      const { projectId } = action.meta.arg;
      return state
        .deleteIn(["data", projectId, action.payload.id])
        .set("status", "deleted");
    },
    [deleteIntegration.rejected]: (state, action) =>
      state.set("errors", action.payload.errors).set("status", "rejected")
  }
});

export const { initForm } = integrations.actions;
export default integrations.reducer;
