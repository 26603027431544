import React from "react";
import PropTypes from "prop-types";
import cronstrue from "cronstrue";

import CopyableArea from "Components/CopyableArea";
import * as S from "./styles";

const Cron = ({ commands, name, spec }) => {
  const humanSpec = cronstrue.toString(spec);

  return (
    <>
      <S.Cell>
        <S.Title>{name}</S.Title>
      </S.Cell>
      <S.Cell>{humanSpec}</S.Cell>
      <CopyableArea content={commands.start} singleLine={true}>
        {commands.start}
      </CopyableArea>
    </>
  );
};

Cron.propTypes = {
  commands: PropTypes.shape({
    start: PropTypes.string,
    stop: PropTypes.string
  }),
  name: PropTypes.string,
  spec: PropTypes.string
};

export default Cron;
