import React from "react";
import PropTypes from "prop-types";

import Dedicated from "./Dedicated";
import Dedication from "./Dedication";
import Grid from "./Grid";
import GoBack from "../components/GoBack";

const View = ({ type, href, collection }) => {
  switch (type) {
    case "dedicated":
      return <Dedicated link={{ href, collection }} />;
    case "dedication":
      return <Dedication chorusURL={href} collection={collection} />;
    case "grid":
      return <Grid chorusURL={href} collection={collection} />;
    default:
      return <GoBack />;
  }
};

View.propTypes = {
  type: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired
};

export default View;
