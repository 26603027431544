import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { ThemeContext } from "styled-components";

import { APP_NAME, DOCS_URL } from "Constants/documentationUrls";

import { themeHelpers } from "Libs/theme";
import useDecodedParams from "Hooks/useDecodedParams";

import { subscriptionSelector } from "Reducers/subscription";
import { prevWizardStep } from "Reducers/project/wizard";

import ArrowIcon from "Components/icons/ArrowIcon";
import Button from "UI/Button";
import Heading2 from "Components/styleguide/Heading2";
import ListSteps from "../ListSteps";

import * as S from "./Outro.styles";

const Outro = ({ project }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  const { organizationId, projectId } = useDecodedParams();

  const subscription = useSelector(state => {
    return subscriptionSelector(state, {
      organizationId,
      projectId,
      id: project?.subscription_id
    });
  });

  const prevStep = e => {
    e?.preventDefault();
    dispatch(prevWizardStep({ organizationId, projectId }));
  };

  return (
    <S.Wrapper>
      <S.Content>
        <S.ImageContainer />
        <Heading2>
          {intl.formatMessage(
            { id: "wizard.outro.title" },
            {
              br: <br />,
              name: project.title,
              app: APP_NAME
            }
          )}
        </Heading2>
        <p>
          {intl.formatMessage(
            { id: "wizard.outro.sentence" },
            {
              plan: subscription?.plan,
              // eslint-disable-next-line react/display-name
              a: (...chunk) => (
                <Link to={`/${organizationId}/${projectId}/-/settings/domains`}>
                  {chunk}
                </Link>
              )
            }
          )}
        </p>

        <S.Footer>
          <Button className="back-link" variant="link" onClick={prevStep}>
            <ArrowIcon
              color={themeHelpers(theme, "primaryColor")}
              direction="left"
            />
          </Button>
          <a href={DOCS_URL} target="_blank" rel="noopener noreferrer">
            {intl.formatMessage({ id: "wizard.outro.view_doc" })}
          </a>
        </S.Footer>
      </S.Content>

      <ListSteps />
    </S.Wrapper>
  );
};

Outro.propTypes = {
  project: PropTypes.object
};

export default Outro;
