import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import IntegrationIcon from "../../../../components/IntegrationIcon";

import * as S from "./Item.styles";

const Item = ({ isActive = false, integration, onSelect }) => {
  const intl = useIntl();

  return (
    <S.Item onClick={() => onSelect(integration.id)} isActive={isActive}>
      <IntegrationIcon type={integration.type} />
      <S.Name>
        {intl.formatMessage({ id: `integration.type.${integration.type}` })}
      </S.Name>
      <S.Id>{integration.id}</S.Id>
    </S.Item>
  );
};

Item.propTypes = {
  isActive: PropTypes.bool,
  integration: PropTypes.object,
  onSelect: PropTypes.func
};

export default Item;
