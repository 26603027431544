import React from "react";

import withReducers from "Hocs/withReducers";

import List from "./containers/List";

const Page = () => <List />;

export default withReducers({
  tickets: () => import("Reducers/tickets/list")
})(Page);
