import React from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { IbanElement, injectStripe } from "react-stripe-elements";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";
import client from "Libs/platform";

import logger from "Libs/logger";

import Button from "Components/Button";
import InputField from "Components/fields/InputField";
import Label from "Components/fields/Label";
import RequiredTag from "Components/fields/RequiredTag";
import InfoDialog from "Components/InfoDialog";
import Error from "Components/Error";

const Layout = styled.div`
  .StripeElement {
    background: #f5f7fa;
    padding: 12px 16px;
    line-height: 40px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
  }
  .setting-line {
    background: #f5f7fa;
    border: 1px solid #f5f7fa;
    border-radius: 2px;
    min-height: 32px;
    line-height: 38px;
    height: 40px;
    font-size: 15px;
  }
  .card-number input {
    letter-spacing: 4px;
  }
  .input-field,
  .stripe-card {
    width: 316px;
  }
`;

const Disclamer = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 24px;
  color: #4b6180;
  margin-bottom: 16px;
  .info-dialog {
    height: 24px;
    width: 24px;
    min-width: 24px;
    margin-right: 12px;
    .dialog > div {
      width: 520px;
    }
  }
`;

const NewCardWrapper = styled.div`
  padding: 0;
  @media (min-width: 1440px) {
    display: grid;
    grid-template-columns: 316px 316px;
    grid-column-gap: 32px;
    width: auto;
  }
`;

class SepaSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
  }

  async handleSubmit(ev) {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    const { email } = this.state;
    if (this.props.edit) {
      this.setState({
        isLoading: true
      });
      try {
        const sourceData = {
          type: "sepa_debit",
          // Hardcoded currency -- SEPA transactions must be in EUR.
          currency: "eur",
          owner: {
            name: this.props.name,
            email
          },
          mandate: {
            // Automatically send a mandate notification email to your customer
            // once the source is charged.
            notification_method: "email"
          }
        };

        const response = await this.props.stripe.createSource(sourceData);

        if (response.error) {
          throw response.error;
        }

        const data = await client.addPaymentSource(
          "stripe_sepa_debit",
          response.source.id
        );

        this.setState({
          isLoading: false,
          error: null
        });

        this.props.onSuccess && this.props.onSuccess(data);
      } catch (err) {
        logger(err);
        this.setState({
          isLoading: false,
          error: err
        });
      }
    } else {
      this.props.onSuccess && this.props.onSuccess();
    }
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onNameChange(e) {
    this.setState({
      name: e.target.value
    });
  }

  render() {
    const { email, name, error } = this.state;
    const { intl, number, onEdit, onCancel, hideButtons, edit } = this.props;

    return (
      <Layout>
        {error && (
          <Error>
            {error.message ||
              error.description ||
              JSON.stringify(error, null, 4)}
          </Error>
        )}
        <Disclamer>
          <InfoDialog
            title={intl.formatMessage({ id: "bank_account_payments" })}
            text={intl.formatMessage({ id: "sepa_disclamer" })}
          />
          <span>{intl.formatMessage({ id: "sepa_disclamer_short" })}</span>
        </Disclamer>
        <form aria-labelledby="edit-card" id="stripe-payment-form">
          {!edit && number ? (
            <React.Fragment>
              <LiveMessage
                message="click update credit card to edit"
                aria-live="polite"
              />
              <InputField
                label="credit card number"
                className="card-number"
                isDisabled={true}
                value={number && number.replace(/X/g, "•").replace(/-/g, " ")}
              />
              <div>
                <Button
                  onClick={onEdit}
                  type="button"
                  aria-label={intl.formatMessage({ id: "update_bank_account" })}
                >
                  {intl.formatMessage({ id: "update_bank_account" })}
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <NewCardWrapper className="new-card">
              <LiveMessage
                message={intl.formatMessage({ id: "bank_account_payments" })}
                aria-live="polite"
              />
              <InputField
                id="name_line"
                name="name_line"
                label={intl.formatMessage({ id: "full_name" })}
                placeholder={intl.formatMessage({ id: "full_name" })}
                className="name_line"
                value={name}
                onChange={this.onNameChange}
              />
              <InputField
                label={intl.formatMessage({ id: "email" })}
                value={email}
                onChange={this.onChangeEmail}
              />
              <div>
                <Label>
                  {intl.formatMessage({ id: "bank_account_number" })}{" "}
                  <RequiredTag />
                </Label>
                <IbanElement
                  supportedCountries={["SEPA"]}
                  className="stripe-card"
                  style={{
                    base: {
                      fontSize: "14px",
                      iconColor: "rgba(0,0,0,0.3)",
                      "::placeholder": {
                        fontSize: "14px",
                        color: "rgba(56, 72, 94, 0.5)"
                      }
                    }
                  }}
                  iconStyle="solid"
                />
              </div>
              {!hideButtons && (
                <React.Fragment>
                  <Button
                    type="button"
                    aria-label={intl.formatMessage({ id: "save_changes" })}
                    id="stripe_cardsection_submit"
                    className="primary"
                    onClick={this.handleSubmit}
                  >
                    {intl.formatMessage({ id: "save_changes" })}
                  </Button>
                  {number && (
                    <Button
                      type="button"
                      aria-label={intl.formatMessage({ id: "save_changes" })}
                      id="stripe_cardsection_cancel"
                      className="secondary"
                      onClick={onCancel}
                    >
                      {"cancel"}
                    </Button>
                  )}
                </React.Fragment>
              )}
            </NewCardWrapper>
          )}
        </form>
      </Layout>
    );
  }
}

SepaSection.propTypes = {
  name: PropTypes.string,
  number: PropTypes.string,
  edit: PropTypes.bool,
  hideButtons: PropTypes.bool,
  stripe: PropTypes.object,
  intl: PropTypes.object,
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func
};

export default injectStripe(injectIntl(SepaSection));
