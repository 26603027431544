import typeOf from "just-typeof";

export default function jsonToYaml(data) {
  let handlers,
    indentLevel = "";

  handlers = {
    undefined: function() {
      // objects will not have `undefined` converted to `null`
      // as this may have unintended consequences
      // For arrays, however, this behavior seems appropriate
      return "null";
    },
    null: function() {
      return "null";
    },
    number: function(x) {
      return x;
    },
    boolean: function(x) {
      return x ? "true" : "false";
    },
    string: function(x) {
      let output = "|";
      if (x.indexOf("\n") === -1) {
        return JSON.stringify(x);
      }
      let text = x.split(/\\n|\n/);
      indentLevel = indentLevel.replace(/$/, "  ");
      text.forEach(function(y) {
        output += "\n" + indentLevel + y;
      });
      indentLevel = indentLevel.replace(/ {2}/, "");

      return output;
    },
    array: function(x) {
      let output = "";

      if (0 === x.length) {
        output += "[]";
        return output;
      }

      indentLevel = indentLevel.replace(/$/, "  ");
      x.forEach(function(y) {
        let handler = handlers[typeOf(y)];

        if (!handler) {
          throw new Error("what the crap: " + typeOf(y));
        }

        output += "\n" + indentLevel + "- " + handler(y, true);
      });
      indentLevel = indentLevel.replace(/ {2}/, "");

      return output;
    },
    object: function(x, inArray, rootNode) {
      let output = "";

      if (0 === Object.keys(x).length) {
        output += "{}";
        return output;
      }

      if (!rootNode) {
        indentLevel = indentLevel.replace(/$/, "  ");
      }

      Object.keys(x).forEach(function(k, i) {
        let val = x[k],
          handler = handlers[typeOf(val)];

        if ("undefined" === typeof val) {
          return;
        }

        if (!handler) {
          throw new Error("Error jsonToYaml: " + typeOf(val));
        }

        if (!(inArray && i === 0)) {
          output += "\n" + indentLevel;
        }

        if (
          /\/|:|\{|\}|\|[|\|]|\|\,|\&|\*|\#|\?\||\-|\<|\>|\=|\!|\%|\@/g.test(k) // eslint-disable-line
        ) {
          k = `'${k}'`;
        }

        output += k + ": " + handler(val);
      });
      indentLevel = indentLevel.replace(/ {2}/, "");

      return output;
    },
    function: function() {
      return "[object Function]";
    }
  };

  return (handlers[typeOf(data)](data, true, true) + "\n").trim();
}
