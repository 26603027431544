import React from "react";
import { Route, useRouteMatch, Switch } from "react-router-dom";

import Page from "./Page";
import ConfigurationPage from "./configuration/Page";
import OverviewPage from "./overview/Page";

const Routes = () => {
  const match = useRouteMatch();

  return (
    <Page>
      <Switch>
        <Route exact path={`${match.path}/configuration`}>
          <ConfigurationPage />
        </Route>
        <Route path={match.path}>
          <OverviewPage />
        </Route>
      </Switch>
    </Page>
  );
};

export default Routes;
