import styled from "styled-components";

export const BaseLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

export const Layout = styled(BaseLayout)`
  margin-top: 24px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 64px;
  margin-top: 16px;
`;
