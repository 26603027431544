import React, { useMemo } from "react";

import { Redirect } from "react-router";

const GoBack = () => {
  const path = useMemo(() => {
    const pathname = location.pathname;
    return pathname.split("/").slice(1, -1);
  }, []);

  return <Redirect to={path} />;
};

export default GoBack;
