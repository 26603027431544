import React from "react";
import PropTypes from "prop-types";

import useDecodedParams from "Hooks/useDecodedParams";
import EnvironmentError from "./common/containers/EnvironmentError";
import withProjectRedirect from "../../../common/hocs/withProjectRedirect";

const Environment = ({ children, environmentId }) => {
  const { projectId, organizationId } = useDecodedParams();

  return (
    <EnvironmentError
      environmentId={environmentId}
      projectId={projectId}
      organizationId={organizationId}
    >
      {children}
    </EnvironmentError>
  );
};

Environment.propTypes = {
  children: PropTypes.node.isRequired,
  environmentId: PropTypes.string
};

export default withProjectRedirect(Environment);
