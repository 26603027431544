import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";

const EmptyCard = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.sectionBg};
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 13px;
  line-height: 18px;
  border-radius: 2px;
  ${semiBoldAlias};
`;

export default EmptyCard;
