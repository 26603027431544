import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

const Routes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <h1>New Settings</h1>
      </Route>
    </Switch>
  );
};

export default Routes;
