import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import useURLSearchState, { transforms } from "Hooks/useURLSearchState";

import Timeframe from "../../components/TimeframeSelector";
import HostSelector from "../../components/HostSelector";
import LastUpdated from "../../components/LastUpdated";

import useMultiChart from "../../hooks/useMultiChart";
import useRange from "../../hooks/useRange";

import InlineBanner from "Components/InlineBanner";

import Cpu from "../../containers/Cpu";
import Memory from "../../containers/Memory";
import Disk from "../../containers/Disk";
import * as V from "../../views/views.styles";
import * as S from "../../views/Dedicated/Dedicated.styles";

import { timeframes } from "../../settings";

const fields = {
  "cpu.average-value":
    "AVG(SUM((`cpu.user` + `cpu.kernel`) / `interval`, 'hostname'))",
  "cpu.average-percentage":
    "AVG(100 * SUM((`cpu.user` + `cpu.kernel`) / (`interval` * `cpu.cores`), 'hostname'))",
  "cpu.value": "SUM((`cpu.user` + `cpu.kernel`) / `interval`, 'hostname')",
  "cpu.percentage":
    "SUM(100 * (`cpu.user` + `cpu.kernel`) / ( `interval` * `cpu.cores`), 'hostname')",
  "cpu.max": "MAX(`cpu.cores`)",
  "memory.average-value":
    'AVG(SUM(`memory.apps` + `memory.kernel` + `memory.buffers`, "hostname"))',
  "memory.average-percentage":
    'AVG(SUM(100 * (`memory.apps` + `memory.kernel` + `memory.buffers`) / `memory.limit`, "hostname"))',
  "memory.value":
    'SUM(`memory.apps` + `memory.kernel` + `memory.buffers`, "hostname")',
  "memory.percentage":
    "SUM(100 * (`memory.apps` + `memory.kernel` + `memory.buffers`) / `memory.limit`, 'hostname')",
  "memory.max": "MAX(`memory.limit`)",
  "disk.average-value": "AVG(`disk.space.used`, 'mountpoint')",
  "disk.average-percentage":
    "AVG((`disk.space.used`/`disk.space.limit`)*100, 'mountpoint')",
  "disk.value": "`disk.space.used`",
  "disk.percentage": "(`disk.space.used`/`disk.space.limit`)*100",
  "disk.max": "AVG(`disk.space.limit`, 'mountpoint')"
};

const Dedicated = ({ link, dimensions }) => {
  const hostList = useMemo(
    () => [
      "average",
      ...new Set(dimensions?.map(dimension => dimension?.hostname) || [])
    ],
    [dimensions]
  );

  const [hosts, setHosts] = useURLSearchState(
    hostList,
    "hosts",
    transforms.array
  );

  const selectedHosts = useMemo(
    () => {
      return hosts.map(host =>
        hostList.find(fullHost => fullHost.startsWith(host))
      );
    },
    [hosts]
  );

  const mountpoints = [
    ...new Set(dimensions?.map(e => e?.mountpoint) || [])
  ].filter(mountpoint => mountpoint);

  const [selectedTimeframe, setSelectedTimeframe] = useRange();

  const range = useMemo(
    () => {
      if (selectedTimeframe.range) {
        return selectedTimeframe.range;
      }

      return { from: selectedTimeframe.from, to: selectedTimeframe.to };
    },
    [JSON.stringify(selectedTimeframe)]
  );

  const [data, isLoading, error] = useMultiChart(
    fields,
    link.collection,
    link.href,
    selectedTimeframe.interval,
    range
  );

  const onBrush = useCallback(
    (from, to) => {
      setSelectedTimeframe({ from, to });
    },
    [setSelectedTimeframe]
  );

  const onTimeframeChange = useCallback(
    timeframe => setSelectedTimeframe(timeframe.label),
    [setSelectedTimeframe]
  );

  if (error) {
    return (
      <InlineBanner padding="16px" level="error">
        {error.message}
      </InlineBanner>
    );
  }

  const lastDataPoint = data?.cpu?.last.timestamp;
  const firstDataPoint = data?.cpu?.data[0] && data.cpu.data[0].timestamp;
  return (
    <>
      <V.Header>
        <V.Heading>
          <FormattedMessage id="metrics.dedicated.heading" />
        </V.Heading>
        <V.TimeframeColumn>
          {selectedTimeframe && (
            <LastUpdated
              interval={selectedTimeframe.interval}
              lastDataPoint={lastDataPoint}
              isCustomTimeframe={!selectedTimeframe.id}
            />
          )}
          <V.Row style={{ marginLeft: "auto" }}>
            <HostSelector
              hosts={hostList}
              selected={hosts}
              onChange={setHosts}
            />
            <Timeframe
              selectedTimeframe={selectedTimeframe}
              timeframes={timeframes}
              onChange={onTimeframeChange}
              to={lastDataPoint}
              from={firstDataPoint}
            />
          </V.Row>
        </V.TimeframeColumn>
      </V.Header>
      <S.Layout>
        <Cpu
          hosts={selectedHosts}
          data={data.cpu}
          isLoading={isLoading}
          timeframe={selectedTimeframe}
          onBrush={onBrush}
        />
        <Memory
          hosts={selectedHosts}
          data={data.memory}
          isLoading={isLoading}
          timeframe={selectedTimeframe}
          onBrush={onBrush}
        />
      </S.Layout>
      <S.DisksLayout>
        {mountpoints.map(
          mountpoint =>
            data.disks &&
            data.disks[mountpoint] && (
              <Disk
                diskPerRow={mountpoints.length > 2 ? 3 : 2}
                key={mountpoint}
                hosts={selectedHosts}
                mountpoint={mountpoint}
                isLoading={isLoading}
                data={data.disks[mountpoint]}
                timeframe={selectedTimeframe}
                onBrush={onBrush}
              />
            )
        )}
      </S.DisksLayout>
    </>
  );
};

Dedicated.propTypes = {
  link: PropTypes.shape({
    collection: PropTypes.string,
    href: PropTypes.string
  }),
  dimensions: PropTypes.array
};

export default Dedicated;
