import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const FeatureList = styled.ul`
  padding: 0;
  margin: 0 auto 30px;
  list-style: none;
  width: 400px;
  max-width: 100%;
`;

export const FeatureItem = styled.li`
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  min-height: 22px;
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    margin-right: 10px;
  }
  span {
    display: inline-block;
    line-height: 16px;
    &:first-letter {
      text-transform: capitalize;
    }
  }
`;
