import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  margin-top: 8px;

  > p {
    font-size: 14px;
    line-height: 24px;
    color: ${props =>
      props.theme.name === "dark" ? props.theme.text : props.theme.sideNavText};
  }
`;
