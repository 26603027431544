import styled from "styled-components";

import Priority from "../../../../common/components/Priority";
import { semiBoldAlias } from "Libs/theme";

export const Layout = styled(Priority)`
  ${semiBoldAlias};
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.label};
`;
