import getAccessToken from "./getAccessToken";

import streamToArray from "../utils/streamToArray";
import parseDimensions from "../utils/parseDimensions";

/**
 * range time fromat 2021-01-15T11:08:41+01:00
 *
 * DimensionsQuery {
 *   stream: {
 *     collection: string,
 *     stream: string
 *   },
 *   range: {
 *     to: string,
 *     from: string
 *   }
 * }
 *
 * Dimension {
 *  [key string]: string
 * }
 *
 * @async
 * @param {string} chorusURL
 * @param {DimensionsQuery} query
 *
 * @returns {Promise<Array<Dimension>>} list of dimensions available
 */
const getDimensions = async (chorusURL, query) => {
  const endpoint = `${chorusURL}/v1/metrics/dimensions`;
  const accessToken = await getAccessToken();

  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.access_token}`
    },
    body: JSON.stringify(query)
  });

  return streamToArray(response.body, ({ dimension }) =>
    parseDimensions(dimension)
  ).then(dimensions => dimensions.filter(dimension => !!dimension));
};

export default getDimensions;
