import moment from "moment";

const TIME_FORMAT = "HH:mm";
const DATE_FORMAT = "MMM D, YYYY";

export const getDateFormat = (from, to) => {
  const momentFrom = moment(from);
  const momentTo = moment(to);

  const fromTime = momentFrom.format(TIME_FORMAT);
  const toTime = momentTo.format(TIME_FORMAT);
  const fromDate = momentFrom.format(DATE_FORMAT);
  const toDate = momentTo.format(DATE_FORMAT);

  return `${fromTime} - ${toTime}, ${fromDate} - ${toDate}`;
};

export const getNowFormat = () => moment().format(DATE_FORMAT);
