import styled from "styled-components";

import { semiBoldAlias, makePalette } from "Libs/theme";

const theme = makePalette(palette => ({
  tooltip_color: {
    default: palette.ebonyLight,
    dark: palette.snow
  },
  tooltip_background: {
    default: palette.snow,
    dark: palette.ebony
  }
}));

export const Text = styled.div.attrs(({ x, y }) => ({
  style: {
    transform: `translate(${x}px, ${y}px)`
  }
}))`
  position: absolute;
  transform-origin: 0 0;
  z-index: 9999;
  color: ${theme.tooltip_color};
  background-color: ${theme.tooltip_background};
  font-size: 10px;
  ${semiBoldAlias()};
  user-select: none;
  line-height: 1;
  pointer-events: none;
  opacity: ${props => (props.isVisible ? "1" : "0")};
`;
