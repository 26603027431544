import { fromJS, Map } from "immutable";

import { entities } from "Libs/platform";
import logger from "Libs/logger";
import { normalize } from "Libs/utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getApiTokens = createAsyncThunk(
  "apiToken/list",
  async ({ userId }) => {
    const tokens = await entities.ApiToken.query({
      userId
    });

    return normalize(tokens);
  }
);

export const addApiToken = createAsyncThunk(
  "apiToken/add",
  async ({ name, userId }) => {
    const newApiToken = new entities.ApiToken({ name }, { userId });
    const responseFromSavingToken = await newApiToken.save().catch(err => {
      const message = JSON.parse(err).message;
      logger(
        {
          message
        },
        {
          action: "addApiToken",
          meta: {
            userId
          }
        }
      );
      throw new Error(message);
    });

    const finalApiToken = new entities.ApiToken(responseFromSavingToken.data, {
      userId
    });
    return finalApiToken;
  }
);

export const deleteApiToken = createAsyncThunk(
  "apiToken/delete",
  async ({ tokenId, username, userId }, { getState }) => {
    const tokenToDelete = getState().apiToken.getIn([
      "data",
      username,
      tokenId
    ]);

    await tokenToDelete.delete({ userId }).catch(err => {
      const errMessage = JSON.parse(err);
      logger("deleteApiToken", { errMessage, userId });
      throw new Error(errMessage.error);
    });

    return tokenToDelete;
  }
);

const setError = (state, action) =>
  state
    .set("currentlySavingToken", false)
    .set("errors", action.error.message)
    .set("loading", false);

const apiToken = createSlice({
  name: "apiToken",
  initialState: Map(),
  reducers: {
    expandNewApiTokenForm(state) {
      return state.set("canAddNew", true);
    },
    cancelAddNew(state) {
      return state.set("canAddNew", false).set("errors", null);
    },
    closeTokenBanner(state) {
      return state.delete("newApiToken");
    }
  },
  extraReducers: {
    [getApiTokens.pending]: state => state.set("loading", true),
    [getApiTokens.fulfilled]: (state, action) =>
      state
        .setIn(["data", action.meta.arg.username], fromJS(action.payload))
        .set("loading", false),

    [getApiTokens.rejected]: (state, action) =>
      state.set("errors", action.payload).set("loading", false),
    [addApiToken.pending]: state =>
      state.set("currentlySavingToken", true).remove("errors"),
    [addApiToken.rejected]: (state, action) => setError(state, action),
    [addApiToken.fulfilled]: (state, action) =>
      state
        .setIn(
          ["data", action.meta.arg.username, action.payload.id],
          fromJS(action.payload)
        )
        .set("newApiToken", fromJS(action.payload))
        .set("currentlySavingToken", false)
        .set("canAddNew", false),
    [deleteApiToken.rejected]: (state, action) => setError(state, action),
    [deleteApiToken.pending]: state => state.set("loading", true),
    [deleteApiToken.fulfilled]: (state, action) =>
      state
        .deleteIn(["data", action.meta.arg.username, action.payload.id], Map())
        .set("loading", false),
    [deleteApiToken.rejected]: (state, action) => setError(state, action)
  }
});

export const {
  expandNewApiTokenForm,
  cancelAddNew,
  closeTokenBanner
} = apiToken.actions;

export default apiToken.reducer;
