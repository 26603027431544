import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";

import * as S from "./Card.styles";

const Card = ({ title, description, onClick, active, image }) => {
  const theme = useTheme();

  return (
    <S.Wrapper
      onClick={onClick}
      onKeyUp={e => {
        if ([13, 27, 38, 40].includes(e.keyCode)) {
          onClick();
        }
      }}
      active={active}
      className={active ? "active" : ""}
    >
      {theme?.name === "contrast" && <span className="active" />}
      <S.ImgWrapper className="image-wrapper">{image}</S.ImgWrapper>
      <S.Title className="title">{title}</S.Title>
      <S.Description>{description}</S.Description>
    </S.Wrapper>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  image: PropTypes.node
};

export default Card;
