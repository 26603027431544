import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import ToggleSwitch from "Components/ToggleSwitch";

const EmailSetting = ({ environment, enable_smtp, onEmailSendingChange }) => {
  const intl = useIntl();

  return (
    <ToggleSwitch
      id="environment-is-sending-email-switch"
      title={intl.formatMessage({ id: "is_sending_email" })}
      description={intl.formatMessage({
        id: "is_sending_email.description"
      })}
      onClick={onEmailSendingChange}
      value={enable_smtp}
      enabled={environment.hasPermission && environment.hasPermission("#edit")}
    />
  );
};

EmailSetting.propTypes = {
  environment: PropTypes.object,
  enable_smtp: PropTypes.bool,
  onEmailSendingChange: PropTypes.func
};

export default EmailSetting;
