import React from "react";
import PropTypes from "prop-types";

import { usePalette } from "Libs/theme";
import { colorDefinitions } from "./Chart.styles";

const LineGradients = ({ kind, threshold75, threshold87, ...props }) => {
  const theme = usePalette(colorDefinitions);

  const baseColor =
    kind === "average"
      ? theme.average_gradient__stop_1
      : theme.host_gradient__stop_1;

  return (
    <linearGradient gradientUnits="userSpaceOnUse" x1="0" x2="0" {...props}>
      <stop offset="0%" stopColor={baseColor} />
      <stop offset={`${threshold75}%`} stopColor={baseColor} />
      <stop offset={`${threshold75}%`} stopColor={theme.threshold__warning} />
      <stop offset={`${threshold87}%`} stopColor={theme.threshold__warning} />
      <stop offset={`${threshold87}%`} stopColor={theme.threshold__max} />
    </linearGradient>
  );
};

LineGradients.propTypes = {
  kind: PropTypes.string.isRequired,
  threshold87: PropTypes.number.isRequired,
  threshold75: PropTypes.number.isRequired
};
export default React.memo(LineGradients);
