import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { capitalize } from "Libs/utils";
import { ENVIRONMENT_TYPES } from "Constants/constants";
import Dropdown from "Components/Dropdown";
import Error from "Components/Error";
import InputField from "Components/fields/InputField";

import * as S from "../styles";

const BasicSetting = ({
  environment,
  environmentParent,
  environmentTitle,
  environmentType,
  errors,
  onEnvironmentParentChange,
  onEnvironmentTitleChange,
  onEnvironmentTypeChange,
  parentEnvironments
}) => {
  const intl = useIntl();
  return (
    <>
      <p className="description">
        {intl.formatMessage({ id: "configure_environment" })}
      </p>
      {errors.get("type") && <Error>{errors.get("type")}</Error>}
      <div className="inline-fields">
        <div className="field field-environment-name">
          <InputField
            id="environment-name-input"
            label={intl.formatMessage({
              id: "environment_name"
            })}
            value={environmentTitle}
            onChange={onEnvironmentTitleChange}
            enabled={
              environment.hasPermission && environment.hasPermission("#edit")
            }
          />
        </div>
        <div className="field field-environment-parent">
          <S.DropdownWrapper>
            <Dropdown
              id="environment-parent-options"
              className="environment-parent"
              label={intl.formatMessage({
                id: "parent_environment"
              })}
              options={parentEnvironments}
              onChange={onEnvironmentParentChange}
              defaultValue={{
                value: environmentParent,
                label: environmentParent
              }}
              searchable={false}
              clearable={false}
              disabled={
                !environment.hasPermission ||
                !environment.hasPermission("#edit")
              }
              aria-disabled={
                !environment.hasPermission ||
                !environment.hasPermission("#edit")
              }
            />
          </S.DropdownWrapper>
        </div>
        {environmentType && (
          <div className="field field-environment-type">
            <S.DropdownWrapper>
              <Dropdown
                id="environment-type-options"
                className="environment-type"
                label={intl.formatMessage({
                  id: "environment_type"
                })}
                options={ENVIRONMENT_TYPES.map(et => ({
                  value: et,
                  label: capitalize(et)
                }))}
                onChange={onEnvironmentTypeChange}
                defaultValue={{
                  value: environment.type,
                  label: capitalize(environment.type)
                }}
                searchable={false}
                clearable={false}
                disabled={
                  !environment.hasPermission ||
                  !environment.hasPermission("#edit")
                }
                aria-disabled={
                  !environment.hasPermission ||
                  !environment.hasPermission("#edit")
                }
              />
            </S.DropdownWrapper>
          </div>
        )}
      </div>
    </>
  );
};

BasicSetting.propTypes = {
  environment: PropTypes.object,
  environmentParent: PropTypes.string,
  environmentTitle: PropTypes.string,
  environmentType: PropTypes.string,
  errors: PropTypes.object,
  intl: PropTypes.object,
  onEnvironmentParentChange: PropTypes.func,
  onEnvironmentTitleChange: PropTypes.func,
  onEnvironmentTypeChange: PropTypes.func,
  parentEnvironments: PropTypes.array
};

export default BasicSetting;
