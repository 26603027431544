import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";

import ModalWrapper from "Components/Modal";
import ContentPaneLayout from "Components/ContentPaneLayout";

import * as S from "./InvitationWaiting.styles";

const MAX_TRY_NUMBER = 5;

const InvitationWaiting = () => {
  const [count, setCount] = useState(0);
  const [error, setError] = useState(false);
  const { id } = useDecodedParams();

  const intl = useIntl();
  const { push } = useHistory();

  useEffect(
    () => {
      let isCanceled = false;
      const timeout = setTimeout(async () => {
        const platformLib = await import("Libs/platform");
        const client = platformLib.default;

        try {
          const organization = await client.getOrganization(id);
          push(`/${organization.name}`);
        } catch (err) {
          if (isCanceled) return;

          if (count < MAX_TRY_NUMBER) {
            setCount(count + 1);
          } else {
            setError(true);
          }
        }
      }, count && 5000);

      return () => {
        isCanceled = true;
        clearTimeout(timeout);
      };
    },
    [count]
  );

  const onClose = () => push("/");

  return (
    <ModalWrapper
      id="organization-wait-invitation-modal"
      shouldCloseOnOverlayClick={true}
      isOpen={true}
      title={[intl.formatMessage({ id: "invitation.pending" })]}
      onRequestClose={onClose}
      closeModal={onClose}
      modalClass="modal-medium modal-invitation"
    >
      <ContentPaneLayout className="modal-body">
        <S.Message>
          {intl.formatMessage({
            id: `invitation.pending${error ? "Fail" : "Loading"}`
          })}
        </S.Message>
      </ContentPaneLayout>
    </ModalWrapper>
  );
};

export default InvitationWaiting;
