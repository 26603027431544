import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useParams, useLocation } from "react-router-dom";

import ProjectError from "../containers/ProjectError";

import ContentLayout from "Components/ContentLayout";
import SideNavBar from "Components/SideNavBar";

import withProjectRedirect from "../../../common/hocs/withProjectRedirect";

const ProjectSettingsPage = styled.div`
  position: relative;
  .settings-content {
    width: 100%;
    box-sizing: border-box;
  }
  @media (min-width: 600px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .settings-content {
      width: calc(100% - 200px);
      padding-left: 32px;
      padding-top: 12px;
    }
  }
  @media (min-width: 1280px) {
    .settings-content {
      width: calc(100% - 274px);
    }
  }
`;

const ProjectSettingsLayout = styled.div`
  background-color: transparent;
  a.header-arrow-list {
    display: flex;
    width: 100%;
    color: #000;
  }
`;

const getProjectSettingTabs = (organizationId, projectId) => [
  {
    name: "basics",
    route: `/${organizationId}/${projectId}/-/settings`,
    permission: "#edit"
  },
  {
    name: "access",
    route: `/${organizationId}/${projectId}/-/settings/access`,
    permission: "#manage-access"
  },
  {
    name: "domains",
    route: `/${organizationId}/${projectId}/-/settings/domains`,
    permission: "#edit"
  },
  {
    name: "certificates",
    route: `/${organizationId}/${projectId}/-/settings/certificates`,
    permission: "#edit"
  },
  {
    name: "deploy_key",
    route: `/${organizationId}/${projectId}/-/settings/deploy_key`
  },
  {
    name: "variables",
    route: `/${organizationId}/${projectId}/-/settings/variables`,
    permission: "#edit"
  }
];

const ProjectSettings = ({ children }) => {
  const { pathname } = useLocation();
  const { organizationId, projectId } = useParams();

  return (
    <ProjectError projectId={projectId} organizationId={organizationId}>
      <ContentLayout className="settings-wrapper">
        <ProjectSettingsPage>
          <SideNavBar
            currentPathName={pathname}
            tabs={getProjectSettingTabs(organizationId, projectId)}
          />
          <ProjectSettingsLayout className="settings-content">
            {children}
          </ProjectSettingsLayout>
        </ProjectSettingsPage>
      </ContentLayout>
    </ProjectError>
  );
};

ProjectSettings.propTypes = {
  children: PropTypes.node
};

export default withProjectRedirect(ProjectSettings);
