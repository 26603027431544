import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

export default styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: ${props => themeHelpers(props.theme, "sectionColor")};
  color: ${props => props.theme.text};
  padding: 32px;
  margin-top: 8px;
`;
