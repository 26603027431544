import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import List from "./containers/List";

import Layout from "../../components/Layout";

import withReducers from "Hocs/withReducers";

const OrganizationBilling = () => {
  const { username } = useParams();

  return (
    <Layout>
      <ContentLayout id="settings-billing" className="settings-content">
        <Heading2>
          <FormattedMessage id="plans" />
        </Heading2>
        <List username={username} />
      </ContentLayout>
    </Layout>
  );
};

export default withReducers({
  project: () => import("Reducers/project"),
  subscription: () => import("Reducers/subscription")
})(OrganizationBilling);
