import React from "react";
import momentjs from "moment";
import PropTypes from "prop-types";

import useMediaQuery from "Hooks/useMediaQuery";
import { formatAsCurrency } from "Libs/utils";
import {
  ContainerSS,
  OrderStatusSS,
  OrderIdSS,
  OrderPeriodSS,
  OrderTotalSS,
  OrderTotalLabelSS
} from "./OrderItem.styles";

const formatDate = date => momentjs(date).format("D MMM, YYYY");
const month = date => momentjs(date).format("MMM");
const longPeriod = (start, end) => formatDate(start) + " - " + formatDate(end);

function orderTotal(components, currency) {
  const total = Object.keys(components).reduce((total, component) => {
    return total + components[component].amount;
  }, 0);

  return formatAsCurrency(total, currency);
}

const OrderItem = ({ order, organizationId }) => {
  const date = useMediaQuery("(min-width: 32rem)")
    ? longPeriod(order.billing_period_start, order.billing_period_end)
    : month(order.billing_period_start);

  return (
    <ContainerSS>
      <OrderIdSS
        to={{
          pathname: `/${organizationId}/-/billing/history/${order.id}`,
          state: { from: "invoices" }
        }}
      >
        {order.id}
      </OrderIdSS>

      <OrderPeriodSS>{date}</OrderPeriodSS>

      <OrderStatusSS>
        {`${order.status === "recurring_open" ? " Upcoming" : " Completed"}`}
      </OrderStatusSS>

      <OrderTotalSS>
        <OrderTotalLabelSS>Total</OrderTotalLabelSS>
        {orderTotal(order.components, order.components.base_price.currency)}
      </OrderTotalSS>
    </ContainerSS>
  );
};

OrderItem.propTypes = {
  order: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired
};

export default OrderItem;
