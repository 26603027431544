import styled from "styled-components";

import Header from "../../../../common/components/Header";

export const TableHeading = styled(Header)`
  margin-bottom: 0;
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 20px;
    flex-grow: 1;
  }
`;

export const TableFilters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const TableLayout = styled.div`
  overflow-x: auto;
  position: relative;
  padding-bottom: 16px;

  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 0px;
    left: 0px;
    height: 1px;
    box-shadow: inset 0px -1px 0px ${props => props.theme.environmentTreeLine};
    z-index: 1;
  }

  @media (min-width: 768px) {
    &:after,
    &:before {
      right: -32px;
      left: -32px;
    }
  }

  &:after {
    top: 27px;
  }

  &:before {
    bottom: 16px;
  }

  @media screen and (min-width: 768px) {
    overflow-x: visible;
  }
`;

export const Table = styled.table`
  width: 100%;

  &,
  tr,
  tbody,
  thead {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    background-image: none;
  }
`;

export const TableHead = styled.thead`
  position: relative;
`;
