import { Map, List } from "immutable";

import client from "Libs/platform";

const initialState = Map({
  isLoading: false,
  isLoadingAttachments: false
});

// Actions
const LOAD_START = "tickets/view/load_start";
const LOAD_SUCCESS = "tickets/view/load_sucesss";
const LOAD_FAILURE = "tickets/view/load_failure";

const LOAD_ALL_ATTACHMENTS_START = "tickets/view/load_all_attachments_start";
const LOAD_ALL_ATTACHMENTS_SUCCESS =
  "tickets/view/load_all_attachments_success";
const LOAD_ALL_ATTACHMENTS_FAILURE =
  "tickets/view/load_all_attachments_failure";

const UPDATE_STATUS_START = "tickets/view/update_status_start";
const UPDATE_STATUS_SUCCESS = "tickets/view/update_status_success";
const UPDATE_STATUS_FAILURE = "tickets/view/update_status_failure";

// Action creators
export const updateStatusStart = () => ({
  type: UPDATE_STATUS_START
});
export const updateStatusFailure = () => ({
  type: UPDATE_STATUS_FAILURE
});
export const updateStatusSuccess = ticket => ({
  type: UPDATE_STATUS_SUCCESS,
  payload: ticket
});

export const loadAllAttachmentsStart = () => ({
  type: LOAD_ALL_ATTACHMENTS_START
});
export const loadAllAttachmentsFailure = message => ({
  type: LOAD_ALL_ATTACHMENTS_FAILURE,
  payload: message
});
export const loadAllAttachmentsSuccess = attachments => ({
  type: LOAD_ALL_ATTACHMENTS_SUCCESS,
  payload: attachments
});

export const loadStart = () => ({ type: LOAD_START });
export const loadSuccess = ticket => ({
  type: LOAD_SUCCESS,
  payload: ticket
});
export const loadFailure = message => ({
  type: LOAD_FAILURE,
  payload: message
});

// Thunk
export const load = ticketId => async dispatch => {
  dispatch(loadStart());
  try {
    const [response, attachments] = await Promise.all([
      client.getTickets({
        filter: { ticket_id: ticketId }
      }),
      client.getTicketAttachments(ticketId)
    ]);

    if (response.tickets.length) {
      dispatch(loadSuccess({ ...response.tickets[0], attachments }));
    } else {
      dispatch(loadFailure({ message: "404" }));
    }
  } catch (error) {
    dispatch(loadFailure({ message: error.message || error.title }));
  }
};

export const loadAllAttachments = ticketId => async dispatch => {
  dispatch(loadAllAttachmentsStart());
  try {
    const response = await client.getAllTicketAttachments(ticketId);
    dispatch(loadAllAttachmentsSuccess(response));
  } catch (error) {
    dispatch(
      loadAllAttachmentsFailure({ message: error.message || error.title })
    );
  }
};

export const updateTicketStatus = (ticketId, status) => async dispatch => {
  dispatch(updateStatusStart());
  try {
    const response = await client.updateTicketStatus(ticketId, status);
    dispatch(updateStatusSuccess(response));
  } catch (error) {
    dispatch(updateStatusFailure({ message: error.message || error.title }));
  }
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_START:
      return state
        .set("isLoading", true)
        .delete("error")
        .delete("content");
    case LOAD_FAILURE:
      return state.set("isLoading", false).set("error", action.payload);
    case UPDATE_STATUS_SUCCESS:
    case LOAD_SUCCESS:
      return state.set("content", action.payload).set("isLoading", false);
    case LOAD_ALL_ATTACHMENTS_START:
      return state.delete("attachments").set("isLoadingAttachments", true);
    case LOAD_ALL_ATTACHMENTS_SUCCESS:
      return state
        .set("isLoadingAttachments", false)
        .set("attachments", List(action.payload));
    case LOAD_ALL_ATTACHMENTS_FAILURE:
      return state
        .set("isLoadingAttachments", false)
        .set("attachmentsError", action.payload);
    default:
      return state;
  }
};

export default reducer;
