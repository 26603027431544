import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
import withReducers from "Hocs/withReducers";
import withProjectRedirect from "../../../../common/hocs/withProjectRedirect";

import { loadDeployment as loadDeploymentAction } from "Reducers/environment/deployment";
import { loadProjectWizard } from "Reducers/project/wizard";

import ActivitySection from "../../../../common/containers/ActivitySection";
import ContentLayout from "Components/ContentLayout";
import ContentWizard from "../../containers/ContentWizard";
import EnvironmentOverview from "../overview/Page";
import Heading2 from "Components/styleguide/Heading2";
import InfoDropButtons from "../../../../common/containers/InfoDropButtons";
import OverviewSkeleton from "../overview/containers/Overview/OverviewSkeleton";
import { ServicesDisplay } from "../../../../common/containers/ServicesDisplay";
import ServicesDisplaySkeleton from "../../../../common/components/ServicesDisplaySkeleton";

import * as S from "./Details.styles";

const Details = ({ children }) => {
  const { environmentId, organizationId, projectId } = useDecodedParams();
  const router = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [areServicesReady, setAreServicesReady] = useState(false);

  const user = useSelector(state => state.app?.get("me").toJS());
  const envIsLoaded = useSelector(
    state => !state.environment.get("loading", true)
  );
  const environment = useSelector(state =>
    state.environment?.getIn(
      ["data", organizationId, projectId, environmentId],
      {}
    )
  );
  const project = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId])
  );

  const loadDeployment = () =>
    dispatch(loadDeploymentAction(organizationId, projectId, environmentId));

  useEffect(
    () => {
      if (environmentId) {
        loadDeployment();
      }
    },
    [environmentId]
  );

  useEffect(
    () => {
      if (!project?.id || !environment?.id) return;
      dispatch(
        loadProjectWizard({
          user,
          organizationId,
          projectId,
          hasCode:
            typeof environment?.hasPermission === "function" &&
            environment.hasPermission("#initialize")
        })
      );
    },
    [project, environment]
  );

  if (!environment) return null;

  // Redirect to settings page if env doesn't exist and name matches
  // settings pages [settings, integrations. plan, wait]
  if (envIsLoaded && Object.entries(environment).length === 0) {
    if (["settings", "integrations", "plan", "wait"].includes(environmentId)) {
      const url = `/${organizationId}/${projectId}/-/${environmentId}`;
      return <Redirect to={url} />;
    }
  }

  return (
    <ContentLayout>
      {project && (
        <InfoDropButtons
          className="buttons"
          router={router}
          project={project}
        />
      )}

      <S.ColumnWrapper>
        <aside className="col-4">
          <S.ListGroup
            className="environment-info"
            aria-labelledby="environment-heading"
          >
            {!envIsLoaded ? (
              <OverviewSkeleton />
            ) : (
              <S.ListGroup labelledby="environment-info-heading">
                <S.OverviewBlock>
                  {children || <EnvironmentOverview />}
                </S.OverviewBlock>
              </S.ListGroup>
            )}
          </S.ListGroup>

          <S.ListGroup aria-labelledby="environment-services-heading">
            {!areServicesReady && <ServicesDisplaySkeleton />}
            <S.ServicesBlock>
              <Heading2 id="environment-services-heading">
                {intl.formatMessage({ id: "apps_services" })}
              </Heading2>
              <ServicesDisplay
                height="250px"
                minHeight="250px"
                treePositionY={10}
                maxHeight={220}
                hasInfoTooltip
                onLoadEnd={() => setAreServicesReady(true)}
              />
            </S.ServicesBlock>
          </S.ListGroup>
        </aside>

        <div>
          <ContentWizard project={project} user={user} />
          <ActivitySection context="environment" />
        </div>
      </S.ColumnWrapper>
    </ContentLayout>
  );
};

Details.propTypes = {
  children: PropTypes.node
};

export default withReducers({
  environment: () => import("Reducers/environment"),
  deployment: () => import("Reducers/environment/deployment"),
  service: () => import("Reducers/environment/service")
})(withProjectRedirect(Details));
