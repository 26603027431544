import styled from "styled-components";

export const Wrapper = styled.aside`
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 55px;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

export const Title = styled.h2`
  white-space: nowrap;
  color: ${props => props.sectionHeading};
  padding: 0;
  margin: 0;
  @media (min-width: 768px) {
    position: absolute;
  }
`;

export const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
`;
