import { fromJS, Map } from "immutable";

import { LOAD_DEPLOYMENT_SUCCESS } from "./deployment";

const addClass = (list = {}, newClass) => {
  return Object.keys(list).reduce((current, key) => {
    const icon = list[key].type && list[key].type.split(":")[0];
    current[key] = { name: key, icon, class: newClass, ...list[key] };
    return current;
  }, {});
};

export default function environmentDeploymentReducer(
  state = new Map(),
  action
) {
  switch (action.type) {
    case LOAD_DEPLOYMENT_SUCCESS:
      return state.setIn(
        [
          "data",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.meta.environmentDescriptionId,
          action.meta.deploymentId
        ],
        fromJS({
          routes: action.payload.routes,
          ...addClass(action.payload.services, "service"),
          ...addClass(action.payload.webapps, "app"),
          workers: addClass(Object.values(action.payload.workers), "worker")
        })
      );
    default:
      return state;
  }
}
