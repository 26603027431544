import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

import UnstyledButtonLink from "Components/ButtonLink";

export const ButtonLink = styled(UnstyledButtonLink)`
  white-space: nowrap;
`;

export const InfoLayout = styled.div`
  display: flex;
  align-items: center;

  span:first-of-type {
    width: 50%;
  }

  span:nth-of-type(2) {
    width: 20%;
  }
`;

export const Wrapper = styled.div`
  > p {
    margin-bottom: 30px;
  }
  .page-description {
    p {
      line-height: 25px;
      margin: 0 0 5px !important;
      .info-dialog {
        margin-bottom: 0;
      }
    }
    .copy-content-wrapper pre {
      background: ${props => props.theme.preBg};
    }
  }
`;

export const AlertWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: inherit;
  p {
    margin: 0;
    ${semiBoldAlias};
    font-size: 13px;
  }
`;
