import React from "react";

import useDecodedParams from "Hooks/useDecodedParams";
import EnvironmentGeneralSettings from "./containers/EnvironmentGeneralSettings";

const EnvironmentSettingsGeneral = () => {
  const params = useDecodedParams();

  return <EnvironmentGeneralSettings {...params} />;
};

export default EnvironmentSettingsGeneral;
