import { Map, Set, List } from "immutable";
import localForage from "localforage";

const initialState = new Map({
  announcements: new List()
});

const INIT = "app/announcements/setAlertState";
const DISPLAY_ANNOUNCEMENT = "app/announcements/display";
const HIDE_ANNOUNCEMENT = "app/announcements/hide";
const MARK_AS_READ = "app/announcements/read";
const FAILURE = "app/announcements/failure";

const FORAGE_KEY = "readAnnouncements";

const endpoint = "https://platform.sh/product/announcements/index.json";
const defaultError = "Unable to load announcements";

const transformArtworkURLs = response => {
  if (!response.items) {
    return null;
  }

  const urlRoot = response.production_url;

  return response.items.map(announcement => ({
    ...announcement,
    artwork: announcement.artwork && `${urlRoot}${announcement.artwork}`,
    tileTheme: announcement.artwork && announcement.tileTheme,
    steps: announcement.steps.map(step => ({
      ...step,
      artwork: step.artwork && `${urlRoot}${step.artwork}`,
      tileTheme: step.artwork && (step.tileTheme || announcement.tileTheme)
    }))
  }));
};

const hasURLFlag = () => window.location.search.includes("show-drafts=");

const filterDrafts = announcements => {
  const showDrafts = hasURLFlag();
  return announcements.filter(
    announcement => announcement.publish || showDrafts
  );
};

const fetchAnnouncements = () =>
  fetch(`${endpoint}?h=${window.location.hostname}`, {
    headers: {
      Accept: "application/json"
    }
  })
    .then(response => response.json())
    .then(transformArtworkURLs)
    .then(filterDrafts)
    .then(announcements => new List(announcements));

const getReadAnnouncements = async () =>
  (await localForage.getItem(FORAGE_KEY)) || [];

const flagReadAnnouncements = (announcements, read) =>
  announcements.map(announcement => ({
    ...announcement,
    read:
      announcement.read ||
      announcement.steps.length === 0 ||
      read.includes(announcement.id),
    active: false
  }));

const updateAnnouncement = (state, _id, props) =>
  state.updateIn(
    [
      "announcements",
      state.get("announcements").findIndex(({ id }) => id === _id)
    ],
    announcement => ({
      ...announcement,
      ...props
    })
  );

export const init = () => {
  return async dispatch => {
    try {
      const announcements = flagReadAnnouncements(
        ...(await Promise.all([fetchAnnouncements(), getReadAnnouncements()]))
      );

      dispatch({
        type: INIT,
        payload: new Map({
          announcements: announcements
        })
      });
    } catch (error) {
      dispatch({ type: FAILURE, payload: error.message });
    }
  };
};

export const markAsRead = id => async dispatch => {
  const items = new Set([...(await getReadAnnouncements()), id]);
  await localForage.setItem(FORAGE_KEY, items.toArray());
  dispatch({
    type: MARK_AS_READ,
    payload: id
  });
};

export const display = id => ({
  type: DISPLAY_ANNOUNCEMENT,
  payload: id
});

export const hide = id => ({
  type: HIDE_ANNOUNCEMENT,
  payload: id
});

export const failure = (message = defaultError) => ({
  type: FAILURE,
  payload: message
});

export default function announcementsReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case INIT:
      return action.payload;
    case DISPLAY_ANNOUNCEMENT:
      return updateAnnouncement(state, action.payload, { active: true });
    case HIDE_ANNOUNCEMENT:
      return updateAnnouncement(state, action.payload, { active: false });
    case MARK_AS_READ:
      return updateAnnouncement(state, action.payload, { read: true });
    case FAILURE:
      return state.set("error", action.payload || defaultError);
    default:
      return state;
  }
}
