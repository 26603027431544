import styled from "styled-components";

export const SmallNote = styled.small`
  font-weight: 600;
  margin-top: 24px;
  font-size: 13px;
`;

export const ModalChangeMethodWrapper = styled.div`
  p {
    line-height: 24px;
    margin-bottom: 24px;
    color: ${props => props.theme.buttonCancelText};
  }
  small {
    line-height: 18px;
    color: ${props => props.theme.buttonCancelText};
  }
  .modal-buttons {
    padding-top: 40px;
    button {
      margin-left: 8px;
      &:focus {
        margin: -2px -1px 0 7px;
        &:active {
          margin: 0 0 0 8px;
        }
      }
    }
  }
`;
