import styled from "styled-components";

import DefaultHostSelector from "../../components/HostSelector";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  > div {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

export const HostHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media (min-width: 768px) {
    width: 50%;

    :first-child {
      padding-right: 16px;
    }

    :last-child {
      padding-left: 16px;
    }
  }
`;

export const HostType = styled.span`
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.sectionHeading};
`;

export const HostCount = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.subtitleText};
`;

export const HostSelector = styled(DefaultHostSelector)`
  margin-right: 0;
`;
