import styled from "styled-components";

import { semiBoldAlias, makePalette } from "Libs/theme";

const theme = makePalette(palette => ({
  light_text: {
    default: palette.granite,
    dark: palette.periwinkleGrey,
    contrast: palette.ebonyLight
  },
  container_background: {
    default: palette.snow,
    dark: palette.ebony
  },
  description: {
    default: palette.granite,
    contrast: palette.slate
  }
}));

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DiskRow = styled(Row)`
  @media (min-width: 1240px) and (max-width: 1350px) {
    width: 100%;
  }
`;

export const ChartTitle = styled.h3`
  font-weight: bold;
  margin-top: 0;
  margin-right: 0;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.sectionHeading};
  margin-bottom: 0;
`;

export const InfoDialogWrapper = styled.div`
  margin-left: 8px;
`;

export const ChartHeading = styled.div`
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.sectionHeading};
  ${semiBoldAlias};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;

  @media (min-width: 1240px) and (max-width: 1350px) {
    flex-wrap: wrap;
  }
`;

export const Info = styled.div`
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  color: ${theme.light_text};
`;

export const DiskInfo = styled(Info)`
  @media (min-width: 1240px) and (max-width: 1350px) {
    text-align: right;
    width: 100%;
  }
`;

export const Description = styled.div`
  color: ${theme.description};
`;

export const Current = styled.span`
  color: ${({ theme }) => theme.sectionHeading};
`;

export const Percentage = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  text-align: right;
  color: ${({ theme }) => theme.sectionHeading};
  margin-left: 8px;
`;

export const DiskPercentage = styled(Percentage)`
  @media (min-width: 1240px) and (max-width: 1350px) {
    padding-top: 4px;
    margin-left: auto;
  }
`;

export const Container = styled.div`
  background-color: ${theme.container_background};
  border-radius: 2px;
  padding-top: 28px;
  padding-bottom: 16px;
  flex: 1;
  min-width: 0;

  margin-bottom: 32px;

  @media (min-width: 1240px) {
    &:not(:first-child) {
      margin-left: 16px;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export const DiskLayout = styled.div`
  padding-bottom: 32px;

  @media (min-width: 1240px) {
    max-width: calc(100% / ${({ diskPerRow = 3 }) => diskPerRow});
    min-width: calc(100% / ${({ diskPerRow = 3 }) => diskPerRow});
    padding-left: 16px;
    padding-right: 16px;

    &:nth-child(${({ diskPerRow = 3 }) => diskPerRow}n) {
      padding-right: 0;
    }

    &:first-child,
    &:nth-child(${({ diskPerRow = 3 }) => diskPerRow}n + 1) {
      padding-left: 0;
    }
  }
`;

export const Mountpoint = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  padding-top: 4px;
  color: ${theme.light_text};
  padding-left: 8px;
`;
