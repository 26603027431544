import styled from "styled-components";

export const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  span:first-of-type {
    width: 50%;
  }
  span:nth-of-type(2) {
    width: 20%;
  }
`;

export const FormWrapper = styled.div`
  padding: 24px 32px 32px;
  font-size: 14px;
  color: ${props => props.theme.sectionText};

  form {
    padding: 0;
  }
`;

export const BrowsersList = styled.div`
  padding-top: 32px;
  .row {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 26px;
    label {
      width: 118px;
    }
  }
`;
