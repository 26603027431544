import styled, { css } from "styled-components";
import { Field } from "../components/IntegrationForm/IntegrationForm.styles";

export const Header = styled.div`
  h2 {
    margin-top: 0;
  }
`;

export const Content = styled.div`
  padding: 32px;
  background: ${props => props.theme.orderBg};
  border-radius: 4px;
  ${props =>
    props.theme.name !== "dark" &&
    css`
      box-shadow: 0px 6px 24px rgba(152, 160, 171, 0.4);
    `};
  form {
    header {
      margin-bottom: 24px;
    }
  }
  ${Field}, input,
  textarea,
  .rcs-custom-scroll {
    width: 432px;
  }
`;
