import { Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const callStatusPage = path => {
  const status = new window.StatusPage.page({ page: process.env.STATUS_PAGE });
  return new Promise(resolve => {
    status.get(path, {
      success: data => resolve(data)
    });
  });
};

export const getSystemStatus = createAsyncThunk("system/status", async () => {
  if (!process.env.STATUS_PAGE || !window.StatusPage) return false;
  const result = await callStatusPage("summary");
  return result;
});

const setError = (state, action) =>
  state.set("errors", action.error.message).set("loading", false);

const systemStatus = createSlice({
  name: "systemStatus",
  initialState: Map({
    incidents: new Map(),
    maintenances: new Map(),
    status: null,
    loading: "idle"
  }),
  reducers: {},
  extraReducers: {
    // GET SUMMARY
    [getSystemStatus.pending]: state => state.set("loading", true),
    [getSystemStatus.fulfilled]: (state, { payload }) => {
      if (!payload.status) return state.set("loading", false);
      return state
        .set("status", { ...payload.status, ...payload.page })
        .set("incidents", payload.incidents)
        .set("maintenances", payload.scheduled_maintenances)
        .set("loading", false);
    },
    [getSystemStatus.rejected]: (state, action) => setError(state, action)
  }
});

export default systemStatus.reducer;
