import { createGlobalStyle } from "styled-components";
import { semiBoldAlias, themeHelpers } from "Libs/theme";
import { shade, saturate } from "polished";

export default createGlobalStyle`
  html, body, #app {

    margin: 0;
    font-family: 'Open Sans', Helvetica,Arial,sans-serif;
    font-size: 16px;
    background: ${props =>
      props.theme.name === "dark"
        ? saturate(0.07, shade(0.4, props.theme.dark))
        : props.theme.background};
    color: ${props => props.theme.text};
    height: 100%;
  }

  #app {
    font-family: 'Open Sans', Helvetica,Arial,sans-serif;
    min-width: 414px;
  }

  #main {
    box-sizing: border-box;
  }
  #user-widget {
    z-index: 9;
  }

  #home-navigation:first-letter {
    text-transform: uppercase;
  }

  h1, h5, h6 {
    font-weight: 400;
  }
  h2, h3 {
    font-weight: 700;
  }
  h4 {
    ${semiBoldAlias};
  }

  pre,
  code {
    font-family: "Overpass Mono", monospace;
    font-size: 12px;
  }
  code {
    color: ${props =>
      props.theme.name === "dark"
        ? props.theme.codeText
        : themeHelpers(props.theme, "primaryShade")};
    background: ${props =>
      props.theme.name === "dark"
        ? themeHelpers(props.theme, "darkTint")
        : themeHelpers(props.theme, "primaryTint")};
    line-height: 20px;
    height: 20px;
    padding: 0 8px;
    border-radius: 2px;
    display: inline-block;
    margin: 0 4px;
    height: 100%;
  }
  pre {
    background: ${props => props.theme.preBg};
    color: ${props => props.theme.preText};
    border-radius: 2px;
    code {
      background: ${props => props.theme.preBg};
      color: ${props => props.theme.preText};
    }
  }
  label {
    font-size: 13px;
    color: ${props => props.theme.label};
    ${semiBoldAlias};
  }

  a {
    font-weight: 400;
    font-size: 14px;
    color: ${props => props.theme.links};
    &.text-link {
      font-weight: 400;
      font-size: 14px;
      color: ${props => props.theme.links};
      margin: 0 -8px;
      padding: 0 8px;
      cursor: pointer;
      position: relative;
      &:hover {
        &:after {
          content: "";
          width: calc(100% - 16px);
          position: absolute;
          height: 1px;
          background: ${props => props.theme.links};
          bottom: 2px;
          left: 8px;
          display: inline-block;
        }
      }
      &:focus {
        outline: none;
        border: 1px solid ${props => props.theme.inputFocus};
        border-radius: 4px;
        box-shadow: 0 0 2px ${props => props.theme.inputFocus};
        text-decoration: none;
        margin: -1px -9px;
      }
      &:active {
        border: none;
        box-shadow: none;
        text-decoration: underline;
        margin: 0 -8px;
      }
    }
  }
  hr {
    background: ${props => props.theme.sectionBorder};
    box-shadow: none;
    height: 1px;
    border: none;
    margin: 16px 0;
    &.full-width {
      margin: 16px -32px;
    }
  }
  /* Icon Font */
  @font-face {
    font-family: 'Platform';
    src:url('./webfonts/platform.eot');
    src:url('./webfonts/platform.eot?#iefix') format('embedded-opentype');
  }
  @font-face {
    font-family: 'Platform';
    src: url('./webfonts/platform.woff') format('woff'),
    url('./webfonts/platform.ttf') format('truetype'),
    url('./webfonts/platform.svg#wf') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  .platform {
    font-family: 'Platform';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
  }

  /* Animations */

  @keyframes slideOutEnvironementDrawerLeft {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes slideInEnvironementDrawerLeft {
    from {
      transform: translate3d(-100%, 0, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  .slideOutLeftAnimation {
    animation-name: slideOutEnvironementDrawerLeft;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .slideInLeftAnimation {
    animation-name: slideInEnvironementDrawerLeft;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  @keyframes slideOutEnvironementDrawerRight {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(100%, 0, 0);
    }
  }

  @keyframes slideInEnvironementDrawerRight {
    from {
      transform: translate3d(100%, 0, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  .slideOutRightAnimation {
    animation-name: slideOutEnvironementDrawerRight;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .slideInRightAnimation {
    animation-name: slideInEnvironementDrawerRight;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .modal {
    position: absolute;
    top: 80px;
    width: 90%;
    margin: 0 5%;
    z-index: 100;
    background: ${props => props.theme.modalBg};
    overflow: auto;
    outline: none;
    padding: 32px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(37,36,40,0.30);
    box-sizing: border-box;
    max-height: calc(100% - 160px);
    .modal-title-bar.fixed {
      width: 70%;
    }

    @media(min-width: 700px) {
      width: 70%;
      margin: 0 15%;
    }
    &.modal-small {
      top: 80px;
      width: 480px;
      left: 50%;
      margin: 0 0 0 -240px;
    }
    &.modal-medium {
      top: 80px;
      @media(min-width: 768px) {
        width: 600px;
        left: 50%;
        margin: 0 0 0 -300px;
      }
    }
    &.modal-large {
      top: 80px;
      max-width: 80%;
      margin: 0 10%;
      @media(min-width: 960px) {
        width: 900px;
        left: 50%;
        margin: 0 0 0 -450px;
      }
    }
    &.modal-fullpage {
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      margin: 0;
      padding: 16px;
      max-height: 100vh;
      border-radius: 0;

      @media(min-width: 960px) {
        padding: 32px;
        top: 32px;
        bottom: 32px;
        left: 32px;
        right: 32px;
        max-width: calc(100vw - 64px);
        max-height: calc(100vh - 64px);
        border-radius: 4px;
      }

      #modal-body {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    },
    &.modal-plan-pricing {
      .modal-title-bar {
        margin-bottom: 24px;
      }
      .title-wrapper {
        padding-top: 16px;
      }
      .modal-body {
        margin-bottom: 0;
      }
    }
    &.modal-environment-action {
      .field {
        margin-bottom: 24px;
      }
      .toggle-label {
        text-transform: uppercase;
      }
      @media (min-width: 1000px) {
        width: 640px;
        left: 50%;
        margin-left: -325px;
      }
    }
    &.not-found {
      padding: 50px;
      max-width: 650px;
      border-radius: 4px;
      box-shadow: 0 4px 8px rgba(37, 36, 40, 0.3);
      font-size: 18px;
      .actions {
        padding-top: 30px;
      }
      @media (min-width: 1000px) {
        width: 640px;
        left: 50%;
        margin-left: -325px;
      }
    }
    &.modal-header-styles {
      padding: 0;
      .modal-title-bar {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        &.fixed {
          margin-left: 0;
          margin-top: 0;
        }
        h3 {
          margin: 0;
          text-align: left;
          .title {
            display: inline-block;
            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
      .modal-content-wrapper {
        padding: 40px;
        h2 {
          text-align: center;
        }
        .modal-body {
          padding: 0;
        }
      }
    }
    &.modal-environment-sync {
      .checkbox-field {
        margin-bottom: 14px;
        label {
          margin-bottom: 0;
        }
        &:last-child {
          margin-bottom: 32px;
        }
      }
    }
    &.modal-plan-pricing {
      width: 700px;
    }
    &.modal-add-template {
      background: ${props =>
        props.theme.name === "dark"
          ? props.theme.dark
          : props.theme.modalBg} !important;
    }

 &.modal-announcements {
      padding: 0;
      max-width: 600px;
      top: 68px;
      left: 0;
      right: 0;
      margin-left: 8px;
      margin-right: 8px;
      overflow: visible;
      border: 0;
      position: relative;
      width: auto;
      background: ${props =>
        props.theme.name === "dark"
          ? props.theme.dark
          : props.theme.modalBg} !important;

      @media screen and (min-width: 600px) {
        top: 99px;
        margin-right: auto;
        margin-left: auto;
        height: 528px;
      }
    }
    footer {
      margin-top: 40px;
      text-align: right;
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => themeHelpers(props.theme, "overlayColor")};
    z-index: 99;

    &.overlay-announcements {
      background-color: rgba(48, 47, 69, 0.8);
    }
  }

  img {
    max-width: 100%;
  }
  span[data-tip="true"] {
    position: relative;
  }
  .type-dark {
    background: ${props => props.theme.dark} !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    font-family: 'Open Sans', Helvetica,Arial,sans-serif !important;
    padding: 8px 16px !important;
    line-height: 18px;
    border-radius: 2px !important;
    opacity: 1 !important;
    max-width: 480px;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${semiBoldAlias};
    &:after {
      border-width: 8px !important;
    }
    &.place-bottom:after {
      border-bottom-color: ${props => props.theme.dark} !important;
    }
    &.place-top:after {
      border-top-color: ${props => props.theme.dark} !important;
    }
    &.place-left {
      position: absolute;
      top: 100% !important;
      left: auto !important;
      right: 0;
      margin-top: 8px !important;
      &:after {
        top: -8px !important;
        right: 16px !important;
        border-left-color: ${props => props.theme.dark} !important;
        transform: rotate(-90deg);
      }
    }
    &.place-right {
      position: absolute;
      top: 100% !important;
      left: 0 !important;
      margin-top: 8px !important;
      margin-left: 0 !important;
      &:after {
        top: -8px !important;
        left: 16px !important;
        border-right-color: ${props => props.theme.dark} !important;
        transform: rotate(90deg);
      }
    }
  }

  .setup-modal {
    height: calc(100vh - 40px);
  }

  .setup-modal main {
    min-height: 0px !important;
  }

  .setup-modal .modal-content-wrapper {
    height: 100%;
  }

  .overflow-hidden {
    overflow: hidden;
  }
`;
