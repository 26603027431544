/**
 * Is split architecture if there are two different host types
 *
 * @param {Array<Dimension>} dimensions list of dimensions
 */
const isSplitArchitecture = dimensions => {
  const hostTypes = Array.from(
    new Set(
      dimensions
        ?.map(dimension => dimension?.hostType)
        .filter(hostType => !!hostType)
    )
  );

  return hostTypes.length > 1;
};

export default isSplitArchitecture;
