import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { ENVIRONMENT_TYPES } from "Constants/constants";

import SettingLine from "Components/SettingLine";
import UserIcon from "Components/icons/UserIcon";
import LabeledInfo from "Components/LabeledInfo";

import InvitationDetails from "./InvitationDetails";

import * as S from "./styles";

import { capitalize } from "Libs/utils";

const InvitationLine = ({
  invitation,
  isEdited,
  onEdit,
  onResendInvitation,
  onRevoke,
  environments
}) => {
  const intl = useIntl();

  return (
    <SettingLine
      icon={
        <UserIcon
          size={30}
          backgroundColor="black"
          color="white"
          padding={10}
        />
      }
      info={
        <S.InfoLayout>
          <S.StateLabel>
            {intl.formatMessage({
              id: "invitation.pending"
            })}
          </S.StateLabel>
          <S.Email>{invitation.email}</S.Email>
          {invitation.role === "admin" ? (
            <S.RoleWrapper>
              <S.LabeledInfoWrapper>
                <LabeledInfo label="project" value="Admin" />
              </S.LabeledInfoWrapper>
            </S.RoleWrapper>
          ) : (
            <S.RoleWrapper>
              {ENVIRONMENT_TYPES.map(envType => {
                const p = invitation?.environments?.find(
                  p => p.type === envType
                );
                return (
                  <S.LabeledInfoWrapper key={p?.type || envType}>
                    <LabeledInfo
                      key={p?.type || envType}
                      label={p?.type || envType}
                      value={capitalize(
                        p?.role || intl.formatMessage({ id: "no-access" })
                      )}
                    />
                  </S.LabeledInfoWrapper>
                );
              })}
            </S.RoleWrapper>
          )}
        </S.InfoLayout>
      }
      isOpen={isEdited}
      openText={intl.formatMessage({ id: "view" })}
      onClick={onEdit}
    >
      {isEdited && (
        <InvitationDetails
          environments={environments}
          invitation={invitation}
          onResendInvitation={onResendInvitation}
          onRevoke={onRevoke}
        />
      )}
    </SettingLine>
  );
};

InvitationLine.propTypes = {
  invitation: PropTypes.object.isRequired,
  isEdited: PropTypes.bool,
  onEdit: PropTypes.func,
  onResendInvitation: PropTypes.func,
  onRevoke: PropTypes.func,
  environments: PropTypes.object
};

export default InvitationLine;
