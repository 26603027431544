import React from "react";
import PropTypes from "prop-types";

import { Skeleton, Square } from "Components/Skeleton";
import * as S from "./CardSkeleton.styles";

const CardSkeleton = ({ Wrapper = React.Fragment, ...props }) => (
  <Wrapper {...props}>
    <S.Layout hasWrapper={!!Wrapper}>
      <Skeleton synchronize>
        <S.Row>
          <Square height="24px" width="64px" />
          <S.Content>
            <Square height="24px" width="110px" />
            <Square height="24px" width="32px" margin="0 0 0 12px" />
          </S.Content>
        </S.Row>
        <Square height="206px" width="100%" margin="16px 0 0 0" />
      </Skeleton>
    </S.Layout>
  </Wrapper>
);

CardSkeleton.propTypes = {
  Wrapper: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
};

export default CardSkeleton;
