import styled from "styled-components";

import { breakpoints } from "Libs/theme";
import Heading3Base from "Components/styleguide/Heading3";
import ButtonBase from "UI/Button";

export const Button = styled(ButtonBase)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const PaymentFormWrapper = styled.div`
  max-width: 100%;

  h3 {
    margin-top: 0;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    color: ${props => props.theme.sectionHeading};
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .action-buttons-wrapper {
    margin-top: 48px;

    @media (min-width: ${breakpoints[1]}) {
      display: flex;
      justify-content: flex-start;
    }

    button + button {
      margin-left: 10px;
    }
  }
  .card-form-stripe_sepa_debit {
    span {
      font-size: 14px;
      color: ${props => props.theme.sectionHeading};
    }
  }
  #consent-label {
    font-size: 14px;
    color: ${props => props.theme.labelLight};
  }
`;

export const HeaderLayout = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.sectionHeading};
  margin: 32px 0 24px;
  position: relative;

  @media (min-width: ${breakpoints[2]}) {
    flex-direction: row;
    margin-bottom: 5px;
  }
`;

export const Heading3 = styled(Heading3Base)`
  margin: 0 0 16px;

  @media (min-width: ${breakpoints[1]}) {
    font-size: 20px;
  }

  @media (min-width: ${breakpoints[2]}) {
    margin-right: 16px;
  }
`;

export const SubHeader = styled.span`
  font-size: 15px;
  line-height: 26px;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const PaymentTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints[0]}) {
    flex-direction: row;
  }

  @media (min-width: ${breakpoints[1]}) {
    flex-direction: column;
  }

  @media (min-width: ${breakpoints[2]}) {
    flex-direction: row;
  }
`;
