import googleLogo from "Images/connected_accounts/google.svg";
import githubLogo from "Images/connected_accounts/github.svg";
import bitbucketLogo from "Images/connected_accounts/bitbucket.svg";
import gitlabLogo from "Images/connected_accounts/gitlab.svg";

export const PROVIDERS = [
  { label: "GitHub", name: "github", image: githubLogo },
  { label: "Bitbucket", name: "bitbucket", image: bitbucketLogo },
  { label: "Google", name: "google", image: googleLogo },
  { label: "GitLab", name: "gitlab", image: gitlabLogo }
];
