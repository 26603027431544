import formatBytes from "./formatBytes";
import formatPercentage from "./formatPercentage";
import formatSeconds from "./formatSeconds";

const formatValue = (kind, value, ...args) => {
  switch (kind) {
    case "time":
      return formatSeconds(value);
    case "cpu":
      return `${value?.toFixed(2) || 0}CPU`;
    case "%":
    case "percentage":
      return formatPercentage(value, ...args);
    case "memory":
    case "disk":
    default:
      return formatBytes(value, "GB", ...args);
  }
};

export default formatValue;
