import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import ToggleSwitch from "Components/ToggleSwitch";
import EnvironmentSettingsHttpAccess from "../EnvironmentSettingsHttpAccess";

const HttpAccessSetting = ({
  basicAuth,
  environment,
  errors,
  ipText,
  isEnabled,
  onHttpAccessControlChange,
  onIpTextAreaChange,
  onLoginChange,
  projectId
}) => {
  const intl = useIntl();

  return (
    <>
      <ToggleSwitch
        id="environment-is-controlled-switch"
        title={intl.formatMessage({ id: "is_controlled" })}
        description={intl.formatMessage({
          id: "is_controlled.description"
        })}
        onClick={onHttpAccessControlChange}
        value={isEnabled}
        enabled={
          environment.hasPermission && environment.hasPermission("#edit")
        }
      />
      {isEnabled && (
        <>
          <hr className="full-width" />
          <EnvironmentSettingsHttpAccess
            errors={errors}
            environment={environment}
            projectId={projectId}
            basicAuth={basicAuth}
            ipText={ipText}
            onLoginChange={onLoginChange}
            onIpTextAreaChange={onIpTextAreaChange}
          />
        </>
      )}
    </>
  );
};

HttpAccessSetting.propTypes = {
  basicAuth: PropTypes.array,
  environment: PropTypes.object,
  errors: PropTypes.object,
  ipText: PropTypes.array,
  isEnabled: PropTypes.bool,
  onHttpAccessControlChange: PropTypes.func,
  onIpTextAreaChange: PropTypes.func,
  onLoginChange: PropTypes.func,
  projectId: PropTypes.string
};

export default HttpAccessSetting;
