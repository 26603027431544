import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Map } from "immutable";

import logger from "Libs/logger";

export const redeploy = createAsyncThunk(
  "app/environment/actions/redeploy",
  async ({ organizationId, projectId, environmentId }, { getState }) => {
    try {
      const environment = getState().environment.getIn(
        ["data", organizationId, projectId, environmentId],
        {}
      );

      return environment.redeploy();
    } catch (error) {
      if (![404, 403].includes(error.code)) {
        logger(error, {
          action: "redeploy",
          meta: {
            organizationId,
            environmentId,
            projectId
          }
        });
      }
      throw error;
    }
  }
);

const redeploySlice = createSlice({
  name: "redeploy",
  initialState: Map({
    error: undefined,
    isLoading: false,
    data: undefined
  }),
  reducers: {
    clearRedeploy: state =>
      state
        .set("isLoading", false)
        .set("error", null)
        .set("data", undefined)
  },
  extraReducers: {
    [redeploy.pending]: state => state.set("isLoading", true),
    [redeploy.rejected]: (state, action) =>
      state.set("isLoading", false).set("error", action.error),
    [redeploy.fulfilled]: (state, action) =>
      state
        .set("isLoading", false)
        .set("error", null)
        .set("data", action.payload)
  }
});

export const { clearRedeploy } = redeploySlice.actions;

export default redeploySlice.reducer;
