export const colorSchemeFields = {
  contrast: {
    messageId: "enable_high_contrast"
  },
  light: {
    messageId: "lightmode"
  },
  dark: {
    messageId: "darkmode"
  }
};

export const userDetailFields = [
  "first_name",
  "last_name",
  "username",
  "email"
];
