import styled from "styled-components";
import InfoDialog from "Components/InfoDialog";
import { semiBoldAlias } from "Libs/theme";

export const FieldDescription = styled.div`
  line-height: 20px;
`;

export const EnvironmentHiddenInfoDialog = styled(InfoDialog)`
  margin-left: 0;
  width: 19px;
  top: 3px;
`;

export const TextWrapper = styled.div`
  margin-bottom: 32px;
  font-size: 14px;
  h4 {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #dedede;
  }
  .description {
    color: ${props => props.theme.descriptionText};
    font-size: 15px;
  }
  ul {
    margin-bottom: 32px;
    li {
      margin-bottom: 10px;
    }
  }

  pre {
    border: none;
    background: ${props => props.theme.preBg};
    line-height: 40px;
    padding: 0 15;
    margin-right: 0;
    color: #fff;
  }
  .copy-to-clipboard {
    border: none;
    background: #1a192b;
    line-height: 40px;
    height: 40px;
    padding: 0 15px;
    border-radius: 0;
    font: 0/0 none;
    svg > g > g {
      fill: #fff;
    }
    &:hover svg > g > g {
      fill: rgb(22, 100, 255);
    }
  }
  .copied-message {
    border-radius: 0;
  }
`;

export const InfoLayout = styled.div`
  span:first-of-type {
    width: 50%;
  }
  span:nth-of-type(2) {
    width: 20%;
  }
  .cap {
    display: inline-block;
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

export const WarningText = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: ${props => props.theme.sectionText};
  font-size: 13px;
  ${semiBoldAlias};
  svg {
    margin-right: 8px;
  }
`;

export const AgreeWrapper = styled.div`
  .field {
    width: 100%;
  }
`;

export const DropdownWrapper = styled.div`
  .Select-control {
    border: none;
    background: #f7f7f7 !important;
    padding: 0 10px;
    line-height: 40px;
    height: 40px;
    width: 300px !important;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .select-box__control {
    min-height: 38px;
    height: 40px;
    .select-box__value-container {
      height: 40px;
      .select-box__single-value {
        height: 40px;
        line-height: 38px;
      }
    }
  }
  .dropdown > div {
    display: block;
  }
  .highlight {
    bottom: 21px;
  }
`;
