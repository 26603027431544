import React from "react";
import { useIntl } from "react-intl";

import Label from "Components/fields/Label";

import * as S from "./Sumup.styles";

const Sumup = () => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.Description>
        <p>
          {intl.formatMessage({ id: "plan_and_pricing_sumup_description_1" })}{" "}
          <b>{intl.formatMessage({ id: "dev_plan" })}</b>.
        </p>
        <p>
          {intl.formatMessage({ id: "plan_and_pricing_sumup_description_2" })}
        </p>
      </S.Description>
      <S.PlanSummary>
        <S.Side>
          <Label style={{ marginBottom: "4px" }}>
            {intl.formatMessage({ id: "plan" })}
          </Label>
          <S.PlanName>development</S.PlanName>
          <Label style={{ marginBottom: "6px" }}>
            {intl.formatMessage({ id: "monthly_price" })}
          </Label>
          <S.PlanPrice>
            {intl.formatMessage(
              { id: "plan_price" },
              {
                // eslint-disable-next-line react/display-name
                label: (...chunks) => <Label>{chunks}</Label>
              }
            )}
          </S.PlanPrice>
        </S.Side>
        <div>
          <Label style={{ marginBottom: "0" }}>
            {intl.formatMessage({ id: "plan_includes.title" })}
          </Label>
          <S.List>
            <S.ListItem>
              {intl.formatMessage({ id: "plan_includes.item.user" })}
            </S.ListItem>
            <S.ListItem>
              {intl.formatMessage({ id: "plan_includes.item.environments" })}
            </S.ListItem>
            <S.ListItem>
              {intl.formatMessage({ id: "plan_includes.item.storage" })}
            </S.ListItem>
            <S.ListItem>
              {intl.formatMessage({ id: "plan_includes.item.prod" })}
            </S.ListItem>
          </S.List>
        </div>
      </S.PlanSummary>
    </S.Wrapper>
  );
};

export default Sumup;
