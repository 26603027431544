import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import PageDescription from "Components/PageDescription";
import OrganizationConnectedAccounts from "./containers/ConnectedAccounts";

const FlexibleHeading2 = styled(Heading2)`
  font-size: 18px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

class OrganizationAccounts extends React.Component {
  render() {
    return (
      <ContentLayout id="settings-members" className="settings">
        <FlexibleHeading2>
          <FormattedMessage id="connected_accounts" />
        </FlexibleHeading2>
        <PageDescription>
          <FormattedMessage id="you_can_connect_external_provider" />
        </PageDescription>
        <OrganizationConnectedAccounts />
      </ContentLayout>
    );
  }
}

OrganizationAccounts.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    username: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};
export default OrganizationAccounts;
