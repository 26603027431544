import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import momentjs from "moment";

import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import OrderDetail from "../../../../components/OrderDetail";
import SettingLine from "Components/SettingLine";
import OrderProjectList from "../../components/OrderProjectList";
import OrderItem from "../../../../components/OrderItem";
import BackLink from "Components/BackLink";
import Layout from "../../../../components/Layout";
import ButtonLink from "Components/ButtonLink";
import {
  DetailLayout,
  OrderDetailLayout,
  HeaderLayout,
  OrderLayout
} from "./styles";

class OrganizationBillingOrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expandedItem: null };
    this.expand = this.expand.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.loadOrder(this.props.params.orderId);
  }

  expand(id) {
    if (this.state.expandedItem === id) {
      return this.setState({ expandedItem: null });
    }

    this.setState({ expandedItem: id });
  }

  render() {
    const {
      params: { username, orderId },
      order,
      loading
    } = this.props;
    const billingCycle = date => momentjs(date).format("MMMM YYYY");

    return (
      !loading && (
        <Layout>
          <DetailLayout>
            <OrderDetailLayout>
              <ContentLayout id="settings-billing" className="settings-content">
                <BackLink to={`/-/users/${username}/billing/history`} />
                <HeaderLayout>
                  <Heading2>{orderId}</Heading2>
                  {order.invoice_url && (
                    <ButtonLink
                      to={`${order.invoice_url}?download=1`}
                      text="Download PDF"
                      external={true}
                    />
                  )}
                </HeaderLayout>
                <OrderLayout>
                  <SettingLine
                    key={order.id}
                    id={`billing-history-recurring-${order.id}`}
                    info={<OrderItem order={order} username={username} />}
                    noToggle={true}
                  >
                    <OrderDetail
                      loading={loading}
                      order={order}
                      billingCycle={billingCycle(order?.billing_period_end)}
                      showProjects={false}
                      showPDF={false}
                      username={username}
                    />
                  </SettingLine>
                </OrderLayout>
                <OrderProjectList
                  order={order}
                  title="Projects Included"
                  expandedItem={this.state.expandedItem}
                  expand={this.expand}
                  username={username}
                />
              </ContentLayout>
            </OrderDetailLayout>
          </DetailLayout>
        </Layout>
      )
    );
  }
}

const mapDispatchToProps = dispatch => ({
  loadOrder: orderId =>
    import("Reducers/user/order").then(reducer =>
      dispatch(reducer.loadOrder(orderId))
    )
});

const mapStateToProps = (state, props) => {
  return {
    loading: state.order.getIn(["byId", props.params.orderId, "loading"], true),
    order: state.order.getIn(["byId", props.params.orderId, "data"], {})
  };
};

OrganizationBillingOrderDetails.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    username: PropTypes.string,
    orderId: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  loadOrder: PropTypes.func,
  order: PropTypes.object,
  loading: PropTypes.bool
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationBillingOrderDetails);
