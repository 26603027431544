import React from "react";
import { useHistory } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
import EnvironmentMergePane from "./containers/MergePane";

import withReducers from "Hocs/withReducers";

const EnvironmentActionsMerge = () => {
  const { push } = useHistory();
  const params = useDecodedParams();

  return <EnvironmentMergePane push={push} {...params} />;
};

export default withReducers({
  merge: () => import("Reducers/environment/actions/merge")
})(EnvironmentActionsMerge);
