import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { capitalize } from "Libs/utils";
import { breakpoints } from "Libs/theme";
import ButtonBase from "UI/Button";
import CopyableArea from "Components/CopyableArea";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import { injectIntl } from "react-intl";
import InputField from "Components/fields/InputField";

const SshKeyViewLayout = styled.div`
  --px: 24px;
  --pb: 32px;

  padding-top: 24px;
  padding-right: var(--px);
  padding-bottom: var(--pb);
  padding-left: var(--px);
  min-height: 200px;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;

  @media (min-width: ${breakpoints[1]}) {
    --px: 32px;
  }
`;

const Layout = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
`;

const Button = styled(ButtonBase)`
  position: absolute;
  right: var(--px);
  bottom: var(--pb);
`;

class SshKeyView extends React.Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      isModalOpen: false
    };
  }

  confirmDelete() {
    this.setState({
      isModalOpen: true
    });
  }

  openModal() {
    this.setState({
      isModalOpen: true
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  render() {
    let { sshKey, intl, deleteFunc } = this.props;
    return (
      <Layout>
        <SshKeyViewLayout>
          <InputField
            label={intl.formatMessage({ id: "title" })}
            value={sshKey.title}
            isDisabled={true}
            id={`ssh-key-field-${sshKey.id}`}
          />
          <div className="row">
            <CopyableArea id={`ssh-key-${sshKey.id}`} content={sshKey.value}>
              {sshKey.value}
            </CopyableArea>
            <Button
              variant="tertiary"
              id={`organization-sshkey-list-${sshKey.id}-delete-btn`}
              onClick={() => this.confirmDelete()}
              aria-label={intl.formatMessage({ id: "delete" })}
            >
              {capitalize(intl.formatMessage({ id: "delete" }))}
            </Button>
          </div>
          <ModalConfirmDelete
            isOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            deleteFunction={deleteFunc}
            itemType="SSH key"
            title={intl.formatMessage({ id: "ssh.confirm.title" })}
            body={intl.formatMessage(
              { id: "ssh.confirm.body" },
              { key: sshKey.title }
            )}
            deleteText={intl.formatMessage({ id: "ssh.confirm.yes" })}
            itemName={sshKey && sshKey.title}
            itemId={sshKey && sshKey.title}
          />
        </SshKeyViewLayout>
      </Layout>
    );
  }
}

SshKeyView.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  deleteFunc: PropTypes.func,
  intl: PropTypes.object,
  sshKey: PropTypes.object,
  close: PropTypes.func
};

export default injectIntl(SshKeyView);
