import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

export const Wrapper = styled.button.attrs({ type: "button" })`
  display: inline-flex;
  flex-direction: column;
  border-radius: 2px;
  background-color: ${props =>
    props.theme.name === "dark"
      ? themeHelpers(props.theme, "darkTint")
      : props.theme.sectionBg};
  font-size: 15px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  width: 316px;
  align-items: center;
  padding: 32px 32px 53px;
  box-sizing: border-box;
  border: none;
  font-weight: 400;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;

  &:hover,
  &:focus {
    box-shadow: ${props => props.theme.menuDropShadow};
    outline: none;
    ${props =>
      props.theme.name === "dark" &&
      `&.active {
      box-shadow: none;
    }
  `};
  }
  &.active {
    background: ${props =>
      props.theme.name === "dark"
        ? themeHelpers(props.theme, "darkShade")
        : "none"};
    position: relative;
    .title {
      color: ${props => props.theme.links};
    }
    span.active {
      position: absolute;
      display: inline-block;
      height: 4px;
      width: 4px;
      border-radius: 4px;
      background: ${props => props.theme.buttonBg};
      border: 2px solid #fff;
      top: -2px;
      right: -2px;
    }
  }
  &:hover,
  &.active {
    svg .highlight {
      fill: #ffeeee;
      &.primary {
        fill: #ffbdbb;
      }
    }
  }
`;

export const ImgWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 100%;
  margin-bottom: 32px;
`;

export const Title = styled.strong`
  font-weight: bold;
  color: ${props => props.theme.sectionHeading};
  margin-bottom: 8px;
`;

export const Description = styled.span`
  color: ${props => props.theme.sectionTextLight};
`;
