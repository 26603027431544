import { formatAsCurrency } from "Libs/utils";
import PropTypes from "prop-types";
import React from "react";

const OrderDetailItemTotal = ({ lineItems }) => {
  // Assume the currency is the same for all components of the line item.
  const currency = lineItems[0].components.base_price.currency;
  const defaultTotal = {
    totalPrice: {
      amount: 0,
      displayTitle: "Total"
    }
  };

  // Nested loops here because we need to add up the totals for *each project*
  // based on each component *within the project*.
  const totals = lineItems.reduce((totals, item) => {
    Object.keys(item.components).forEach(component => {
      if (totals[component]) {
        totals[component].amount =
          item.components[component].amount + totals[component].amount;
      } else {
        totals[component] = {
          amount: item.components[component].amount,
          displayTitle: item.components[component].display_title
        };
      }
    });

    totals.totalPrice.amount = totals.totalPrice.amount + item.total_price;
    return totals;
  }, defaultTotal);

  return (
    <React.Fragment>
      {Object.keys(totals)
        .filter(item => item !== "totalPrice")
        .map((item, i) => (
          <tr key={i} className="discount-row">
            <td className="discount table-data">{totals[item].displayTitle}</td>
            <td colSpan="4" className="total table-data">
              {formatAsCurrency(totals[item].amount, currency)}
            </td>
          </tr>
        ))}
      <tr className="total-row">
        <td className="total table-data">Total</td>
        <td colSpan="4" className="total table-data">
          {formatAsCurrency(totals.totalPrice.amount, currency)}
        </td>
      </tr>
    </React.Fragment>
  );
};

OrderDetailItemTotal.propTypes = {
  lineItems: PropTypes.array
};

export default OrderDetailItemTotal;
