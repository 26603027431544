import React from "react";
import PropTypes from "prop-types";

import AccessibleTooltip from "Components/AccessibleTooltip";

import formatValue from "../../../utils/formatValue";

import { ContainerChart } from "../Service.styles";
import MetricName from "../Metric/MetricName";
import * as S from "../Metric/Metric.styles";

import Skeleton from "./LargeMetric.skeleton";
import { useIntl } from "react-intl";

const LargeMetric = ({
  isLoading,
  name,
  onBrush,
  hosts,
  value,
  selectedTimeframe,
  kind,
  serviceKind,
  ...props
}) => {
  const intl = useIntl();

  if (isLoading) {
    return (
      <Skeleton
        name={<MetricName name={name} kind={kind} serviceKind={serviceKind} />}
      />
    );
  }

  return (
    <div>
      <S.Layout {...props}>
        <S.Row style={{ flexGrow: 2 }}>
          <MetricName name={name} kind={kind} serviceKind={serviceKind} />
          <div style={{ whiteSpace: "nowrap" }}>
            <S.Value>
              {formatValue(name, value[value.length - 1]["average@value"])}
            </S.Value>
            <S.Max> / {formatValue(name, value[value.length - 1].max)}</S.Max>
          </div>
        </S.Row>
        <S.Row flexEnd>
          <AccessibleTooltip
            tooltipProps={{
              children: intl.formatMessage({ id: "metrics.currentaverage" })
            }}
          >
            <S.Percentage>
              {formatValue("%", value[value.length - 1]["average@percentage"])}
            </S.Percentage>
          </AccessibleTooltip>
        </S.Row>
      </S.Layout>
      <ContainerChart
        key={name}
        hosts={hosts}
        data={value}
        max={value[0].max}
        yFormatter={value => formatValue(name, value)}
        tooltipFormatter={value => formatValue(name, value)}
        timeframe={selectedTimeframe}
        onBrush={onBrush}
      />
    </div>
  );
};

LargeMetric.propTypes = {
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBrush: PropTypes.func.isRequired,
  hosts: PropTypes.array.isRequired,
  selectedTimeframe: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
  kind: PropTypes.string,
  serviceKind: PropTypes.string
};

export default LargeMetric;
