import styled, { css } from "styled-components";
import { semiBoldAlias } from "Libs/theme";

import SettingLine from "Components/SettingLine";

export const Line = styled(SettingLine)`
  .setting-content {
    display: flex;
    overflow: hidden;
  }
`;

export const Name = styled.div`
  margin-right: 4px;
`;

export const InfoMain = styled.div`
  display: flex;
  flex: 1;
  min-width: 1px;
  padding-right: 16px;
  align-items: center;
  font-size: 15px;
  ${semiBoldAlias};
`;

export const Value = styled.div`
  display: flex;
  min-width: 0;
  margin-left: 4px;
`;

export const Info = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  ${props =>
    props.disabled &&
    css`
      ${Name}, ${InfoMain}, ${Value} {
        color: ${props => props.theme.cancelled};
      }
    `};
`;

export const Truncate = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Badge = styled.div`
  margin: 4px 0 0 16px;
  padding: 0 8px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 24px;
  ${semiBoldAlias};
`;

export const Badges = styled.div`
  display: flex;
  min-width: 175px;
  padding-right: 16px;
  justify-content: flex-end;
`;

export const Inherited = styled(Badge)`
  background: ${props => props.theme.label};
  color: ${props => props.theme.navText};
`;

export const Overridden = styled(Badge)`
  background: ${props => props.theme.badgeWarningBg};
  color: ${props => props.theme.badgeWarningText};
`;

export const Inactive = styled(Badge)`
  background: ${props => props.theme.background};
  color: ${props => props.theme.sectionTextLight};
`;

export const Disabled = styled(Inactive)``;

export const Labels = styled.div`
  width: 210px;
  color: ${props => props.theme.settingBarLabel};
  font-size: 13px;
  ${semiBoldAlias};
`;
