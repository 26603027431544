import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { ThemeContext } from "styled-components";

import useDecodedParams from "Hooks/useDecodedParams";

import { themeHelpers } from "Libs/theme";

import withReducers from "Hocs/withReducers";
import {
  loadRegistry,
  nextWizardStep,
  prevWizardStep,
  toggleProjectWizard,
  projectWizardSelector,
  configDataSelector
} from "Reducers/project/wizard";
import { loadSshKeys } from "Reducers/organization/settings/sshKey";

import Button from "UI/Button";
import CloseBtn from "Components/CloseBtn";

import ArrowIcon from "Components/icons/ArrowIcon";

import ListSteps from "../../components/Wizard/ListSteps";
import Intro from "../../components/Wizard/Intro";
import Outro from "../../components/Wizard/Outro";
import Step from "../../components/Wizard/Step";

import * as S from "./ContentWizard.styles";

const ContentWizard = ({ project, user }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  const containerRef = useRef();

  const { organizationId, projectId } = useDecodedParams();

  const projectWizard = useSelector(state =>
    projectWizardSelector(state, { organizationId, projectId })
  );
  const configData = useSelector(configDataSelector);

  useEffect(() => {
    dispatch(loadSshKeys());
  }, []);

  useEffect(
    () => {
      if (projectWizard?.isOpen && !configData) dispatch(loadRegistry());
    },
    [projectWizard?.isOpen]
  );

  useEffect(
    () => {
      const container = containerRef.current;
      if (!container) return;
      container.setAttribute("tabindex", 0);
      container.focus();
      container.removeAttribute("tabindex");
    },
    [projectWizard?.currentStep]
  );

  if (!projectWizard.data || !projectWizard.isOpen || projectWizard.loading)
    return null;

  const { currentStep, finish, start, data: steps } = projectWizard;

  const prevStep = e => {
    e?.preventDefault();
    dispatch(prevWizardStep({ organizationId, projectId }));
  };

  const nextStep = e => {
    e?.preventDefault();
    dispatch(nextWizardStep({ organizationId, projectId }));
  };

  const handleClose = e => {
    e?.preventDefault();
    dispatch(toggleProjectWizard({ organizationId, projectId }));
  };

  const progress = currentStep && steps && (currentStep / steps.length) * 100;

  return (
    <S.Wrapper
      className={`wizard$${!start && !finish ? " step-wizard" : ""}`}
      animate={projectWizard?.animate}
      ref={containerRef}
    >
      {start && <Intro project={project} />}
      {finish && <Outro project={project} />}

      {!start &&
        !finish && (
          <>
            <S.ProgressBar completed={progress} />

            <S.Header>
              <S.StepsDropdown
                id={`step-${currentStep}`}
                label={intl.formatMessage(
                  { id: "wizard.steps.dropdown" },
                  {
                    current: <span>{currentStep}</span>,
                    total: steps.length
                  }
                )}
                ariaLabel={`${currentStep} of ${steps && steps.length}`}
              >
                <ListSteps embedded={true} />
              </S.StepsDropdown>
              <CloseBtn onClick={handleClose} />
            </S.Header>

            <Step project={project} step={steps[currentStep - 1]} user={user} />

            <S.Footer>
              <Button className="back-link" variant="link" onClick={prevStep}>
                <ArrowIcon
                  color={themeHelpers(theme, "primaryColor")}
                  direction="left"
                />
              </Button>

              <Button className="next" variant="link" onClick={nextStep}>
                {steps[currentStep]
                  ? steps[currentStep].label
                  : intl.formatMessage({ id: "wizard.finish" })}{" "}
                <ArrowIcon color={themeHelpers(theme, "primaryColor")} />
              </Button>
            </S.Footer>
          </>
        )}
    </S.Wrapper>
  );
};

ContentWizard.propTypes = {
  project: PropTypes.object,
  user: PropTypes.object
};

export default withReducers({
  projectWizard: () => import("Reducers/project/wizard"),
  userSshKey: () => import("Reducers/organization/settings/sshKey")
})(ContentWizard);
