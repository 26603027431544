import { useEffect, useState } from "react";

import getDimensions from "../api/getDimensions";

const useDimensions = (chorusURL, query) => {
  // state = [data, isLoading, error]
  const [state, setState] = useState([null, false, null]);

  useEffect(
    () => {
      if (chorusURL && query) {
        setState([null, true, null]);
        getDimensions(chorusURL, query)
          .then(data => setState([data, false, null]))
          .catch(error => setState([null, false, error]));
      }
    },
    [chorusURL, query.stream.collection]
  );

  return state;
};

export default useDimensions;
