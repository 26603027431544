import React, { useEffect } from "react";
import useDecodedParams from "Hooks/useDecodedParams";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Map } from "immutable";
import Loading from "Components/Loading";

import withReducers from "Hocs/withReducers";
import { getIntegrations } from "Reducers/integration";

import withProjectRedirect from "../../../../common/hocs/withProjectRedirect";

import Button from "UI/Button";
import ContentLayout from "Components/ContentLayout";

import ThirdParties from "../third_parties";
import {
  EmptyIntegrations,
  GettingStarted,
  ListIntegrations
} from "./containers";

import * as S from "./Details.styles";

const getCategory = obj => {
  return ThirdParties.find(elt => elt.type === obj.type)?.category;
};

const ProjectIntegrations = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { organizationId, projectId } = useDecodedParams();

  const loading = useSelector(state =>
    state.integration?.get("loading", false)
  );
  const integrations = useSelector(state => {
    return state.integration
      ?.getIn(["data", projectId], Map())
      .toArray()
      .map(elt =>
        Object.assign({}, elt.data, {
          category: getCategory(elt)
        })
      )
      .filter(elt => elt.category)
      .sort((a, b) => {
        const catA = a.cat;
        const catB = b.cat;
        return catA < catB ? -1 : catA > catB ? 1 : 0;
      });
  });

  useEffect(
    () => {
      dispatch(getIntegrations({ projectId }));
    },
    [projectId]
  );

  if (loading) {
    return <Loading iconOnly={true} />;
  }

  return (
    <section>
      <S.Flex>
        <Button
          as={Link}
          to={`/${organizationId}/${projectId}/-/integrations/setup`}
        >
          {intl.formatMessage({
            id: "integrations.add",
            defaultMessage: "Add integration"
          })}
        </Button>
      </S.Flex>

      {integrations.length === 1 && <GettingStarted />}

      <ContentLayout>
        {integrations.length > 0 ? (
          <ListIntegrations integrations={integrations} />
        ) : (
          <EmptyIntegrations />
        )}
      </ContentLayout>
    </section>
  );
};

export default withReducers({
  integration: () => import("Reducers/integration")
})(withProjectRedirect(ProjectIntegrations));
