import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

export const Layout = styled.div`
  .StripeElement {
    background: ${props =>
      props.theme.name === "dark"
        ? themeHelpers(props.theme, "darkShade")
        : props.theme.inputBg};
    padding: 12px 16px;
    line-height: 40px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
  }
  .setting-line {
    background: ${props => props.theme.menuSearchBg};
    border: 1px solid ${props => props.theme.menuSearchBg};
    border-radius: 2px;
    min-height: 32px;
    line-height: 38px;
    height: 40px;
    font-size: 15px;
  }
  .card-number input {
    letter-spacing: 4px;
  }
  .input-field,
  .stripe-card {
    width: 316px;
  }
`;

export const Disclaimer = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.descriptionText};
  margin-bottom: 16px;
  .info-dialog {
    height: 24px;
    width: 24px;
    min-width: 24px;
    margin-right: 12px;
    svg path {
      fill: ${props => props.theme.subtitleText};
    }
    &:hover,
    &:focus {
      svg path {
        fill: ${props => props.theme.descriptionText};
      }
    }
    .dialog > div {
      width: 520px;
    }
  }
`;

export const NewCardWrapper = styled.div`
  padding: 0;
  @media (min-width: 1440px) {
    display: grid;
    grid-template-columns: 316px 316px;
    grid-column-gap: 32px;
    width: auto;
  }
`;
