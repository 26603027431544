import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useTheme } from "styled-components";

import SuccessStateIcon from "Icons/SuccessStateIcon";
import CloseBtn from "Components/CloseBtn";

import * as S from "./styles";

const ApiTokenBanner = ({ name, token, onClose }) => {
  const theme = useTheme();

  return (
    <S.Container>
      <SuccessStateIcon color={theme && theme.success} />
      <S.MessageText>
        <FormattedMessage
          id="api_token_add_success"
          values={{
            tokenName: name
          }}
        />
      </S.MessageText>
      <S.TokenValue>{token}</S.TokenValue>
      <S.Copy
        id={`${token}-copy-to-clipboard-btn`}
        className="link-style"
        text={token ? token : ""}
        tabIndex="0"
      />
      <S.Close>
        <CloseBtn onClick={onClose} />
      </S.Close>
    </S.Container>
  );
};

ApiTokenBanner.propTypes = {
  name: PropTypes.string,
  token: PropTypes.string,
  onClose: PropTypes.func
};

export default ApiTokenBanner;
