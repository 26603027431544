import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";

import useMediaQuery from "Hooks/useMediaQuery";

import Cell from "../Cell";
import Priority from "../../../../../common/components/Priority";
import Status from "../../../../../common/components/Status";

import { Layout, Subject, Project, SubjectLink } from "./Row.styles";

const TicketRow = ({
  ticket_id,
  priority,
  subject,
  project,
  updated,
  status
}) => {
  const { url } = useRouteMatch();
  const showAll = useMediaQuery("(min-width: 768px)");

  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: "%d sec",
      ss: "%d secs",
      m: "%d min",
      mm: "%d mins",
      h: "%d hr",
      hh: "%d hrs",
      d: "%d day",
      dd: "%d days",
      M: "%d month",
      MM: "%d months",
      y: "%d year",
      yy: "%d years"
    }
  });

  return (
    <Layout>
      <Cell priority={priority}>{ticket_id}</Cell>
      {showAll && (
        <Cell>
          <Priority priority={priority} />
        </Cell>
      )}
      <Subject>
        {process.env.ZENDESK_URL ? (
          <SubjectLink
            as="a"
            href={`${process.env.ZENDESK_URL}/${ticket_id}`}
            target="_blank"
          >
            {subject}
          </SubjectLink>
        ) : (
          <SubjectLink
            to={{ pathname: `${url}/${ticket_id}`, state: { from: "tickets" } }}
          >
            {subject}
          </SubjectLink>
        )}
      </Subject>
      {showAll && (
        <>
          <Project>{project || "-"}</Project>
          <Cell>{moment(updated).fromNow()}</Cell>
        </>
      )}
      <Cell>
        <Status status={status} />
      </Cell>
    </Layout>
  );
};
TicketRow.propTypes = {
  ticket_id: PropTypes.number,
  priority: PropTypes.string,
  subject: PropTypes.string,
  project: PropTypes.string,
  updated: PropTypes.string,
  status: PropTypes.string
};

export default TicketRow;
