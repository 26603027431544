import React from "react";
import PropTypes from "prop-types";

import SettingLine from "Components/SettingLine";
import { InfoLayout, FormWrapper } from "./styles";

const Pane = ({ header, children, ...props }) => (
  <SettingLine
    info={<InfoLayout className="account-info">{header}</InfoLayout>}
    noToggle={true}
    isOpen={true}
    {...props}
  >
    <FormWrapper>{children}</FormWrapper>
  </SettingLine>
);

Pane.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node
};

export default Pane;
