import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback
} from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

import { usePalette } from "Libs/theme";
import useUniqueId from "Libs/useUniqueId";

import { debounce } from "../../utils/debounce";

import { margin, timeFormats } from "./settings";
import Lines from "./Lines";
import { getOverCommitInfo, getGradient } from "./thresholdUtils";
import LineGradient from "./LineGradient";
import Ruler from "./Ruler";
import TimeTooltip from "./TimeTooltip";
import Tooltip from "./Tooltip";
import applyBrush from "./brush";

import useOnResize from "../../hooks/useOnResize";

import * as S from "./Chart.styles";
import Thresholds from "./Thresholds/Thresholds";

export const CHART_HEIGHT = 226;

const generateAxis = (data, max, width, height = CHART_HEIGHT) => {
  if (!data) {
    return [];
  }

  const xAxis = d3
    .scaleTime()
    .range([margin.left, width - margin.right])
    .domain([data[0].timestamp, data[data.length - 1].timestamp]);

  const yAxis = d3
    .scaleLinear()
    .range([height - margin.bottom, margin.top])
    .domain([0, max]);

  return [xAxis, yAxis];
};

const getTickInterval = (timeframe, width) => {
  let tickInterval;

  if (timeframe === "short") {
    tickInterval = d3.timeMinute.every(width > 480 ? 1 : 2);
  } else if (timeframe === "medium") {
    tickInterval = d3.timeMinute.every(width > 480 ? 5 : 10);
  } else if (timeframe === "long") {
    tickInterval = d3.timeHour.every(width > 480 ? 2 : 3);
  } else {
    if (width < 480) {
      tickInterval = 5;
    } else if (width < 560) {
      tickInterval = 6;
    } else {
      tickInterval = 8;
    }
  }

  return tickInterval;
};

const getXTimeFormat = (from, to) => {
  const timeFrom = new Date(from).getTime();
  const timeTo = new Date(to).getTime();
  const diff = timeTo - timeFrom;

  if (diff < 5 * 60 * 1000) {
    return timeFormats.long;
  }

  return timeFormats.short;
};

const renderXAxis = (xAxis, ref, timeframe, width) => {
  const tickInterval = getTickInterval(timeframe.id, width);
  const format = timeframe.id
    ? timeFormats.short
    : getXTimeFormat(timeframe.from, timeframe.to);

  const ticks = d3
    .axisBottom(xAxis)
    .ticks(tickInterval)
    .tickFormat(format);

  d3.select(ref.current)
    .select("svg [data-role=x-axis]")
    .call(ticks);
};

const timestampBisector = d3.bisector(point => point.timestamp).left;
const getCurrentMousePosition = (pointerX, pointerY, data, x) => {
  let dataIndex = timestampBisector(data, x.invert(pointerX)) || 0;
  if (dataIndex === data.length) {
    dataIndex--;
  }
  const currentX = x(data[dataIndex].timestamp);
  const previousX =
    dataIndex === 0 ? currentX : x(data[dataIndex - 1].timestamp);

  const closestDataPointX =
    pointerX - currentX > previousX - pointerX ? currentX : previousX;
  const closestDataPoint =
    data[timestampBisector(data, x.invert(closestDataPointX))];

  return { closestDataPoint, closestDataPointX, pointerX, pointerY };
};

const getTimeTooltipCoordinates = ($targetArea, $elementContainer) => {
  if (!$targetArea || !$elementContainer) {
    return [0, 0];
  }

  const {
    bottom: squareBottom,
    left: squareLeft
  } = $targetArea.getBoundingClientRect();
  const {
    top: dotContainerTop,
    left: dotContainerLeft
  } = $elementContainer.getBoundingClientRect();

  return [
    squareLeft - dotContainerLeft,
    squareBottom - dotContainerTop - margin.bottom + 14
  ];
};

const Chart = ({
  onBrush,
  data,
  hosts,
  timeframe,
  yFormatter = v => v,
  tooltipFormatter = v => v,
  ...props
}) => {
  const id = useUniqueId();
  const theme = usePalette(S.colorDefinitions);
  const layoutRef = useRef();
  const tooltipRef = useRef();
  const timeTooltipRef = useRef();

  const [pointer, setPointer] = useState([0, 0]);
  const [[xAxis, yAxis], setAxis] = useState([]);
  const [isBrushing, setIsBrushing] = useState();
  const [activeLine, setActiveLine] = useState();
  const [closestDataPoint, setClosestDataPoint] = useState();

  const debouncedSsetClosestDataPoint = useMemo(
    () => debounce(setClosestDataPoint),
    []
  );
  const debouncedSsetPointer = useMemo(() => debounce(setPointer), []);

  const { max, lineGradient, overCommitInfo } = useMemo(
    () => {
      const overCommitInfo = getOverCommitInfo(data);
      const lineGradient = getGradient(
        overCommitInfo.fixedMax,
        overCommitInfo.reportedMax
      );
      return {
        max: overCommitInfo.max,
        lineGradient,
        overCommitInfo
      };
    },
    [data]
  );

  const timeTooltipContent = useMemo(
    () => {
      const id = timeframe?.id;
      const timestamp = closestDataPoint?.timestamp || Date.now();
      const formatter =
        !id || id === "short" ? timeFormats.long : timeFormats.short;

      return formatter(timestamp);
    },
    [timeframe, closestDataPoint]
  );

  const [timeTooltipX, timeTooltipY] = useMemo(
    () => {
      if (!xAxis) {
        return [0, 0];
      }
      const [relativeX, relativeY] = getTimeTooltipCoordinates(
        layoutRef.current,
        timeTooltipRef.current
      );

      return [relativeX + xAxis(closestDataPoint?.timestamp), relativeY];
    },
    [closestDataPoint]
  );

  const updateAxis = () => {
    const width = layoutRef.current.offsetWidth;
    const [x, y] = generateAxis(data, max, width, CHART_HEIGHT);

    renderXAxis(x, layoutRef, timeframe, width);

    const onBrushstart = () => {
      setIsBrushing(true);
    };
    const onBrushMove = event => {
      if (event.selection) {
        const { closestDataPoint } = getCurrentMousePosition(
          event.selection[1],
          0,
          data,
          x
        );

        setClosestDataPoint(closestDataPoint);
      }
    };

    d3.select(layoutRef.current)
      .select("svg [data-role=brush-container]")
      .call(applyBrush(x, y, onBrush, onBrushstart, onBrushMove));

    setAxis([x, y]);
  };

  const onMouseLeave = useCallback(() => debouncedSsetClosestDataPoint(null), [
    debouncedSsetClosestDataPoint
  ]);

  const onMouseMove = useCallback(
    event => {
      if (!xAxis || !data) {
        return;
      }
      const [relativeX, relativeY] = d3.pointer(event);
      const [absoluteX, absoluteY] = d3.pointer(event, document.body);

      const { closestDataPoint } = getCurrentMousePosition(
        relativeX,
        relativeY,
        data,
        xAxis
      );

      debouncedSsetClosestDataPoint(
        prev =>
          prev?.timestamp.getTime() === closestDataPoint.timestamp.getTime()
            ? prev
            : closestDataPoint
      );
      debouncedSsetPointer([absoluteX, absoluteY]);
    },
    [xAxis, debouncedSsetClosestDataPoint, debouncedSsetClosestDataPoint]
  );

  useEffect(updateAxis, [data, max, layoutRef]);
  useOnResize(updateAxis);

  return (
    <S.Layout ref={layoutRef} chartHeight={CHART_HEIGHT} {...props}>
      <S.Svg onMouseMove={onMouseMove} onMouseLeave={onMouseLeave}>
        <defs>
          <linearGradient id={`gradient-${id}`}>
            <stop offset="0" stopColor="transparent" />
            <stop offset="12%" stopColor="white" />
          </linearGradient>
          <mask
            id={`mask-${id}`}
            maskContentUnits="userSpaceOnUse"
            maskUnits="userSpaceOnUse"
          >
            <rect
              x={margin.left}
              width="100%"
              height="100%"
              fill={`url(#gradient-${id})`}
            />
          </mask>
          <LineGradient
            kind="average"
            id={`average-fire-${id}`}
            y1={CHART_HEIGHT - margin.bottom}
            y2={margin.top}
            threshold75={lineGradient[0]}
            threshold87={lineGradient[1]}
          />
          <LineGradient
            kind="host"
            id={`host-fire-${id}`}
            y1={CHART_HEIGHT - margin.bottom}
            y2={margin.top}
            threshold75={lineGradient[0]}
            threshold87={lineGradient[1]}
          />
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id={`overcommit-${id}`}
            x1="0"
            y1={CHART_HEIGHT - margin.bottom}
            x2="0"
            y2={margin.top}
          >
            <stop
              offset={`${lineGradient[2]}%`}
              stopOpacity=".5"
              stopColor="transparent"
            />
            <stop
              offset={`${lineGradient[2]}%`}
              stopOpacity=".5"
              stopColor={theme.threshold__max}
            />
          </linearGradient>
        </defs>
        <S.Brush
          data-role="brush-container"
          transform={`translate(0, ${margin.top})`}
        />
        {!isBrushing &&
          closestDataPoint && (
            <Ruler
              y1={yAxis.range()[0]}
              y2={yAxis.range()[1]}
              transform={`translate(${xAxis(closestDataPoint.timestamp)}, ${
                margin.top
              })`}
            />
          )}
        <S.XAxis
          transform={`translate(0, ${CHART_HEIGHT -
            margin.bottom +
            margin.top})`}
          data-role="x-axis"
        />
        <Thresholds
          data={data}
          xAxis={xAxis}
          yAxis={yAxis}
          transform={`translate(0, ${margin.top})`}
          chartId={id}
          overCommitInfo={overCommitInfo}
          yFormatter={yFormatter}
        />
        {xAxis &&
          yAxis && (
            <Lines
              chartId={id}
              data={data}
              hosts={hosts}
              xAxis={xAxis}
              yAxis={yAxis}
              onActiveChange={setActiveLine}
              activeLine={activeLine}
              transform={`translate(0, ${margin.top})`}
            />
          )}
      </S.Svg>
      {!!closestDataPoint && (
        <TimeTooltip
          forwardedRef={timeTooltipRef}
          x={timeTooltipX || 0}
          y={timeTooltipY || 0}
          isVisible={!!closestDataPoint}
        >
          {timeTooltipContent}
        </TimeTooltip>
      )}

      {!!(!isBrushing && closestDataPoint && pointer[0] && pointer[1]) && (
        <Tooltip
          forwardedRef={tooltipRef}
          isVisible={!!(!isBrushing && closestDataPoint)}
          x={pointer[0]}
          y={pointer[1]}
          data={closestDataPoint}
          hosts={hosts}
          activeLine={activeLine}
          tooltipFormatter={tooltipFormatter}
        />
      )}
    </S.Layout>
  );
};

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  hosts: PropTypes.arrayOf(PropTypes.string).isRequired,
  timeframe: PropTypes.object.isRequired,
  yFormatter: PropTypes.func.isRequired,
  tooltipFormatter: PropTypes.func,
  onBrush: PropTypes.func
};
export default Chart;
