import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import * as d3 from "d3";

import { margin } from "../settings";
import * as S from "./Thresholds.styles";
import { getThresholds } from "../thresholdUtils";

const LABEL_GAP = 2;

const getLineShape = (thresholdMultiplier, data, xAxis, yAxis) =>
  d3
    .line()
    .defined(point => point?.max >= 0)
    .x(point => xAxis(point.timestamp))
    .y(point => yAxis(point.max * thresholdMultiplier))(data);

const getOverCommitLineShape = (max, data, xAxis, yAxis) =>
  d3
    .line()
    .x(point => xAxis(point.timestamp))
    .y(() => yAxis(max))(data);

const isThresholdActive = (points, threshold) =>
  points.some(point => point > threshold);

const Thresholds = ({
  data,
  xAxis,
  yAxis,
  chartId,
  overCommitInfo,
  yFormatter,
  ...props
}) => {
  if (!xAxis || !yAxis) {
    return null;
  }

  const lines = [0, 0.5, 0.75, 0.87, 1];

  const rightEdge = xAxis.range()[1];
  const thresholds = getThresholds(
    overCommitInfo.fixedMax,
    overCommitInfo.reportedMax
  );

  const dataPoints = data
    ?.flatMap(point =>
      Object.entries(point).map(
        ([key, value]) => (key.indexOf("@value") > -1 ? value : undefined)
      )
    )
    .filter(point => typeof point === "number");

  const isWarningActive = isThresholdActive(dataPoints, thresholds[2]);
  const isCriticalActive = isThresholdActive(dataPoints, thresholds[3]);

  return (
    <S.YAxis {...props}>
      {thresholds.filter(threshold => !!threshold).map(threshold => (
        <S.Tick key={threshold} x={xAxis.range()[0]} y={yAxis(threshold) + 3}>
          {yFormatter(threshold)}
        </S.Tick>
      ))}
      {lines.map(line => (
        <S.Threshold
          key={line}
          kind={line}
          d={getLineShape(line, data, xAxis, yAxis)}
          mask={`url(#mask-${chartId})`}
        />
      ))}
      {isCriticalActive && (
        <S.CriticalLabel y={yAxis(thresholds[3]) - LABEL_GAP} x={rightEdge}>
          <FormattedMessage id="metrics.thresholds.critical" />
        </S.CriticalLabel>
      )}
      {isWarningActive && (
        <S.WarningLabel
          y={(yAxis && yAxis(thresholds[2])) - LABEL_GAP}
          x={rightEdge}
        >
          <FormattedMessage id="metrics.thresholds.warning" />
        </S.WarningLabel>
      )}
      {overCommitInfo.hasOverCommit && (
        <>
          <S.Threshold
            kind="overcommit"
            d={getOverCommitLineShape(overCommitInfo.max, data, xAxis, yAxis)}
            mask={`url(#mask-${chartId})`}
          />
          <S.CriticalLabel y={margin.top - LABEL_GAP} x={rightEdge}>
            <FormattedMessage id="metrics.thresholds.burst" />
          </S.CriticalLabel>
        </>
      )}
    </S.YAxis>
  );
};

Thresholds.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string,
      max: PropTypes.number
    })
  ),
  xAxis: PropTypes.func,
  yAxis: PropTypes.func,
  chartId: PropTypes.number,
  overCommitInfo: PropTypes.object,
  yFormatter: PropTypes.func
};

export default React.memo(Thresholds);
