import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import withReducers from "Hocs/withReducers";

import EnvironmentTypeAccessForm from "../../../../../../common/components/EnvironmentTypeAccessForm";

import {
  invitationErrorSelector,
  invitationsSelector
} from "Reducers/invitation";
import {
  environmentAccessErrorsSelector,
  isLoadingSelector
} from "Reducers/environment/access";
import { editedProjectAccessSelector } from "Reducers/project/access";

const EnvironmentAccessListFields = ({
  access,
  enabled,
  environments,
  projectErrors,
  onCancel,
  onDelete,
  updateProjectAccess,
  updateEnvironmenTypesAccess,
  addEnvironmenTypesAccess,
  environmentTypes
}) => {
  const { organizationId, projectId } = useParams();

  const editedLine = useSelector(editedProjectAccessSelector);

  const invitations = useSelector(state =>
    invitationsSelector(state, { organizationId, projectId })
  );
  const invitationError = useSelector(state =>
    invitationErrorSelector(state, { organizationId, projectId, editedLine })
  );

  const errors = useSelector(environmentAccessErrorsSelector);
  const isLoading = useSelector(isLoadingSelector);

  const handleSave = data => {
    const role = data.superUser ? "admin" : "viewer";
    if (access) {
      // update project access
      if (access.role !== role) updateProjectAccess(access, role);

      // don't update env  if user is admin
      if (role === "admin") return;

      // update access for each environments type
      data.accesses.forEach(a => {
        if (a.id) {
          updateEnvironmenTypesAccess(a.environmentType, a);
        } else if (a.role !== "no_access") {
          addEnvironmenTypesAccess(a.environmentType, {
            email: access.getUser().email,
            ...a
          });
        }
      });
    }
  };

  return (
    <EnvironmentTypeAccessForm
      key={`${access ? access.user : "new"}-access-edit`}
      access={access}
      enabled={enabled}
      environments={environments}
      environmentTypes={environmentTypes}
      isLoading={isLoading === true}
      onCancel={onCancel}
      onDelete={onDelete}
      onSave={handleSave}
      errors={
        (errors || projectErrors || invitationError) && {
          ...errors,
          ...projectErrors,
          ...invitationError
        }
      }
      invitation={invitations.get(editedLine, false)}
    />
  );
};

EnvironmentAccessListFields.propTypes = {
  access: PropTypes.object,
  createInvitation: PropTypes.func,
  createProjectAccess: PropTypes.func,
  enabled: PropTypes.bool,
  environments: PropTypes.object,
  environmentTypes: PropTypes.object,
  projectErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  updateProjectAccess: PropTypes.func,
  updateProjectAccesses: PropTypes.func,
  addEnvironmenTypesAccess: PropTypes.func,
  updateEnvironmenTypesAccess: PropTypes.func
};

export default withReducers({
  environmentAccess: () => import("Reducers/environment/access"),
  invitation: () => import("Reducers/invitation"),
  projectAccess: () => import("Reducers/project/access")
})(EnvironmentAccessListFields);
