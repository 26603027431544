/**
 * Convenience method to transform a hash into a name/expr object.
 *
 * @param {object} object key value pair to be transformed
 * @returns Array<{name: string, expr: string}> a list of objects matching the
 * api input format
 */
const objectToFields = fieldsDescription => {
  if (typeof fieldsDescription !== "object") {
    return undefined;
  }

  return Object.entries(fieldsDescription).map(([key, value]) => ({
    name: key,
    expr: value
  }));
};

export default objectToFields;
