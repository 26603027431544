import PageLayout from "Components/PageLayout";
import PageMeta from "Components/PageMeta";
import NavBar from "Containers/NavBar";
import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import ListTicketsPage from "./list/Page";
import OpenTicketPage from "./open/Page";
import ViewTicketPage from "./view/Page";

const TicketsLayout = styled(PageLayout)`
  padding-top: 32px;
`;

const Routes = () => {
  const intl = useIntl();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <Fragment>
      <PageMeta title={intl.formatMessage({ id: "tickets.documenttitle" })} />
      <NavBar role="navigation" currentPathName={pathname} />
      <TicketsLayout>
        <Switch>
          <Route path={`${path}/open`}>
            <OpenTicketPage />
          </Route>
          {!process.env.ZENDESK_URL && (
            <Route path={`${path}/:ticketId`}>
              <ViewTicketPage />
            </Route>
          )}
          <Route path={path}>
            <ListTicketsPage />
          </Route>
        </Switch>
      </TicketsLayout>
    </Fragment>
  );
};

export default Routes;
