import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { capitalize } from "Libs/utils";
import Button from "UI/Button";
import CardSection from "Components/CardSection";
import CheckboxField from "Components/fields/CheckboxField";
import { useSelector } from "react-redux";
import SepaSection from "./SepaSection";
import {
  Layout,
  PaymentWrapper,
  CardWrapper,
  Flex
} from "./PaymentForm.styles";

const PaymentForm = ({
  currentPaymentSourceType,
  edit,
  finalize,
  cancel,
  number
}) => {
  const intl = useIntl();

  const username = useSelector(
    state => state.app && state.app.getIn(["me", "username"])
  );
  const email = useSelector(state =>
    state.profile.getIn(["data", username, "email"])
  );

  const [goingForward, setGoingForward] = useState(false);
  const [consent, setConsent] = useState();
  const [consentError, setConsentError] = useState();

  const onConsentChange = event => {
    setConsent(event.target.checked);
    setConsentError(undefined);
  };

  const goForward = () => {
    if (consent) {
      setGoingForward(true);
      const el = document.getElementById("stripe_cardsection_submit");
      if (el) return el.click();
      finalize && finalize();
    } else {
      setConsentError(intl.formatMessage({ id: "check_consent" }));
    }
  };

  const onSuccess = () => {
    goingForward && finalize && finalize();
  };

  const PaymentComponent =
    currentPaymentSourceType === "stripe_sepa_debit"
      ? SepaSection
      : CardSection;

  return (
    <Layout>
      <PaymentWrapper>
        <CardWrapper className={`card-form-${currentPaymentSourceType}`}>
          <PaymentComponent
            edit={edit}
            hideButtons={false}
            onSuccess={onSuccess}
            email={email}
            number={number}
          />
        </CardWrapper>
        <CheckboxField
          onChange={onConsentChange}
          value={number || consent}
          error={consentError}
          label={intl.formatMessage({ id: "payment_consent" })}
          forId="consent"
          disabled={!!number}
        />
      </PaymentWrapper>
      {!number && (
        <Flex>
          <Button
            id="create-project-submit"
            onClick={goForward}
            aria-label={intl.formatMessage({
              id: "save"
            })}
            disabled={!consent}
          >
            {capitalize(
              intl.formatMessage({
                id: "save"
              })
            )}
          </Button>
          {cancel && (
            <Button
              id="create-project-back"
              variant="secondary"
              onClick={cancel}
              aria-label={intl.formatMessage({
                id: "cancel"
              })}
            >
              {capitalize(
                intl.formatMessage({
                  id: "cancel"
                })
              )}
            </Button>
          )}
        </Flex>
      )}
    </Layout>
  );
};

PaymentForm.propTypes = {
  finalize: PropTypes.func,
  currentPaymentSourceType: PropTypes.string,
  cancel: PropTypes.func,
  edit: PropTypes.bool,
  number: PropTypes.string
};

export default PaymentForm;
