import styled, { css } from "styled-components";
import { Link as Unstyledlink } from "react-router-dom";

import { semiBoldAlias, themeHelpers } from "Libs/theme";

import { Button } from "@platformsh/ui-kit";
import UnstyledAvatar from "Components/Avatar";
import Heading3 from "Components/styleguide/Heading3";
import URLOverlay from "../../components/URLOverlay";

export const Wrapper = styled.div``;

export const Header = styled.header`
  display: flex;
  max-width: 100%;
  height: 200px;
  margin: 0;
`;

export const Url = styled(URLOverlay)`
  width: 100%;
`;

export const Details = styled.div`
  min-height: 250px;
  padding: 32px 32px 0 32px;
  box-sizing: border-box;
  color: ${props => props.theme.sectionHeading};
`;

export const ProjectName = styled(Heading3)`
  margin: 0 0 1px 0;
  line-height: 32px;
`;

export const IdRegion = styled.div`
  margin-top: 2px;
  font-size: 13px;
  line-height: 16px;
  ${semiBoldAlias};
  color: ${props => props.theme.subtitleText};
`;

export const Wizard = styled.div`
  display: flex;
  margin: 16px 0 0 0;

  ${Button} {
    font-size: 13px;
    ${semiBoldAlias};
    line-height: 28px;
    height: 28px;
    border: none;
    svg {
      margin: 0 4px 0 0;
      vertical-align: middle;
      fill: var(--link);
    }

    &:hover {
      &:after {
        content: none;
      }
      svg {
        fill: var(--link-visited);
      }
    }
  }
`;

export const Plan = styled.div`
  margin: 18px 0 0 0;
  ${semiBoldAlias};
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.sectionHeading};

  a {
    margin-left: 8px;
    font-size: 13px;
    text-decoration: none;
    ${semiBoldAlias};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Info = styled.div`
  display: block;
  margin-top: 16px;
`;

export const Label = styled.label`
  display: block;
  color: ${props => (props.theme.name === "contrast" ? "#4A495E" : "#98a0ab")};
  font-size: 14px;
  line-height: 16px;
  ${semiBoldAlias};
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const Value = styled.span`
  visibility: hidden;
  display: block;
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 18px;
  ${semiBoldAlias};
  color: ${props => props.theme.sectionHeading};

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Infos = styled.div`
  display: grid;
  box-sizing: border-box;
  color: ${props => props.theme.sectionHeading};
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;

  ${props =>
    props.displayValues &&
    css`
      ${Value} {
        visibility: visible;
      }
    `};
`;

export const Users = styled.div`
  display: flex;
  margin-top: 24px;

  span {
    margin: 0 0 0 5px;
    font-size: 12px;
    line-height: 24px;
    ${semiBoldAlias};
    color: ${props => props.theme.sectionHeading};
  }
`;

export const UsersFullList = styled.div`
  display: none;
  position: absolute;
  z-index: 2;
  top: 24px;
  left: 0;
  width: 224px;
  height: 201px;
  margin: 0;
  padding: 9px 0 9px 16px;
  box-sizing: border-box;
  background: ${props => props.theme.sectionBg};
  box-shadow: ${props => props.theme.shadow.large};
  border-radius: 2px;
  list-style-type: none;

  .rcs-inner-handle {
    background: ${props => themeHelpers(props.theme, "darkTint")};
    width: 4px;
    border-radius: 4px;
    opacity: 1;
  }
`;

export const User = styled.div`
  display: block;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  & + & {
    margin-top: 8px;
  }
`;

export const UsersMore = styled.div`
  position: relative;
  &:hover {
    ${UsersFullList} {
      display: block;
    }

    > span {
      color: #ffffff;
      background: var(--button-secondary-foreground);
    }
  }
  > span {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0;
    border-radius: 2px;
    background: var(--button-secondary-disabled-foreground);
    font-weight: bold;
    font-size: 10px;
    line-height: 24px;
    color: var(--text_body);
    text-align: center;
    cursor: pointer;
  }
`;

export const Avatar = styled(UnstyledAvatar)`
  margin-right: 2px;
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
  }
`;

export const MainUrl = styled.div`
  display: flex;
  margin: 24px 8px 8px 8px;
  padding: 12px 16px;
  background: #e7f0ff;
  border-radius: 2px;
  align-items: center;
`;

export const DomainLink = styled(Unstyledlink)`
  display: flex;
  line-height: 1;
  svg {
    fill: var(--link);
  }
`;

export const Truncate = styled.div`
  flex: 1;
  margin-right: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props => props.theme.primary};
  > div {
    min-width: 0;
    display: unset;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
