import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Layout from "../../../../components/Layout";
import Row from "../../../../components/Row";
import RouteInfos from "../../../../../../../../common/components/RouteInfos/RouteInfos";
import ServiceNode from "../../../../containers/ServiceNode";

const Wrapper = styled.div`
  margin-bottom: 8px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
  margin-bottom: 16px;
  padding: 0 16px 0 0;

  svg {
    margin-right: 16.43px;
  }
`;

const RouterOverview = ({ metadata }) => (
  <React.Fragment>
    {Object.keys(metadata).map(k => (
      <Wrapper key={k}>
        <Layout>
          <Title>
            <ServiceNode name="router" id={k.replace(/\/|:|\./g, "")} /> {k}
          </Title>
          <Row>
            <RouteInfos metadata={metadata[k]} />
          </Row>
        </Layout>
      </Wrapper>
    ))}
  </React.Fragment>
);

RouterOverview.propTypes = {
  metadata: PropTypes.object
};

export default RouterOverview;
