import React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import StepTitle from "../../../../../setup/components/Breadcrumbs/StepTitle";

import * as S from "./Stepper.styles";

const Stepper = () => {
  const { organizationId, projectId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const gotToSelect = () => {
    history.push(`/${organizationId}/${projectId}/-/integrations/setup`);
  };

  const isOnConfigure = location.pathname.includes("/configure");

  return (
    <S.Wrapper>
      <StepTitle
        key="step-type"
        isActive={!isOnConfigure}
        isComplete={isOnConfigure}
        onClick={gotToSelect}
        step="type"
      />

      <StepTitle
        key="step-configure"
        isActive={isOnConfigure}
        step="configure"
      />
    </S.Wrapper>
  );
};

export default Stepper;
