import React from "react";

import useDecodedParams from "Hooks/useDecodedParams";
import withReducers from "Hocs/withReducers";

import Backups from "./containers/Backups";

const Page = () => {
  const { environmentId, projectId, organizationId } = useDecodedParams();

  return (
    <Backups
      projectId={projectId}
      organizationId={organizationId}
      environmentId={environmentId}
    />
  );
};

export default withReducers({
  environment: () => import("Reducers/environment"),
  deployment: () => import("Reducers/environment/deployment"),
  activity: () => import("Reducers/activity"),
  service: () => import("Reducers/environment/service")
})(Page);
