import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "immutable";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import { DOCS_ENV_TYPES } from "Constants/documentationUrls";

import useDecodedParams from "Hooks/useDecodedParams";
import withReducers from "Hocs/withReducers";

import {
  addAccess,
  deleteAccess,
  editAccess,
  getAccesses,
  updateAccess
} from "Reducers/environment/access";

import {
  addInvitation,
  deleteInvitation,
  editInvitation,
  getInvitations,
  invitationEditedSelector,
  invitationsSelector,
  invitationErrorSelector,
  invitationStatusSelector
} from "Reducers/invitation";

import AccessibleTooltip from "Components/AccessibleTooltip";
import { Title as FlagTitle } from "Components/Flag";
import UserIcon from "Components/icons/UserIcon";
import Loading from "Components/Loading";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import PageMeta from "Components/PageMeta";
import Heading2 from "Components/styleguide/Heading2";
import SettingLine from "Components/SettingLine";
import PageDescription from "Components/PageDescription";

import User from "./../../../../../components/User";
import LockIcon from "Icons/LockIcon";
import RedeployIcon from "Icons/RedeployIcon";

import InvitationAlert from "../../../../../../../common/components/InvitationAlert";
import InvitationLine from "../../../../../../../common/components/InvitationLine";
import InvitationRevokeModal from "../../../../../../../common/components/InvitationRevokeModal";
import InvitationResendModal from "../../../../../../../common/components/InvitationResendModal";

import AccessForm from "../../../../../../../common/components/AccessForm";

import * as S from "./styles";

const EnvironmentAccessList = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { environmentId, organizationId, projectId } = useDecodedParams();

  const [accessesAreLoading, setAccessesAreLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [invitAlert, setInvitAlert] = useState({ isOpen: false });
  const [modal, setModal] = useState({ isOpen: false });

  const project = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId], new Map())
  );

  const environment = useSelector(state => {
    return state.environment?.getIn(
      ["data", organizationId, projectId, environmentId],
      new Map()
    );
  });
  const childrenEnv = useSelector(state => {
    const childrenIds = state.environment
      ?.getIn(["tree", organizationId, projectId], new Map())
      .filter(child => child.get("path")?.includes(environmentId))
      .keySeq()
      .toArray();
    return state.environment
      ?.getIn(["data", organizationId, projectId], new Map())
      .filter(elt => childrenIds.includes(elt.id));
  });

  const editedAccess = useSelector(state =>
    state.environmentAccess?.get("edited")
  );
  const envAccesses = useSelector(state =>
    state.environmentAccess?.getIn(
      ["data", organizationId, projectId, environmentId],
      new Map()
    )
  );
  const childrenAccesses = useSelector(state => {
    const envList = childrenEnv.keySeq().toArray();
    return state.environmentAccess
      ?.getIn(["data", organizationId, projectId], new Map())
      .filter((acc, id) => envList.includes(id));
  });
  const isLoading = useSelector(state =>
    state.environmentAccess?.get("loading")
  );
  const statusAccess = useSelector(state =>
    state.environmentAccess?.get("status")
  );
  const errorsAccess = useSelector(state =>
    state.environmentAccess?.get("errors")
  );
  const editedLine = useSelector(state =>
    state.environmentAccess?.get("edited")
  );

  const editedInvitation = useSelector(invitationEditedSelector);
  const invitations = useSelector(state =>
    invitationsSelector(state, { projectId, organizationId })
  );
  const statusInvitation = useSelector(invitationStatusSelector);
  const invitationError = useSelector(state =>
    invitationErrorSelector(state, { organizationId, projectId, editedLine })
  );

  useEffect(() => {
    return () => {
      cancel();
    };
  }, []);

  useEffect(
    () => {
      dispatch(getInvitations({ organizationId, projectId }));
    },
    [projectId]
  );

  useEffect(
    () => {
      if (
        ["added", "updated", "deleted"].includes(statusAccess) ||
        ["added", "deleted"].includes(statusInvitation)
      ) {
        cancel();
      }
    },
    [statusAccess, statusInvitation]
  );

  useEffect(
    () => {
      if (environment.id)
        dispatch(getAccesses({ organizationId, projectId, environment }));
    },
    [environment]
  );

  useEffect(
    () => {
      childrenEnv.forEach(environment =>
        dispatch(getAccesses({ organizationId, projectId, environment }))
      );
    },
    [childrenEnv?.size]
  );

  useEffect(
    () => {
      if (accessesAreLoading) return;
      const accessesKeys = childrenAccesses.keySeq().toArray();
      const fullLoaded = !childrenEnv
        .keySeq()
        .toArray()
        .some(elt => !accessesKeys.includes(elt));
      if (fullLoaded) setAccessesAreLoading(true);
    },
    [childrenAccesses]
  );

  const cancel = () => {
    handleEditInvitation();
    handleEditAccess();
  };

  const addNewAccess = () => {
    if (process.env.CUSTOM_USER_MANAGEMENT_URL) {
      window.location.href = process.env.CUSTOM_USER_MANAGEMENT_URL;
      return;
    }

    dispatch(editInvitation({ id: null }));
    dispatch(editAccess({ id: "new" }));
  };

  const handleEditAccess = (id = null) => {
    dispatch(editInvitation({ id: null }));
    dispatch(editAccess({ id }));
  };

  const handleSave = data => {
    if (editedLine === "new" && checkUserExists(data.email)) return;
    if (editedLine !== "new" || !process.env.INVITATION_V2_ENABLED) {
      // update access for each environments
      data.accesses.forEach(acc => {
        if (acc.id) {
          const envAccess =
            decodeURIComponent(acc.environmentId) === environmentId
              ? envAccesses.get(acc.id)
              : childrenAccesses.getIn([acc.environmentId, acc.id]);

          // don't call api if role hasn't changed
          if (envAccess.role === acc.role) return;
          dispatch(
            updateAccess({
              organizationId,
              projectId,
              environmentId: acc.environmentId,
              access: envAccess,
              role: acc.role
            })
          );
        } else {
          const env = childrenEnv.get(acc.environmentId);
          if (!env || env.status === "inactive") return;
          dispatch(
            addAccess({
              data: { email: data.email, role: acc.role },
              environment: env,
              organizationId,
              projectId
            })
          );
        }
      });
    } else {
      const accesses = data.accesses.reduce((acc, cu) => {
        const env =
          cu.environmentId === environmentId
            ? environment
            : childrenEnv.get(cu.environmentId);
        if (!env || env.status === "inactive") return acc;
        if (cu.role && ["admin", "viewer", "contributor"].includes(cu.role)) {
          acc.push({ id: cu.environmentId, role: cu.role });
        }
        return acc;
      }, []);
      dispatch(
        addInvitation({
          organizationId,
          projectId,
          email: data.email,
          environments: accesses,
          role: "viewer",
          force: false
        })
      );
    }
    setHasChanged(true);
  };

  const checkUserExists = email => {
    const access = envAccesses.find(elt => elt.getUser().email === email);
    if (access) {
      setInvitAlert({ isOpen: true, user: access.getUser() });
      return true;
    }
    return false;
  };

  const handleEditInvitation = (id = null) => {
    dispatch(editAccess({ id: null }));
    dispatch(
      editInvitation({
        id
      })
    );
  };

  const handleRevokeInvit = invitation => {
    if (!invitation) return;
    dispatch(
      deleteInvitation({
        organizationId,
        projectId,
        invitation
      })
    );
    handleCloseModal();
  };

  const handleResendInvit = invitation => {
    if (!invitation) return;
    dispatch(
      addInvitation({
        organizationId,
        projectId,
        email: invitation.email,
        environments: invitation.environments,
        role: invitation.role,
        force: true,
        invitation
      })
    );
    handleCloseModal();
  };

  const openInvitModal = (invitation, type) => {
    setModal({ open: true, type, invitation });
  };

  const handleCloseModal = () => {
    setModal({ open: false, type: null, access: null, invitation: null });
  };

  if (!environment) return null;

  return (
    <S.Wrapper>
      <LiveMessage
        message={`${environment?.title} environment-level access settings`}
        aria-live="polite"
      />
      <PageMeta title={`Access | ${environment?.title} | ${project?.title}`} />

      {invitAlert.isOpen && (
        <InvitationAlert
          project={project.title}
          environment={environment.title}
          user={invitAlert.user}
          onClose={() => setInvitAlert({ isOpen: false, user: null })}
        />
      )}

      {environment?.hasPermission &&
        environment.hasPermission("#manage-access") && (
          <S.AddLink isWarningVisible={hasChanged} onClick={addNewAccess} />
        )}

      {hasChanged &&
        environment?.hasLink("#redeploy") && (
          <S.RedeployWarning onClose={() => setHasChanged(false)}>
            <FlagTitle singleLine>
              {intl.formatMessage({ id: "environment.access.redeploymessage" })}
              <S.RedeployButton
                to={`/${organizationId}/${projectId}/${encodeURIComponent(
                  environmentId
                )}/actions/redeploy`}
              >
                <RedeployIcon />
                <span>{intl.formatMessage({ id: "redeploy" })}</span>
              </S.RedeployButton>
            </FlagTitle>
          </S.RedeployWarning>
        )}

      <Heading2 id="settings-heading" style={{ marginBottom: "16px" }}>
        {intl.formatMessage({ id: "access.title" })}
      </Heading2>

      {environment?.type ? (
        <S.EnvironmentTypeBanner level="warning">
          {intl.formatMessage({ id: "environment.access.environment_types" })}{" "}
          <a href={DOCS_ENV_TYPES} rel="noopener noreferrer" target="_blank">
            {intl.formatMessage({ id: "learnmore" })}
          </a>
        </S.EnvironmentTypeBanner>
      ) : (
        <PageDescription>
          {intl.formatMessage({ id: "environment.accesslist.description" })}
        </PageDescription>
      )}

      <section aria-labelledby="settings-heading">
        {editedAccess === "new" && (
          <div key={`access-create`}>
            <SettingLine
              id={`access-create`}
              icon={
                <UserIcon
                  size={30}
                  backgroundColor="black"
                  color="white"
                  padding={10}
                />
              }
              isNew={true}
              isOpen={true}
              addNewTitle={intl.formatMessage({
                id: "environment.accesslist.add_user",
                defaultMessage: "Add user"
              })}
              onClick={() => handleEditAccess()}
            >
              <AccessForm
                key={`access-create`}
                accessesAreLoading={accessesAreLoading}
                enabled={
                  environment.hasPermission &&
                  environment.hasPermission("#manage-access")
                }
                environmentAccesses={childrenAccesses}
                environments={childrenEnv}
                isLoading={isLoading === true}
                onCancel={cancel}
                onSave={handleSave}
                errors={invitationError}
                invitation={invitations.get(editedLine, false)}
              />
            </SettingLine>
          </div>
        )}

        {invitations?.entrySeq().map(([id, invitation]) => {
          // don't display if invitation doesn't contain env
          if (!invitation.environments.find(elt => elt.id === environmentId))
            return null;
          return (
            <InvitationLine
              key={`invitation-${id}-line`}
              invitation={invitation}
              isEdited={editedInvitation === id}
              onEdit={() =>
                handleEditInvitation(editedInvitation === id ? null : id)
              }
              onResendInvitation={() => openInvitModal(invitation, "resend")}
              onRevoke={() => openInvitModal(invitation, "revoke")}
            />
          );
        })}

        {envAccesses?.entrySeq().map(([id, access]) => (
          <SettingLine
            key={`access-${id}-read`}
            info={
              <S.InfoLayout>
                <User user={access.getUser()} />
                {access.hasPermission && access.hasPermission("#edit") ? (
                  <span className="role">
                    {intl.formatMessage({
                      id: `access.options.${access.role}`
                    })}
                  </span>
                ) : (
                  <span className="role super-user">
                    {intl.formatMessage({
                      id: "access.project_admin"
                    })}{" "}
                    <AccessibleTooltip
                      className="lock-icon"
                      tooltipProps={{
                        id: `access-${id}-tooltip`,
                        place: "top",
                        children: "You do not have permission to edit this user"
                      }}
                    >
                      <LockIcon />
                    </AccessibleTooltip>
                  </span>
                )}
              </S.InfoLayout>
            }
            addNewTitle={intl.formatMessage({
              id: "environment.accesslist.add_user",
              defaultMessage: "Add user"
            })}
            isOpen={editedAccess === id}
            openText={
              access?.hasPermission && access.hasPermission("#edit")
                ? intl.formatMessage({ id: "edit" })
                : intl.formatMessage({ id: "view" })
            }
            onClick={() => handleEditAccess(editedAccess === id ? null : id)}
          >
            {editedAccess === id && (
              <AccessForm
                access={access}
                accessesAreLoading={accessesAreLoading}
                enabled={access.hasPermission && access.hasPermission("#edit")}
                environmentAccesses={childrenAccesses}
                environments={childrenEnv}
                isLoading={isLoading === true}
                onCancel={cancel}
                onSave={handleSave}
                errors={{ ...invitationError, ...errorsAccess }}
                invitation={invitations.get(editedLine, false)}
                onDelete={() =>
                  setModal({ open: true, type: "delete", access })
                }
              />
            )}
          </SettingLine>
        ))}

        <ModalConfirmDelete
          isOpen={modal.open && modal.type === "delete"}
          closeModal={handleCloseModal}
          deleteFunction={() =>
            dispatch(
              deleteAccess({
                organizationId,
                projectId,
                environmentId,
                access: modal.access
              })
            )
          }
          itemType="user"
          itemName={modal.access?.getUser().display_name}
          itemId={modal.access?.id}
        />

        <InvitationRevokeModal
          isOpen={modal.open && modal.type === "revoke"}
          closeModal={handleCloseModal}
          email={modal.invitation?.email}
          revokeInvitation={() => handleRevokeInvit(modal.invitation)}
        />

        <InvitationResendModal
          isOpen={modal.open && modal.type === "resend"}
          closeModal={handleCloseModal}
          email={modal.invitation?.email}
          resendInvitation={() => handleResendInvit(modal.invitation)}
        />

        {isLoading && <Loading />}
      </section>
    </S.Wrapper>
  );
};

export default withReducers({
  environment: () => import("Reducers/environment"),
  environmentAccess: () => import("Reducers/environment/access"),
  invitation: () => import("Reducers/invitation"),
  project: () => import("Reducers/project")
})(EnvironmentAccessList);
