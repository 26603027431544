import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import AccessibleTooltip from "Components/AccessibleTooltip";

import * as S from "./TemplateItem.styles";

const TemplateItem = ({ template, onClick, openModal, modalOpen }) => {
  const intl = useIntl();

  const handleSelect = event => {
    event?.preventDefault();
    document.activeElement.blur();
    onClick();
  };

  const showDetails = event => {
    event?.stopPropagation();
    openModal();
    document.activeElement.blur();
  };

  const { id, image, name } = template.info;
  const tplId = id.replace(/\//gm, "-") || "";

  return (
    <S.Wrapper onClick={handleSelect}>
      <S.Content>
        <AccessibleTooltip
          onClick={handleSelect}
          onKeyUp={e => e.keyCode === 13 && onClick()}
          tooltipProps={{
            id: `template-${tplId}-tooltip`,
            disable: !(name.length > 20),
            children: name
          }}
        >
          <S.Name>{name}</S.Name>
        </AccessibleTooltip>
        <S.DetailsLink
          onClick={showDetails}
          onKeyDown={e => {
            if (e.keyCode === 13 && !modalOpen) {
              openModal();
            }
          }}
          role="link"
          tabIndex="0"
        >
          {intl.formatMessage({ id: "setup.type.template.details" })}
        </S.DetailsLink>
      </S.Content>

      <S.Logo>
        <img alt={`${name} logo`} src={image} />
      </S.Logo>
    </S.Wrapper>
  );
};

TemplateItem.propTypes = {
  stack: PropTypes.object,
  template: PropTypes.object,
  onClick: PropTypes.func,
  openModal: PropTypes.func,
  modalOpen: PropTypes.bool
};

export default TemplateItem;
