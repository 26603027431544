import styled, { css } from "styled-components";

import { themeHelpers } from "Libs/theme";

import Priority from "../../../../common/components/Priority";

export const StyledPriority = styled(Priority)`
  font-size: 14px;
  line-height: 24px;
`;

export const Layout = styled.div`
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 24px;
  padding-left: 16px;
  background-color: transparent;
  transition: background-color 0.1s ease-in;
  cursor: pointer;

  ${props =>
    props.isActive &&
    css`
      background-color: #e7f0ff;
      border-radius: 2px;
      ${StyledPriority} {
        color: ${({ theme }) => theme.primary};
      }
    `};

  :hover {
    background: ${props => themeHelpers(props.theme, "linkHover")};
    border-radius: 2px;
  }
`;

export const Description = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.sectionText};
`;
