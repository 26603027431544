import styled from "styled-components";

import InlineBanner from "Components/InlineBanner/InlineBanner";

export const HeaderWrapper = styled.div`
  padding-top: 25px;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  h2 {
    margin-top: ${props => (props.hasBackButton ? "0" : "15px")};
  }
`;

export const Banner = styled(InlineBanner)`
  display: flex;
  padding: 12px 16px 12px 32px;
  p {
    flex: 1;
    margin: 5px 10px 0 0;
  }
`;
