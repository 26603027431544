import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
import ContentLayout from "Components/ContentLayout";
import SideNavBar from "Components/SideNavBar";

const EnvironmentSettingsLayout = styled.div`
  width: 100%;
  background: transparent;
  > div {
    background: transparent;
  }
`;

const EnvironmentSettingsPage = styled.div`
  .settings-content {
    width: 100%;
    box-sizing: border-box;
  }
  @media (min-width: 600px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .settings-content {
      width: calc(100% - 200px);
      padding-left: 32px;
    }
  }
  @media (min-width: 1280px) {
    .settings-content {
      width: calc(100% - 274px);
    }
  }
`;

const EnvironmentSettings = ({ children, entries = [] }) => {
  const { organizationId, projectId, environmentId } = useDecodedParams();
  const { pathname } = useLocation();

  const tabs = useMemo(
    () => {
      const envId = encodeURIComponent(environmentId);
      const basePath = `/${organizationId}/${projectId}/${envId}`;

      return [
        {
          name: "general",
          route: `${basePath}/settings`,
          permission: "#edit"
        },
        {
          name: "access",
          route: `${basePath}/settings/access`,
          permission: "#manage-access"
        },
        {
          name: "variables",
          route: `${basePath}/settings/variables`,
          permission: "#manage-variables"
        }
      ].filter(elt => entries.includes(elt.name));
    },
    [organizationId, projectId, environmentId, entries]
  );

  return (
    <ContentLayout className="settings-wrapper">
      <EnvironmentSettingsPage>
        <SideNavBar currentPathName={pathname} tabs={tabs} />
        <EnvironmentSettingsLayout className="settings-content">
          {children}
        </EnvironmentSettingsLayout>
      </EnvironmentSettingsPage>
    </ContentLayout>
  );
};

EnvironmentSettings.propTypes = {
  children: PropTypes.node,
  entries: PropTypes.array
};

export default EnvironmentSettings;
