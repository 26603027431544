import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Pane from "../../../../common/components/Pane";
import Header from "../../../../common/components/Header";
import Attachment from "../../../../common/components/Attachment";
import AttachmentList from "../../../../common/components/AttachmentList";

import UserDetails from "../../containers/UserDetails";

import BodyText from "../BodyText";

import { Subject, UserInfo, Date } from "./styles";

const Description = ({ subject, description, userId, date, attachments }) => (
  <Pane>
    <Header>
      <FormattedMessage id="tickets.view.description" />
    </Header>

    <Subject>{subject}</Subject>

    <UserInfo>
      <UserDetails userId={userId} />
      <Date data-test="description-date">
        {moment(date).format("h:mm a, MMMM D, YYYY")}
      </Date>
    </UserInfo>

    <BodyText>{description}</BodyText>

    {attachments && (
      <AttachmentList>
        {attachments.map(({ filename, url }) => (
          <Attachment
            as="a"
            target="_blank"
            href={url}
            key={url}
            hasPaperclip
            name={filename}
          />
        ))}
      </AttachmentList>
    )}
  </Pane>
);

Description.propTypes = {
  subject: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      filename: PropTypes.string,
      contentType: PropTypes.string
    })
  ),
  userId: PropTypes.string
};

export default Description;
