import styled from "styled-components";
import Heading2 from "Components/styleguide/Heading2";

export const Wrapper = styled.header`
  display: flex;
  margin-bottom: 24px;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 16px;
  }

  ${Heading2} {
    margin: 0 16px 0 0;
  }
`;

export const Kind = styled.span`
  padding: 3px 8px;
  background: ${props => props.theme.orderBg};
  border-radius: 2px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.theme.label};
`;
