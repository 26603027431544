import styled, { css } from "styled-components";

export const Line = styled.path`
  stroke-linejoin: round;
  stroke-linecap: round;
  shape-rendering: geometricPrecision;
  stroke-width: 1.5;
  fill: none;
  stroke: ${({ id }) => `url(#host-fire-${id})`};
  opacity: 1;
  cursor: pointer;

  ${({ isAverage, id }) =>
    isAverage &&
    css`
      stroke: url(#average-fire-${id});
    `};

  ${({ anyActive, isActive }) =>
    anyActive &&
    !isActive &&
    css`
      opacity: 0.3;
    `};
`;

export const OverCommitArea = styled.path`
  mask: url(#mask-${({ chartId }) => chartId});
  fill: url(#overcommit-${({ chartId }) => chartId});
  pointer-events: none;
`;
