import React from "react";
import PropTypes from "prop-types";
import momentjs from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { formatAsCurrency } from "Libs/utils";
import useMediaQuery from "Hooks/useMediaQuery";
import ChevronIcon from "Icons/ChevronIcon";
import {
  VoucherSS,
  VoucherHeaderSS,
  CodeSS,
  VoucherHeaderItemSS,
  NoOrdersSS,
  VoucherPanelSS,
  VoucherPanelSectionSS,
  VoucherPanelTableHeaderSS,
  OrderStatusSS,
  VoucherMoneyPanelSS,
  VoucherHeaderChevronWrapperSS
} from "./VouchersList.styles";

const formatDate = date => {
  return momentjs(date).format("HH:MM, DD/MM/YYYY");
};

function VouchersList({ vouchers }) {
  const [expanded, setExpanded] = React.useState(null);

  const toggleExpanded = code => {
    expanded === code ? setExpanded(null) : setExpanded(code);
  };

  return vouchers.map(voucher => (
    <Voucher
      key={voucher.code}
      isExpanded={expanded === voucher.code}
      toggleExpanded={() => {
        toggleExpanded(voucher.code);
      }}
      voucher={voucher}
    />
  ));
}

VouchersList.propTypes = {
  vouchers: PropTypes.arrayOf(PropTypes.object)
};

function Voucher({ voucher, isExpanded, toggleExpanded }) {
  const renderMoneyDetailIn = useMediaQuery("(min-width: 48rem)")
    ? "header"
    : "panel";

  return (
    <VoucherSS isExpanded={isExpanded}>
      <VoucherHeader
        expanded={isExpanded}
        toggleExpanded={toggleExpanded}
        voucher={voucher}
        shouldRenderVoucherMoney={renderMoneyDetailIn === "header"}
      />
      {isExpanded && (
        <VoucherPanel
          voucher={voucher}
          shouldRenderVoucherMoney={renderMoneyDetailIn === "panel"}
        />
      )}
    </VoucherSS>
  );
}

Voucher.propTypes = {
  voucher: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleExpanded: PropTypes.func
};

function VoucherHeader({
  voucher,
  toggleExpanded,
  idx,
  expanded,
  shouldRenderVoucherMoney
}) {
  const hasOrdersApplied = voucher.orders.length > 0;
  const isExpandable = hasOrdersApplied || !shouldRenderVoucherMoney;

  function handleClick() {
    isExpandable && toggleExpanded();
  }

  function handleKeyPress(e) {
    isExpandable && e.key === "Enter" && toggleExpanded;
  }

  return (
    <VoucherHeaderSS
      tabIndex={idx}
      role="button"
      active={expanded}
      clickable={isExpandable}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
    >
      <CodeSS>
        Code <strong>{voucher.code}</strong>
      </CodeSS>
      {shouldRenderVoucherMoney && (
        <VoucherMoneyDetail voucher={voucher} smHidden />
      )}
      <VoucherHeaderItemSS sm-hidden>
        {!hasOrdersApplied && (
          <NoOrdersSS>
            <FormattedMessage id="no_orders_applied" />
          </NoOrdersSS>
        )}
      </VoucherHeaderItemSS>
      {isExpandable && (
        <VoucherHeaderChevronWrapperSS>
          <ChevronIcon animate hideArrow={!isExpandable} isOpen={expanded} />
        </VoucherHeaderChevronWrapperSS>
      )}
    </VoucherHeaderSS>
  );
}

VoucherHeader.propTypes = {
  voucher: PropTypes.object,
  toggleExpanded: PropTypes.func,
  expanded: PropTypes.bool,
  shouldRenderVoucherMoney: PropTypes.bool,
  idx: PropTypes.number
};

function VoucherMoneyDetail({ voucher, smHidden }) {
  return (
    <>
      <VoucherHeaderItemSS sm-hidden={smHidden}>
        Credit{" "}
        <strong>{formatAsCurrency(voucher.amount, voucher.currency)}</strong>
      </VoucherHeaderItemSS>
      <VoucherHeaderItemSS sm-hidden={smHidden}>
        Balance{" "}
        <strong>
          {formatAsCurrency(voucher.remaining_balance, voucher.currency)}
        </strong>
      </VoucherHeaderItemSS>
    </>
  );
}

VoucherMoneyDetail.propTypes = {
  voucher: PropTypes.object,
  smHidden: PropTypes.bool
};

function VoucherPanel({ voucher, shouldRenderVoucherMoney }) {
  const intl = useIntl();
  return (
    <VoucherPanelSS>
      {shouldRenderVoucherMoney && (
        <VoucherMoneyPanelSS>
          <VoucherMoneyDetail voucher={voucher} />
        </VoucherMoneyPanelSS>
      )}

      <VoucherPanelTableHeaderSS>
        <span>Order number</span>
        <span>Credit used to date</span>
        <span>Order date</span>
        <span>Order status</span>
      </VoucherPanelTableHeaderSS>

      {voucher.orders.map(order => (
        <VoucherPanelSectionSS key={order.order_id}>
          <header>{order.status}</header>
          <main>
            <span>{order.order_id}</span>
            <span>
              {formatAsCurrency(order.order_discount, voucher.currency)}
            </span>
            <span>{formatDate(order.billing_period_start)}</span>
            <OrderStatusSS>
              {intl.formatMessage({ id: `order_status.${order.status}` })}
            </OrderStatusSS>
          </main>
        </VoucherPanelSectionSS>
      ))}
    </VoucherPanelSS>
  );
}

VoucherPanel.propTypes = {
  voucher: PropTypes.object,
  shouldRenderVoucherMoney: PropTypes.bool
};

export default VouchersList;
