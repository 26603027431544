import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import ThirdParties from "../../third_parties";

import { DOCS_INTEGRATIONS_CONFIGURING_SCRIPTS } from "Constants/documentationUrls";
import { initForm } from "Reducers/integration";
import useInput from "Hooks/useInput";
import { capitalize } from "Libs/utils";

import Button from "UI/Button";
import CheckboxField from "Components/fields/CheckboxField";
import IdeField from "Components/fields/IdeField";
import Error from "Components/Error";
import InputField from "Components/fields/InputField";
import TextAreaField from "Components/fields/TextAreaField";
import Heading2 from "Components/styleguide/Heading2";

import IntegrationIcon from "../IntegrationIcon";

import * as S from "./IntegrationForm.styles";

const getFields = (list, parentId) => {
  return list
    .reduce((acc, cu) => {
      const obj = Object.assign({}, cu);
      if (parentId) {
        obj.id = `${parentId}/${cu.id}`;
      }

      if (cu.type === "object") {
        acc.push(getFields(cu.fields, obj.id));
      } else {
        acc.push(obj);
      }
      return acc;
    }, [])
    .flat();
};

const getValue = (path, data = []) => {
  const entries = path.split("/");
  const key = entries.shift();

  const value = data[key] || "";
  if (entries.length) {
    return getValue(entries.join("/"), value);
  }
  return value;
};

const IntegrationForm = ({ onCancel, onRemove, onSubmit, integration }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const params = useParams();

  useEffect(() => {
    dispatch(initForm({ id: integration?.id }));
  }, []);

  const type = integration ? integration.type : params.type;
  const config = ThirdParties.find(
    elt => elt.type === type.replace("health_", "health.")
  );

  const fields = getFields(config?.fields);

  const getInitialValues = () => {
    if (!config) return {};
    const data = integration?.data || [];

    const initialValues = fields?.reduce((acc, cu) => {
      const fieldValue = getValue(cu.id, data) || "";

      if (cu.type === "array") {
        acc[cu.id] = Array.isArray(fieldValue)
          ? fieldValue.join(cu.separator ? cu.separator : " ")
          : fieldValue;
      } else {
        acc[cu.id] = fieldValue;
      }
      return acc;
    }, {});
    const initialOptions = config.options?.reduce((acc, cu) => {
      acc[cu] = data[cu] || false;
      return acc;
    }, {});

    return Object.assign({}, initialValues, initialOptions);
  };

  const { inputs, handleInputChange, initialValues } = useInput(
    getInitialValues()
  );

  const loading = useSelector(state =>
    state.integration?.get("loading", false)
  );
  const errors = useSelector(state => state.integration?.get("errors", ""));

  if (!config) return <p>Error</p>;

  const handleSubmit = e => {
    e.preventDefault();
    if (canSubmit()) onSubmit(formatData(), type);
  };

  const handleCancel = e => {
    e.preventDefault();
    onCancel();
  };

  const handleRemove = e => {
    e.preventDefault();
    onRemove();
  };

  const canSubmit = () => {
    if (initialValues === inputs) return false;
    for (const field of fields) {
      // sensitive fields are not return by the api
      if (integration && field.sensitive === true) continue;
      if (!!field.required && !inputs[field.id]) return false;
    }
    return true;
  };

  const formatData = () => {
    return Object.entries(inputs).reduce((acc, [key, value]) => {
      const field = fields.find(field => field.id === key);
      let formattedValue = value;

      // Add default value if field is empty
      if (formattedValue === "" && field?.default)
        formattedValue = field.default;

      // Transform string to  array
      if (field?.type === "array") {
        formattedValue = formattedValue
          .split(field.separator ? field.separator : " ")
          .map(elt => elt.trim())
          .filter(elt => elt); // remove empty element
      }

      key.split("/").reduce((acc1, cu, i, arr) => {
        if (formattedValue !== "") {
          return acc1[cu] || (acc1[cu] = arr[i + 1] ? {} : formattedValue);
        }
      }, acc);

      return acc;
    }, {});
  };

  const getIntlKey = key => {
    if (intl.messages[`integration.configure.${type}.${key}`] !== undefined)
      return `integration.configure.${type}.${key}`;
    return `integration.configure.${key}`;
  };

  const hasKey = key => {
    return intl.messages[`integration.configure.${key}`] !== undefined;
  };

  const getFieldComp = field => {
    if (field.type === "array" || field.kind === "textarea")
      return TextAreaField;
    if (field.kind === "ide") return IdeField;
    return InputField;
  };

  return (
    <S.Form onSubmit={onSubmit} disabled={loading}>
      <S.Header>
        <IntegrationIcon type={config.type} />
        <Heading2>
          {intl.formatMessage({
            id: `integration.type.${config.type}`,
            defaultMessage: type
          })}
        </Heading2>
        <p>
          {intl.formatMessage(
            {
              id: getIntlKey("intro"),
              defaultMessage: "Intro"
            },
            {
              b: (...chunks) => <b>{chunks}</b>, // eslint-disable-line react/display-name
              br: <br />,
              // eslint-disable-next-line react/display-name
              a: (...chunks) => (
                <a href={config.link} target="_blank">
                  {chunks}
                </a>
              )
            }
          )}
        </p>
      </S.Header>
      {errors &&
        (errors[""] || typeof errors === "string") && (
          <Error>{errors[""] || errors}</Error>
        )}

      {fields?.map(field => {
        const FieldComp = getFieldComp(field);
        return (
          <S.Field
            key={`field-${field.id}`}
            className={field.kind === "ide" ? "large" : ""}
          >
            <FieldComp
              id={field.id}
              name={field.id}
              label={intl.formatMessage({
                id: getIntlKey(`field.${field.id}.label`),
                defaultMessage: field.id
              })}
              placeholder={intl.formatMessage({
                id: getIntlKey(`field.${field.id}.placeholder`),
                defaultMessage: field.id
              })}
              required={!!field.required}
              value={inputs[field.id]}
              error={errors[field.id]}
              onChange={handleInputChange}
              options={{ lineNumbers: false }}
              expandable={true}
              height="280px"
            />
            {hasKey(`field.${field.id}.info.title`) && (
              <S.Info
                title={intl.formatMessage({
                  id: getIntlKey(`field.${field.id}.info.title`)
                })}
                text={intl.formatMessage({
                  id: getIntlKey(`field.${field.id}.info.text`)
                })}
                linkText={intl.formatMessage({
                  id: "learnmore"
                })}
                to={DOCS_INTEGRATIONS_CONFIGURING_SCRIPTS}
              />
            )}
          </S.Field>
        );
      })}

      <S.Options>
        {!integration &&
          config.options && (
            <p>
              {intl.formatMessage({
                id: "integration.configure.options",
                defaultMessage:
                  "Choose how your project should respond to repository activity."
              })}
            </p>
          )}
        {config.options?.map(option => (
          <CheckboxField
            key={`options.${option}`}
            onChange={handleInputChange}
            value={inputs[option]}
            label={intl.formatMessage(
              {
                id: getIntlKey(`option.${option}`),
                defaultMessage: option
              },
              {
                i: (...chunks) => <i>{chunks}</i> // eslint-disable-line react/display-name
              }
            )}
            forId={option}
            error={errors[option]}
          />
        ))}
      </S.Options>

      <S.Footer>
        <Button
          id="save"
          onClick={handleSubmit}
          aria-label={intl.formatMessage({
            id: integration ? "save" : "integration.add"
          })}
          type="submit"
          disabled={!canSubmit() || loading}
        >
          {capitalize(
            intl.formatMessage({
              id: integration ? "save" : "integration.add"
            })
          )}
        </Button>
        {onCancel && (
          <Button
            id="cancel"
            variant="secondary"
            onClick={handleCancel}
            disabled={loading}
            aria-label={intl.formatMessage({ id: "cancel" })}
          >
            {capitalize(intl.formatMessage({ id: "cancel" }))}
          </Button>
        )}
        {onRemove && (
          <Button
            id="remove"
            variant="tertiary"
            onClick={handleRemove}
            disabled={loading}
            aria-label={intl.formatMessage({ id: "remove" })}
          >
            {capitalize(intl.formatMessage({ id: "remove" }))}
          </Button>
        )}
      </S.Footer>
    </S.Form>
  );
};

IntegrationForm.propTypes = {
  integration: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onRemove: PropTypes.func
};

export default IntegrationForm;
