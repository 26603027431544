import React from "react";
import { useParams } from "react-router-dom";

import ApiTokenList from "./containers/ApiTokenList";

import ContentLayout from "Components/ContentLayout";

const Page = () => {
  const { username } = useParams();
  return (
    <ContentLayout>
      <ApiTokenList username={username} />
    </ContentLayout>
  );
};

export default Page;
