import { fromJS, Map } from "immutable";

import logger from "Libs/logger";

const LOAD_ORDER_START = "app/orders/load_order_start";
const LOAD_ORDER_SUCCESS = "app/orders/load_order_success";
const LOAD_ORDER_FAILURE = "app/orders/load_order_failure";

const LOAD_ORDERS_START = "app/orders/load_orders_start";
const LOAD_ORDERS_SUCCESS = "app/orders/load_orders_success";
const LOAD_ORDERS_FAILURE = "app/orders/load_orders_failure";

const LOAD_RECURRING_ORDER_START = "app/orders/load_recurring_order_start";
const LOAD_RECURRING_ORDER_SUCCESS = "app/orders/load_recurring_order_success";
const LOAD_RECURRING_ORDER_FAILURE = "app/orders/load_recurring_order_failure";

export const loadOrders = orgId => {
  return async dispatch => {
    dispatch({
      type: LOAD_ORDERS_START
    });
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const orders = await client.getOrders();
      const ordersGrouped = orders.reduce((groupedOrders, order) => {
        if (order.status === "recurring_open") {
          return groupedOrders.setIn(["recurring", order.id], order);
        } else {
          return groupedOrders.setIn(["previous", order.id], order);
        }
      }, new Map());

      dispatch({
        type: LOAD_ORDERS_SUCCESS,
        payload: ordersGrouped,
        meta: {
          orgId
        }
      });
    } catch (err) {
      if (![404, 403].includes(err.code)) {
        logger(err, {
          action: "loadOrders",
          orgId
        });
      }
      dispatch({
        type: LOAD_ORDERS_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const loadOrder = orderId => {
  return async dispatch => {
    dispatch({
      type: LOAD_ORDER_START,
      meta: { orderId }
    });
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const order = await client.getOrder(orderId);

      dispatch({
        type: LOAD_ORDER_SUCCESS,
        payload: order,
        meta: { orderId }
      });
    } catch (err) {
      if (![404, 403].includes(err.code)) {
        logger(err, {
          action: "loadOrders",
          orderId
        });
      }
      dispatch({
        type: LOAD_ORDER_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const loadRecurringOrder = orgId => {
  return async dispatch => {
    dispatch({
      type: LOAD_RECURRING_ORDER_START
    });
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const orders = await client.getOrders(orgId, ["recurring_open"]);

      dispatch({
        type: LOAD_RECURRING_ORDER_SUCCESS,
        payload: orders[0],
        meta: {
          orgId
        }
      });
    } catch (err) {
      if (![404, 403].includes(err.code)) {
        logger(err, {
          action: "loadOrders",
          orgId
        });
      }
      dispatch({
        type: LOAD_RECURRING_ORDER_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export default function orderReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_ORDERS_START:
      return state.set("loading", true);
    case LOAD_ORDER_START:
      return state.setIn(["byId", action.meta.orderId, "loading"], true);
    case LOAD_RECURRING_ORDER_START:
      return state.setIn(["recurring", "loading"], true);
    case LOAD_ORDERS_SUCCESS:
      return state
        .setIn(["data", action.meta.orgId], fromJS(action.payload))
        .set("loading", false);
    case LOAD_ORDER_SUCCESS:
      return state
        .setIn(["byId", action.meta.orderId, "data"], fromJS(action.payload))
        .setIn(["byId", action.meta.orderId, "loading"], false);
    case LOAD_RECURRING_ORDER_SUCCESS:
      return state
        .setIn(["recurring", "data"], fromJS(action.payload))
        .setIn(["recurring", "loading"], false);
    case LOAD_ORDER_FAILURE:
    case LOAD_ORDERS_FAILURE:
      return state.set("loading", false).set("errors", action.payload);
    case LOAD_RECURRING_ORDER_FAILURE:
      return state
        .setIn(["recurring", "loading"], false)
        .setIn(["recurring", "errors"], action.payload);
    default:
      return state;
  }
}
