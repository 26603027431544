import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import momentTZ from "moment-timezone";

import { capitalize, getRegionLabel } from "Libs/utils";

import { DOCS_CONFIG_APP_TIMEZONE } from "Constants/documentationUrls";

import Button from "UI/Button";
import InputField from "Components/fields/InputField";
import Dropdown from "Components/Dropdown";

import * as S from "./styles";

const ProjectBasicForm = ({ closeForm, onChange, onSave, project }) => {
  const [name, setName] = useState(project.title);
  const [timezone, setTimezone] = useState(project.data?.timezone);
  const [isChanged, setIsChanged] = useState(false);

  const intl = useIntl();

  const onNameChange = e => {
    setName(e.target.value);
    setIsChanged(true);
    onChange();
  };

  const onTimezoneChange = tz => {
    setTimezone(tz.value);
    setIsChanged(true);
    onChange();
  };

  const formattedRegion = getRegionLabel(project.region_label);

  const timezoneList = momentTZ.tz.names().map(tz => {
    return { label: tz, value: tz };
  });

  return (
    <S.Wrapper>
      <S.Form aria-label="Project settings">
        <InputField
          onChange={onNameChange}
          id="project-name"
          label={intl.formatMessage({
            id: "project.settings.basic.form.name",
            defaultMessage: "Project name"
          })}
          value={name}
        />
        <S.FakeInput>
          <div>
            <label>
              {intl.formatMessage({
                id: "project.settings.basic.form.region",
                defaultMessage: "Region"
              })}
            </label>
            <div>
              {formattedRegion.title} <span>{formattedRegion.suffix}</span>
            </div>
          </div>
        </S.FakeInput>
        <S.Timezone>
          <Dropdown
            label={intl.formatMessage({
              id: "project.settings.basic.form.timezone.label",
              defaultMessage: "Timezone"
            })}
            options={timezoneList}
            defaultValue={{
              label: project.data?.timezone,
              value: project.data?.timezone
            }}
            searchable
            onChange={onTimezoneChange}
            clearable={false}
            fieldType={true}
            required={true}
            defaultText={intl.formatMessage({
              id: "project.settings.basic.form.timezone.placeholder",
              defaultMessage: "Select timezone"
            })}
          />
          <S.Info
            text={intl.formatMessage({
              id: "project.settings.basic.form.timezone.info",
              defaultMessage:
                "The timezone is used to trigger crons, maintenance windows and to run automated backups."
            })}
            to={DOCS_CONFIG_APP_TIMEZONE}
            linkText={intl.formatMessage({
              id: "learnmore"
            })}
          />
        </S.Timezone>

        {isChanged && (
          <S.ButtonsWrapper>
            <Button
              id="save_project_general_settings_button"
              type="submit"
              aria-label={intl.formatMessage({ id: "save" })}
              onClick={() => onSave({ title: name, timezone: timezone })}
            >
              {capitalize(intl.formatMessage({ id: "save" }))}
            </Button>
            <Button
              id="cancel-project-general-btn"
              type="button"
              variant="secondary"
              aria-label={intl.formatMessage({ id: "cancel" })}
              onClick={closeForm}
            >
              {capitalize(intl.formatMessage({ id: "cancel" }))}
            </Button>
          </S.ButtonsWrapper>
        )}
      </S.Form>
    </S.Wrapper>
  );
};

ProjectBasicForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

export default ProjectBasicForm;
