import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";

export const Subject = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.sectionHeading};
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Date = styled.span`
  font-family: Open Sans;
  font-style: normal;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 18px;
  color: #98a0ab;
`;
