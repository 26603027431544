import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button } from "@platformsh/ui-kit";

import Calendar from "Icons/IconCalendar";
import { DOCS_ENV_REDEPLOY } from "Constants/documentationUrls";

import {
  Grid as MetricsGrid,
  Layout as MetricsLayout
} from "../../../../metrics/containers/Service/Service.styles";

import * as S from "./ServiceMetrics.styles";
import useService from "../../../../metrics/hooks/useService";
import webappsSelector from "../../../../metrics/utils/webappsSelector";

const ServiceMetrics = ({ name }) => {
  const [data, isLoading, range, kind] = useService(name);
  const webapps = useSelector(webappsSelector);

  const hasData = Object.keys(data).length > 0;

  if (kind === "grid" || kind === "dedication") {
    return null;
  }

  return (
    <S.Container>
      <S.Header>
        <S.Heading>
          <FormattedMessage id="metrics" />
        </S.Heading>

        <S.HeaderRight>
          <Button
            variant="link"
            as={Link}
            to={`../metrics?range=${range}hrs&service=${name}`}
          >
            <FormattedMessage id="view_metrics" />
          </Button>
          <S.Timeframe>
            <Calendar />
            <FormattedMessage
              id="metrics.pasthours"
              values={{ hours: range }}
            />
          </S.Timeframe>
        </S.HeaderRight>
      </S.Header>
      {isLoading ? (
        <S.ServiceMetricsSkeleton />
      ) : (
        <MetricsLayout>
          {!hasData ? (
            <S.EmptyMetrics>
              <FormattedMessage id="metrics.error.empty.paragraph" />{" "}
              <Button
                variant="link"
                as="a"
                target="_blank"
                href={DOCS_ENV_REDEPLOY}
              >
                <FormattedMessage id="learnmore" />
              </Button>
            </S.EmptyMetrics>
          ) : (
            <MetricsGrid>
              {Object.entries(data).map(
                ([metricName, metrics]) =>
                  metrics?.length && (
                    <S.ServiceMetric
                      key={metricName}
                      name={metricName}
                      value={metrics[metrics.length - 1]["average@value"]}
                      max={metrics[metrics.length - 1].max}
                      percentage={
                        metrics[metrics.length - 1]["average@percentage"]
                      }
                      data={metrics.map(value => ({
                        timestamp: value.timestamp,
                        value: isNaN(value["average@value"])
                          ? 0
                          : value["average@value"]
                      }))}
                      isLoading={isLoading}
                      kind={kind}
                      serviceKind={
                        webapps.includes(name) ? "Application" : "Service"
                      }
                    />
                  )
              )}
            </MetricsGrid>
          )}
        </MetricsLayout>
      )}
    </S.Container>
  );
};

ServiceMetrics.propTypes = {
  name: PropTypes.string
};

export default ServiceMetrics;
