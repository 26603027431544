import styled from "styled-components";

export const VoucherSS = styled.div`
  ${props => props.isExpanded && `box-shadow: ${props.theme.settingShadow}`};
`;

export const VoucherHeaderSS = styled.div`
  display: flex;
  padding: 20px;
  margin-bottom: ${props => (props.active ? "0" : "16px")};
  font-size: 13px;
  line-height: 22px;
  color: ${props => props.theme.subtitleText};
  background: ${props =>
    props.active ? props.theme.settingBarActive : props.theme.sectionBg};
  ${props => props.clickable && "cursor: pointer"};

  & strong {
    margin-left: 12px;
    color: ${props => props.theme.sectionText};
  }
`;

export const CodeSS = styled.span`
  flex-grow: 1;
  min-width: 35%;
  font-size: 15px;
  color: ${props => props.theme.sectionText};

  & strong {
    font-weight: 700;
  }
`;

export const VoucherHeaderItemSS = styled.span`
  ${props => props["sm-hidden"] && "display: none"};
  flex-grow: 1;

  @media (min-width: 48rem) {
    display: inline;
  }
`;

export const NoOrdersSS = styled.span`
  display: inline-block;
  padding: 4px 16px;
  line-height: 13px;
  color: ${props => props.theme.sectionText};
  background: ${props => props.theme.background};
  border-radius: 2px;
`;

export const VoucherPanelSS = styled.div`
  margin-bottom: 16px;
  background: ${props => props.theme.sectionBg};
`;

export const VoucherPanelSectionSS = styled.section`
  padding: 12px 0;
  margin: 0 24px;
  font-size: 14px;
  color: ${props => props.theme.sectionText};
  background: ${props => props.theme.sectionBg};
  border-top: 1px solid ${props => props.theme.tableBorder};

  header {
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;

    @media (min-width: 48rem) {
      display: none;
    }
  }

  main {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;

    span {
      flex-grow: 1;
      flex-basis: 25%;
    }
  }
`;

export const VoucherPanelTableHeaderSS = styled.div`
  display: none;

  @media (min-width: 48rem) {
    display: flex;
    padding: 12px 0;
    margin: 0 24px;
    font-size: 13px;
    color: ${props => props.theme.subtitleText};

    & span {
      flex-grow: 1;
      flex-basis: 25%;
    }
  }
`;

export const OrderStatusSS = styled.span`
  display: none;

  @media (min-width: 48rem) {
    display: block;
    text-transform: capitalize;
  }
`;

export const VoucherMoneyPanelSS = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  margin: 0 24px;
  font-size: 13px;
  color: ${props => props.theme.subtitleText};
  background: ${props => props.theme.sectionBg};

  & strong {
    margin-left: 12px;
    color: ${props => props.theme.sectionText};
  }
`;
