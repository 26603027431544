const getTimeUnit = seconds => {
  let unit = "hours";
  if (seconds === 1) {
    return "second";
  } else if (seconds === 60) {
    return "minute";
  } else if (seconds === 3600) {
    unit = "hour";
  } else if (seconds < 60) {
    unit = "seconds";
  } else if (seconds < 3600) {
    unit = "minutes";
  }

  return unit;
};

const getTimeValue = seconds => {
  if (seconds === 60 || seconds === 3600) {
    return 1;
  }

  if (seconds > 60 && seconds < 3600) {
    return seconds / 60;
  }

  if (seconds > 3600) {
    return seconds / 3600;
  }

  return seconds;
};

const formatSeconds = seconds => {
  const unit = getTimeUnit(seconds);
  const value = getTimeValue(seconds);

  if (value === 1) {
    return unit;
  }

  return `${value} ${unit}`;
};

export default formatSeconds;
