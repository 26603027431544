import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";

import { Label, Value } from "Components/LabeledInfo/styles";

export const Wrapper = styled.div`
  ${Value} {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    color: ${props => props.theme.sectionText};
  }

  ${Label} {
    font-size: 13px;
    ${semiBoldAlias};
    line-height: 19px;
    color: ${props => props.theme.envTreeInactiveIcon};
  }
`;
