import styled from "styled-components";

import CopyButton from "Components/CopyButton";

export const Container = styled.div`
  display: flex;
  padding: 5px 12px;
  background: ${props => props.theme.sectionBg};
  box-shadow: 0px 6px 44px rgba(152, 160, 171, 0.6);
  border-radius: 2px;
  margin-bottom: 36px;

  .icon-success-wrapper {
    margin-top: 6px;
  }
`;

export const MessageText = styled.div`
  font-size: 13px;
  line-height: 34px;
  padding-left: 12px;
`;

export const TokenValue = styled.code`
  margin: 6px 0 0 17px;
`;

export const Close = styled.span`
  margin-left: auto;
`;

export const Copy = styled(CopyButton)`
  margin-left: 15px;
`;
