import styled from "styled-components";

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.sectionText};
  margin-bottom: 96px;
`;
