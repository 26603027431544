import styled from "styled-components";

import CloseBtn from "Components/CloseBtn";

export const Wrapper = styled.section`
  position: relative;
  margin: 24px 0 42px 0;
  padding: 32px;
  background: ${props => props.theme.sectionBg};
  border-radius: 2px;

  h2 {
    margin-bottom: 8px;
  }
  p {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    color: ${props => props.theme.sectionTextLight};
  }
`;

export const CloseButton = styled(CloseBtn)`
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  right: 24px;
  position: absolute;
  top: 24px;
  cursor: pointer;
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
`;
