import { fromJS, Map } from "immutable";

import { push } from "Reducers/app";
import logger from "Libs/logger";

const LOAD_BRANCH_START = "app/environmentBranch/load_branch_start";
const LOAD_BRANCH_SUCCESS = "app/environmentBranch/load_branch_success";
const LOAD_BRANCH_FAILURE = "app/environmentBranch/load_branch_failure";

export const branch = (
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId,
  options
) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_BRANCH_START });

    try {
      const environment = getState().environment.getIn(
        [
          "data",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId
        ],
        {}
      );

      const branch = await environment.branch(options.branchName, options.type);

      dispatch({
        type: LOAD_BRANCH_SUCCESS,
        payload: branch,
        meta: {
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId
        }
      });

      dispatch(
        push("/:organizationDescriptionId/:projectDescriptionId/:branchName", {
          organizationDescriptionId,
          projectDescriptionId,
          branchName: encodeURIComponent(branch.parameters.environment)
        })
      );
    } catch (err) {
      logger(err, {
        action: "branch",
        payload: branch,
        meta: {
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId
        }
      });
      dispatch({
        type: LOAD_BRANCH_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export default function environmentBranchReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_BRANCH_START:
      return state.set("loading", true).remove("errors");
    case LOAD_BRANCH_SUCCESS:
      return state
        .set("loading", false)
        .setIn(
          [
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.meta.environmentDescriptionId
          ],
          fromJS(action.payload)
        )
        .remove("errors");
    case LOAD_BRANCH_FAILURE:
      return state.set("loading", false).set("errors", fromJS(action.payload));
    default:
      return state;
  }
}
