import React from "react";
import { useLocation } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
import ServiceConfiguration from "./containers/ServiceConfiguration";
import ServiceInfoPanel from "../containers/ServiceInfoPanel";

const Configuration = () => {
  const params = useDecodedParams();
  const location = useLocation();

  return (
    <ServiceInfoPanel currentPathName={location.pathname} {...params}>
      <ServiceConfiguration />
    </ServiceInfoPanel>
  );
};

Configuration.propTypes = {};

export default Configuration;
