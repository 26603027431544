import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Map } from "immutable";

import { entities } from "Libs/platform";
import BasicFormSettings from "./components/BasicFormSettings";
import Error from "Components/LargeInlineError";
import styled from "styled-components";

import {
  clearErrors,
  pictureError,
  loadUserProfile,
  updateUserProfile
} from "Reducers/profile";

const ErrorBox = styled.div`
  max-width: 100%;
  padding-right: 24px;
`;

const BasicSettingsContainer = ({ username }) => {
  const [newOrganizationId, setNewOrganizationId] = useState(null);
  const [apiErrors, setApiErrors] = useState();
  const dispatch = useDispatch();
  const {
    me,
    emailReset,
    errors,
    profile,
    cacheKey,
    isLoadingProfile
  } = useSelector(({ app, profile }) => ({
    me: app.get("me", new Map()).toJS(),
    emailReset: profile.get("emailReset"),
    errors: profile.get("errors"),
    profile: profile.getIn(["data", username], new Map()).toJS(),
    isLoadingProfile: profile.get("loading"),
    cacheKey: profile.getIn(["cacheKeys", username], null)
  }));

  useEffect(
    () => {
      let isCanceled = false;
      (async () => {
        const shouldLoadOtherUser = me.username !== username;
        if (shouldLoadOtherUser) {
          try {
            const otherUser = await entities.AuthUser.getUserByUsername(
              username
            );

            dispatch(loadUserProfile(otherUser.id));
          } catch (error) {
            if (isCanceled) {
              return;
            }
            setApiErrors(JSON.parse(error));
          }
        }

        return () => (isCanceled = true);
      })();
    },
    [username, profile.id]
  );

  useEffect(
    () => {
      if (!isLoadingProfile && newOrganizationId && !errors) {
        setNewOrganizationId(null);
        window.location = `/-/users/${newOrganizationId}/settings`;
      }
    },
    [isLoadingProfile, newOrganizationId]
  );

  const onSave = async data => {
    let newProfile = data;
    if (data.first_name || data.last_name) {
      newProfile = {
        ...newProfile,
        display_name: `${data.first_name ||
          profile.first_name} ${data.last_name || profile.last_name}`
      };
    }
    dispatch(updateUserProfile(profile.id, newProfile));
    setNewOrganizationId(data.username !== username ? data.username : null);
  };

  let errorMessage =
    errors?.message ||
    errors?.title ||
    apiErrors?.title ||
    JSON.stringify(errors);

  if (errorMessage?.includes("exceeding the maximum file size")) {
    errorMessage = (
      <FormattedMessage
        id="settings.profile.maxfilesizeerror"
        values={{ name: errors.name, size: (errors.size / 1000000).toFixed(2) }}
      />
    );
  }

  return (
    <>
      {errorMessage && (
        <ErrorBox>
          <Error onClose={() => dispatch(clearErrors())}>{errorMessage}</Error>
        </ErrorBox>
      )}
      <BasicFormSettings
        onSave={onSave}
        className="organization-basic-form"
        {...profile}
        emailReset={emailReset}
        cacheKey={cacheKey}
        onPictureError={(error, picture) =>
          dispatch(pictureError(error, picture))
        }
      />
    </>
  );
};

BasicSettingsContainer.propTypes = {
  username: PropTypes.string
};

export default BasicSettingsContainer;
