import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

import Button from "UI/Button";
import InputField from "Components/fields/InputField";

export const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
  &.add-form {
    background: ${props => themeHelpers(props.theme, "sectionColor")};
    border-radius: 0 0 4px 4px;
    margin-bottom: 16px;
  }
`;

export const FormWrapper = styled.form`
  padding: 32px;
  min-height: 200px;
  width: 100%;
`;

export const Description = styled.p`
  display: flex;
  margin: 0 0 25px 0;
  align-items: flex-start;
  font-size: 15px;
  line-height: 20px;
  color: ${props => props.theme.descriptionText};

  .info-dialog {
    margin: -2px 8px 0px -4px;
  }

  code {
    align-self: end;
  }
`;

export const DeleteButton = styled(Button)`
  margin-left: auto !important;
`;

export const InputWrapper = styled.div`
  position: relative;
  max-width: 264px;
  width: 100%;
`;

export const Info = styled.p`
  font-size: 15px;
  color: ${props => props.theme.sectionTextLight};
  margin-bottom: 24px;
`;

export const Input = styled(InputField)`
  width: 100%;
`;

export const Option = styled.div`
  label {
    margin: 0;
  }
`;
