import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Pane from "../../../../common/components/Pane";
import Header from "../../../../common/components/Header";
import Status from "../../../../common/components/Status";
import Priority from "../../../../common/components/Priority";

import IconLink from "Components/icons/IconLink";

import {
  Heading,
  FieldList,
  Field,
  Value,
  Label,
  RegionSuffix,
  URLPane,
  StyledLink
} from "./styles";

const TicketInfo = ({
  ticketId,
  status,
  projectId,
  region,
  environment,
  priority,
  url
}) => (
  <>
    <Pane>
      <Heading>
        <Header>
          <FormattedMessage
            id="tickets.view.info.header"
            values={{ ticketId }}
          />
        </Header>

        <Status status={status} />
      </Heading>

      <FieldList>
        <Field>
          <Value id="field-projectid">{projectId || "-"}</Value>
          <Label>
            <FormattedMessage id="tickets.view.info.projectId" />
          </Label>
        </Field>
        <Field>
          <Value id="field-region">
            {region ? (
              <>
                {region?.title}
                <RegionSuffix>{region?.suffix}</RegionSuffix>
              </>
            ) : (
              "-"
            )}
          </Value>
          <Label>
            <FormattedMessage id="tickets.view.info.region" />
          </Label>
        </Field>
        <Field>
          <Value id="field-environment">{environment || "-"}</Value>
          <Label>
            <FormattedMessage id="tickets.view.info.environment" />
          </Label>
        </Field>
        <Field>
          <Value>
            <Priority priority={priority} />
          </Value>
          <Label>
            <FormattedMessage id="tickets.view.info.priority" />
          </Label>
        </Field>
      </FieldList>
    </Pane>
    {url && (
      <URLPane id="url-pane">
        <IconLink />
        <StyledLink id="url-link" href={url}>
          {url}
        </StyledLink>
      </URLPane>
    )}
  </>
);

TicketInfo.propTypes = {
  ticketId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.string,
  projectId: PropTypes.string,
  region: PropTypes.shape({
    title: PropTypes.string,
    suffix: PropTypes.string
  }),
  environment: PropTypes.string,
  priority: PropTypes.string,
  url: PropTypes.string
};

export default TicketInfo;
