import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "immutable";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { LiveMessage } from "react-aria-live";

import withReducers from "Hocs/withReducers";
import useDecodedParams from "Hooks/useDecodedParams";

import {
  addVariable,
  deleteVariable,
  getVariables,
  updateVariable
} from "Reducers/project/settings/variable";

import { DOCS_CONFIG_APP_VARIABLES_URL } from "Constants/documentationUrls";

import { sortBy } from "Libs/utils";

import ButtonAdd from "Components/ButtonAdd";
import Loading from "Components/Loading";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";
import PageDescription from "Components/PageDescription";
import PageMeta from "Components/PageMeta";

import SettingLine from "Components/SettingLine";
import Empty from "../components/Empty";
import VariableLine from "../../../environment/settings/variables/components/VariableLine";
import VariableForm from "../../../../../common/components/VariableForm";

import * as S from "./ProjectVariables.styles";

export const encodeVariableId = id => {
  return id.replace(/\./g, "");
};

const ProjectVariables = ({ isNew = false, url }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizationId, projectId, variableId } = useDecodedParams();

  const [modal, setModal] = useState({ isOpen: false });
  const [isChanged, setIsChanged] = useState(false);

  const project = useSelector(({ project }) =>
    project?.getIn(["data", organizationId, projectId])
  );
  const projectVariable = useSelector(
    ({ projectVariable }) => projectVariable || Map()
  );
  const loading = projectVariable.get("loading", true);
  const status = projectVariable.get("status");
  const errors = projectVariable.get("errors", {});
  const variables = projectVariable
    .getIn(["data", organizationId, projectId], Map())
    ?.valueSeq()
    ?.toJS();

  useEffect(
    () => {
      if (["added", "updated", "deleted"].includes(status)) cancel();
    },
    [status]
  );

  useEffect(
    () => {
      dispatch(getVariables({ organizationId, projectId }));
    },
    [projectId]
  );

  useEffect(
    () => {
      if (isNew || loading) return;
      if (!variables.find(elt => encodeVariableId(elt.id) === variableId))
        cancel();
    },
    [variableId, loading]
  );

  const cancel = () => {
    history.replace(url);
  };

  const save = data => {
    setIsChanged(false);

    if (isNew) {
      dispatch(addVariable({ organizationId, project, data }));
    } else {
      const variable = variables.find(elt => elt.id === data.id);
      dispatch(
        updateVariable({
          organizationId,
          projectId,
          variable,
          data
        })
      );
    }
  };

  const handleCancel = () => {
    if (isChanged && !modal.isOpen) {
      setModal({ isOpen: true, type: "leave" });
      setIsChanged(true);
    } else {
      setIsChanged(false);
      cancel();
    }
  };

  const expand = id => {
    if (isNew && id === "new") return;
    if (!id || variableId === encodeVariableId(id)) {
      handleCancel();
    } else {
      if (isChanged && !modal.isOpen) {
        setModal({ isOpen: true, type: "leave", next: id });
        setIsChanged(true);
      } else {
        history.replace(
          `${url}/${
            id === "new" ? "-/new" : encodeURIComponent(encodeVariableId(id))
          }`
        );
        setIsChanged(false);
      }
    }
  };

  return (
    <S.Wrapper>
      <LiveMessage
        message={`${project?.title} project-level variables`}
        aria-live="polite"
      />
      <PageMeta title={`Variables | ${project?.title}`} />

      {variables?.length > 0 && (
        <ButtonAdd
          id="add-new-variable"
          css={{ float: "right" }}
          customText={intl.formatMessage({
            id: "settings.variables.add.button"
          })}
          onClick={() => expand("new")}
        />
      )}

      <S.Heading2 id="settings-heading">
        {intl.formatMessage({ id: "variables", defaultMessage: "variables" })}
      </S.Heading2>

      <PageDescription>
        <p>
          {intl.formatMessage(
            { id: "settings.project.variables.description" },
            {
              // eslint-disable-next-line react/display-name
              link: txt => (
                <a href={DOCS_CONFIG_APP_VARIABLES_URL} target="_blank">
                  {txt}
                </a>
              )
            }
          )}
        </p>

        <S.Note>
          {intl.formatMessage(
            { id: "settings.variables.note" },
            {
              dt: (...chunks) => <dt>{chunks}</dt>, // eslint-disable-line react/display-name
              dd: (...chunks) => <dd>{chunks}</dd>, // eslint-disable-line react/display-name
              code: (...chunks) => <code>{chunks}</code> // eslint-disable-line react/display-name
            }
          )}
        </S.Note>
      </PageDescription>

      <section aria-labelledby="settings-heading">
        {loading ? (
          <Loading />
        ) : (
          <>
            {isNew && (
              <SettingLine
                id={`project-variable-new`}
                isNew={true}
                addNewTitle={intl.formatMessage({
                  id: "settings.variables.add.title"
                })}
                isOpen={true}
                onClick={expand}
              >
                <VariableForm
                  key="var-new-form"
                  onSave={save}
                  onCancel={handleCancel}
                  errors={errors}
                  isLoading={status === "pending"}
                  isChanged={isChanged}
                  isChangedUpdate={() => setIsChanged(true)}
                />
              </SettingLine>
            )}

            {variables?.length === 0 && !isNew ? (
              <Empty onClick={() => expand("new")} />
            ) : (
              <>
                {sortBy(variables, "id").map(variable => (
                  <VariableLine
                    key={`var-${variable.id}`}
                    variable={variable}
                    isOpen={variableId === encodeVariableId(variable.id)}
                    expand={() => expand(variable.id)}
                  >
                    {variableId === variable.id && (
                      <VariableForm
                        key={`var-${variableId}-form`}
                        onSave={save}
                        onCancel={handleCancel}
                        onDelete={() =>
                          setModal({ isOpen: true, variable, type: "delete" })
                        }
                        errors={errors}
                        isLoading={status === "pending"}
                        variable={variable}
                        isChanged={isChanged}
                        isChangedUpdate={() => setIsChanged(true)}
                      />
                    )}
                  </VariableLine>
                ))}
              </>
            )}
          </>
        )}

        <ModalConfirmDelete
          isOpen={modal.isOpen && modal.type === "delete"}
          closeModal={() => setModal({ isOpen: false })}
          deleteFunction={() =>
            dispatch(
              deleteVariable({
                organizationId,
                projectId,
                variable: modal.variable
              })
            )
          }
          itemType="variable"
          itemName={modal.variable?.name}
          itemId={modal.variable?.name}
        />

        <ModalConfirmLeaveForm
          isOpen={modal.isOpen && modal.type === "leave"}
          closeModal={() => setModal({ isOpen: false })}
          continueFunction={() => expand(modal.next)}
          cancelFunction={() => setModal({ isOpen: false })}
        />
      </section>
    </S.Wrapper>
  );
};

ProjectVariables.propTypes = {
  isNew: PropTypes.bool,
  url: PropTypes.string.isRequired
};

export default withReducers({
  project: () => import("Reducers/project"),
  projectVariable: () => import("Reducers/project/settings/variable")
})(ProjectVariables);
