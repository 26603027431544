import styled from "styled-components";

export const Wrapper = styled.section`
  margin-top: 24px;
  p {
    margin: 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: ${props => props.theme.subNavText};
    & + p {
      margin-top: 16px;
    }
  }
  label {
    display: block;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: ${props => props.theme.sectionTextLight};
  }

  a {
    text-decoration: none;
  }

  dl {
    margin: 24px 0 0 0;
  }
  dt {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const SettingsLine = styled.dd`
  position: relative;
  margin: 0 0 8px 0;
  padding: 0 0 0 21px;
  font-size: 14px;
  line-height: 24px;
  color: ${props =>
    props.isActivate ? props.subNavText : props.theme.subNavText};
  opacity: ${props => (props.isActivate ? 1 : 0.5)};
  span {
    text-transform: capitalize;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  svg {
    position: absolute;
    left: -3px;
    top: 1px;
    & > path {
      fill: ${props => (props.isActivate ? "#4786FF" : props.theme.subNavText)};
    }
  }
`;

export const Tag = styled.span`
  display: inline-block;
  margin: 0 4px 0 0;
  padding: 2px 4px;
  font-family: "Overpass Mono", monospace;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.integrationTagColor};
  background: ${props => props.theme.integrationTagBg};
  border-radius: 2px;
`;

export const EmailRecipient = styled.span`
  color: ${({ theme }) => theme.primary};
`;
