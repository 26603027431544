import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

export const DisplayName = styled.div`
  display: flex;

  font-size: 15px;
  line-height: 24px;

  ${semiBoldAlias};

  color: ${props => props.theme.textLight};
`;

export const Tag = styled.div`
  margin: 0 4px 0 16px;
  padding: 2px 8px;
  border-radius: 2px;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 20px;
  background: ${props => props.theme.background};
  color: ${props => props.theme.textLight};
`;
