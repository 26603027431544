import styled, { keyframes } from "styled-components";
import {
  ShareIcon,
  SuccessIcon,
  SuccessIconBackground
} from "@platformsh/ui-kit";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Share = styled(ShareIcon)`
  animation: 0.2s linear 1 ${fadeIn};
`;
export const Success = styled(SuccessIcon)`
  animation: 0.4s linear 1 ${fadeIn};

  ${SuccessIconBackground} {
    fill: var(--icon-primary);
  }
`;
