import styled from "styled-components";

export const Layout = styled.div`
  background: ${props => props.theme.sectionBg};
  padding: 32px;
  min-width: 268px;
  max-height: 183px;
  flex: 0.5;
  border-radius: 4px;
`;

export const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 12px;
  color: ${props => props.theme.sectionHeading};
  ${props => props.withTopSpacing && "margin-top: 32px"};
  ${props =>
    props.border &&
    `
    border-${props.border}: 1px solid ${props.theme.sectionBorder};
    margin-${props.border}: 31px;
    padding-${props.border}: 30px;
  `};
`;

export const Text = styled.span`
  color: ${props => props.theme.sectionText};
  font-size: 14px;
  line-height: 22px;
  min-height: 44px;
`;

export const LearnMore = styled.div`
  margin-top: 32px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
