import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";

import Header from "../../../../common/components/Header";
import Pane from "../../../../common/components/Pane";
import Link from "../../../../common/components/Link";

export const URLPane = styled(Pane)`
  background-color: ${props =>
    props.theme.name === "contrast" ? "#302F45" : "#E7F0FF"};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    padding-top: 16px;
  }

  svg {
    margin-right: 8px;
    min-width: 24px;
  }
`;
export const StyledLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FieldList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Field = styled.div`
  flex: 50%;
  margin-bottom: 24px;
`;

export const Value = styled.div`
  font-style: normal;
  ${semiBoldAlias};
  font-size: 15px;
  line-height: 18px;
  color: ${props => props.theme.sectionHeading};
  margin-bottom: 8px;
`;

export const Label = styled.div`
  font-style: normal;
  ${semiBoldAlias};
  font-size: 14px;
  line-height: 19px;
  color: ${props => (props.theme.name === "contrast" ? "#4A495E" : "#98a0ab")};
`;

export const RegionSuffix = styled.span`
  color: #98a0ab;
  margin-left: 7px;
  font-size: 14px;
  line-height: 0.8;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  ${Header} {
    margin-bottom: 0;
  }
`;
