import { fromJS, Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { hasHtml } from "Libs/utils";

export const loadSshKeys = createAsyncThunk(
  "app/users/sshkeys/load",
  async () => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const keys = await client.getSshKeys();
    return keys;
  }
);

export const addSshKey = createAsyncThunk(
  "app/users/sshKeys/add",
  async ({ sshKey }, { rejectWithValue }) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const key = await client.addSshKey(sshKey.value, sshKey.title);
      return key;
    } catch (err) {
      if (![404, 403, 400].includes(err.code) && !hasHtml(err)) {
        logger(err, {
          action: "user_add_sshkey"
        });
      }
      return rejectWithValue({ errors: new Map(err) });
    }
  }
);

export const deleteSshKey = createAsyncThunk(
  "app/users/sshKeys/delete",
  async ({ id }, { getState, rejectWithValue }) => {
    try {
      const sshKey = getState().userSshKey.getIn(["data", id]);
      if (!sshKey) {
        throw Error("Ssh key not found");
      }
      await sshKey.delete();
      return sshKey;
    } catch (err) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        logger(err, {
          action: "user_delete_sshkey"
        });
      }
      return rejectWithValue({ errors: new Map(err) });
    }
  }
);

const sshKeys = createSlice({
  name: "sshkeys",
  initialState: Map({ data: new Map(), loading: false, status: "idle" }),
  reducers: {
    editLine(state, action) {
      return state.set("editedLine", action.payload.index);
    },
    cancelAddSshKey(state) {
      return state.set("editedLine", false).set("error", new Map());
    }
  },
  extraReducers: {
    // GET
    [loadSshKeys.pending]: state => state.delete("error").set("loading", true),
    [loadSshKeys.fulfilled]: (state, action) =>
      state
        .setIn(
          ["data"],
          fromJS(
            action.payload.reduce((list, key) => {
              list[key.key_id] = fromJS(key);
              return list;
            }, {})
          )
        )
        .set("loading", false),
    [loadSshKeys.rejected]: (state, action) =>
      state.set("error", new Map(action.payload)).set("loading", false),

    // ADD
    [addSshKey.pending]: state =>
      state
        .delete("error")
        .set("loading", true)
        .set("status", "pending"),
    [addSshKey.fulfilled]: (state, action) =>
      state
        .setIn(["data", action.payload.key_id], fromJS(action.payload))
        .set("editedLine", false)
        .set("loading", false)
        .set("status", "added"),
    [addSshKey.rejected]: (state, action) =>
      state
        .set("error", action.payload.errors)
        .set("loading", false)
        .set("status", "rejected"),

    // DELETE
    [deleteSshKey.pending]: state => state.delete("error").set("loading", true),
    [deleteSshKey.fulfilled]: (state, action) =>
      state.deleteIn(["data", action.payload.key_id]).set("loading", false),
    [deleteSshKey.rejected]: (state, action) =>
      state.set("error", action.payload.errors)
  }
});

export const { editLine, cancelAddSshKey } = sshKeys.actions;
export default sshKeys.reducer;
