import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import ModalWrapper from "Components/Modal";
import ModalBody from "Components/ModalBody";
import Heading3 from "Components/styleguide/Heading3";
import Button from "Components/Button";
import ButtonWrapper from "Components/ButtonWrapper";

class ModalBillingAdded extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalCustomClass: "modal-after-open",
      overlayCustomClass: "overlay-after-open"
    };
  }

  render() {
    const { closeModal, isOpen, intl } = this.props;
    return (
      <ModalWrapper
        modalClass={`modal-small`}
        isOpen={isOpen}
        closeModal={closeModal}
      >
        <ModalBody>
          <Heading3>
            {intl.formatMessage({ id: "billing_details_added" })}
          </Heading3>
          <p>{intl.formatMessage({ id: "billing_details_added_a" })}</p>
          <p>{intl.formatMessage({ id: "billing_details_added_b" })}</p>
          <ButtonWrapper className="modal-buttons">
            <Button
              id={`add-billing-modal-button`}
              type="submit"
              aria-label={intl.formatMessage({ id: "ok" })}
              onClick={() => {
                closeModal();
              }}
            >
              {intl.formatMessage({ id: "ok" })}
            </Button>
          </ButtonWrapper>
        </ModalBody>
      </ModalWrapper>
    );
  }
}

ModalBillingAdded.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  intl: PropTypes.object
};

export default injectIntl(ModalBillingAdded);
