import React from "react";

import withReducers from "Hocs/withReducers";

import Billing from "./containers/Billing";

const Page = () => {
  return <Billing />;
};

export default withReducers({
  setup: () => import("Reducers/project/setup"),
  plan: () => import("Reducers/plan"),
  order: () => import("Reducers/user/order"),
  profile: () => import("Reducers/profile")
})(Page);
