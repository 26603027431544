import React from "react";
import PropTypes from "prop-types";

import GenericError from "../Errors/GenericError";

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return { error };
  }

  state = { error: null };

  render() {
    if (this.state.error) {
      return <GenericError>{this.state.error.message}</GenericError>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default ErrorBoundary;
