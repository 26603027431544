import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";

import ModalWrapper from "Components/Modal";
import Button from "Components/Button";
import ButtonWrapper from "Components/ButtonWrapper";
import Error from "Components/Error";

const ModalBody = styled.div`
  margin-bottom: 48px;
  color: ${props => props.theme.sectionText};
`;

const DeleteMemberConfirmationModal = ({
  isOpen,
  deleteMember,
  closeModal,
  editedMember,
  users,
  errors
}) => {
  const intl = useIntl();

  const user = users?.get(editedMember?.user_id);

  return (
    <ModalWrapper
      modalClass="modal-small"
      isOpen={isOpen}
      closeModal={closeModal}
      title={intl.formatMessage({ id: "members.confirmatioDeletenModalTitle" })}
      id={"members-confirmation-delete-modal"}
    >
      <ModalBody className="modal-body">
        {errors && <Error>{errors}</Error>}
        <FormattedMessage
          id="members.confirmationDeleteModalBody"
          values={{
            email: user?.email
          }}
        />
      </ModalBody>
      <ButtonWrapper className="modal-buttons">
        <Button
          type="button"
          className="primary"
          aria-label={intl.formatMessage({ id: "yes" })}
          onClick={deleteMember}
        >
          <FormattedMessage id="yes" />
        </Button>
        <Button
          type="button"
          className="secondary"
          aria-label={intl.formatMessage({ id: "cancel" })}
          onClick={closeModal}
        >
          {intl.formatMessage({ id: "cancel" })}
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  );
};

DeleteMemberConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  errors: PropTypes.string,
  closeModal: PropTypes.func,
  deleteMember: PropTypes.func,
  editedMember: PropTypes.object,
  users: PropTypes.object
};

export default DeleteMemberConfirmationModal;
