import React from "react";

import withReducers from "Hocs/withReducers";

import OpenTicketForm from "./containers/OpenTicketForm/OpenTicketForm";

const Page = () => <OpenTicketForm />;

export default withReducers({
  openTicket: () => import("Reducers/tickets/open"),
  priorities: () => import("Reducers/tickets/priorities"),
  project: () => import("Reducers/project"),
  environment: () => import("Reducers/environment")
})(Page);
