import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import useRange from "../../hooks/useRange";
import useURLSearchState, { transforms } from "Hooks/useURLSearchState";

import Timeframe from "../../components/TimeframeSelector";
import LastUpdated from "../../components/LastUpdated";

import Cpu from "../Cpu";
import Memory from "../Memory";
import Disk from "../Disk";

import useMultiChart from "../../hooks/useMultiChart";
import sortAlphabetically from "../../utils/sortAlphabetically";

import * as V from "../../views/views.styles";
import * as S from "./Split.styles";

import { timeframes } from "../../settings";

const getHostsByType = dimensions => {
  const hosts = {
    unified: new Set(["average"]),
    web: new Set(["average"])
  };

  const mountpoints = {
    unified: new Set(),
    web: new Set()
  };

  dimensions?.forEach(dimension => {
    if (dimension && hosts[dimension.hostType]) {
      hosts[dimension.hostType].add(dimension.hostname);
      if (dimension.mountpoint) {
        mountpoints[dimension.hostType].add(dimension.mountpoint);
      }
    }
  });

  return [
    {
      unified: Array.from(hosts.unified),
      web: Array.from(hosts.web)
    },
    {
      unified: sortAlphabetically(Array.from(mountpoints.unified)),
      web: sortAlphabetically(Array.from(mountpoints.web))
    }
  ];
};
const query = {
  "cpu.average-value":
    "AVG(SUM((`cpu.user` + `cpu.kernel`) / `interval`, 'hostname'))",
  "cpu.average-percentage":
    "AVG(100 * SUM((`cpu.user` + `cpu.kernel`) / (`interval` * `cpu.cores`), 'hostname'))",
  "cpu.value": "SUM((`cpu.user` + `cpu.kernel`) / `interval`, 'hostname')",
  "cpu.percentage":
    "SUM(100 * (`cpu.user` + `cpu.kernel`) / ( `interval` * `cpu.cores`), 'hostname')",
  "cpu.max": "MAX(`cpu.cores`)",
  "memory.average-value":
    'AVG(SUM(`memory.apps` + `memory.kernel` + `memory.buffers`, "hostname"))',
  "memory.average-percentage":
    'AVG(SUM(100 * (`memory.apps` + `memory.kernel` + `memory.buffers`) / `memory.limit`, "hostname"))',
  "memory.value":
    'SUM(`memory.apps` + `memory.kernel` + `memory.buffers`, "hostname")',
  "memory.percentage":
    "SUM(100 * (`memory.apps` + `memory.kernel` + `memory.buffers`) / `memory.limit`, 'hostname')",
  "memory.max": "MAX(`memory.limit`)",
  "disk.average-value": "AVG(`disk.space.used`, 'mountpoint')",
  "disk.average-percentage":
    "AVG((`disk.space.used`/`disk.space.limit`)*100, 'mountpoint')",
  "disk.value": "`disk.space.used`",
  "disk.percentage": "(`disk.space.used`/`disk.space.limit`)*100",
  "disk.max": "AVG(`disk.space.limit`, 'mountpoint')"
};

const Split = ({ link, dimensions }) => {
  const [selectedTimeframe, setSelectedTimeframe] = useRange();

  const [hostTypes, mountpoints] = getHostsByType(dimensions);

  const [webHosts, setWebHosts] = useURLSearchState(
    hostTypes.web,
    "webHosts",
    transforms.array
  );

  const selectedWebHosts = useMemo(
    () => {
      return webHosts.map(host =>
        hostTypes.web.find(fullHost => fullHost.startsWith(host))
      );
    },
    [webHosts]
  );

  const [unifiedHosts, setUnifiedHosts] = useURLSearchState(
    hostTypes.unified,
    "unifiedHosts",
    transforms.array
  );

  const selectedUnifiedHosts = useMemo(
    () => {
      return unifiedHosts.map(host =>
        hostTypes.unified.find(fullHost => fullHost.startsWith(host))
      );
    },
    [unifiedHosts]
  );

  const range = useMemo(
    () => {
      if (selectedTimeframe.range) {
        return selectedTimeframe.range;
      }

      return { from: selectedTimeframe.from, to: selectedTimeframe.to };
    },
    [JSON.stringify(selectedTimeframe)]
  );

  const [web, isLoadingWeb] = useMultiChart(
    query,
    link.collection,
    link.href,
    selectedTimeframe.interval,
    range,
    { filters: [{ key: "hostType", value: "web" }] }
  );

  const [unified, isLoadingUnified] = useMultiChart(
    query,
    link.collection,
    link.href,
    selectedTimeframe.interval,
    range,
    { filters: [{ key: "hostType", value: "unified" }] }
  );

  const onBrush = useCallback(
    (from, to) => {
      setSelectedTimeframe({ from, to });
    },
    [setSelectedTimeframe]
  );

  const onTimeframeChange = useCallback(
    timeframe => setSelectedTimeframe(timeframe.label),
    [setSelectedTimeframe]
  );
  const lastDataPoint = web?.cpu?.last.timestamp;
  const firstDataPoint = web?.cpu?.data[0] && web.cpu.data[0].timestamp;

  return (
    <>
      <V.Header>
        <V.Heading>
          <FormattedMessage id="metrics.split.heading" />
        </V.Heading>
        <V.TimeframeColumn>
          {selectedTimeframe && (
            <LastUpdated
              interval={selectedTimeframe.interval}
              lastDataPoint={lastDataPoint}
              isCustomTimeframe={!selectedTimeframe.id}
            />
          )}
          <V.Row style={{ marginLeft: "auto" }}>
            <Timeframe
              selectedTimeframe={selectedTimeframe}
              timeframes={timeframes}
              onChange={onTimeframeChange}
              to={lastDataPoint}
              from={firstDataPoint}
            />
          </V.Row>
        </V.TimeframeColumn>
      </V.Header>
      <S.Layout>
        <S.Column>
          <S.HostHeader>
            <S.HostType>
              <FormattedMessage id="metrics.split.web" />{" "}
              <S.HostCount>({hostTypes.web.length - 1})</S.HostCount>
            </S.HostType>
            <S.HostSelector
              hosts={hostTypes.web}
              selected={webHosts}
              onChange={e => setWebHosts(e)}
            />
          </S.HostHeader>
          <S.ChartContainer>
            <Cpu
              hosts={selectedWebHosts}
              data={web.cpu}
              isLoading={isLoadingWeb}
              timeframe={selectedTimeframe}
              onBrush={onBrush}
            />
            <Memory
              hosts={selectedWebHosts}
              data={web.memory}
              isLoading={isLoadingWeb}
              timeframe={selectedTimeframe}
              onBrush={onBrush}
            />
            {mountpoints.web.map(
              mountpoint =>
                web.disks &&
                web.disks[mountpoint] && (
                  <Disk
                    diskPerRow={1}
                    key={mountpoint}
                    hosts={selectedWebHosts}
                    mountpoint={mountpoint}
                    isLoading={isLoadingWeb}
                    data={web.disks[mountpoint]}
                    timeframe={selectedTimeframe}
                    onBrush={onBrush}
                  />
                )
            )}
          </S.ChartContainer>
        </S.Column>
        <S.Column>
          <S.HostHeader>
            <S.HostType>
              <FormattedMessage id="metrics.split.unified" />{" "}
              <S.HostCount>({hostTypes.unified.length - 1})</S.HostCount>
            </S.HostType>
            <S.HostSelector
              hosts={hostTypes.unified}
              selected={unifiedHosts}
              onChange={e => setUnifiedHosts(e)}
            />
          </S.HostHeader>
          <S.ChartContainer>
            <Cpu
              hosts={selectedUnifiedHosts}
              data={unified.cpu}
              isLoading={isLoadingUnified}
              timeframe={selectedTimeframe}
              onBrush={onBrush}
            />
            <Memory
              hosts={selectedUnifiedHosts}
              data={unified.memory}
              isLoading={isLoadingUnified}
              timeframe={selectedTimeframe}
              onBrush={onBrush}
            />
            {mountpoints.unified.map(
              mountpoint =>
                unified.disks &&
                unified.disks[mountpoint] && (
                  <Disk
                    diskPerRow={1}
                    key={mountpoint}
                    hosts={selectedUnifiedHosts}
                    mountpoint={mountpoint}
                    isLoading={isLoadingUnified}
                    data={unified.disks[mountpoint]}
                    timeframe={selectedTimeframe}
                    onBrush={onBrush}
                  />
                )
            )}
          </S.ChartContainer>
        </S.Column>
      </S.Layout>
    </>
  );
};

Split.propTypes = {
  link: PropTypes.shape({
    collection: PropTypes.string,
    href: PropTypes.string
  }),
  dimensions: PropTypes.array
};

export default Split;
