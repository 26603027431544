import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

export default styled.a`
  background-color: ${props => themeHelpers(props.theme, "buttonColor")};
  color: ${props => props.theme.buttonText};
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  position: absolute;
  top: -45px;
  left: 50%;
  margin-left: -100px;
  display: block;
  text-decoration: none;
  width: 200px;
  z-index: 10;
  &:hover {
    top: 0;
    outline: none;
    background-color: ${props => themeHelpers(props.theme, "buttonHover")};
  }
  &:focus {
    top: 0;
    outline: none;
    border: 1px solid ${props => themeHelpers(props.theme, "buttonHover")};
    box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "buttonHover")};
  }
`;
