import styled from "styled-components";

import ContentLayout from "Components/ContentLayout";
import QuickLinks from "../../components/QuickLinks";
import MoreHelp from "../../components/MoreHelp";
import TicketTable from "../../components/Table";
import SystemStatus from "../../components/SystemStatus";

export const TicketLayout = styled(ContentLayout)`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }

  &,
  * {
    box-sizing: border-box;
  }
`;

export const TicketTableLayout = styled(TicketTable)`
  margin-bottom: 32px;
  padding: 24px;

  @media screen and (min-width: 768px) {
    padding: 32px;
  }

  @media screen and (min-width: 1200px) {
    width: calc(70% - 16px);
    margin-right: 32px;
  }
`;

export const SystemStatusLayout = styled(SystemStatus)``;

export const QuikcLinksLayout = styled(QuickLinks)`
  margin-bottom: 32px;
  padding: 32px 24px;

  @media screen and (min-width: 768px) {
    flex: calc(50% - 32px);
    padding: 16px;
    padding: 32px;
  }
  @media screen and (min-width: 1200px) {
    margin-right: 0;
  }
`;

export const MoreHelpLayout = styled(MoreHelp)`
  flex: 100%;
`;

export const SubGrid = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 1200px) {
    width: calc(30% - 16px);
    display: block;
  }
`;
