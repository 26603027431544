import styled, { css } from "styled-components";
import { semiBoldAlias, makePalette } from "Libs/theme";

const getStroke = (kind, theme) => {
  switch (kind) {
    case "overcommit":
      return theme.threshold__max;
    case 1:
    case 0.87:
      return theme.threshold__max;
    case 0.75:
      return theme.threshold__warning;
    default:
      return theme.threshold__none;
  }
};

const getStrokeDashArray = kind => {
  switch (kind) {
    case "overcommit":
    case 0.87:
    case 0.75:
      return "4 10";
    default:
      return "none";
  }
};

export const colorDefinitions = palette => ({
  left_gradient: {
    default: palette.snow,
    dark: palette.ebony
  },
  average_gradient__stop_1: {
    default: palette.slate,
    dark: palette.snow,
    contrast: "#01001a"
  },
  host_gradient__stop_1: {
    default: palette.granite,
    contrast: "#8c94a1"
  },
  threshold__warning: {
    default: palette.gold,
    contrast: "#b88d00"
  },
  threshold__max: {
    default: palette.red,
    contrast: "#e50000"
  },
  threshold__none: {
    default: palette.periwinkleGrey
  },
  text: {
    default: palette.granite,
    dark: palette.periwinkleGrey,
    contrast: palette.ebonyLight
  }
});

const theme = makePalette(colorDefinitions);

export const YAxis = styled.g``;

export const Tick = styled.text`
  stroke-width: 0.5;
  font-size: 10px;
  ${semiBoldAlias()};
  user-select: none;
  line-height: 22px;
  fill: ${theme.text};
  pointer-events: none;
`;

export const Threshold = styled.path`
  stroke: ${({ kind }) => getStroke(kind, theme)};
  stroke-dasharray: ${({ kind }) => getStrokeDashArray(kind)};
  fill: none;
  stroke-width: 0.5;
  pointer-events: none;
`;

const __label = css`
  ${semiBoldAlias()};
  font-size: 10px;
  line-height: 22px;
  text-align: right;
  text-transform: lowercase;
  text-anchor: end;
  pointer-events: none;
  user-select: none;
`;

export const CriticalLabel = styled.text`
  ${__label};
  fill: ${theme.threshold__max};
`;

export const WarningLabel = styled.text`
  ${__label};
  fill: ${theme.threshold__warning};
`;
