import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Map } from "immutable";

import { hasSafeRole, getTrial } from "Libs/utils";

import {
  loadSubscription,
  subscriptionSelector
} from "Reducers/organization/subscription";
import { loadProject } from "Reducers/project";
import withReducers from "Hocs/withReducers";
import { organizationByDescriptionIdSelector } from "Reducers/organization";

import ProjectPlanForm from "./containers/ProjectPlanForm";
import ErrorBoundary from "Components/ErrorBoundary";
import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import ListGroup from "Components/ListGroup";
import Description from "Components/fields/Description";
import BackLink from "Components/BackLink";
import ButtonLink from "Components/ButtonLink";

import * as S from "./styles";

const PlanDetail = () => {
  const { organizationId: organizationName, subscriptionId } = useParams();
  const [hasPaymentSource, setPaymentSource] = useState();

  const intl = useIntl();
  const dispatch = useDispatch();

  const user = useSelector(state => state.app?.get("me", new Map())?.toJS());
  const projectId = useSelector(state => {
    return subscriptionSelector(state, {
      organizationId: organizationName,
      subscriptionId
    });
  })?.project_id;
  const project = useSelector(state =>
    state.project?.getIn(["data", organizationName, projectId], null)
  );
  const organizations = useSelector(state =>
    state.organization?.get("data", new Map())
  );

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationName
    })
  );
  const orgId = organization?.id;

  useEffect(() => {
    let isCanceled = false;
    const getPaymentSource = async () => {
      if (hasPaymentSource !== undefined) return;
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const ps = await client.getOrganizationPaymentSource(orgId);
      if (isCanceled) {
        return;
      }
      setPaymentSource(!!ps.type);
    };
    if (!hasSafeRole(user.roles)) getPaymentSource();

    return () => (isCanceled = true);
  }, []);

  useEffect(
    () => {
      if (subscriptionId && organization)
        dispatch(
          loadSubscription({
            organizationId: organizationName,
            id: subscriptionId
          })
        );
    },
    [subscriptionId, organization]
  );

  useEffect(
    () => {
      if (projectId) dispatch(loadProject(projectId));
    },
    [projectId]
  );

  const showBanner = (() => {
    if (hasSafeRole(user.roles)) return false;
    if (
      !getTrial(user, project, organizations.toJS()) &&
      hasPaymentSource === false
    )
      return true;

    const { current_trial } = user.data;
    if (
      current_trial?.active &&
      moment(current_trial?.expiration).isSameOrBefore(moment())
    )
      return true;

    return false;
  })();

  return (
    <ContentLayout className="settings-content">
      <ErrorBoundary>
        <S.HeaderWrapper>
          <BackLink to={`/${organizationName}/-/billing/plan`} from="plans" />

          <S.HeaderContent>
            <Heading2 id="plan-heading">{project?.title}</Heading2>
          </S.HeaderContent>
        </S.HeaderWrapper>

        {showBanner && (
          <S.Banner level="warning">
            <p>
              {intl.formatMessage({
                id: "settings.plan.banner.trial",
                defaultMessage:
                  "To upgrade your plan you need to add a payment method to your billing details."
              })}
            </p>
            <ButtonLink
              to={`/${organizationName}/-/billing`}
              text={intl.formatMessage({
                id: "settings.plan.banner.button",
                defaultMessage: "Add billing details"
              })}
            />
          </S.Banner>
        )}

        <ListGroup>
          {projectId && (
            <ProjectPlanForm
              hasPaymentSource={hasPaymentSource}
              organizationName={organizationName}
              organizationId={orgId}
              projectId={projectId}
              subscriptionId={subscriptionId}
              user={user}
            />
          )}
        </ListGroup>

        <Description>
          {intl.formatMessage({
            id: "settings.plan.downgrade_warning",
            defaultMessage:
              "* Note that you can only upgrade storage at this time. Downgrades can be requested by submitting a"
          })}{" "}
          <a
            href={
              process.env.CUSTOM_SUPPORT_URL
                ? process.env.CUSTOM_SUPPORT_URL
                : `${process.env.ACCOUNTS_URL}/support`
            }
          >
            {intl.formatMessage({ id: "support_ticket" })}
          </a>.
        </Description>
      </ErrorBoundary>
    </ContentLayout>
  );
};

export default withReducers({
  project: () => import("Reducers/project"),
  organizationSubscription: () => import("Reducers/organization/subscription")
})(PlanDetail);
