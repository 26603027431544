import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Description, Layout, StyledPriority } from "./styles";

const PriorityOption = ({ innerProps, data, getValue }) => {
  const currentValue = getValue()[0];

  return (
    <Layout isActive={currentValue?.value == data.value} {...innerProps}>
      <StyledPriority priority={data.value} />
      <Description>
        <FormattedMessage id={`tickets.priority.${data.value}.description`} />
      </Description>
    </Layout>
  );
};

PriorityOption.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string
  }),
  getValue: PropTypes.func,
  innerProps: PropTypes.object
};

export default PriorityOption;
