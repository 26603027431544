import styled from "styled-components";

import { semiBoldAlias, themeHelpers } from "Libs/theme";

import Heading6 from "Components/styleguide/Heading6";
import Button from "Components/Button";
import Label from "Components/fields/Label";

export const Layout = styled.div`
  background: ${props => themeHelpers(props.theme, "sectionColor")};
  padding: 24px 32px;
  border-radius: 4px;
`;

export const EnvironmentPermissions = styled.div`
  hr {
    margin: 0;
    background: ${props => props.theme.sectionBorder};
  }
`;

export const EnvironmentHeaders = styled.div`
  display: flex;
  align-items: center;
  label + label {
    width: 33%;
  }
`;

export const PermissionWrapper = styled.div`
  display: flex;
  align-items: center;
  label + label {
    width: 33%;
  }
  label {
    font-size: 13px;
    ${semiBoldAlias()};
    height: 72px;
    line-height: 56px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
  }
`;

export const InputLayout = styled.div`
  display: flex;
  align-items: center;
`;

export const TableLine = styled.hr`
  margin-top: 0;
`;

export const ResendButton = styled(Button)`
  padding: 0px 8px;

  outline: none !important;
  border: 0 !important;

  &:hover,
  &:active,
  &:focus {
    outline: none !important;
    border: 0 !important;
    margin: 0 !important;
  }
`;

export const RevokeButton = styled(Button)`
  margin-top: 40px !important;
`;

export const EnvironmentTypeLabel = styled(Label)`
  min-width: 200px;
`;

export const TitlePermissionLabel = styled(Label)`
  max-width: 299px;
`;

export const PermissionLabel = styled(Label)`
  max-width: 200px;
  background: ${props => props.theme.inputBg};
  border-radius: 2px;
  padding: 10px 18px;
  max-height: 20px !important;
  cursor: not-allowed;
  font-size: 15px !important;
  line-height: 20px !important;
  font-style: normal !important;
  font-weight: normal !important;
  color: ${props => props.theme.cancelled};
`;

export const Title = styled(Heading6)`
  ${semiBoldAlias()};
  font-size: 14px;
  line-height: 24px;
`;

export const EnvironmentsListWrapper = styled.div`
  margin-left: 66px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  h6 + div {
    margin-left: 12px;
  }
`;
