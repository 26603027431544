import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import withReducers from "Hocs/withReducers";
import { organizationByDescriptionIdSelector } from "Reducers/organization";

import Vouchers from "./containers/Vouchers";
import Layout from "../components/Layout";
import ContentLayout from "Components/ContentLayout";

const Page = () => {
  const { organizationId } = useParams();

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );
  const orgId = organization?.id;

  return (
    <Layout>
      <ContentLayout id="settings-billing" className="settings-content">
        <Vouchers organizationId={orgId} />
      </ContentLayout>
    </Layout>
  );
};

export default withReducers({
  setup: () => import("Reducers/project/setup"),
  plan: () => import("Reducers/plan"),
  organizationOrder: () => import("Reducers/organization/order"),
  profile: () => import("Reducers/profile")
})(Page);
