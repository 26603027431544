import styled from "styled-components";
import { breakpoints } from "Libs/theme";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-bottom: 0;

  @media (min-width: ${breakpoints[1]}) {
    margin-bottom: 0;
  }

  .icon-wrapper {
    height: 19px;
    margin-bottom: 16px;

    @media (min-width: ${breakpoints[1]}) {
      margin-bottom: 30px;
    }
  }

  strong {
    &:first-letter {
      text-transform: uppercase;
    }
  }

  span {
    margin-top: 8px;
    text-align: center;
    flex: 1;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: ${props => props.theme.paymentCardSubtext};
    &:first-letter {
      text-transform: uppercase;
    }

    @media (min-width: ${breakpoints[1]}) {
      flex: 0;
      margin-bottom: 24px;
    }
  }
  .icon-wrapper svg path {
    fill: ${props => props.theme.labelLight};
  }
`;
