import { Map } from "immutable";
import client from "Libs/platform";

const initialState = Map({
  user: Map({
    data: null,
    error: null,
    isLoading: false
  }),
  setup: Map({
    data: null,
    error: null,
    isLoading: false
  }),
  recoveryCodes: Map({
    data: null,
    error: null,
    isLoading: false
  })
});

const getErrorMessage = error => {
  let message;
  if (typeof error === "string") {
    message = JSON.parse(error).error;
  } else if (typeof error === "object") {
    message = error.error || error.message || error;
  }
  return message;
};

const LOAD_AUTH_USER_START =
  "organization/settings/security/load_auth_user_start";
const LOAD_AUTH_USER_SUCCESS =
  "organization/settings/security/load_auth_user_success";
const LOAD_AUTH_USER_FAILURE =
  "organization/settings/security/load_auth_user_failure";

const loadAuthUserStart = () => ({ type: LOAD_AUTH_USER_START });

const loadAuthUserSuccess = user => ({
  type: LOAD_AUTH_USER_SUCCESS,
  payload: user
});

const loadAuthUserError = error => ({
  type: LOAD_AUTH_USER_FAILURE,
  payload: error
});

export const loadAuthUser = userId => async dispatch => {
  dispatch(loadAuthUserStart());
  try {
    const user = await client.getUser(userId);
    dispatch(loadAuthUserSuccess(user));
  } catch (error) {
    dispatch(loadAuthUserError(error.message));
  }
};

const SETUP_START = "organization/settings/security/setup_start";
const SETUP_SUCCESS = "organization/settings/security/setup_success";
const SETUP_FAILURE = "organization/settings/security/setup_failure";

const setupStart = () => ({ type: SETUP_START });
const setupSucess = tfaInfo => ({ type: SETUP_SUCCESS, payload: tfaInfo });
const setupFailure = error => ({ type: SETUP_FAILURE, payload: error });

export const setup = userId => async dispatch => {
  dispatch(setupStart());
  try {
    const tfaInfo = await client.getTFA(userId);
    dispatch(setupSucess(tfaInfo));
  } catch (error) {
    dispatch(setupFailure(getErrorMessage(error)));
  }
};

const ENROLL_START = "organization/settings/security/enroll_start";
const ENROLL_SUCESS = "organization/settings/security/enroll_success";
const ENROLL_FAILURE = "organization/settings/security/enroll_failure";

const enrollStart = () => ({ type: ENROLL_START });
const enrollSucess = recoveryCodes => ({
  type: ENROLL_SUCESS,
  payload: recoveryCodes
});
const enrollfailure = error => ({
  type: ENROLL_FAILURE,
  payload: error
});

export const enroll = (userId, secret, passcode) => async dispatch => {
  dispatch(enrollStart());
  try {
    const { recovery_codes } = await client.enrollTFA(userId, secret, passcode);
    dispatch(enrollSucess(recovery_codes));
    loadAuthUser(userId)(dispatch);
  } catch (error) {
    dispatch(enrollfailure(getErrorMessage(error)));
  }
};

const RESET_RECOVERY_CODES_START =
  "organization/settings/security/reset_recovery_codes_start";
const RESET_RECOVERY_CODES_SUCCESS =
  "organization/settings/security/reset_recovery_codes_success";
const RESET_RECOVERY_CODES_FAILURE =
  "organization/settings/security/reset_recovery_codes_failure";

const resetRecoveryCodesStart = () => ({
  type: RESET_RECOVERY_CODES_START
});

const resetRecoveryCodesSuccess = recoveryCodes => ({
  type: RESET_RECOVERY_CODES_SUCCESS,
  payload: recoveryCodes
});

const resetRecoveryCodesFailure = error => ({
  type: RESET_RECOVERY_CODES_FAILURE,
  payload: error
});

export const resetRecoveryCodes = userId => async dispatch => {
  dispatch(resetRecoveryCodesStart());
  try {
    const { recovery_codes } = await client.resetRecoveryCodes(userId);
    dispatch(resetRecoveryCodesSuccess(recovery_codes));
  } catch (error) {
    dispatch(
      resetRecoveryCodesFailure(error?.error || error?.message || error)
    );
  }
};

const DISABLE_TFA_START = "organization/settings/security/disable_tfa_start";
const DISABLE_TFA_SUCCESS =
  "organization/settings/security/disable_tfa_success";
const DISABLE_TFA_FAILURE =
  "organization/settings/security/disable_tfa_failure";

const disableTFAStart = () => ({ type: DISABLE_TFA_START });
const disableTFASuccess = () => ({ type: DISABLE_TFA_SUCCESS });
const disableTFAFailure = error => ({
  type: DISABLE_TFA_FAILURE,
  payload: error
});

export const disableTFA = userId => async dispatch => {
  dispatch(disableTFAStart());
  try {
    await client.disableTFA(userId);
    dispatch(disableTFASuccess());
    loadAuthUser(userId)(dispatch);
  } catch (error) {
    dispatch(disableTFAFailure(error));
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_AUTH_USER_START:
      return state.setIn(["user", "isLoading"], true);
    case LOAD_AUTH_USER_SUCCESS:
      return state
        .setIn(["user", "data"], action.payload)
        .setIn(["user", "isLoading"], false);
    case LOAD_AUTH_USER_FAILURE:
      return state
        .setIn(["user", "error"], action.payload)
        .setIn(["user", "isLoading"], false);
    case SETUP_START:
      return state
        .setIn(["setup", "data"], action.payload)
        .setIn(["setup", "error"], null)
        .setIn(["setup", "isLoading"], true);
    case SETUP_SUCCESS:
      return state
        .setIn(["setup", "data"], action.payload)
        .setIn(["setup", "isLoading"], false);
    case SETUP_FAILURE:
      return state
        .setIn(["setup", "error"], action.payload)
        .setIn(["setup", "isLoading"], false);
    case ENROLL_FAILURE:
    case RESET_RECOVERY_CODES_FAILURE:
      return state
        .setIn(["recoveryCodes", "isLoading"], false)
        .setIn(["recoveryCodes", "error"], action.payload);
    case ENROLL_START:
    case RESET_RECOVERY_CODES_START:
      return state
        .setIn(["recoveryCodes", "isLoading"], true)
        .setIn(["recoveryCodes", "error"], null);
    case RESET_RECOVERY_CODES_SUCCESS:
    case ENROLL_SUCESS:
      return state
        .setIn(["recoveryCodes", "data"], action.payload)
        .setIn(["recoveryCodes", "isLoading"], false);
    case DISABLE_TFA_SUCCESS:
      return state
        .setIn(["recoveryCodes", "data"], null)
        .setIn(["setup", "data"], null);
    default:
      return state;
  }
};

export default reducer;
