import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import { loadAllAttachments } from "Reducers/tickets/ticket";

import Pane from "../../../../common/components/Pane";
import Header from "../../../../common/components/Header";
import Attachment from "../../../../common/components/Attachment";
import AttachmentList from "../../../../common/components/AttachmentList";

const AllAttachments = ({ ticketId }) => {
  const dispatch = useDispatch();

  const attachments = useSelector(({ view }) =>
    view.ticket?.get("attachments")
  );
  const newComment = useSelector(({ view }) =>
    view?.comments?.get("newComment")
  );

  useEffect(
    () => {
      if (newComment?.comment_id) {
        dispatch(loadAllAttachments(ticketId));
      }
    },
    [newComment]
  );

  useEffect(
    () => {
      dispatch(loadAllAttachments(ticketId));
    },
    [ticketId]
  );

  return attachments?.size ? (
    <Pane>
      <Header>
        <FormattedMessage id="tickets.open.attachments" />
      </Header>
      <AttachmentList>
        {attachments.map(({ url, filename }) => (
          <Attachment
            as="a"
            target="_blank"
            href={url}
            key={url}
            hasPaperclip
            name={filename}
          />
        ))}
      </AttachmentList>
    </Pane>
  ) : null;
};

AllAttachments.propTypes = {
  ticketId: PropTypes.string
};

export default AllAttachments;
