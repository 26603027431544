import { DOCS_INTEGRATIONS_ACTIVITY } from "Constants/documentationUrls";

const thirdParties = [
  // GIT
  {
    type: "bitbucket",
    base_url: "https://bitbucket.com",
    category: "git",
    fields: [
      {
        id: "repository",
        required: true
      },
      {
        id: "app_credentials",
        type: "object",
        optional: true,
        fields: [
          {
            id: "key",
            required: true,
            sensitive: true
          },
          {
            id: "secret",
            required: true,
            sensitive: true
          }
        ]
      }
    ],
    options: [
      "fetch_branches",
      "prune_branches",
      "resync_pull_requests",
      "build_pull_requests"
    ]
  },
  {
    type: "bitbucket_server",
    category: "git",
    fields: [
      {
        id: "url",
        required: true
      },
      {
        id: "username",
        required: true
      },
      {
        id: "token",
        required: true,
        sensitive: true
      },
      {
        id: "project",
        required: true
      },
      {
        id: "repository",
        required: true
      }
    ],
    options: [
      "fetch_branches",
      "prune_branches",
      "pull_requests_clone_parent_data",
      "build_pull_requests"
    ]
  },
  {
    type: "github",
    base_url: "https://github.com",
    link: "https://github.com/settings/tokens/new",
    category: "git",
    fields: [
      {
        id: "token",
        required: true,
        sensitive: true
      },
      {
        id: "repository",
        required: true
      },
      {
        id: "base_url"
      }
    ],
    options: [
      "fetch_branches",
      "prune_branches",
      "build_draft_pull_requests",
      "pull_requests_clone_parent_data",
      "build_pull_requests",
      "build_pull_requests_post_merge"
    ]
  },
  {
    type: "gitlab",
    base_url: "https://gitlab.com",
    link: "https://gitlab.com/-/profile/personal_access_tokens",
    category: "git",
    fields: [
      {
        id: "token",
        required: true,
        sensitive: true
      },
      {
        id: "project",
        required: true
      },

      {
        id: "base_url"
      }
    ],
    options: [
      "fetch_branches",
      "prune_branches",
      "build_wip_merge_requests",
      "build_merge_requests",
      "merge_requests_clone_parent_data"
    ]
  },
  // HEALTH
  {
    type: "health.email",
    category: "health",
    fields: [
      {
        id: "from_address"
      },
      {
        id: "recipients",
        type: "array", // [string]
        separator: ",",
        required: true,
        kind: "textarea"
      }
    ]
  },
  {
    type: "health.pagerduty",
    link: "https://support.pagerduty.com/docs/services-and-integrations",
    category: "health",
    fields: [
      {
        id: "routing_key",
        required: true
      }
    ]
  },
  {
    type: "health.slack",
    link: "https://api.slack.com/bot-users",
    category: "health",
    fields: [
      {
        id: "token",
        required: true
      },
      {
        id: "channel",
        required: true
      }
    ]
  },
  {
    type: "health.webhook",
    category: "health",
    fields: [
      {
        id: "url",
        required: true
      },
      {
        id: "shared_key"
      }
    ]
  },
  // CUSTOM
  {
    type: "webhook",
    category: "custom",
    fields: [
      {
        id: "url",
        required: true
      },
      {
        id: "shared_key"
      },
      {
        id: "excluded_environments",
        type: "array", // [string]
        separator: ","
      },
      {
        id: "states",
        type: "array", // [string]
        separator: ",",
        default: "complete"
      },
      {
        id: "environments",
        type: "array", // [string]
        separator: ",",
        default: "*"
      },
      {
        id: "events",
        type: "array", // [string]
        separator: ",",
        default: "*"
      }
    ]
  },
  {
    type: "script",
    link: DOCS_INTEGRATIONS_ACTIVITY,
    category: "custom",
    fields: [
      {
        id: "script",
        kind: "ide",
        required: true
      },
      {
        id: "events",
        type: "array", // [string]
        separator: ",",
        default: "*"
      },
      {
        id: "states",
        type: "array", // [string]
        separator: ",",
        default: "complete"
      },
      {
        id: "environments",
        type: "array", // [string]
        separator: ",",
        default: "*"
      },
      {
        id: "excluded_environments",
        type: "array", // [string]
        separator: ","
      }
    ]
  }
];

export default thirdParties;
