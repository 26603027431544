import styled from "styled-components";

export const Layout = styled.div`
  padding: 0;
  .rccs {
    float: right;
  }
  button + button {
    margin-left: 10px;
    &:focus,
    &.secondary:focus {
      margin-left: 9px;
    }
    &:active,
    &:active:focus {
      margin-left: 10px;
    }
  }
  .action-buttons-wrapper {
    margin-top: 32px;
  }
`;

export const PaymentWrapper = styled.div`
  h3 {
    margin-top: 0;
    font-size: 15px;
    line-height: 33px;
  }
`;

export const AddressWrapper = styled.form`
  width: 316px;
  .field {
    width: 100%;
    &.administrative_area {
      display: inline-block;
    }
    &.postal_code {
      display: inline-block;
      width: calc(40% - 10px);
      float: right;
    }
    input {
      font-size: 15px;
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      &::placeholder {
        color: ${props => props.theme.subtitleText};
        opacity: 1;
      }
    }
    .Select-placeholder {
      font-size: 14px;
      color: #4a4a4a;
      opacity: 0.4;
    }
  }
  .dropdown {
    margin-bottom: 16px;
    .select-box,
    .select-box__control,
    .select-box__value-container {
      height: 40px;
    }
  }
  .inline-fields {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .input-wrapper {
    width: 100% !important;
  }
  .select-box {
    width: 100% !important;
  }
  @media (min-width: 1440px) {
    display: grid;
    grid-template-columns: 316px 316px;
    grid-column-gap: 32px;
    width: auto;
  }
`;

export const InlineFields = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .input-field {
    width: calc(43% - 30px);
    &:first-child {
      width: 57%;
      margin-right: 20px;
    }
  }
`;
