export const debounce = fn => {
  let timer;

  return (...args) => {
    if (timer) {
      window.cancelAnimationFrame(timer);
    }

    timer = window.requestAnimationFrame(() => fn.apply(null, args));
  };
};
