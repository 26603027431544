import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  color: ${props => props.theme.sectionTextLight};
  justify-content: center;
  align-items: center;
  width: 86px;

  &.active,
  &.complete:hover,
  &.complete:focus {
    cursor: pointer;
    outline: none;
    .name {
      color: ${props => props.theme.links};
      position: relative;
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background: ${props => props.theme.links};
      }
    }
  }
  &.complete {
    .name {
      color: ${props => props.theme.sectionHeading};
    }
  }
  .icon-success-wrapper {
    height: 16px;
    width: 16px;
    padding: 0;
    margin-right: 8px;
  }
  @media (min-width: 1280px) {
    width: 116px;
  }
`;

export const Name = styled.div`
  color: ${props => props.theme.sectionTextLight};
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
`;
