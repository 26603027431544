import styled from "styled-components";

import { EmptyMetrics as EmptyMetricsIllustration } from "Components/illustrations";
import Heading2 from "Components/styleguide/Heading2";
import Heading3 from "Components/styleguide/Heading3";

export const Layout = styled.div`
  margin: 0 auto;
  max-width: 100%;
  text-align: center;
`;

export const H3 = styled(Heading3)`
  line-height: 24px;
  margin-bottom: 16px;
`;

export const Paragraph = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 15px;
  line-height: 24px;
`;

export const Illustration = styled(EmptyMetricsIllustration)`
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const H2 = styled(Heading2)`
  margin-bottom: 0;
`;
